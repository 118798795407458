import React, { Component } from 'react'
import './Notification.css'

export default class Notification extends Component {
    render() {
        return (
            <div className='notification d-flex justify-content-center align-items-center'
                style={{ height: this.props?.size, width: this.props?.size, top: this.props?.top, fontSize: this.props?.fontSize, color:'#000' }}>
                {this.props?.count}
            </div>
        )
    }
}
