import React, { Component } from "react";
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import {
  Button,
  Card,
  Carousel,
  Col,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { Link } from "react-router-dom";
import {
  CardComponent,
  Footer,
  RoundedProfile,
  ProfileComponent,
  NavbarMenu,
  Loader,
  NoData,
} from "../../Components";
import { environment } from "../../environment/env";
import { referFriend, storeBanner } from "../../assets";
import { Navigate } from "react-router-dom";
import {
  appStore,
  getAppImage1,
  getAppImage2,
  getAppImage3,
  playStore,
  getAppBanner,
} from "../../assets";

import "./Matches.css";
import { Box } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";

const baseURL = environment.hostname;
const storyImageURL = environment.storyImageURL;
let latestProfiles,
  matchingProfiles,
  premiumProfiles,
  premiumProfilesToDisplay,
  whoShortlisted,
  whoViewed,
  ignoredProfiles,
  adminRecommendedProfiles,
  recentlyViewedProfiles,
  contactsViewedProfiles,
  plan,
  listValues = [];
let successStories = [];

export default class Matches extends Component {
  _isMounted = false;
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      showAddStoryModal: false,
      weddingPhoto: "",
      body: "",
      showReferModal: false,
      prefix: "",
      number: "",
      isLoading: true,
      currentTab: "All",
      redirectPage: false,
      hidePopUpModal: null,
    };
    this.showAllProfiles = this.showAllProfiles.bind(this);
    this.showLocalProfiles = this.showLocalProfiles.bind(this);
    this.showProfessionProfiles = this.showProfessionProfiles.bind(this);
    this.showEducationProfiles = this.showEducationProfiles.bind(this);
    this.showLocationProfiles = this.showLocationProfiles.bind(this);
  }

  componentDidMount() {
    this.hasOneDayPassed();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this._isMounted = true;
    let premiumProfileApi = fetch(`${baseURL}/featuredmembers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let latestProfileApi = fetch(`${baseURL}/latestprofiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let matchApi = fetch(`${baseURL}/matching`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
        filter: "0",
      }),
      headers: {
        "Content-type": "application/json",
      },
    });
    let adminRecommendApi = fetch(`${baseURL}/admin_recommented`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });
    let recentlyViewedApi = fetch(`${baseURL}/viewed_profiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let contactsViewedApi = fetch(`${baseURL}/viewed_contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });
    let successStoriesApi = fetch(`${baseURL}/success_stories_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let listValueApi = fetch(`${baseURL}/list_value`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let planApi = fetch(`${baseURL}/myplan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });
    let ignoredProfileApi = fetch(`${baseURL}/ignored_profiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let whoShortlistApi = fetch(`${baseURL}/who_shortlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let whoViewApi = fetch(`${baseURL}/who_viewed_contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });
    let loginUpdate = fetch(`${baseURL}/loginupdate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    Promise.all([
      premiumProfileApi,
      latestProfileApi,
      matchApi,
      adminRecommendApi,
      recentlyViewedApi,
      contactsViewedApi,
      successStoriesApi,
      listValueApi,
      planApi,
      whoShortlistApi,
      whoViewApi,
      ignoredProfileApi,
    ])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        if (response[3] === 401) {
          localStorage.clear();

          window.location.assign("/#/login");
        } else {
          premiumProfiles = response[0];
          premiumProfilesToDisplay = premiumProfiles.slice(0, 6);
          latestProfiles = response[1];
          matchingProfiles = response[2];
          adminRecommendedProfiles = response[3];
          recentlyViewedProfiles = response[4];
          contactsViewedProfiles = response[5];
          successStories = response[6];
          listValues = response[7][0];
          plan = response[8][0];
          whoShortlisted = response[9];
          whoViewed = response[10];
          ignoredProfiles = response[11];
          localStorage.setItem("plan", plan.pid);
          localStorage.setItem("planName", plan.planname);
          this.setState({
            isLoading: false,
          });

          this.updateLogin();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.scrollTo(0, 50);
  }

  hasOneDayPassed() {
    window.scrollTo(0, 50);
    var date = new Date().toLocaleDateString();

    if (localStorage.getItem("today") == date) {
      this.setState({ hidePopUpModal: false });
    } else {
      localStorage.setItem("today", date);
      this.setState({ hidePopUpModal: window.innerWidth < 576 ? true : false });
    }
  }

  async showAllProfiles() {
    await fetch(`${baseURL}/matching`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
        filter: "0",
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        matchingProfiles = response;
        this.setState({
          currentTab: "All",
        });
      });
  }

  async showLocalProfiles() {
    await fetch(`${baseURL}/matching`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
        filter: "4",
      }),
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        matchingProfiles = response;
        this.setState({
          currentTab: "Location",
        });
      });
  }

  async showProfessionProfiles() {
    await fetch(`${baseURL}/matching`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
        filter: "1",
      }),
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        matchingProfiles = response;
        this.setState({
          currentTab: "Profession",
        });
      });
  }
  async showLocationProfiles() {
    await fetch(`${baseURL}/matching`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
        filter: "4",
      }),
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        matchingProfiles = response;
        this.setState({
          currentTab: "Location",
        });
      });
  }
  async showEducationProfiles() {
    await fetch(`${baseURL}/matching`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
        filter: "2",
      }),
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        matchingProfiles = response;
        this.setState({
          currentTab: "Education",
        });
      });
  }

  async updateLogin() {
    await fetch(`${baseURL}/loginupdate`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
      headers: {
        "Content-type": "application/json",
      },
    }).then((response) => {});
  }

  changed = () => {
    this.showAllProfiles();
  };

  render() {
    const title1 = "Premium Profiles";

    const playStoreURL = environment.playStoreURL;
    const appStoreURL = environment.appStoreURL;

    if (!this.state.isLoading) {
      return (
        <div>
          {/* <Modal
            className="popup-modal__mobile-window"
            show={this.state.hidePopUpModal}
            onHide={() => this.setState({ hidePopUpModal: false })}
          >
            <Image
              src={getAppBanner}
              className="popup-alert-image__modal"
              style={{
                width: "100%",
                height: "460px",
                opacity: "0.49",
                borderRadius: "0.5rem",
              }}
            />
            <Box sx={{ position: "absolute", top: "2%" }}>
              <Box sx={{ padding: "1rem" }}>
                <AiOutlineClose
                  onClick={() => setHidePopUpModal(false)}
                  size="25px"
                  color="white"
                  style={{ position: "absolute", right: "5px", top: "0px" }}
                />

                <h4 style={{ color: "var(--primary-color)" }}>
                  Get The Mobile App Today
                </h4>
                <h6 style={{ color: "var(--primary-color)" }}>
                  Stay up-to-date with your partner search using our Click4marry
                  App
                </h6>
                <p align="left" style={{ color: "#5B6770" }}>
                  Get access to exclusive features that are only available on
                  the mobile app. We keep introducing new app-only features to
                  make it more intuitive & user-friendly.
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Image
                    src={playStore}
                    onClick={() => window.open(playStoreURL, "_blank")}
                    className="me-3 cursor-pointer get-app-playstore"
                  />
                  <Image
                    src={appStore}
                    onClick={() => window.open(appStoreURL, "_blank")}
                    className="cursor-pointer get-app-appstore"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                  marginBottom: "5rem",
                }}
              >
                <Image
                  src={getAppImage1}
                  style={{ width: "30%", height: "auto" }}
                />
                <Image
                  src={getAppImage2}
                  style={{ width: "30%", height: "auto" }}
                />
                <Image
                  src={getAppImage3}
                  style={{ width: "30%", height: "auto" }}
                />
              </Box>
            </Box>
          </Modal> */}

          <NavbarMenu></NavbarMenu>
          <div className="my-matches">
            {premiumProfiles?.length ? (
              <div>
                <Row className="mb-4">
                  <Col xs={7} className="title d-flex">
                    Featured Profiles
                  </Col>
                  {premiumProfiles?.length > 6 ? (
                    <Col className="d-flex justify-content-end">
                      <Link
                        to={`/show/${title1}`}
                        state={{
                          profiles: premiumProfiles,
                          title: "Featured Profiles",
                          from: "/",
                        }}
                        className="show-more"
                      >
                        More
                      </Link>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Row
                  className={`horizontal-scroll mx-0 ${
                    premiumProfilesToDisplay?.length > 5
                      ? "justify-content-between"
                      : ""
                  }`}
                >
                  {premiumProfilesToDisplay.map((profile, index) => (
                    <div
                      className={`d-flex flex-column px-3 w-auto ${
                        premiumProfilesToDisplay?.length > 5 ? "p-md-0" : "px-2"
                      }`}
                      key={index}
                    >
                      <RoundedProfile profile={profile}></RoundedProfile>
                    </div>
                  ))}
                </Row>
              </div>
            ) : (
              <></>
            )}
            <div className="matching-profiles">
              <Row className="title mx-0">Matching Profiles</Row>
              <Row className="mx-0 mb-2 pt-2">
                <Col
                  lg={1}
                  xs={2}
                  className="d-flex p-2 justify-content-center"
                  style={{
                    borderRadius: "13px",
                    backgroundColor:
                      this.state.currentTab === "All" ? "#5B6770" : "white",
                    color: this.state.currentTab === "All" ? "white" : "black",
                    cursor: "pointer",
                  }}
                  onClick={this.showAllProfiles}
                >
                  All
                </Col>

                <Col
                  lg={1}
                  xs={4}
                  className="d-flex p-2 justify-content-center"
                  style={{
                    borderRadius: "13px",
                    backgroundColor:
                      this.state.currentTab === "Profession"
                        ? "#5B6770"
                        : "white",
                    color:
                      this.state.currentTab === "Profession"
                        ? "white"
                        : "black",
                    cursor: "pointer",
                  }}
                  onClick={this.showProfessionProfiles}
                >
                  Profession{" "}
                </Col>
                <Col
                  lg={1}
                  xs={3}
                  className="d-flex p-2 justify-content-center"
                  style={{
                    borderRadius: "13px",
                    backgroundColor:
                      this.state.currentTab === "Education"
                        ? "#5B6770"
                        : "white",
                    color:
                      this.state.currentTab === "Education" ? "white" : "black",
                    cursor: "pointer",
                  }}
                  onClick={this.showEducationProfiles}
                >
                  Education
                </Col>
                <Col
                  lg={1}
                  xs={3}
                  className="d-flex p-2 justify-content-center"
                  style={{
                    borderRadius: "13px",
                    backgroundColor:
                      this.state.currentTab === "Location"
                        ? "#5B6770"
                        : "white",
                    color:
                      this.state.currentTab === "Location" ? "white" : "black",
                    cursor: "pointer",
                  }}
                  onClick={this.showLocationProfiles}
                >
                  Location
                </Col>
              </Row>
              <Row className="">
                {matchingProfiles?.length ? (
                  matchingProfiles.map((profile, index) => (
                    <Col
                      sm={6}
                      className="d-flex p-0"
                      style={{ padding: "16px" }}
                      key={profile.id}
                    >
                      <ProfileComponent
                        profile={profile}
                        changed={this.changed}
                      ></ProfileComponent>
                    </Col>
                  ))
                ) : (
                  <NoData></NoData>
                )}
              </Row>
            </div>
            {successStories.length ? (
              <Row style={{ marginTop: "20px" }}>
                <Row className="title mx-0 position-relative">
                  Success Stories
                </Row>
                <Carousel
                  prevIcon={<MdOutlineKeyboardArrowLeft size="50px" />}
                  nextIcon={<MdKeyboardArrowRight size="50px" />}
                >
                  {successStories.map((story, index) => (
                    <Carousel.Item className="mt-5 mb-5" key={index}>
                      <Link
                        to={`/success-stories/${story.id}`}
                        className="d-flex p-3 text-decoration-none"
                        style={{
                          background: "#FFFFFF",
                          boxShadow: "0px 1px 3px 2px rgba(0, 0, 0, 0.10)",
                          borderRadius: "10px",
                        }}
                      >
                        <Row>
                          <Col md={5} xs={12} className="col-md-5 col-xs-12">
                            <img
                              className="d-block wedding-image"
                              src={`${storyImageURL}${story.image_url}`}
                              alt="wedding"
                              style={{ borderRadius: "10px" }}
                            />
                          </Col>
                          <Col
                            md={7}
                            xs={12}
                            className="col-md-7 col-xs-12 caroudel-tx"
                            style={{ color: "#000" }}
                          >
                            <h4 style={{ fontWeight: "normal" }}>
                              {story.head}
                            </h4>
                            <p
                              align="left"
                              className="px-4 max-lines"
                              style={{ color: "#5B6770", width: "90%" }}
                            >
                              {story.body}
                            </p>
                            {/* </Carousel.Caption> */}
                          </Col>
                        </Row>
                      </Link>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Row>
            ) : (
              ""
            )}
            <Row style={{ marginTop: "20px" }}>
              <Row className="title mx-0">Wedding Stores</Row>
              <Row className="d-flex justify-content-center">
                <Link
                  to="/wedding-stores"
                  className="d-flex justify-content-center"
                >
                  <Image src={storeBanner} className="wedding-store-banner" />
                </Link>
              </Row>
            </Row>
            <Row className="matches-cards mb-5">
              <Col xs={12} md={4} className="latest">
                <CardComponent
                  title="Latest Profiles"
                  profiles={latestProfiles}
                ></CardComponent>
              </Col>

              <Col xs={12} md={4} className="recommended">
                <CardComponent
                  title="Admin Recommended"
                  plan={plan.admin_recommented_priority === "Y" ? "Y" : "N"}
                  profiles={adminRecommendedProfiles}
                ></CardComponent>
              </Col>
              <Col xs={12} md={4} className="viewed">
                <CardComponent
                  recent={recentlyViewedProfiles}
                  contacts={contactsViewedProfiles}
                  whoShortlist={whoShortlisted}
                  whoView={whoViewed}
                  ignored={ignoredProfiles}
                ></CardComponent>
              </Col>
            </Row>
            <Row
              style={{
                marginBottom: "129px",
                cursor: "pointer",
                width: "100%",
              }}
            >
              <Image
                src={referFriend}
                alt="Refer a friend"
                height="100%"
                onClick={() => this.setState({ showReferModal: true })}
              />
            </Row>
            <Modal
              show={this.state.showReferModal}
              onHide={() => this.setState({ showReferModal: false })}
            >
              <Modal.Header>Invite Friends and get Rewards</Modal.Header>
              <Modal.Body className="justify-content-around d-flex align-items-center">
                <FacebookShareButton
                  url={`https://click4marry.com/#/?code=${localStorage.code}`}
                  quote={`Hey, ${localStorage.getItem(
                    "user_name"
                  )} is inviting you to Click4Marry. Find your partner`}
                >
                  <FacebookIcon size={36} round />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={`https://click4marry.com/#/?code=${localStorage.code}`}
                  title={`Hey, ${localStorage.getItem(
                    "user_name"
                  )} is inviting you to Click4Marry. Find your partner`}
                >
                  <WhatsappIcon size={36} round />
                </WhatsappShareButton>
                <EmailShareButton
                  url={`https://click4marry.com/#/?code=${localStorage.code}`}
                  subject="Find Partner"
                  body={`Hey, ${localStorage.getItem(
                    "user_name"
                  )} is inviting you to Click4Marry. Find your partner`}
                >
                  <EmailIcon size={36} round />
                </EmailShareButton>
                <TwitterShareButton
                  url={`https://click4marry.com/#/?code=${localStorage.code}`}
                  title={`Hey, ${localStorage.getItem(
                    "user_name"
                  )} is inviting you to Click4Marry. Find your partner`}
                >
                  <TwitterIcon size={36} round />
                </TwitterShareButton>
                <TelegramShareButton
                  url={`https://click4marry.com/#/?code=${localStorage.code}`}
                  title={`Hey, ${localStorage.getItem(
                    "user_name"
                  )} is inviting you to Click4Marry. Find your partner`}
                >
                  <TelegramIcon size={36} round />
                </TelegramShareButton>
                <LinkedinShareButton
                  url={`https://click4marry.com/#/?code=${localStorage.code}`}
                  title={`Hey, ${localStorage.getItem(
                    "user_name"
                  )} is inviting you to Click4Marry. Find your partner`}
                >
                  <LinkedinIcon size={36} round />
                </LinkedinShareButton>
              </Modal.Body>
            </Modal>
          </div>
          <Footer></Footer>
        </div>
      );
    } else {
      return (
        <div>
          <NavbarMenu></NavbarMenu>
          <Loader></Loader>
          <Footer></Footer>
        </div>
      );
    }
    // }
  }
}
