import React, { Component } from 'react'
import { Image } from 'react-bootstrap'
import { BackToTopIcon } from '../../assets'
import './BackToTop.css'
import ReactTooltip from "react-tooltip";

export default class BackToTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibile: false
        };

    }

    componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", function (e) {
            scrollComponent.toggleVisibility()
        })
    }

    toggleVisibility() {
        if (window.pageYOffset > 300) {
            this.setState({
                visible: true
            })
        } else {
            this.setState({
                visible: false
            })
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    render() {
        const { visible } = this.state;
        return (
            <div>
                {visible && (
                    <div className='back-to-top-component d-flex justify-content-center align-items-center' onClick={() => this.scrollToTop()}
                    data-tip
                     data-for="help-totop">
                        <Image src={BackToTopIcon} className='back-icon-size' alt='Go to top' />
                        <ReactTooltip
                            className="tooltipClass"
                            id="help-totop"
                            place="top"
                            type="light"
                        >
                            <span>Back To Top</span>
                        </ReactTooltip>
                    </div>
                )}
            </div>
        )
    }
}
