import React, { useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import {
  MdVerified,
  MdCircle,
  MdStarBorder,
  MdFavoriteBorder,
  MdHighlightOff,
  MdClear,
  MdStar,
  MdFavorite,
  MdRemove,
  MdDelete,
} from "react-icons/md";
import { RiChat3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ProtectedImage, UpgradeModal } from "..";
import { environment } from "../../environment/env";
import {
  acceptRejectAlbumRequestService,
  acceptRejectContentRequestService,
  removeAlbumRequestService,
  removeContentRequestService,
} from "../../Services/AlbumRequest";
import {
  acceptRejectInterestService,
  removeInterestService,
} from "../../Services/ExpressInterest";
import { removeRecentvisitService } from "../../Services/RecentVisits";
import "./Profile.css";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ProtectedImage1 from "../ProtectedImage/ProtectedImage1";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ProfileComponent(props) {
  let imageURL = environment.imageURL;
  let baseURL = environment.hostname;
  let image;
  let navigate = useNavigate();
  let [isShortlisted, setIsShortlisted] = React.useState(
    props?.profile?.is_shortlisted === "1"
  );
  let [isInterested, setIsInterested] = React.useState(
    props?.profile?.is_interested === "1"
  );
  const [ShowUpgradeModal, setShowUpgradeModal] = useState(false);
  const [IgnoredProfiles, setIgnoredProfiles] = useState([props.profile]);
  const [showContactReadModal, setShowContactReadModal] = React.useState(false);

  const params = useParams();

  let showProfile = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    localStorage.setItem("visitingUser", id);
    navigate("/profile");
  };

  let shortlist = (e) => {
    e.stopPropagation();
    fetch(`${baseURL}/add_shortlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: localStorage.currentUser,
        to: props.profile.id,
        ip: "111.111.1.1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.sts === 1) {
          setIsShortlisted(true);
          toast.success("Shortlisted successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (response.sts === 3) {
          setIsShortlisted(true);
          toast.warning("Already shortlisted", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  let expressInterest = (e) => {
    e.stopPropagation();
    fetch(`${baseURL}/add_express_interest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: props.profile.id,
        ip: "111.111.1.1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.sts === 1) {
          setIsInterested(true);
          toast.success("interest request send successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (response.sts === 3) {
          setIsInterested(true);
          toast.warning("Already sent request", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  let ignore = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    fetch(`${baseURL}/ignore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: props.profile.id,
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response === "1") toast.success("Ignored successfully");
        else if (response === "0") toast.info("already ignored");
        props.changed();
        window.location.reload(true);
      });
  };
  let removeRecentvisit = (e, id) => {
    e.stopPropagation();
    removeRecentvisitService(id)
      .then((res) => res.json())
      .then((res) => {
        if (res === 1) {
          toast.success("Successfully removed the profile visit", {
            position: "top-center",
          });
          props.changed();
        } else if (res === 0) {
          toast.info("Already removed the profile visit", {
            position: "top-center",
          });
        } else
          toast.error("Failed to remove the profile", {
            position: "top-center",
          });
      });
  };

  let prevent = (id, e) => {
    e.stopPropagation();
    if (localStorage.plan != "2") {
      fetch(`${baseURL}/is_contact_viewed`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          profid: localStorage.currentUser,
          id: id,
          ip: "111.111.1.1",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response != 0) {
            localStorage.setItem("visitingUser", id);
            navigate(`/messages/${localStorage.visitingUser}`);
          } else {
            setShowContactReadModal(true);
          }
        });
    } else {
      setShowUpgradeModal(true);
      console.log("here", ShowUpgradeModal);
      e.preventDefault();
    }
  };

  let removeInterest = (e, id, tab) => {
    e.stopPropagation();
    removeInterestService(id, tab)
      .then((res) => res.json())
      .then((res) => {
        if (res === 1) {
          toast.success("successfully removed interest send", {
            position: "top-center",
          });
          props.changed();
        } else
          toast.error("failed to remove interest send", {
            position: "top-center",
          });
      });
  };

  let removeAlbumRequest = (e, id, tab) => {
    e.stopPropagation();
    removeAlbumRequestService(id, tab).then((res) => {
      toast.success("successfully removed album request send", {
        position: "top-center",
      });
      props.changed();
    });
  };

  let removeContentRequest = (e, id, type, tab) => {
    e.stopPropagation();
    removeContentRequestService(id, type, tab).then((res) => {
      toast.success(`successfylly removed ${type} info request send`, {
        position: "top-center",
      });
      props.changed();
    });
  };

  let acceptInterest = (e, id) => {
    e.stopPropagation();
    acceptRejectInterestService(id, "Y")
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          toast.success("successfully accepted interest", {
            position: "top-center",
          });
          props.changed();
        } else
          toast.error("failed to accept interest", { position: "top-center" });
      });
  };

  let acceptAlbumRequest = (e, id) => {
    e.stopPropagation();
    acceptRejectAlbumRequestService(id, "Y")
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          toast.success("successfully accepted album request", {
            position: "top-center",
          });
          props.changed();
          let albumApi = fetch(`${baseURL}/user_album`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              id: localStorage.currentUser,
              profid: localStorage.currentUser,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.length < 1) {
                localStorage.setItem("manage_photo_album_request", "1");
                navigate("/myprofile/album-upload");
              } else if (res[0].album == null || res[0].album === "") {
                localStorage.setItem("manage_photo_album_request", "1");
                navigate("/myprofile/album-upload");
              }
            });
        } else
          toast.error("failed to accept album request", {
            position: "top-center",
          });
      });
  };

  let acceptContentRequest = (e, id, type) => {
    e.stopPropagation();
    acceptRejectContentRequestService(id, "Y", type)
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          toast.success(`successfully accepted ${type} info request`, {
            position: "top-center",
          });
          props.changed();
        } else
          toast.error(`failed to accept ${type} info request`, {
            position: "top-center",
          });
      });
  };

  let rejectInterest = (e, id) => {
    e.stopPropagation();
    acceptRejectInterestService(id, "N")
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          toast.success("successfully rejected interest", {
            position: "top-center",
          });
          props.changed();
        } else
          toast.error("failed to reject interest", { position: "top-center" });
      });
  };

  let rejectAlbumRequest = (e, id) => {
    e.stopPropagation();
    acceptRejectAlbumRequestService(id, "N")
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          toast.success("successfully rejected album request", {
            position: "top-center",
          });
          props.changed();
        } else
          toast.error("failed to reject album request", {
            position: "top-center",
          });
      });
  };

  let rejectContentRequest = (e, id, type) => {
    e.stopPropagation();
    acceptRejectContentRequestService(id, "N", type)
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          toast.success(`successfully rejected ${type} info request`, {
            position: "top-center",
          });
          props.changed();
        } else
          toast.error(`failed to reject ${type} info request`, {
            position: "top-center",
          });
      });
  };

  let removeShortListbyid = (e, toId) => {
    e.stopPropagation();
    fetch(`${baseURL}/delete_shortlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        to_id: toId,
        session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.toString().includes("401")) {
          toast.success("Shortlisted removed successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          setIsShortlisted(false);
        }
      });
  };

  let removeInterestbyid = (e, toId) => {
    e.stopPropagation();
    fetch(`${baseURL}/removeinterest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        to: toId,
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.toString().includes("401")) {
          toast.success("Interest removed successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          setIsInterested(false);
        }
      });
  };

  let visibleRemove = () => {};

  let removeIgnoredUsers = (e, Id) => {
    e.stopPropagation();
    fetch(`${baseURL}/remove_ignored_profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: localStorage.getItem("currentUser"),
        to_id: Id,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        toast.success("Ignored Profile removed successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        const new_datas = IgnoredProfiles.filter((item) => item.id != Id);

        setIgnoredProfiles(new_datas);
      })
      .catch((err) => {
        console.log(err, "err in remove profile api");
      });
  };

  if (props?.profile?.face_image) image = imageURL + props?.profile?.face_image;
  else if (
    props?.profile?.gender === "Female" ||
    localStorage.gender === "Male"
  )
    image = imageURL + "avatar_Female.png";
  else image = imageURL + "avatar_Male.png";

  if (
    (props?.profile?.album_visibility === "55" && localStorage.plan === "2") ||
    props?.profile?.album_visibility === "56"
  ) {
    if (props?.profile?.gender === "Male" || localStorage.gender === "Female") {
      image = imageURL + "avatar_Male.png";
    } else {
      image = imageURL + "avatar_Female.png";
    }
  }
  if (props.card)
    if (params.hasOwnProperty("page") && params.page === "Igonred Profiles") {
      return IgnoredProfiles.map((item, index) => {
        return (
          <Card className="profile-card-component">
            <Row className="mx-0">
              <Col
                className="ps-0 position-relative"
                onClick={(e) => showProfile(e, item.id)}
              >
                <Image
                  src={image}
                  className="profile-card-component-image"
                  style={{ borderRadius: "15px" }}
                />
                {item.album_visibility === "56" ? (
                  <ProtectedImage
                    id={item.id}
                    requestButton={false}
                  ></ProtectedImage>
                ) : item.face_image ? (
                  <></>
                ) : (
                  ""
                )}
              </Col>
              <Col
                className="p-0 mx-4 col-4 d-flex justify-content-between flex-column"
                onClick={(e) => showProfile(e, item.id)}
              >
                <Row className="name">
                  <Col className="p-0 d-flex align-items-center">
                    {item.id}
                    <MdVerified
                      size="30px"
                      className="mx-2 name"
                      color={
                        item?.verified_badge === "Y"
                          ? "var(--secondary-color)"
                          : "#C4C4C4"
                      }
                    />
                    <MdCircle
                      size="18px"
                      className="mx-1 name"
                      color={
                        new Date(item.lastlogin + " UTC").toDateString() !=
                        new Date().toDateString()
                          ? "#C4C4C4"
                          : new Date(item.lastlogin + " UTC").getHours() !=
                            new Date().getHours()
                          ? "#C4C4C4"
                          : new Date().getMinutes() - 5 <=
                              new Date(item.lastlogin + " UTC").getMinutes() &&
                            new Date(item.lastlogin + " UTC").getMinutes() <=
                              new Date().getMinutes()
                          ? "#00BE63"
                          : "#C4C4C4"
                      }
                    />
                  </Col>
                </Row>
                <Row className="non-card-component-details">
                  {item.occu}&nbsp;-&nbsp;{item.qual}{" "}
                </Row>
                <Row className="non-card-component-details">
                  {item.age ? `${item.age} yrs` : ""}&nbsp; &nbsp;
                  {item.height ? `${item.height} CM` : ""}
                </Row>
                <Row className="non-card-component-details">
                  {item.marital_web}
                </Row>
                <Row className="non-card-component-details">{item.place}</Row>
              </Col>

              <Col>
                <p onClick={(e) => removeIgnoredUsers(e, item.id)}> remove</p>
              </Col>
            </Row>
          </Card>
        );
      });
    } else {
      return (
        <>
          <Modal
            show={showContactReadModal}
            onHide={() => setShowContactReadModal(false)}
          >
            <Modal.Body className="justify-content-center align-items-center d-flex flex-column">
              <div>Chat can be started only after reading contact</div>
              <Button
                className="px-4 mt-3"
                style={{
                  borderColor: "var(--primary-color)",
                  color: "var(--primary-color)",
                  backgroundColor: "#FFF",
                }}
                onClick={() => setShowContactReadModal(false)}
              >
                Ok
              </Button>
            </Modal.Body>
          </Modal>

          <Card className="profile-card-component">
            <Row className="mx-0">
              <Col
                className="ps-0 position-relative"
                onClick={(e) => showProfile(e, props?.profile?.id)}
              >
                <Image
                  src={image}
                  className="profile-card-component-image"
                  style={{ borderRadius: "15px" }}
                />
                {props?.profile?.album_visibility === "56" ? (
                  <ProtectedImage
                    id={props?.profile?.id}
                    requestButton={false}
                  ></ProtectedImage>
                ) : !props?.profile?.face_image ? (
                  <></>
                ) : (
                  ""
                )}
              </Col>
              <Col
                className="p-0 mx-4 col-4 d-flex justify-content-between flex-column"
                onClick={(e) => showProfile(e, props?.profile?.id)}
              >
                <Row className="name">
                  <Col className="p-0 d-flex align-items-center">
                    {props?.profile?.id}
                    <MdVerified
                      size="30px"
                      className="mx-2 name"
                      color={
                        props?.profile?.verified_status === "1"
                          ? "var(--secondary-color)"
                          : "#C4C4C4"
                      }
                    />
                    <MdCircle
                      size="18px"
                      className="mx-1 name"
                      color={
                        new Date(
                          props?.profile?.lastlogin + " UTC"
                        ).toDateString() != new Date().toDateString()
                          ? "#C4C4C4"
                          : new Date(
                              props?.profile?.lastlogin + " UTC"
                            ).getHours() != new Date().getHours()
                          ? "#C4C4C4"
                          : new Date().getMinutes() - 5 <=
                              new Date(
                                props?.profile?.lastlogin + " UTC"
                              ).getMinutes() &&
                            new Date(
                              props?.profile?.lastlog_ + " UTC"
                            ).getMinutes() <= new Date().getMinutes()
                          ? "#00BE63"
                          : "#C4C4C4"
                      }
                    />
                  </Col>
                </Row>
                <Row className="non-card-component-details">
                  {" "}
                  {props?.profile?.occu}&nbsp;-&nbsp;{props?.profile?.qual}
                </Row>
                <Row className="non-card-component-details">
                  {props?.profile?.age ? `${props?.profile?.age} yrs` : ""}
                  &nbsp; &nbsp;
                  {props?.profile?.height ? `${props.profile?.height} CM` : ""}
                </Row>
                <Row className="non-card-component-details">
                  {props?.profile?.marital_web}
                </Row>
                <Row className="non-card-component-details">
                  {props?.profile?.place}
                </Row>
                <Row className="action">
                  {!props?.searchresult && props?.page ? (
                    <Row>
                      {props?.tab === "Recieved" &&
                      props?.activityTab === "Pending" ? (
                        <Col
                          className=""
                          style={{
                            cursor: "pointer",
                            borderRadius: "10px",
                            padding: "4px",
                            textAlign: "center",
                            backgroundColor: "#009901",
                            color: "#FFF",
                          }}
                          onClick={
                            props?.page === "interest"
                              ? (e) => acceptInterest(e, props?.profile?.id)
                              : props?.page === "album"
                              ? (e) => acceptAlbumRequest(e, props?.profile?.id)
                              : (e) =>
                                  acceptContentRequest(
                                    e,
                                    props?.profile?.id,
                                    props?.type
                                  )
                          }
                        >
                          Accept
                        </Col>
                      ) : (
                        ""
                      )}
                      {props?.tab === "Recieved" &&
                      props?.activityTab === "Pending" ? (
                        <Col
                          // className="pe-4 p-0 d-flex"
                          style={{
                            cursor: "pointer",
                            borderRadius: "10px",
                            padding: "4px",
                            textAlign: "center",
                            backgroundColor: "#c80000",
                            color: "#FFF",
                            marginLeft: "2px",
                          }}
                          onClick={
                            props?.page === "interest"
                              ? (e) => rejectInterest(e, props?.profile?.id)
                              : props?.page === "album"
                              ? (e) => rejectAlbumRequest(e, props?.profile?.id)
                              : (e) =>
                                  rejectContentRequest(
                                    e,
                                    props?.profile?.id,
                                    props?.type
                                  )
                          }
                        >
                          Reject
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  ) : props?.page ? (
                    <div>
                      <Row className="mt-md-3 align-items-center justify-content-between">
                        <div className="p-0 d-flex w-auto">
                          <MdStarBorder
                            className="non-card-component-icon"
                            color="#000"
                            onClick={shortlist}
                          />
                        </div>
                        <div className="p-0 d-flex w-auto">
                          <MdFavoriteBorder
                            className="non-card-component-icon"
                            color="#000"
                            onClick={expressInterest}
                          />
                        </div>
                        <div className="p-0 d-flex w-auto">
                          {/* <Link to={`/messages/${localStorage.visitingUser}`}> */}
                          <RiChat3Line
                            className="non-card-component-icon"
                            color="#000"
                            onClick={(e) => prevent(props?.profile?.id, e)}
                          />
                          {/* </Link> */}
                        </div>
                        <div className="p-0 d-flex w-auto">
                          <MdHighlightOff
                            className="non-card-component-icon"
                            color="#000"
                            onClick={ignore}
                          />
                        </div>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}
                  <UpgradeModal
                    upgradeNow={ShowUpgradeModal}
                    closeModal={() => setShowUpgradeModal(false)}
                  ></UpgradeModal>
                </Row>
              </Col>

              <Col
                className="d-flex flex-column justify-content-between align-items-end mx-0 p-0 remresponseissue"
                onClick={(e) => showProfile(e, props?.profile?.id)}
              >
                <div></div>
                {/* } */}
                {!props?.searchresult && props?.page != undefined && (
                  <div
                    visibility="collapse"
                    // className="p-0 d-flex align-items-end"
                    style={{
                      cursor: "pointer",
                      borderRadius: "10px",
                      padding: "5px",
                      textAlign: "center",
                      color: "rgb(255, 255, 255)",
                      marginLeft: "2px",
                      /* border-radius: 100%; */
                      width: "28px",
                      height: "28px",
                      verticalAlign: "middle",
                      background: "#fff",
                      border: "1px solid #4c4c4c",
                      // cursor: "pointer",
                      // borderRadius: "10px",
                      // padding: "4px",
                      // textAlign: "center",
                      // backgroundColor: "#C3C3C3",
                      // color: "#FFF",
                      // marginLeft: "2px",
                    }}
                    onClick={
                      props?.page === "interest"
                        ? (e) =>
                            removeInterest(e, props?.profile?.id, props?.tab)
                        : props?.page === "album"
                        ? (e) =>
                            removeAlbumRequest(
                              e,
                              props?.profile?.id,
                              props?.tab
                            )
                        : // : (e) => removeRecentvisit(e, props?.id)

                          (e) =>
                            removeContentRequest(
                              e,
                              props?.profile?.id,
                              props?.type,
                              props?.tab
                            )
                      // (
                      //   props?.page != "album" &&
                      //     ((e) => removeRecentvisit(e, props?.id))
                      // )
                    }
                  >
                    {/* Remove */}
                    {/* color: rgb(91, 103, 112);margin-left: 2px !important;vertical-align: middle */}

                    <MdDelete
                      className="mx-2 iconRe"
                      size="17px"
                      color="#5B6770"
                      verticalAlign="middle"
                      marginLeft="2px !important"
                      marginBottom="5px !impotant"
                    />
                  </div>
                )}
              </Col>

              {params.hasOwnProperty("page") &&
                params.page === "Igonred Profiles" && (
                  <Col>
                    <div
                      visibility="collapse"
                      style={{
                        cursor: "pointer",
                        borderRadius: "10px",
                        padding: "5px",
                        textAlign: "center",
                        color: "rgb(255, 255, 255)",
                        marginLeft: "2px",
                        /* border-radius: 100%; */
                        width: "28px",
                        height: "28px",
                        verticalAlign: "middle",
                        background: "#fff",
                        border: "1px solid #4c4c4c",
                      }}
                      onClick={(e) => removeIgnoredUsers(e, props?.profile?.id)}
                    >
                      {" "}
                      <MdDelete
                        className="mx-2 iconRe"
                        size="17px"
                        color="#5B6770"
                        verticalAlign="middle"
                        marginLeft="2px !important"
                        marginBottom="5px !impotant"
                      />
                    </div>
                  </Col>
                )}
            </Row>
          </Card>
        </>
      );
    }
  else
    return (
      <>
        <Modal
          show={showContactReadModal}
          onHide={() => setShowContactReadModal(false)}
        >
          <Modal.Body className="justify-content-center align-items-center d-flex flex-column">
            <div>Chat can be started only after reading contact</div>
            <Button
              className="px-4 mt-3"
              style={{
                borderColor: "var(--primary-color)",
                color: "var(--primary-color)",
                backgroundColor: "#FFF",
              }}
              onClick={() => setShowContactReadModal(false)}
            >
              Ok
            </Button>
          </Modal.Body>
        </Modal>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            width: "100%",
            margin: "1.5rem 0",
          }}
          onClick={(e) => showProfile(e, props?.profile?.id)}
        >
          <div>
            <Image
              src={image}
              className="profile-non-card-component-image"
              style={{ borderRadius: "25px" }}
            />
            {props?.profile?.album_visibility === "56" ? (
              <ProtectedImage1
                id={props?.profile?.id}
                requestButton={false}
              ></ProtectedImage1>
            ) : !props?.profile?.face_image ? (
              <></>
            ) : (
              ""
            )}
          </div>
          <div style={{ width: "50%", paddingLeft: "1rem" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <p
                style={{
                  fontSize: "18px",
                  color: "var(--primary-color)",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
              >
                {props?.profile?.id}
              </p>
              <div style={{ paddingLeft: "3%" }}>
                <MdVerified
                  size="29px"
                  color={
                    props?.profile?.verified_badge === "Y"
                      ? "var(--secondary-color)"
                      : "#C4C4C4"
                  }
                />
                <MdCircle
                  size="18px"
                  color={
                    new Date(
                      props?.profile?.lastlogin_new + " UTC"
                    ).toDateString() != new Date().toDateString()
                      ? "#C4C4C4"
                      : new Date(
                          props?.profile?.lastlogin_new + " UTC"
                        ).getHours() != new Date().getHours()
                      ? "#C4C4C4"
                      : new Date().getMinutes() - 5 <=
                          new Date(
                            props?.profile?.lastlogin_new + " UTC"
                          ).getMinutes() &&
                        new Date(
                          props?.profile?.lastlogin_new + " UTC"
                        ).getMinutes() <= new Date().getMinutes()
                      ? "#00BE63"
                      : "#C4C4C4"
                  }
                />
              </div>
            </div>
            <div>
              <p style={{ margin: "0" }} className="quli-description__profile">
                {props?.profile?.occu}&nbsp;-&nbsp;{props?.profile?.qual}
              </p>
              <p style={{ margin: "0" }}>
                {props?.profile?.age ? `${props?.profile?.age} yrs` : ""}&nbsp;
                &nbsp;
                {props?.profile?.height ? `${props.profile?.height} CM` : ""}
              </p>
              <p style={{ margin: "0" }}>{props?.profile?.marital_web}</p>
              <p className="place-description__profile">
                {props?.profile?.place}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                maxWidth: "155px",
              }}
            >
              {isShortlisted ? (
                <MdStar
                  className="non-card-component-icon"
                  color="var(--secondary-color)"
                  onClick={(e) => removeShortListbyid(e, props?.profile?.id)}
                />
              ) : (
                <MdStarBorder
                  className="non-card-component-icon"
                  color="#000"
                  onClick={shortlist}
                />
              )}
              {isInterested ? (
                <MdFavorite
                  className="non-card-component-icon"
                  color="var(--secondary-color)"
                  onClick={(e) => removeInterestbyid(e, props?.profile?.id)}
                />
              ) : (
                <MdFavoriteBorder
                  className="non-card-component-icon"
                  color="#000"
                  onClick={expressInterest}
                />
              )}
              <RiChat3Line
                className="non-card-component-icon"
                color="#000"
                onClick={(e) => prevent(props?.profile?.id, e)}
              />
              <MdHighlightOff
                className="non-card-component-icon"
                color="#000"
                onClick={ignore}
              />
            </div>
          </div>
        </div>
      </>
    );
}
