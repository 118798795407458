import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Image,
} from "react-bootstrap";
import { BsCalendar, BsXCircle, BsExclamationOctagon } from "react-icons/bs";
import { MdMoreVert } from "react-icons/md";
import { toast } from "react-toastify";
import { environment } from "../../environment/env";
import { ReportModal } from "..";
import "./VideoScheduleCard.css";
import DatePicker from "react-date-picker";

export default function VideoScheduleCard(props) {
  const baseURL = environment.hostname;
  const imageURL = environment.imageURL;
  let day, month;
  let a = props.schedule.scheduled?.split("T");
  let b = a ? a[1]?.split("Z") : "";
  let c = a && b ? a[0]?.concat(b[1]) : "";

  let date = c
    ? new Date(c)
    : new Date((props?.schedule?.scheduled + " UTC").replace(/-/g, "/"));
  let monthNo = date.getMonth();
  let dayNo = date.getDay();
  let time = date.toLocaleTimeString();
  var MS_PER_SEC = 1000;
  var resultDate = new Date(
    date - localStorage.getItem("time_diff") * MS_PER_SEC
  );
  // let time = resultDate.toLocaleTimeString();

  var expiredDate =
    date.getFullYear() + "" + date.getMonth() + "" + date.getDate() <
    new Date().getFullYear() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getDate();

  const [showModal, setShowModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [rescheduleTime, setRescheduleTime] = useState(null);
  const [rescheduleDate, setRescheduleDate] = useState(null);
  const [cancelMessage, setCancelMessage] = useState("");
  const [meetingExist, setMeetingExist] = useState(false);
  const [meetingExistMessage, setMeetingExistMessage] = useState("");

  switch (dayNo) {
    case 0:
      day = "Sunday";
      break;
    case 1:
      day = "Monday";
      break;
    case 2:
      day = "Tuesday";
      break;
    case 3:
      day = "Wednesday";
      break;
    case 4:
      day = "Thursday";
      break;
    case 5:
      day = "Friday";
      break;
    default:
      day = "Saturday";
      break;
  }
  switch (monthNo) {
    case 0:
      month = "Jan";
      break;
    case 1:
      month = "Feb";
      break;
    case 2:
      month = "Mar";
      break;
    case 3:
      month = "Apr";
      break;
    case 4:
      month = "May";
      break;
    case 5:
      month = "Jun";
      break;
    case 6:
      month = "Jul";
      break;
    case 7:
      month = "Aug";
      break;
    case 8:
      month = "Sep";
      break;
    case 9:
      month = "Oct";
      break;
    case 10:
      month = "Nov";
      break;
    default:
      month = "Dec";
      break;
  }

  let ignoreProfile = () => {
    fetch(`${baseURL}/ignore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: props.schedule.id,
        check_session_id: localStorage.session,
        check_user_id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response === "1") toast.success("ignored successfully");
        else if (response === "0") toast.info("already ignored");
        props.reschedule();
      });
  };

  let reschedule = () => {
    setShowModal(true);
  };

  let closeModal = () => {
    setShowModal(false);
  };

  let checkRescheduled = (e) => {
    e.preventDefault();

    if (rescheduleDate == null || rescheduleDate == "") {
      toast.error("Please input date");
      return 1;
    }
    if (rescheduleTime == null) {
      toast.error("Please input time");
      return 1;
    }

    var date1 = new Date();
    var date2 = new Date(rescheduleDate);
    var diffDays = date2.getDate() - date1.getDate();

    if (diffDays == 0) {
      var timeNowMins = new Date().getHours() * 60 + new Date().getMinutes();
      var timeScheduledMins =
        Number(rescheduleTime.split(":")[0]) * 60 +
        Number(rescheduleTime.split(":")[1]);
      if (timeNowMins > timeScheduledMins) {
        toast.error("Please choose a time not passed");
        return 1;
      }
    }
    fetch(`${baseURL}/schedule_create_check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: props.schedule.id,
        date: `${rescheduleDate.getFullYear()}-${
          rescheduleDate.getMonth() + 1 < 10
            ? "0" + (rescheduleDate.getMonth() + 1)
            : rescheduleDate.getMonth() + 1
        }-${
          rescheduleDate.getDate() < 10
            ? "0" + rescheduleDate.getDate()
            : rescheduleDate.getDate()
        }`,
        time: rescheduleTime + ":00",
        session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          sendRescheduleRequest(e);
        } else {
          setMeetingExist(true);
          setMeetingExistMessage(response.message);
        }
      });
  };

  let sendRescheduleRequest = (e) => {
    e.preventDefault();
    setMeetingExist(false);

    if (rescheduleDate === null || rescheduleTime === null) {
      toast.error("Please select a Date and Time");
      return;
    }
    setShowModal(false);
    fetch(`${baseURL}/schedule_creation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: props.schedule.id,
        date: `${rescheduleDate.getFullYear()}-${
          rescheduleDate.getMonth() + 1 < 10
            ? "0" + (rescheduleDate.getMonth() + 1)
            : rescheduleDate.getMonth() + 1
        }-${
          rescheduleDate.getDate() < 10
            ? "0" + rescheduleDate.getDate()
            : rescheduleDate.getDate()
        }`,
        time: rescheduleTime + ":00",
        session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          fetch(`${baseURL}/cancel_schedule`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: localStorage.currentUser,
              meeting_id: props.schedule.meeting_id,
              cancel_note: cancelMessage,
              session: localStorage.session,
              reshcedule_date: `${rescheduleDate.getFullYear()}-${
                rescheduleDate.getMonth() + 1 < 10
                  ? "0" + (rescheduleDate.getMonth() + 1)
                  : rescheduleDate.getMonth() + 1
              }-${
                rescheduleDate.getDate() < 10
                  ? "0" + rescheduleDate.getDate()
                  : rescheduleDate.getDate()
              }`,
            }),
          })
            .then((res) => res.json())
            .then((response) => {
              toast.success("Rescheduled successfully", {
                position: "top-center",
                autoClose: 10000,
              });
              props.reschedule();
              setShowModal(false);
            });
        } else {
          toast.error("Recheduling Failed", {
            position: "top-center",
            autoClose: 10000,
          });
          setShowModal(false);
        }
      });
    e.preventDefault();
  };

  let accept = () => {
    fetch(`${baseURL}/accept_schedule`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        meeting_id: props.schedule.meeting_id,
        session: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          toast.success("Schedule accepted successfully", {
            position: "top-center",
          });
          props.reschedule();
        } else toast.error("Error occured", { position: "top-center" });
      });
  };

  let reject = () => {
    fetch(`${baseURL}/decline_schedule`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        meeting_id: props.schedule.meeting_id,
        session: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          toast.success("Schedule rejected successfully", {
            position: "top-center",
          });
          props.reschedule();
        } else toast.error("Error occured", { position: "top-center" });
      });
  };

  let cancel = () => {
    fetch(`${baseURL}/cancel_schedule`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        meeting_id: props.schedule.meeting_id,
        cancel_note: "",
        session: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          toast.success("Schedule cancelled successfully", {
            position: "top-center",
          });
          props.reschedule();
        } else toast.error("Error occured", { position: "top-center" });
      })
      .catch((error) => {
        toast.success("Schedule cancelled successfully", {
          position: "top-center",
        }),
          props.reschedule();
      });
  };

  function checkPlanAndImage(imageP, album_visibility, gender) {
    var image = imageURL + imageP;
    if (
      (album_visibility == "55" && localStorage.plan === "2") ||
      album_visibility == "56" ||
      imageP == null
    ) {
      if (gender === "Male" || localStorage.gender === "Female") {
        image = imageURL + "avatar_Male.png";
      } else {
        image = imageURL + "avatar_Female.png";
      }
    }
    return image;
  }

  return (
    <Card className="video-schedule-card">
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Title className="modal-title d-flex justify-content-center">
          Video meet with this profile
        </Modal.Title>
        <Modal.Body className="d-flex justify-content-center pt-1 pb-5">
          You can turn on video meeting with this profile
        </Modal.Body>

        {meetingExist ? (
          <Form
            onSubmit={sendRescheduleRequest}
            onReset={() => setMeetingExist(false)}
          >
            <div
              className="d-flex"
              style={{ textAlign: "center", justifyContent: "center" }}
            >
              {meetingExistMessage}
            </div>
            <div className="d-flex justify-content-center">
              <Button type="reset" className="cancel-request-button mt-5 m-2">
                Back
              </Button>
              <Button
                type="submit"
                className="schedule-request-button mt-5  m-2 w-auto"
              >
                Continue Request
              </Button>
            </div>
          </Form>
        ) : (
          <Form onSubmit={checkRescheduled} onReset={() => setShowModal(false)}>
            <div className={window.innerWidth < 425 ? "" : "d-flex"}>
              <Form.Group
                className="w-50 d-flex pe-3 align-items-end"
                style={{
                  marginLeft: window.innerWidth < 425 ? "15px" : "",
                  minWidth: "235px",
                }}
              >
                <Form.Label
                  className="pe-2"
                  style={{
                    marginRight: window.innerWidth < 425 ? "25px" : "30px",
                  }}
                >
                  Date
                </Form.Label>
                <DatePicker
                  className="form-control"
                  minDate={new Date()}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  value={rescheduleDate}
                  name="date"
                  onChange={(event) => setRescheduleDate(new Date(event))}
                  format="dd-MM-y"
                />
              </Form.Group>
              <Form.Group
                className="w-50 d-flex ps-3 align-items-end"
                style={{
                  marginTop: window.innerWidth < 425 ? "20px" : "",
                  minWidth: "235px",
                }}
              >
                <Form.Label
                  className="pe-2"
                  style={{ marginRight: window.innerWidth < 425 ? "20px" : "" }}
                >
                  Time
                </Form.Label>
                <Form.Control
                  type="time"
                  value={rescheduleTime}
                  onChange={(event) => setRescheduleTime(event.target.value)}
                  style={{ minWidth: "190px" }}
                ></Form.Control>
              </Form.Group>
            </div>
            <Form.Group
              className="mt-3 d-flex  align-items-end"
              style={{ margin: window.innerWidth < 425 ? "0px 15px" : "" }}
            >
              <Form.Label className="pe-2">Remarks</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type here"
                onChange={(e) => setCancelMessage(e.target.value)}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button type="reset" className="cancel-request-button mt-5 m-2">
                Cancel
              </Button>
              <Button
                type="submit"
                className="schedule-request-button mt-5  m-2 w-auto"
              >
                Request to Reschedule
              </Button>
            </div>
          </Form>
        )}
      </Modal>
      <Row className="m-0">
        <Col sm={2} md={3} lg={2} className="position-relative col">
          <BsCalendar color="var(--primary-color)" size="70px" />
          <div
            className="d-flex flex-column justify-content-center align-items-center position-absolute pt-3"
            style={{ top: "0", width: "70px", height: "70px" }}
          >
            <div style={{ color: "#5B6770" }}> {month}</div>
            <div style={{ color: "var(--primary-color)", marginTop: "-4px" }}>
              {date.getDate()}
            </div>
          </div>
        </Col>
        <Col className="p-0">
          <Row style={{ color: "var(--primary-color)" }}>
            {props.schedule.id}
          </Row>
          <Row style={{ color: "#5B6770" }}>{day}</Row>
          <Row style={{ color: "#5B6770" }}>{time}</Row>
        </Col>
        <Col className="d-flex justify-content-between align-items-end p-0 flex-column">
          <Dropdown drop="start">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="more-dropdown p-0"
            >
              <MdMoreVert color="var(--primary-color)" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={ignoreProfile}>
                <BsXCircle className="me-2" />
                Ignore this profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowReportModal(true)}>
                <BsExclamationOctagon className="me-2" />
                Report this profile
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ReportModal
            profile={props.schedule.id}
            ReportNow={showReportModal}
            closeModal={() => setShowReportModal(false)}
            success={() => {
              props.reschedule();
              setShowReportModal(false);
            }}
          ></ReportModal>

          {props.title === "upcoming" ? (
            <Row className="m-0 align-items-center">
              {props?.schedule?.status === "0" ? (
                props.schedule.send_status != "0" ? (
                  <>
                    {" "}
                    <Col
                      style={{ color: "#5B6770", cursor: "pointer" }}
                      onClick={accept}
                    >
                      Accept
                    </Col>
                    <Col
                      style={{ color: "#5B6770", cursor: "pointer" }}
                      onClick={reject}
                    >
                      Reject
                    </Col>
                    <Col
                      style={{ color: "#5B6770", cursor: "pointer" }}
                      onClick={reschedule}
                    >
                      Reschedule
                    </Col>
                  </>
                ) : (
                  <Col
                    style={{ color: "#5B6770", cursor: "pointer" }}
                    onClick={cancel}
                  >
                    Cancel
                  </Col>
                )
              ) : props?.schedule?.status === "1" ? (
                <>
                  {" "}
                  <Col style={{ color: "#C4C4C4" }}>Accepted</Col>
                  <Col
                    style={{ color: "#5B6770", cursor: "pointer" }}
                    onClick={reschedule}
                  >
                    Reschedule
                  </Col>
                  {props.schedule?.join_status === "1" ? (
                    <Button
                      className="w-auto p-1"
                      style={{
                        backgroundColor: "var(--primary-color)",
                        border: "none",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                      onClick={() => window.open(props.schedule?.link)}
                    >
                      Join
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              ) : props?.schedule?.status === "2" ? (
                <Col style={{ color: "#C4C4C4" }}>Rejected</Col>
              ) : (
                <Col style={{ color: "#C4C4C4" }}>Cancelled</Col>
              )}
            </Row>
          ) : (
            <Row className="m-0" style={{ color: "#C4C4C4" }}>
              {props.schedule.status === "0"
                ? expiredDate
                  ? "Expired"
                  : "Pending"
                : props.schedule.status === "1"
                ? expiredDate
                  ? "Expired"
                  : "Accepted"
                : props.schedule.status === "2"
                ? "Rejected"
                : "Cancelled"}
            </Row>
          )}
        </Col>
      </Row>
      {props?.schedule.msg_note != "" && props?.schedule.msg_note != 0 && (
        <div style={{ flexDirection: "row", marginTop: "5px" }}>
          <Image
            roundedCircle
            src={checkPlanAndImage(
              props?.schedule.cancelled_status,
              props?.schedule?.album_visibility,
              null
            )}
            width="18px"
            height="18px"
          />{" "}
          {props?.schedule.msg_note}
        </div>
      )}
    </Card>
  );
}
