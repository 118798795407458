import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  ListGroup,
  Modal,
  NavLink,
  Row,
} from "react-bootstrap";
import { Notification } from "..";
import { Link, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { logo, logoMob } from "../../assets";
import { BsCameraVideo, BsChatDots, BsBell } from "react-icons/bs";
import { MdOutlinePhoto, MdFavoriteBorder } from "react-icons/md";
import { environment } from "../../environment/env";
import { AiOutlineUser } from "react-icons/ai";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "./Navbar.css";
import { FiMenu } from "react-icons/fi";
import { IoSearchOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import NotificationHistory from "./NotificationHistory/NotificationHistory";
import { GiConsoleController } from "react-icons/gi";

function NavbarMenu(props) {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [pendingRequest, setPendingRequest] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isNotificationShow, setIsNotificationShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const asPath = useLocation();

  const notificationHandler = () => {
    setIsNotificationShow(!isNotificationShow);
  };
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  const baseURL = environment.hostname;
  const classNameFunc = ({ isActive }) => {
    isActive ? "activenav" : "";
    if (localStorage.getItem("activeLink") === "plans") {
      navigate("/plans-and-payment");
    } else if (localStorage.getItem("activeLink") === "search") {
      navigate("/search");
    } else if (localStorage.getItem("activeLink") === "dashboard") {
      navigate("/dashboard");
    } else if (localStorage.getItem("activeLink") === "matching") {
      navigate("/matching");
    }
  };
  useEffect(() => {
    window.addEventListener("popstate", () => {
      const activeLink = window.location.pathname.split("/")[1];
      localStorage.setItem("activeLink", activeLink);
    });
  }, []);
  useEffect(() => {
    fetch(`${baseURL}/count_pending`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setPendingRequest(response);
        if (
          response.register_status != "" &&
          response.register_status != "completed"
        ) {
          localStorage.setItem(
            "register_complete_status",
            response.register_status
          );
          // navigate("/register")
          setShowRegisterModal(true);
        }
      });
  }, []);

  return (
    <Container className="container-navbar">
      <Modal
        show={showLogoutModal}
        // onHide={() => setShowLogoutModal(false)}
        className="logout-modal"
      >
        <Modal.Title className="d-flex flex-column align-items-center">
          Logout
        </Modal.Title>
        <Modal.Body className="d-flex flex-column align-items-center">
          Are you sure to logout?
          <Row
            className="w-100 mt-3"
            style={{ justifyContent: "space-evenly" }}
          >
            <Button
              className="w-auto"
              style={{
                borderColor: "var(--primary-color)",
                backgroundColor: "#FFF",
                color: "var(--primary-color)",
              }}
              onClick={() => setShowLogoutModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="w-auto"
              style={{
                border: "none",
                backgroundColor: "var(--primary-color)",
                color: "#FFF",
              }}
              onClick={() => logout()}
            >
              Logout
            </Button>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showRegisterModal}
        // onHide={() => setShowRegisterModal(true)}
        className="logout-modal"
      >
        <Modal.Title className="d-flex flex-column align-items-center">
          Your Registration is Incomplete
        </Modal.Title>
        <Modal.Body className="d-flex flex-column align-items-center">
          Please fill your details and complete profile
          <Row
            className="w-100 mt-3"
            style={{ justifyContent: "space-evenly" }}
          >
            <Button
              className="w-auto"
              style={{
                borderColor: "var(--primary-color)",
                backgroundColor: "#FFF",
                color: "var(--primary-color)",
              }}
              onClick={() => logout()}
            >
              Logout
            </Button>
            <Button
              className="w-auto"
              style={{
                border: "none",
                backgroundColor: "var(--primary-color)",
                color: "#FFF",
              }}
              onClick={() => {
                navigate("/register");
              }}
            >
              Continue
            </Button>
          </Row>
        </Modal.Body>
      </Modal>

      <Row
        className="main-wrap"
        style={{
          width: "100%",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Col lg={4} md={4} sm={6} xs={6}>
          <Link to="/">
            <img
              alt=""
              src={window.innerWidth > 500 ? logo : logoMob}
              className="navbar-logo"
            />{" "}
          </Link>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
          lg={8}
          md={8}
          sm={6}
          xs={6}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Col className="big-screen-nav-items">
                {localStorage.plan == 2 ? (
                  <Link
                    to="/plans-and-payment"
                    style={{
                      background: "var(--primary-color)",
                      borderRadius: "10px",
                      color: "white",
                      padding: "5px 1rem",
                    }}
                  >
                    Upgrade Plan
                  </Link>
                ) : (
                  <div
                    style={{
                      background: "white",
                      borderRadius: "10px",
                      color: "white",
                      padding: "5px 1rem",
                    }}
                  >
                    .
                  </div>
                )}
                <Link
                  to="/video-schedule"
                  alt="hello"
                  style={{ padding: "5px  0.7rem 5px 2rem", height: "20px" }}
                >
                  <BsCameraVideo
                    data-tip
                    data-for="bs-camera"
                    size="24px"
                    color="var(--primary-color)"
                  />
                  <ReactTooltip
                    className="tooltipClass"
                    id="bs-camera"
                    place="bottom"
                    type="light"
                  >
                    <span>Video Meet</span>
                  </ReactTooltip>
                  {(props.pending?.video || pendingRequest?.video) > 0 ? (
                    <Notification
                      count={props.pending?.video || pendingRequest?.video}
                      size={15}
                      top={-27}
                      fontSize={10}
                    ></Notification>
                  ) : (
                    <></>
                  )}
                </Link>
                <Link
                  to="/express-interest"
                  state={{ title: "Interests", from: asPath.pathname }}
                  style={{ padding: "5px 0.7rem", height: "20px" }}
                >
                  <MdFavoriteBorder
                    data-tip
                    data-for="bs-intrests"
                    size="25px"
                    color="var(--primary-color)"
                  />
                  <ReactTooltip
                    className="tooltipClass"
                    id="bs-intrests"
                    place="bottom"
                    type="light"
                  >
                    <span>Interests</span>
                  </ReactTooltip>
                  {(props.pending?.express || pendingRequest?.express) > 0 ? (
                    <Notification
                      count={props.pending?.express || pendingRequest?.express}
                      size={15}
                      top={-27}
                      fontSize={10}
                    ></Notification>
                  ) : (
                    <></>
                  )}
                </Link>
                <Link
                  to="/album-request"
                  state={{ title: "Requests", from: asPath.pathname }}
                  style={{ padding: "5px 0.7rem", height: "20px" }}
                >
                  <MdOutlinePhoto
                    data-tip
                    data-for="bs-requests"
                    size="24px"
                    color="var(--primary-color)"
                  />
                  <ReactTooltip
                    className="tooltipClass"
                    id="bs-requests"
                    place="bottom"
                    type="light"
                  >
                    <span>Request</span>
                  </ReactTooltip>
                  {(props.pending?.album || pendingRequest?.album) > 0 ? (
                    <Notification
                      count={props.pending?.album || pendingRequest?.album}
                      size={15}
                      top={-27}
                      fontSize={10}
                    ></Notification>
                  ) : (
                    <></>
                  )}
                </Link>
                <Link
                  to="/messages"
                  style={{ padding: "5px 0.7rem 5px 0.7rem", height: "20px" }}
                >
                  <BsChatDots
                    data-tip
                    data-for="bs-messages"
                    size="22px"
                    color="var(--primary-color)"
                  />
                  <ReactTooltip
                    className="tooltipClass"
                    id="bs-messages"
                    place="bottom"
                    type="light"
                  >
                    <span>Messages</span>
                  </ReactTooltip>
                  {(props.pending?.message || pendingRequest?.chat_only) > 0 ? (
                    <Notification
                      count={
                        props.pending?.chat_only || pendingRequest?.chat_only
                      }
                      size={15}
                      top={-27}
                      fontSize={10}
                    ></Notification>
                  ) : (
                    <></>
                  )}
                </Link>

                <Link
                  to=""
                  onClick={notificationHandler}
                  style={{ padding: "5px 0.7rem 5px 0.7rem", height: "20px" }}
                >
                  <BsBell
                    data-tip
                    data-for="bs-notification"
                    size="22px"
                    color="var(--primary-color)"
                  />
                  {(props.pending?.notification_count ||
                    pendingRequest?.notification_count) > 0 ? (
                    <Notification
                      count={
                        props.pending?.notification_count ||
                        pendingRequest?.notification_count
                      }
                      size={15}
                      top={-27}
                      fontSize={10}
                    ></Notification>
                  ) : (
                    <></>
                  )}
                </Link>
              </Col>
              {isNotificationShow && (
                <Modal
                  className="notificationhistory"
                  show={isNotificationShow}
                  onHide={() => {
                    setIsNotificationShow(false);
                  }}
                >
                  <NotificationHistory />
                </Modal>
              )}
              <Col
                className="big-screen-nav-items"
                style={{ marginTop: "1rem" }}
              >
                <div style={{ width: "100px" }}></div>
                <Link
                  to="/matching"
                  style={{
                    color: "var(--primary-color)",
                    textDecoration: "none",
                    padding: "5px 0.5rem",
                  }}
                  className={classNameFunc}
                  onClick={() => localStorage.setItem("activeLink", "matching")}
                >
                  <div
                    style={{
                      fontWeight: asPath.pathname === "/matching" && "bold",
                    }}
                  >
                    MATCHES
                  </div>
                </Link>
                <Link
                  to="/dashboard"
                  style={{
                    color: "var(--primary-color)",
                    textDecoration: "none",
                    padding: "5px 0.5rem",
                    fontWeight: asPath.pathname === "/dashboard" && "bold",
                  }}
                  onClick={() =>
                    localStorage.setItem("activeLink", "dashboard")
                  }
                  className={classNameFunc}
                >
                  ACTIVITIES{" "}
                </Link>
                <Link
                  to="/search"
                  style={{
                    color: "var(--primary-color)",
                    textDecoration: "none",
                    padding: "5px 0.5rem",
                    fontWeight: asPath.pathname === "/search" && "bold",
                  }}
                  className={classNameFunc}
                  onClick={() => {
                    localStorage.setItem("activeLink", "search");
                    localStorage.setItem("last_search", "Advanced Search");
                  }}
                >
                  SEARCH
                </Link>
                <Link
                  to="/plans-and-payment"
                  style={{
                    color: "var(--primary-color)",
                    textDecoration: "none",
                    padding: "5px 1rem",
                    fontWeight:
                      asPath.pathname === "/plans-and-payment" && "bold",
                  }}
                  className={classNameFunc}
                  onClick={() => {
                    localStorage.setItem("activeLink", "plans");
                  }}
                >
                  PLANS
                </Link>
              </Col>
            </div>
            <div
              style={{ marginTop: "0.2rem" }}
              className="big-screen-nav-items"
            >
              <Col style={{ flexDirection: "column" }}>
                <Dropdown className="profile-drowdown">
                  <Dropdown.Toggle id="dropdown-custom-components">
                    <AiOutlineUser size="53px" color="#662666" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="1">
                      <Link to="/profile-settings">Profile Settings</Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2">
                      <Link to="/myprofile">My Profile</Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => setShowLogoutModal(true)}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Link
                  to="/myprofile"
                  className={classNameFunc}
                  style={{
                    color: "var(--primary-color)",
                    padding: "0 !important",
                    textDecoration: "none",
                    fontWeight: asPath.pathname === "/myprofile" && "bold",
                  }}
                  onClick={() =>
                    localStorage.setItem("activeLink", "myprofile")
                  }
                >
                  PROFILE
                </Link>
              </Col>
            </div>
          </div>

          <Col
            className="small-screen-nav-items"
            style={{ marginTop: "1rem", width: "60px" }}
          >
            <div style={{ marginRight: "1rem" }}>
              <Link
                to="/search"
                style={{
                  color: "var(--primary-color)",
                  textDecoration: "none",
                }}
                className={classNameFunc}
                onClick={() => {
                  localStorage.setItem("last_search", "Advanced Search");
                }}
              >
                <IoSearchOutline
                  size="35px"
                  style={{
                    padding: "3px",
                    color: "gray",
                    borderRadius: "3px",
                    cursor: "pointer",
                  }}
                />
              </Link>
            </div>
            <div onClick={() => setIsOpenDrawer(!isOpenDrawer)}>
              <FiMenu
                size="35px"
                style={{
                  // border: "0.1px solid gainsboro",
                  padding: "3px",
                  color: "gray",
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
              />
              {Number(props.pending?.video || pendingRequest?.video) +
                Number(props.pending?.express || pendingRequest?.express) +
                Number(props.pending?.album || pendingRequest?.album) +
                Number(props.pending?.chat_only || pendingRequest?.chat_only) +
                Number(
                  props.pending?.notification_count ||
                    pendingRequest?.notification_count
                ) >
              0 ? (
                <Notification
                  style={{ padding: "10px" }}
                  count={
                    Number(props.pending?.video || pendingRequest?.video) +
                    Number(props.pending?.express || pendingRequest?.express) +
                    Number(props.pending?.album || pendingRequest?.album) +
                    Number(
                      props.pending?.chat_only || pendingRequest?.chat_only
                    ) +
                    Number(
                      props.pending?.notification_count ||
                        pendingRequest?.notification_count
                    )
                  }
                  size={20}
                  top={-35}
                  fontSize={15}
                ></Notification>
              ) : (
                <></>
              )}
            </div>

            <Drawer
              open={isOpenDrawer}
              onClose={() => setIsOpenDrawer(false)}
              direction="right"
            >
              <ListGroup>
                <ListGroup.Item>
                  <Link to="/">
                    <img
                      alt=""
                      src={window.innerWidth > 500 ? logo : logoMob}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        padding: "1rem",
                      }}
                    />{" "}
                  </Link>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "1rem",
                    }}
                  >
                    <Link to="/video-schedule" alt="hello">
                      <BsCameraVideo
                        data-tip
                        data-for="bs-camera"
                        size="24px"
                        color="var(--primary-color)"
                      />
                      <ReactTooltip
                        className="tooltipClass"
                        id="bs-camera"
                        place="bottom"
                        type="light"
                      >
                        <span>Video Meet</span>
                      </ReactTooltip>
                      {(props.pending?.video || pendingRequest?.video) > 0 ? (
                        <Notification
                          count={props.pending?.video || pendingRequest?.video}
                          size={15}
                          top={-27}
                          fontSize={10}
                        ></Notification>
                      ) : (
                        <></>
                      )}
                    </Link>

                    <Link
                      to="/express-interest"
                      state={{ title: "Interests", from: asPath.pathname }}
                    >
                      <MdFavoriteBorder
                        data-tip
                        data-for="bs-intrests"
                        size="25px"
                        color="var(--primary-color)"
                      />
                      <ReactTooltip
                        className="tooltipClass"
                        id="bs-intrests"
                        place="bottom"
                        type="light"
                      >
                        <span>Interest</span>
                      </ReactTooltip>
                      {(props.pending?.express || pendingRequest?.express) >
                      0 ? (
                        <Notification
                          count={
                            props.pending?.express || pendingRequest?.express
                          }
                          size={15}
                          top={-27}
                          fontSize={10}
                        ></Notification>
                      ) : (
                        <></>
                      )}
                    </Link>

                    <Link
                      to="/album-request"
                      state={{ title: "Requests", from: asPath.pathname }}
                    >
                      <MdOutlinePhoto
                        data-tip
                        data-for="bs-requests"
                        size="24px"
                        color="var(--primary-color)"
                      />
                      <ReactTooltip
                        className="tooltipClass"
                        id="bs-requests"
                        place="bottom"
                        type="light"
                      >
                        <span>Request</span>
                      </ReactTooltip>
                      {(props.pending?.album || pendingRequest?.album) > 0 ? (
                        <Notification
                          count={props.pending?.album || pendingRequest?.album}
                          size={15}
                          top={-27}
                          fontSize={10}
                        ></Notification>
                      ) : (
                        <></>
                      )}
                    </Link>

                    <Link to="/messages">
                      <BsChatDots
                        data-tip
                        data-for="bs-messages"
                        size="22px"
                        color="var(--primary-color)"
                      />
                      <ReactTooltip
                        className="tooltipClass"
                        id="bs-messages"
                        place="bottom"
                        type="light"
                      >
                        <span>Messages</span>
                      </ReactTooltip>
                      {(props.pending?.message || pendingRequest?.chat_only) >
                      0 ? (
                        <Notification
                          count={
                            props.pending?.chat_only ||
                            pendingRequest?.chat_only
                          }
                          size={15}
                          top={-27}
                          fontSize={10}
                        ></Notification>
                      ) : (
                        <></>
                      )}
                    </Link>
                    <Link
                      to="/notification-history"
                      // onClick={notificationHandler}
                      // style={{ padding: "5px 0.7rem 5px 0.7rem", height: "20px" }}
                    >
                      <BsBell
                        data-tip
                        data-for="bs-notification"
                        size="22px"
                        color="var(--primary-color)"
                      />
                      {(props.pending?.notification_count ||
                        pendingRequest?.notification_count) > 0 ? (
                        <Notification
                          count={
                            props.pending?.notification_count ||
                            pendingRequest?.notification_count
                          }
                          size={15}
                          top={-27}
                          fontSize={10}
                        ></Notification>
                      ) : (
                        <></>
                      )}
                    </Link>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    to="/myprofile"
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "none",
                    }}
                    className={classNameFunc}
                  >
                    Profile
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    to="/matching"
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "none",
                    }}
                    className={classNameFunc}
                  >
                    Matches
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    to="/dashboard"
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "none",
                    }}
                    className={classNameFunc}
                  >
                    Activities{" "}
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    to="/search"
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "none",
                    }}
                    className={classNameFunc}
                    onClick={() => {
                      localStorage.setItem("last_search", "Advanced Search");
                    }}
                  >
                    Search
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    to="/plans-and-payment"
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "none",
                    }}
                  >
                    Plans
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <NavLink
                    onClick={() => setShowLogoutModal(true)}
                    style={{
                      color: "var(--primary-color)",
                      textDecoration: "none",
                    }}
                  >
                    Logout
                  </NavLink>
                </ListGroup.Item>
              </ListGroup>
            </Drawer>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default NavbarMenu;
