import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Footer,
  Loader,
  NavbarMenu,
  NoData,
  ProfileComponent,
} from "../../Components";
import { environment } from "../../environment/env";
import {
  saveKeywordSearch,
  saveIdSearch,
  saveAdvancedSearch,
} from "../../Services/SaveSearch";
import "./SearchResult.css";
import { toast } from "react-toastify";

const baseURL = environment.hostname;
let searchResult;

export default function SearchResult(props) {
  let location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredResult, setFilteredResult] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [tag, setTag] = useState("");
  const [searchBy, setSearchBy] = useState("");

  useEffect(() => {
    window.scrollTo(0, 50);
    if (location.state.saved_search_id) {
      fetch(`${baseURL}/saved_search_result`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: localStorage.currentUser,
          search_id: location.state.saved_search_id,
          session_id: localStorage.session,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          searchResult = response;
          setFilteredResult(searchResult.result);
          setSearchBy("keyword");
          setIsLoading(false);
        });
    } else if (location.state.keyword) {
      fetch(`${baseURL}/keyword_search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          profid: localStorage.currentUser,
          gender: localStorage.gender,
          keyword: location.state.keyword,
          text: null,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          searchResult = response;
          setFilteredResult(searchResult.result);
          setSearchBy("keyword");
          setIsLoading(false);
        });
    } else if (location.state.searchId) {
      fetch(`${baseURL}/id_search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          profid: localStorage.currentUser,
          gender: localStorage.gender,
          keyword: null,
          text: null,
          id: location.state.searchId,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          searchResult = response;
          setFilteredResult(searchResult);
          setSearchBy("id");
          setIsLoading(false);
        });
    } else {
      fetch(`${baseURL}/advance_search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: localStorage.currentUser,
          marital_status: location.state.marital_status,
          age:
            location.state.ageFrom && location.state.ageTo
              ? `${location.state.ageFrom}-${location.state.ageTo}`
              : location.state.ageFrom
              ? `${location.state.ageFrom}-60`
              : location.state?.ageTo
              ? `18-${location.state.ageTo}`
              : "",
          religion: location.state.religion,
          caste: location.state.caste,
          city: location.state.city,
          place: location.state.place,
          country_living: location.state.country_living,
          qualification: location.state.qualification,
          occupation: location.state.occupation,
          job_sector: location.state.job_sector,
          annual_income: location.state.annual_income,
          physical_status: location.state.physical_status,
          family_status: location.state.family_status,
          body_type: location.state.body_type,
          height:
            location.state.heightFrom && location.state.heightTo
              ? `${location.state.heightFrom}-${location.state.heightTo}`
              : location.state.heightFrom
              ? `${location.state.heightFrom}-215`
              : location.state?.heightTo
              ? `135-${location.state.ageTo}`
              : "",
          weight:
            location.state.weightFrom && location.state.weightTo
              ? `${location.state.weightFrom}-${location.state.weightTo}`
              : location.state.weightFrom
              ? `${location.state.weightFrom}-120`
              : location.state?.weightTo
              ? `35-${location.state.weightTo}`
              : "",
          complexion: location.state.complexion,
          diet: location.state.diet,

          photo: location.state.photo,
          keyword: "",
          sort_order_type: "",
          is_sort_ascending: "",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          searchResult = response;
          setFilteredResult(searchResult.result);
          setSearchBy("advanced");
          setIsLoading(false);
        });
    }
  }, []);

  let filter = (title, label, checked, value1, value2) => {
    if (title === "Photo status") {
      if (checked)
        setFilteredResult(filteredResult.filter((result) => result[label]));
      else {
        let temp1 = filteredResult;
        let temp2 = searchResult.filter((result) => !result[label]);
        setFilteredResult(temp1.concat(temp2));
      }
    } else if (title === "Physical status") {
      if (checked) {
        setFilteredResult(
          filteredResult,
          filter((result) => result[label] >= value1 && result[label] <= value2)
        );
      }
    } else if (
      title === "Education" ||
      title === "Job/Occupation" ||
      "Family Status"
    ) {
      if (checked)
        setFilteredResult(
          filteredResult.filter((result) => result[label] === value1)
        );
      else {
        let temp1 = filteredResult;
        let temp2 = searchResult.filter((result) => result[label] != value1);
        setFilteredResult(temp1.concat(temp2));
      }
    }
  };

  let saveSearch = async () => {
    if (tag == "") {
      toast.error("Please enter a Title", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }

    if (searchBy === "keyword") {
      await saveKeywordSearch({
        profid: localStorage.currentUser,
        gender: localStorage.gender,
        id: null,
        keyword: location.state.keyword,
        text: tag,
      })
        .then((resp) => resp.json())
        .then((res) => {
          setShowModal(false);
        });
    } else if (searchBy === "id") {
      await saveIdSearch({
        profid: localStorage.currentUser,
        gender: localStorage.gender,
        id: location.state.searchId,
        keyword: null,
        text: tag,
      })
        .then((resp) => resp.json())
        .then((res) => {
          setShowModal(false);
        });
    } else {
      await saveAdvancedSearch({
        id: localStorage.currentUser,
        marital_status: location.state.marital_status || "",
        age:
          location.state.ageFrom && location.state.ageTo
            ? `${location.state.ageFrom}-${location.state.ageTo}`
            : location.state.ageFrom
            ? `${location.state.ageFrom}-100`
            : location.state?.ageTo
            ? `0-${location.state.ageTo}`
            : "",
        religion: location.state.religion || "",
        caste: location.state.caste || "",
        city: location.state.city || "",
        country_living: location.state.country_living || "",
        qualification: location.state.qualification || "",
        occupation: location.state.occupation || "",
        job_sector: location.state.job_sector || "",
        annual_income: location.state.annual_income || "",
        physical_status: location.state.physical_status || "",
        family_status: location.state.familyl_status || "",
        body_type: location.state.body_type || "",
        height:
          location.state.heightFrom && location.state.heightTo
            ? `${location.state.heightFrom}-${location.state.heightTo}`
            : location.state.heightFrom
            ? `${location.state.heightFrom}-215`
            : location.state?.heightTo
            ? `135-${location.state.ageTo}`
            : "",
        weight:
          location.state.weightFrom && location.state.weightTo
            ? `${location.state.weightFrom}-${location.state.weightTo}`
            : location.state.weightFrom
            ? `${location.state.weightFrom}-120`
            : location.state?.weightTo
            ? `35-${location.state.weightTo}`
            : "",
        complexion: location.state.complexion || "",
        diet: location.state.diet || "",
        // place: '',
        place: location.state.place,
        photo: location.state.photo || "",
        text: tag,
      })
        .then((resp) => resp.json())
        .then((res) => {
          setShowModal(false);
        });
    }
  };
console.log(filteredResult,isLoading,'checklocation')
  let cancelSaveSearch = () => {
    setTag("");
    setShowModal(false);
  };

  let backToSearch = (e) => {
    e.preventDefault();

    navigate("/search", {
      state: {
        data: location.state,
      },
    });
  };

  return (
    <div>
      <NavbarMenu></NavbarMenu>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ paddingLeft: "125px", paddingRight: "125px" }}
      >
        <Form.Control
          type="text"
          placeholder="Title"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />
        <div className="d-flex justify-content-end">
          <Row>
            <Button className="cancel-button" onClick={cancelSaveSearch}>
              Cancel
            </Button>
            <Button className="search-button" onClick={saveSearch}>
              Save
            </Button>
          </Row>
        </div>
      </Modal>
      <Row className="search-result">
        <Row className="mb-4 d-flex align-items-center">
          <Col className="col-auto">
            <h5
              style={{ fontSize: "1.5rem", fontWeight: "normal" }}
              className="m-0"
            >
              Search Results
            </h5>
          </Col>
          <Col className="d-flex align-items-center">
            <Link
              to=""
              onClick={backToSearch}
              style={{ color: "#C4C4C4", textDecoration: "none" }}
            >
              Back to Search
            </Link>
          </Col>
          {!location.state.saved_search_id ? (
            <Col className="d-flex justify-content-end">
              <Button
                className="search-button"
                onClick={() => setShowModal(true)}
              >
                Save Results
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row className="mb-4">
          {!isLoading ? (
            filteredResult?.length ? (
              filteredResult?.map((profile) => (
                <Col md={6} className="mb-4">
                  <ProfileComponent
                    card="true"
                    profile={profile}
                    searchresult="true"
                  ></ProfileComponent>
                </Col>
              ))
            ) : location.state.searchId ? (
              <div
                style={{
                  minHeight: "75%",
                  color: "#C4C4C4",
                  fontSize: "20px",
                  marginTop: "10%",
                  marginBottom: "10%",
                }}
                className="d-flex justify-content-center align-items-center"
              >
                No Profile found / Profile may protected
              </div>
            ) : (
              <NoData></NoData>
            )
          ) : (
            <Loader></Loader>
          )}
        </Row>

        {/* </Col> */}
      </Row>
      <Footer></Footer>
    </div>
  );
}
