import { Field, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, FormLabel } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import { Footer, Loader, NavbarMenu, UpgradeModal } from "../../Components";
import { environment } from "../../environment/env";
import "./ProfileSettings.css";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const baseURL = environment.hostname;
let visibility,
  states,
  listValue,
  ageOptions = [],
  privacySettings,
  plan;
let contactLockOptions = [
  { id: "0", name: "Public" },
  { id: "1", name: "Only for Requested Users" },
];
const animatedComponents = makeAnimated();
let values = {};

export default function ProfileSettings() {
  const [activeTab, setActiveTab] = useState("password");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPassword0, setShowPassword0] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [confirm, setConfirm] = useState(0);
  const [reason, setReason] = useState("");
  const [moreReason, setMoreReason] = useState("");
  const [partner, setPartner] = useState("");
  const [isContactLocked, setIsContactLocked] = useState("0");
  const [agefrom, setAgeFrom] = useState("");
  const [ageTo, setAgeTo] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    fetch(`${baseURL}/myplan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        plan = res;
      });
  }, [activeTab]);

  let gotoPrivacySettings = () => {
    setActiveTab("privacy");
    values = {};
    ageOptions = [];
    for (let i = 18; i <= 60; i++) {
      ageOptions.push(i);
    }
    let visibilityApi = fetch(`${baseURL}/profile_status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });

    let stateApi = fetch(`${baseURL}/getstate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let listValueApi = fetch(`${baseURL}/list_value`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let privacySettingsApi = fetch(`${baseURL}/privacy_settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });

    let planApi = fetch(`${baseURL}/myplan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let isContactLockedApi = fetch(`${baseURL}/is_contact_locked`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });

    Promise.all([
      visibilityApi,
      stateApi,
      listValueApi,
      privacySettingsApi,
      planApi,
      isContactLockedApi,
    ])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        visibility = response[0];
        states = response[1];
        listValue = response[2][0];
        privacySettings = response[3];
        if (privacySettings.state) getDistricts(privacySettings.state);

        values.profile_visibility = privacySettings?.profile_visibility;
        values.state = privacySettings?.state;
        values.locations = privacySettings?.locations;
        values.education = privacySettings?.education;
        values.job = privacySettings?.job;
        values.marital_status = privacySettings?.marital_status;
        values.ageFrom = privacySettings?.age_limit?.split("-")[0];
        values.ageTo = privacySettings?.age_limit?.split("-")[1];
        plan = response[4][0];
        if (
          privacySettings?.profile_visibility === "2" ||
          privacySettings?.profile_visibility === "3"
        ) {
          setShowAll(true);
        }
        setIsContactLocked(response[5]?.data[0]?.locked + "");

        setLoading(false);
      });
  };

  let logout = () => {
    localStorage.clear();
    navigate("/");
  };

  let changePassword = (values, { isSubmitting, resetForm }) => {
    fetch(`${baseURL}/resetpass`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
        npass: values.newPassword,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === 1) {
          toast.success("Password reset Successfully");
          isSubmitting = false;
          resetForm({});
          // logout()
        } else {
          toast.error(
            "Couldn't reset password. Please Login again to continue"
          );
          isSubmitting = false;
          resetForm({});
          // logout()
        }
      })
      .catch((err) => {
        toast.error("Couldn't reset password. Please Login again to continue");
        isSubmitting = false;
        resetForm({});
        // logout()
      });
  };

  let getDistricts = (e) => {
    values.state = e.id;
    fetch(`${baseURL}/getdistrict`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state_id: e.id || e,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setDistricts(res);
      });
  };

  let savePrivacySettings = () => {
    values.id = localStorage.currentUser;
    values.session_id = localStorage.session;
    values.age_limit = `${values.ageFrom || 18}-${values.ageTo || 60}`;
    if (values.profile_visibility === "1") {
      values = {};
      values.id = localStorage.currentUser;
      values.session_id = localStorage.session;
      values.profile_visibility = "1";
    }

    fetch(`${baseURL}/save_privacy_setting`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 1) {
          toast.success("Privacy settings updated successfully!");
        } else {
          toast.error("An error occured");
        }
        updateLockStatusAPI();
      });
  };

  let deleteAccount = () => {
    fetch(`${baseURL}/deleteprofile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reason: reason,
        partner_id: partner,
        more_reason: moreReason,
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
        id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          toast.success("Your profile has been deleted successfully");
          localStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => toast.error("An error occured"));
  };

  let updateLockStatusAPI = () => {
    fetch(`${baseURL}/lock_content`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        status: isContactLocked,
        session_id: localStorage.session,
        type: "contact",
      }),
    })
      .then((res) => res.json())
      .then((res) => {})
      .catch((error) => {});
  };

  return (
    <div>
      <NavbarMenu></NavbarMenu>
      <Card className="profile-settings-card">
        <Card.Header className="d-flex settings-card-tabs p-0">
          <Col
            className="col-auto settings-tab px-3"
            style={{
              backgroundColor: activeTab === "password" ? "#FFF" : "",
              color: activeTab === "password" ? "var(--primary-color)" : "",
              borderRadius: activeTab === "password" ? "10px 10px 0px 0px" : "",
            }}
            onClick={() => setActiveTab("password")}
          >
            Change Password
          </Col>
          <Col
            className="col-auto settings-tab px-3"
            style={{
              backgroundColor: activeTab === "privacy" ? "#FFF" : "",
              color: activeTab === "privacy" ? "var(--primary-color)" : "",
              borderRadius: activeTab === "privacy" ? "10px 10px 0px 0px" : "",
            }}
            onClick={() =>
              plan[0]?.privacy_priority != "Y"
                ? setShowUpgradeModal(true)
                : gotoPrivacySettings()
            }
          >
            Privacy Settings
          </Col>
          <Col
            className="col-auto settings-tab px-3"
            style={{
              backgroundColor: activeTab === "delete" ? "#FFF" : "",
              color: activeTab === "delete" ? "var(--primary-color)" : "",
              borderRadius: activeTab === "delete" ? "10px 10px 0px 0px" : "",
            }}
            onClick={() => setActiveTab("delete")}
          >
            Delete Account
          </Col>
        </Card.Header>
        <UpgradeModal
          upgradeNow={showUpgradeModal}
          closeModal={() => setShowUpgradeModal(false)}
        ></UpgradeModal>
        {activeTab === "privacy" ? (
          <Card.Body style={{ padding: "16px 32px" }}>
            {!loading ? (
              <>
                <h5 className="settings-title">Set who can see your profile</h5>
                <Form.Label>Profile Visibility</Form.Label>
                <div className="mb-3">
                  {visibility?.map((options, i) => (
                    <Form.Check
                      key={i}
                      style={{ color: "#4c524e" }}
                      type="radio"
                      name="visibility"
                      label={options.name}
                      value={options.id}
                      defaultChecked={
                        privacySettings.profile_visibility === options.id
                      }
                      onChange={(e) => {
                        values.profile_visibility = e.target.value;
                        e.target.value === "1"
                          ? setShowAll(false)
                          : setShowAll(true);
                      }}
                    />
                  ))}
                </div>
                {showAll ? (
                  <div>
                    <Form.Group className="mb-3">
                      <FormLabel>State</FormLabel>
                      <Select
                        placeholder=""
                        isSearchable={true}
                        options={states}
                        getOptionLabel={(options) => options["name"]}
                        getOptionValue={(options) => options["id"]}
                        components={animatedComponents}
                        defaultValue={states.filter(
                          (state) => state.id === privacySettings?.state
                        )}
                        onChange={getDistricts}
                      />
                    </Form.Group>

                    {districts.length != 0 && (
                      <Form.Group className="mb-3">
                        <FormLabel>Locations</FormLabel>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          placeholder=""
                          isSearchable={true}
                          closeMenuOnScroll={false}
                          blurInputOnSelect={false}
                          options={districts}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["id"]}
                          components={animatedComponents}
                          defaultValue={districts.filter((district) =>
                            privacySettings?.locations
                              ?.split("~")
                              .includes(district.id)
                              ? district
                              : ""
                          )}
                          onChange={(e) =>
                            (values.locations = e
                              .map((opt) => opt.id)
                              .join("~"))
                          }
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="mb-3">
                      <FormLabel>Education</FormLabel>
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        placeholder=""
                        isSearchable={true}
                        closeMenuOnScroll={false}
                        blurInputOnSelect={false}
                        options={listValue.qualification}
                        getOptionLabel={(options) => options["name"]}
                        getOptionValue={(options) => options["id"]}
                        components={animatedComponents}
                        defaultValue={listValue.qualification?.filter((qual) =>
                          privacySettings?.education
                            ?.split("~")
                            .includes(qual.id)
                            ? qual
                            : ""
                        )}
                        onChange={(e) =>
                          (values.education = e.map((opt) => opt.id).join("~"))
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <FormLabel>Job</FormLabel>
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        placeholder=""
                        isSearchable={true}
                        closeMenuOnScroll={false}
                        blurInputOnSelect={false}
                        options={listValue.occupation}
                        getOptionLabel={(options) => options["name"]}
                        getOptionValue={(options) => options["id"]}
                        components={animatedComponents}
                        defaultValue={listValue.occupation?.filter((job) =>
                          privacySettings?.job?.split("~").includes(job.id)
                            ? job
                            : ""
                        )}
                        onChange={(e) =>
                          (values.job = e.map((opt) => opt.id).join("~"))
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <FormLabel>Marital Status</FormLabel>
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        placeholder=""
                        isSearchable={true}
                        closeMenuOnScroll={false}
                        blurInputOnSelect={false}
                        options={listValue.marital_status}
                        getOptionLabel={(options) => options["name"]}
                        getOptionValue={(options) => options["id"]}
                        components={animatedComponents}
                        defaultValue={listValue.marital_status?.filter(
                          (status) =>
                            privacySettings?.marital_status
                              ?.split("~")
                              .includes(status.id)
                              ? status
                              : ""
                        )}
                        onChange={(e) =>
                          (values.marital_status = e
                            .map((opt) => opt.id)
                            .join("~"))
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <FormLabel>Age</FormLabel>
                      <div className="d-flex">
                        <select
                          className="form-control me-2"
                          onChange={(e) => {
                            values.ageFrom = e.target.value;
                            setAgeFrom(e.target.value);
                          }}
                        >
                          <option
                            selected
                            hidden
                            value={privacySettings.age_limit.split("-")[0]}
                          >
                            {privacySettings.age_limit.split("-")[0]}
                          </option>
                          {ageOptions.map((option) => (
                            <option
                              hidden={
                                ageTo == "" ? option <= ageTo : option >= ageTo
                              }
                              value={option}
                            >
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          className="form-control ms-2"
                          onChange={(e) => {
                            values.ageTo = e.target.value;
                            setAgeTo(e.target.value);
                          }}
                        >
                          <option
                            hidden
                            selected
                            value={privacySettings.age_limit.split("-")[1]}
                          >
                            {privacySettings.age_limit.split("-")[1]}
                          </option>
                          {ageOptions.map((option) => (
                            <option hidden={option <= agefrom} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                ) : (
                  ""
                )}
                <h5 className="settings-title">Set who can see your content</h5>
                <Form.Label>Contact Info</Form.Label>
                <div className="mb-3">
                  {contactLockOptions?.map((options, i) => (
                    <Form.Check
                      key={i}
                      style={{ color: "#4c524e" }}
                      type="radio"
                      name="contact_info"
                      label={options.name}
                      value={options.id}
                      defaultChecked={isContactLocked === options.id}
                      onChange={(e) => {
                        setIsContactLocked(e.target.value);
                      }}
                    />
                  ))}
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    className="invite-button"
                    onClick={savePrivacySettings}
                  >
                    Save
                  </Button>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </Card.Body>
        ) : activeTab === "password" ? (
          <Card.Body className="p-5">
            <Formik
              enableReinitialize={true}
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              onSubmit={changePassword}
              validate={async (values) => {
                const errors = {};

                if (!values.currentPassword) {
                  errors.currentPassword = "Enter your current password";
                }
                if (values.currentPassword.length <= 5) {
                  errors.currentPassword = "Enter minimum 6 characters!!";
                }
                if (values.currentPassword.length > 15) {
                  errors.currentPassword = "Enter Maximum 15 characters!!";
                }
                if (values.currentPassword) {
                  await fetch(`${baseURL}/check_password`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      id: localStorage.currentUser,
                      pass: values.currentPassword,
                    }),
                  })
                    .then((res) => res.json())
                    .then((res) => {
                      if (!res)
                        errors.currentPassword = "Enter your current password";
                    });
                }
                if (!values.newPassword) {
                  errors.newPassword = "Enter a new password";
                }
                if (values.newPassword.length <= 5) {
                  errors.newPassword = "Enter minimum 6 characters!!";
                }
                if (values.newPassword.length > 15) {
                  errors.newPassword = "Enter Maximum 15 characters!!";
                }
                if (!values.confirmPassword) {
                  errors.confirmPassword = "Confirm new password";
                }
                if (values.confirmPassword.length <= 5) {
                  errors.confirmPassword = "Enter minimum 6 characters!!";
                }
                if (values.confirmPassword.length > 15) {
                  errors.confirmPassword = "Enter Maximum 15 characters!!";
                }
                if (values.confirmPassword != values.newPassword) {
                  errors.confirmPassword = "Password does not match";
                }

                return errors;
              }}
            >
              {({
                resetForm,
                handleSubmit,
                errors,
                touched,
                values,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Current Password</label>
                    <div className="position-relative">
                      <Field
                        className="field form-control"
                        type={showPassword0 ? "text" : "password"}
                        name="currentPassword"
                      />
                      {showPassword0 ? (
                        <BsEyeSlash
                          color="#5B6770"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "15px",
                          }}
                          onClick={() => setShowPassword0(false)}
                        />
                      ) : (
                        <BsEye
                          color="#5B6770"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "15px",
                          }}
                          onClick={() => setShowPassword0(true)}
                        />
                      )}
                    </div>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.currentPassword && touched.currentPassword
                        ? errors.currentPassword
                        : ""}
                    </div>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">New Password</label>
                    <div className="position-relative">
                      <Field
                        className="field form-control"
                        type={showPassword1 ? "text" : "password"}
                        name="newPassword"
                      />
                      {showPassword1 ? (
                        <BsEyeSlash
                          color="#5B6770"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "15px",
                          }}
                          onClick={() => setShowPassword1(false)}
                        />
                      ) : (
                        <BsEye
                          color="#5B6770"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "15px",
                          }}
                          onClick={() => setShowPassword1(true)}
                        />
                      )}
                    </div>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.newPassword && touched.newPassword
                        ? errors.newPassword
                        : ""}
                    </div>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Confirm Password</label>
                    <div className="position-relative">
                      <Field
                        className="field form-control"
                        type={showPassword2 ? "text" : "password"}
                        name="confirmPassword"
                      />
                      {showPassword2 ? (
                        <BsEyeSlash
                          color="#5B6770"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "15px",
                          }}
                          onClick={() => setShowPassword2(false)}
                        />
                      ) : (
                        <BsEye
                          color="#5B6770"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "15px",
                          }}
                          onClick={() => setShowPassword2(true)}
                        />
                      )}
                    </div>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.confirmPassword && touched.confirmPassword
                        ? errors.confirmPassword
                        : ""}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="invite-button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Change Password
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        ) : (
          <div className="p-5">
            <h2 className="title mb-3">Do you want to delete profile?</h2>
            <div className="mx-3 mb-5">
              <div style={{ color: "#5B6770", marginBottom: "8px" }}>
                This action is permanent. Profile once deleted cannot be
                restored
              </div>
              <Form.Check
                checked={confirm === 1}
                style={{ color: "#5B6770" }}
                value={confirm}
                name="delete"
                label="Yes, I want to delete my profile"
                onChange={() => (confirm === 0 ? setConfirm(1) : setConfirm(0))}
              />
            </div>
            {confirm === 1 ? (
              <div className="mx-3">
                Please select reason for deletion
                <Form.Check
                  className="mb-3 mt-3"
                  checked={reason === "Found a partner on click4marry"}
                  name="reason"
                  type="radio"
                  label="Found a partner on click4marry"
                  onChange={() => setReason("Found a partner on click4marry")}
                />
                <Form.Check
                  className="mb-3"
                  checked={reason === "Found a partner through other source"}
                  name="reason"
                  type="radio"
                  label="Found a partner through other source"
                  onChange={() =>
                    setReason("Found a partner through other source")
                  }
                />
                <Form.Check
                  className="mb-3"
                  checked={reason === "Partner search currently put on hold"}
                  name="reason"
                  type="radio"
                  label="Partner search currently put on hold"
                  onChange={() =>
                    setReason("Partner search currently put on hold")
                  }
                />
                <Form.Check
                  className="mb-3"
                  checked={reason === "Not getting suitable response"}
                  name="reason"
                  type="radio"
                  label="Not getting suitable response"
                  onChange={() => setReason("Not getting suitable response")}
                />
                <Form.Check
                  className="mb-3"
                  checked={reason === "Prefer not to mention"}
                  name="reason"
                  type="radio"
                  label="Prefer not to mention"
                  onChange={() => setReason("Prefer not to mention")}
                />
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="more"
                    onChange={(e) => setMoreReason(e.target.value)}
                  />
                </Form.Group>
                {reason === "Found a partner on click4marry" ? (
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Partner ID"
                      onChange={(e) => setPartner(e.target.value)}
                    />
                  </Form.Group>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-end">
                  <Button className="invite-button" onClick={deleteAccount}>
                    Delete
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </Card>
      <Footer></Footer>
    </div>
  );
}
