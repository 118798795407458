import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { MdCheck } from "react-icons/md";
import { environment } from "../../environment/env";
import "./ProfileMatch.css";

export default function ProfileMatch(props) {
  const baseURL = environment.hostname;
  const imageURL = environment.imageURL;
  const [preference, setPreference] = useState([]);
  const [candidate, setCandidate] = useState([]);

  useEffect(() => {
    let preferenceApi = fetch(`${baseURL}/get_preference`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        profid: localStorage.visitingUser,
      }),
    });

    let candidateApi = fetch(`${baseURL}/candidate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        profid: localStorage.visitingUser,
      }),
    });

    Promise.all([preferenceApi, candidateApi])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        setPreference(response[0][0]);
        setCandidate(response[1][0]);
      });
  }, []);

  let checkMatchContains = (range, value) => {
    if (
      range === "Any" ||
      range === "Doesn't Matter" ||
      range === "" ||
      range == null ||
      range === "0"
    ) {
      return true;
    } else {
      if (range.includes(value)) {
        return true;
      } else {
        return false;
      }
    }
  };

  let checkMatchRange = (range, value) => {
    if (
      range === "0" ||
      range === null ||
      range === undefined ||
      range === "Any" ||
      range === "Doesn't Matter" ||
      range === ""
    ) {
      return true;
    } else {
      const rangevalues = range.split("-");

      if (rangevalues[0] <= value && value <= rangevalues[1]) {
        return true;
      } else {
        return false;
      }
    }
  };

  let checkPlanAndImage = (image, album_visibility, gender) => {
    if (
      (album_visibility == "55" && localStorage.plan == "2") ||
      album_visibility == "56" ||
      image == null
    ) {
      if (gender === "Male" || localStorage.gender === "Female") {
        return (image = imageURL + "avatar_Male.png");
      } else {
        return (image = imageURL + "avatar_Female.png");
      }
    }
    return imageURL + image;
  };

  let checkMatchCount = (values) => {
    const commonItems = [];

    for (let i = 0; i < values.length; i++) {
      if (values[i][2] === "contains") {
        if (
          values[i][0] === "Any" ||
          values[i][0] === "Doesn't Matter" ||
          values[i][0] === "" ||
          values[i][0] == null ||
          values[i][0] === "0"
        ) {
          commonItems.push(true);
        } else {
          if (values[i][0].includes(values[i][1])) {
            commonItems.push(true);
          } else {
            commonItems.push(false);
          }
        }
      } else if (values[i][2] === "range") {
        if (
          values[i][0] === "0" ||
          values[i][0] === null ||
          values[i][0] === undefined ||
          values[i][0] === "Any" ||
          values[i][0] === "Doesn't Matter" ||
          values[i][0] === ""
        ) {
          commonItems.push(true);
        } else {
          const prefValues = values[i][0].split("-");

          if (prefValues[0] <= values[i][1] && values[i][1] <= prefValues[1]) {
            commonItems.push(true);
          } else {
            commonItems.push(false);
          }
        }
      }
    }
    return commonItems;
  };

  return (
    <Card className="match-card">
      <Card.Title className="match-title">Profile Match</Card.Title>
      <Card.Body>
        <div className="d-flex justify-content-center">
          <div className="position-relative w-auto">
            {props?.face_image ? (
              <Image
                src={checkPlanAndImage(
                  props?.face_image,
                  props?.albumVisibility
                )}
                style={{ marginRight: "114px" }}
                roundedCircle
                height="146px"
                width="146px"
                className="blur-image match-face-image"
              />
            ) : candidate.gender === "Male" ? (
              <Image
                src={imageURL + "avatar_Female.png"}
                style={{ marginRight: "114px" }}
                roundedCircle
                height="146px"
                width="146px"
                className=" match-face-image"
              />
            ) : (
              <Image
                src={imageURL + "avatar_Male.png"}
                style={{ marginRight: "114px" }}
                roundedCircle
                height="146px"
                width="146px"
                className=" match-face-image"
              />
            )}
            {localStorage.face_image && localStorage.face_image != "null" ? (
              <Image
                src={imageURL + localStorage.face_image}
                roundedCircle
                height="146px"
                width="146px"
                className="position-absolute  match-face-image"
              />
            ) : localStorage.gender === "Male" ? (
              <Image
                src={imageURL + "avatar_Male.png "}
                roundedCircle
                height="146px"
                width="146px"
                className="position-absolute  match-face-image"
              />
            ) : (
              <Image
                src={imageURL + "avatar_Female.png"}
                roundedCircle
                height="146px"
                width="146px"
                className="position-absolute  match-face-image"
              />
            )}
          </div>
        </div>
        <p style={{ textAlign: "center", paddingTop: "1rem" }}>
          {" "}
          Match{" "}
          {
            checkMatchCount([
              [preference.partner_age, candidate?.age, "range"],
              [preference?.partner_city, candidate?.cityname, "contains"],
              [preference.partner_height, candidate?.height, "range"],
              [preference.partner_job_sector, candidate?.u_sector, "contains"],
              [
                preference.partner_marital_status,
                candidate?.mstatus,
                "contains",
              ],
              [
                preference.partner_physical_status,
                candidate?.pstatus,
                "contains",
              ],
              [preference.partner_weight, candidate?.weight, "range"],
              [preference.partner_religion, candidate?.religion, "contains"],
              [preference.partner_place, candidate?.placename, "contains"],
              [preference.partner_diet, candidate?.u_diet, "contains"],
              [preference.partner_complexion, candidate?.complex, "contains"],
              [preference.partner_qualification, candidate?.u_qual, "contains"],
              [preference.partner_occupation, candidate?.occu, "contains"],
              [preference.partner_caste, candidate?.cast, "contains"],
              [preference.partner_family_status, candidate?.family, "contains"],
              [preference.partner_drink, candidate?.drink, "contains"],
              [preference.partner_smoke, candidate?.smoke, "contains"],
              [preference.partner_annual_income, candidate?.income, "contains"],
            ]).filter((i) => i === true).length
          }{" "}
          /18 to your preference
        </p>

        <Row
          className="justify-content-between align-items-center mb-4 mt-5 "
          style={{ fontSize: "20px" }}
        >
          <Col lg={6} className="justify-content-between d-flex">
            <div className="">
              {localStorage.gender === "Male" ? "Preference" : "Preference"}
            </div>
            <div className="w-auto">Match</div>
          </Col>
        </Row>
        <Row className="mb-2 ">
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Marital Status</div>
              <div className="w-auto">{preference?.partner_marital_status}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_marital_status,
                candidate?.mstatus
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Physical Status</div>
              <div className="w-auto">
                {preference?.partner_physical_status}
              </div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_physical_status,
                candidate?.pstatus
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>

          {/* <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex ps-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">City</div>
              <div className="w-auto">{preference?.partner_city}</div>
            </div>
            <div className="w-auto">
              {checkMatc---hContains(
                preference.partner_city,
                candidate?.cityname
              ) ? (
                <MdCh---eck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col> */}
        </Row>
        <Row className="justify-content-between align-items-center mb-2">
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">
                Age Between (in cm)
              </div>
              <div className="w-auto">
                {preference?.partner_age === "0"
                  ? "Any"
                  : preference?.partner_age}
              </div>
            </div>
            <div className="w-auto">
              {checkMatchRange(preference.partner_age, candidate?.age) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">
                Height Between (in cm)
              </div>
              <div className="w-auto">
                {preference?.partner_height === "0"
                  ? "Any"
                  : preference?.partner_height}
              </div>
            </div>
            <div className="w-auto">
              {checkMatchRange(preference.partner_height, candidate?.height) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center mb-2">
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">
                Weight Between (in kg)
              </div>

              <div className="w-auto">
                {preference?.partner_weight == "0" ||
                preference?.partner_weight == null
                  ? "Any"
                  : preference?.partner_weight.replaceAll("null", "")}
              </div>
            </div>
            <div className="w-auto">
              {checkMatchRange(preference.partner_weight, candidate?.weight) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Complexion</div>
              <div className="w-auto">{preference?.partner_complexion}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_complexion,
                candidate?.complex
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center mb-2">
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Job </div>
              <div className="w-auto"> {preference?.partner_occupation}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_occupation,
                candidate?.occu
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Qualification</div>
              <div className="w-auto">{preference?.partner_qualification}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_qualification,
                candidate?.u_qual
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center mb-2">
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Sector </div>
              <div className="w-auto"> {preference?.partner_job_sector}</div>
            </div>

            <div className="w-auto">
              {checkMatchContains(
                preference.partner_job_sector,
                candidate?.u_sector
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>{" "}
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Annual Income</div>
              <div className="w-auto">
                {preference?.partner_annual_income === "0"
                  ? "Any"
                  : preference?.partner_annual_income}
              </div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_annual_income,
                candidate?.income
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center mb-2">
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Family Status</div>
              <div className="w-auto">{preference?.partner_family_status}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_family_status,
                candidate?.family
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">State</div>
              <div className="w-auto">{preference?.partner_city}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_city,
                candidate?.cityname
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center mb-2">
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">District</div>
              <div className="w-auto">{preference?.partner_place}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_place,
                candidate?.placename
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Religion</div>
              <div className="w-auto">{preference?.partner_religion}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_religion,
                candidate?.religion
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center mb-2">
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Subgroup</div>
              <div className="w-auto">{preference?.partner_caste}</div>
            </div>

            <div className="w-auto">
              {checkMatchContains(preference.partner_caste, candidate?.cast) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>

          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Diet</div>
              <div className="w-auto">{preference?.partner_diet}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_diet,
                candidate?.u_diet
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center mb-2">
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Drink</div>
              <div className="w-auto">
                {preference?.partner_drink ? preference?.partner_drink : "Any"}
              </div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_drink,
                candidate?.drink
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            className="justify-content-between d-flex pe-lg-4"
          >
            <div className="w-auto">
              <div className="w-auto profile-match-label">Smoke</div>
              <div className="w-auto">{preference?.partner_smoke}</div>
            </div>
            <div className="w-auto">
              {checkMatchContains(
                preference.partner_smoke,
                candidate?.smoke
              ) ? (
                <MdCheck color="var(--primary-color)" />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
