import React, { Component } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import {
  MdOutlinePhoto,
  MdFavoriteBorder,
  MdOutlineAccountBox,
  MdOutlineTipsAndUpdates,
  MdOutlineAddPhotoAlternate,
} from "react-icons/md";
import { BsChatDots } from "react-icons/bs";
import {
  CardComponent,
  Footer,
  MyProfile,
  NavbarMenu,
  RoundedProfile,
  Notification,
  Loader,
  NoData,
} from "../../Components";
import { Link } from "react-router-dom";
import { environment } from "../../environment/env";
import { addVerifiedBadgeImage, planBanner } from "../../assets";
import "./Dashboard.css";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import IgnoredProfiles from "../IgnoredProfiles/IgnoredProfiles";
const baseURL = environment.hostname;
let myProfile,
  shortlist,
  recent,
  pendingRequest,
  adminRecommented,
  recentlyViewed,
  contactsViewed,
  whoShortlisted,
  whoViewed,
  myPlan,
  verification,
  ignoredProfiles,
  idProofTypes;

let initialValues = { name: "", type: "Adhaar", number: "" };
let encodedFrontImage, encodedBackImage;

export default class Dashboard extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      showIdproofModal: false,
      front: false,
      back: false,
      frontfile: false,
      backfile: false,
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this._isMounted = true;
    let basicInfoApi = fetch(`${baseURL}/basic_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        profid: localStorage.currentUser,
      }),
    });

    let shortlistApi = fetch(`${baseURL}/shortlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let viewedProfilesApi = fetch(`${baseURL}/viewed_profiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let countPendingApi = fetch(`${baseURL}/count_pending`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let adminRecommendApi = fetch(`${baseURL}/admin_recommented`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });
    let recentlyViewedApi = fetch(`${baseURL}/viewed_profiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let contactsViewedApi = fetch(`${baseURL}/viewed_contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let whoShortlistApi = fetch(`${baseURL}/who_shortlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let whoViewApi = fetch(`${baseURL}/who_viewed_contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let myPlanApi = fetch(`${baseURL}/myplan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let verificationStatusCheckApi = fetch(`${baseURL}/status_check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });

    let ignoredProfileApi = fetch(`${baseURL}/ignored_profiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let verifyIdTpyes = fetch(`${baseURL}/idtype`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    Promise.all([
      basicInfoApi,
      shortlistApi,
      viewedProfilesApi,
      countPendingApi,
      adminRecommendApi,
      recentlyViewedApi,
      contactsViewedApi,
      whoShortlistApi,
      whoViewApi,
      myPlanApi,
      verificationStatusCheckApi,
      ignoredProfileApi,
      verifyIdTpyes,
    ])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        myProfile = response[0][0];
        shortlist = response[1];
        recent = response[2];
        pendingRequest = response[3];
        adminRecommented = response[4];
        recentlyViewed = response[5];
        contactsViewed = response[6];
        whoShortlisted = response[7];
        whoViewed = response[8];
        myPlan = response[9][0];
        verification = response[10];
        ignoredProfiles = response[11];
        idProofTypes = response[12];
        localStorage.setItem("plan", myPlan.pid);
        localStorage.setItem("planName", myPlan.planname);
        this.setState({
          isLoading: false,
        });
        this.updateLogin();
      })
      .catch((err) => {
        localStorage.removeItem("session");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("visitingUser");
        localStorage.removeItem("visitingUser");
        localStorage.removeItem("plan");
        localStorage.removeItem("planName");
        window.location.assign("/#/login");
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
    initialValues = {};
  }

  onHideIdProofModal = () => {
    this.setState({
      showIdproofModal: false,
      backfile: false,
      frontfile: false,
      back: false,
      front: false,
    });
  };

  async updateLogin() {
    await fetch(`${baseURL}/loginupdate`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
      headers: {
        "Content-type": "application/json",
      },
    }).then((response) => {});
  }

  uploadFrontImage = (e) => {
    var exts = /(.png|.webp|.jpg|.jpeg)/;
    if (e.target.value.match(exts)) {
      this.setState({
        front: false,
        frontfile: true,
      });
      let file = e.target.files[0];
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          encodedFrontImage = reader.result
            .toString()
            .replace(/^data:(.*,)?/, "");
          if (encodedFrontImage.length % 4 > 0) {
            encodedFrontImage += "=".repeat(4 - (encodedFrontImage.length % 4));
          }
          resolve(encodedFrontImage);
        };
        reader.onerror = (error) => reject(error);
      });
    } else {
      toast.error("Please choose  valid Image file like .png|.webp|.jpg|.jpeg");
    }
  };

  uploadBackImage = (e) => {
    var exts = /(.png|.webp|.jpg|.jpeg)/;
    if (e.target.value.match(exts)) {
      this.setState({
        back: false,
        backfile: true,
      });
      let file = e.target.files[0];
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          encodedBackImage = reader.result
            .toString()
            .replace(/^data:(.*,)?/, "");
          if (encodedBackImage.length % 4 > 0) {
            encodedBackImage += "=".repeat(4 - (encodedBackImage.length % 4));
          }
          resolve(encodedBackImage);
        };
        reader.onerror = (error) => reject(error);
      });
    } else {
      toast.error("Please choose a valid Image file");
    }
  };

  submitIdproof = (values) => {
    if (values.number == "") {
      toast.error("Please enter ID Number", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.onHideIdProofModal();
    } else {
      values.uid = localStorage.currentUser;
      values.file = encodedFrontImage;
      values.file2 = encodedBackImage || "";
      values.idno = values.number;
      this.onHideIdProofModal();
      fetch(`${baseURL}/verify_id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((res) => {
          this.refreshUI();

          if (res === "1") {
            toast.success("ID Proof Uploaded Successfully");
            this.onHideIdProofModal();
          } else {
            toast.error(res.message, { position: toast.POSITION.TOP_CENTER });
            this.onHideIdProofModal();
          }
        });
    }
  };

  refreshUI = () => {
    let verificationStatusCheckApi = fetch(`${baseURL}/status_check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });

    Promise.all([verificationStatusCheckApi])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        verification = response[0];

        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {});
  };

  removeItemFromIgnoredProfiles = (id) => {
    ignoredProfiles.filter((i, j) => {});
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div>
          <Modal
            show={this.state.showIdproofModal}
            onHide={this.onHideIdProofModal}
          >
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values) => this.submitIdproof(values)}
              validate={async (values) => {
                const errors = {};

                let regexname = /^[a-z A-Z]*\.?[a-z A-Z]*$/;
                if (!regexname.test(values?.name))
                  errors.name = "Provide your valid name";
                let kerName = values.name;
                if (kerName.replaceAll(" ", "") < 3)
                  errors.name = "Provide  valid name";
                if (!values.name) errors.name = "Enter your name";

                if (!values.type) errors.type = "Enter ID type";

                // let regexIDnum =
                //   /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
                // if (!regexIDnum.test(values?.number))
                //   errors.number = "Provide your valid ID number";
                if (!values.number) errors.number = "Enter ID number";

                if (this.state.frontfile === false) {
                  this.setState({
                    front: true,
                  });
                }
                if (this.state.backfile === false) {
                  this.setState({
                    back: true,
                  });
                }

                return errors;
              }}
            >
              {({
                isSubmitting,
                dirty,
                handleChange,
                handleSubmit,
                errors,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit} onReset={this.onHideIdProofModal}>
                  <div>
                    <div>
                      <label className="label form-label">
                        Full Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        className="field form-control"
                        type="text"
                        name="name"
                        value={values.name}
                      />
                      {errors.name && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.name}
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <label className="label form-label">
                        ID Type <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        className="field form-select"
                        name="type"
                        as="select"
                        value={values.type}
                      >
                        {idProofTypes.map((idProofType, index) => {
                          return (
                            <option
                              key={index}
                              value={idProofType.type}
                              selected
                            >
                              {idProofType.type}
                            </option>
                          );
                        })}
                      </Field>
                      {errors.type && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.type}
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <label className="label form-label">
                        ID Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        className="field form-control"
                        type="text"
                        name="number"
                        value={values.number}
                      />
                      {errors.number && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {errors.number}
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <label className="label form-label">
                        Upload Front Image{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <label
                        role="button"
                        className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          name="frontImg"
                          className="form-control"
                          onChange={(e) => this.uploadFrontImage(e)}
                        />
                      </label>
                      {this.state.front && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Upload Front image
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <label className="label form-label">
                        Upload Back Image{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <label
                        role="button"
                        className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          name="backImg"
                          className="form-control"
                          onChange={(e) => this.uploadBackImage(e)}
                        />
                      </label>
                      {this.state.back && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Upload Back image
                        </div>
                      )}
                    </div>
                    <div
                      className="position-sticky d-flex align-items-center"
                      style={{ bottom: "0", backgroundColor: "white" }}
                    >
                      <Button
                        className="register-login-button"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                      <Button className="cancel-login-button m-2" type="reset">
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal>
          <NavbarMenu></NavbarMenu>
          <div className="dashboard">
            <Row>
              <Col md={4} xs={12}>
                <MyProfile myProfile={myProfile} dashboard="true"></MyProfile>
              </Col>
              <Col md={8} xs={12}>
                <Card className="dashboard-all-notification-cards h-100">
                  <Row>
                    <Col md={6} lg={3} xs={6} className="pb-2">
                      <Card className="dashboard-notification-card align-items-center justify-content-center">
                        <Link
                          to="/express-interest"
                          className="d-flex align-items-center flex-column"
                          state={{ title: "Interests", from: "/dashboard" }}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          <MdFavoriteBorder
                            size="30px"
                            color="var(--primary-color)"
                            className="position-relative"
                          />
                          <Notification
                            count={pendingRequest?.express}
                          ></Notification>
                          <Card.Title>Interest</Card.Title>
                          <Card.Text
                            align="center"
                            style={{ fontSize: "12px", color: "#909090" }}
                          >
                            You can see the interest request you have sent and
                            received here.
                          </Card.Text>
                        </Link>
                      </Card>
                    </Col>
                    <Col md={6} lg={3} xs={6} className="pb-2">
                      <Card className="dashboard-notification-card align-items-center justify-content-center">
                        <Link
                          to="/album-request"
                          className="d-flex align-items-center flex-column"
                          state={{ title: "Requests", from: "/dashboard" }}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          <MdOutlinePhoto
                            size="30px"
                            color="var(--primary-color)"
                          />
                          <Notification
                            count={pendingRequest?.album}
                          ></Notification>
                          <Card.Title>Request</Card.Title>
                          <Card.Text
                            align="center"
                            style={{ fontSize: "12px", color: "#909090" }}
                          >
                            You can see Album requests and contact requests
                            here.
                          </Card.Text>
                        </Link>
                      </Card>
                    </Col>
                    <Col md={6} lg={3} xs={6} className="pb-2">
                      <Card className="dashboard-notification-card align-items-center justify-content-center">
                        <Link
                          to="/messages"
                          className="d-flex align-items-center flex-column"
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          <BsChatDots
                            size="30px"
                            color="var(--primary-color)"
                          />
                          <Notification
                            count={pendingRequest?.chat_only}
                          ></Notification>
                          <Card.Title>Messages</Card.Title>
                          <Card.Text
                            align="center"
                            style={{ fontSize: "12px", color: "#909090" }}
                          >
                            Your can see the messages you have send and received
                            here.
                          </Card.Text>
                        </Link>
                      </Card>
                    </Col>
                    <Col md={6} lg={3} xs={6} className="pb-2">
                      <Card className="dashboard-notification-card align-items-center justify-content-center">
                        <Link
                          to="/video-schedule"
                          className="d-flex align-items-center flex-column"
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          <MdOutlineAccountBox
                            size="30px"
                            color="var(--primary-color)"
                          />
                          <Notification
                            count={pendingRequest?.video}
                          ></Notification>
                          <Card.Title>Video Meet</Card.Title>
                          <Card.Text
                            align="center"
                            style={{ fontSize: "12px", color: "#909090" }}
                          >
                            You can see all the video request send and received
                            here.
                          </Card.Text>
                        </Link>
                      </Card>
                    </Col>
                    {
                      // (window.innerWidth < 560) ? <></> :

                      myPlan.pid != "2" ? (
                        verification.length > 0 ? (
                          <>
                            <Card.Title
                              style={{
                                marginTop: "19px",
                                marginBottom: "19px",
                              }}
                            >
                              Tips
                              <MdOutlineTipsAndUpdates
                                className="ms-1"
                                color="var(--secondary-color)"
                                size="24px"
                              />
                            </Card.Title>
                            <Card className="badge-card">
                              <Row className="m-0">
                                <Col className="col-auto p-0">
                                  <Image src={addVerifiedBadgeImage} />
                                </Col>
                                <Col>
                                  <Card.Title>
                                    {verification[0].head}
                                  </Card.Title>
                                  <Card.Subtitle
                                    style={{
                                      fontSize: "14px",
                                      color: "#C4C4C4",
                                    }}
                                  >
                                    {verification[0].body}
                                  </Card.Subtitle>
                                  {verification[0].head.includes("proof") ? (
                                    <Card.Link
                                      onClick={() =>
                                        this.setState({
                                          showIdproofModal: true,
                                        })
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Verify Now
                                    </Card.Link>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Card>
                          </>
                        ) : (
                          <div className="mt-5">
                            <h5 className="mb-4">Current Plan</h5>
                            <Row className="justify-content-between align-items-baseline mx-0">
                              <Col className="col-md-4 col-12 d-flex flex-column align-items-center mb-4 mb-md-0">
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "22px",
                                  }}
                                >
                                  &#8377;{myPlan.amount}
                                </div>
                                <div
                                  style={{
                                    fontWeight: "900",
                                    fontSize: "22px",
                                  }}
                                >
                                  {" "}
                                  {myPlan.planname}
                                </div>
                              </Col>
                              <Col className="col-md-4 col-12 d-flex flex-column align-items-center">
                                <svg width="200" height="200">
                                  <g transform="rotate(-90 100 100)">
                                    <circle
                                      r="70"
                                      cx="100"
                                      cy="100"
                                      fill="transparent"
                                      stroke="lightgrey"
                                      stroke-width="10px"
                                      stroke-dasharray="439.8"
                                      stroke-dashoffset="0"
                                    ></circle>
                                    <circle
                                      r="70"
                                      cx="100"
                                      cy="100"
                                      fill="transparent"
                                      stroke="var(--primary-color)"
                                      stroke-width="10px"
                                      stroke-dasharray="439.8"
                                      stroke-dashoffset={
                                        439.8 -
                                        (439.8 *
                                          ((myPlan.balancecontact * 100) /
                                            myPlan.contacts_allowed)) /
                                          100
                                      }
                                    ></circle>
                                  </g>
                                  <text
                                    x="50%"
                                    y="50%"
                                    dominant-baseline="central"
                                    text-anchor="middle"
                                  >
                                    {myPlan?.balancecontact}/{" "}
                                    {myPlan.contacts_allowed}
                                  </text>
                                </svg>
                                <div
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    paddingTop: "14px",
                                  }}
                                >
                                  {myPlan?.balancecontact} Contacts Left
                                </div>
                              </Col>
                              <Col className="col-md-4 col-12 d-flex flex-column align-items-center">
                                <svg width="200" height="200">
                                  <g transform="rotate(-90 100 100)">
                                    <circle
                                      r="70"
                                      cx="100"
                                      cy="100"
                                      fill="transparent"
                                      stroke="lightgrey"
                                      stroke-width="10px"
                                      stroke-dasharray="439.8"
                                      stroke-dashoffset="0"
                                    ></circle>
                                    <circle
                                      r="70"
                                      cx="100"
                                      cy="100"
                                      fill="transparent"
                                      stroke="var(--primary-color)"
                                      stroke-width="10px"
                                      stroke-dasharray="439.8"
                                      stroke-dashoffset={
                                        439.8 -
                                        (439.8 *
                                          ((myPlan.balancedays * 100) /
                                            myPlan.duration)) /
                                          100
                                      }
                                    ></circle>
                                  </g>
                                  <text
                                    x="50%"
                                    y="50%"
                                    dominant-baseline="central"
                                    text-anchor="middle"
                                  >
                                    {myPlan?.balancedays}/ {myPlan.duration}
                                  </text>
                                </svg>
                                <div
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    paddingTop: "14px",
                                  }}
                                >
                                  {myPlan?.balancedays} Days Left
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )
                      ) : (
                        <>
                          <Row
                            style={{
                              marginTop: "24px",
                              width: "100%",
                              paddingRight: "0",
                              paddingLeft: "0",
                            }}
                          >
                            <Image
                              src="https://click4marry.com/app/uploads/static_image/plan_upgrade_banner.png"
                              style={{ width: "100%" }}
                            />
                          </Row>
                        </>
                      )
                    }
                  </Row>
                </Card>
              </Col>
            </Row>

            {myPlan.admin_recommented_priority === "Y" ? (
              <Row>
                <h4 className="dashboard-title">Admin Recommended</h4>
                <Row
                  style={{ marginBottom: "57px" }}
                  className="horizontal-scroll mx-0"
                >
                  <Row>
                    {myPlan.admin_recommented_priority === "Y" ? (
                      adminRecommented?.length ? (
                        adminRecommented?.map((profile) => (
                          <div className="d-flex flex-column p-0 col-md-4">
                            <RoundedProfile profile={profile}></RoundedProfile>
                          </div>
                        ))
                      ) : (
                        <>
                          <div
                            style={{
                              minHeight: "75%",
                              color: "#C4C4C4",
                              fontSize: "20px",
                              marginTop: "10%",
                              marginBottom: "10%",
                            }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            No profiles to display. Please contact Admin
                          </div>
                        </>
                      )
                    ) : (
                      <Row className="align-items-center flex-column">
                        <div
                          style={{
                            fontSize: "16px",
                            color: "var(--primary-color)",
                          }}
                          className="w-auto mb-3"
                        >
                          This is a premium feature
                        </div>
                        <div
                          style={{ color: "#C4C4C4", fontSize: "14px" }}
                          className="w-auto mb-3"
                        >
                          You need higher plan to access this feature
                        </div>
                        <Link
                          className="p-2"
                          to="/plans-and-payment"
                          style={{
                            backgroundColor: "var(--primary-color)",
                            color: "#FFF",
                            width: "auto",
                            border: "none",
                            borderRadius: "10px",
                          }}
                        >
                          Upgrade Plan
                        </Link>
                      </Row>
                    )}
                  </Row>
                </Row>
              </Row>
            ) : (
              <></>
            )}
            <Row className="cards">
              <Col xs={12} sm={4} className="latest">
                <CardComponent
                  profiles={shortlist}
                  title="Shortlisted Profiles"
                ></CardComponent>
              </Col>
              <Col xs={12} sm={4} className="recommended">
                <CardComponent
                  profiles={recent}
                  title="Recently Viewed"
                ></CardComponent>
              </Col>
              <Col xs={12} sm={4} className="viewed">
                <CardComponent
                  recent={recentlyViewed}
                  contacts={contactsViewed}
                  whoShortlist={whoShortlisted}
                  whoView={whoViewed}
                  ignored={ignoredProfiles}
                ></CardComponent>
              </Col>
            </Row>
          </div>
          <Footer></Footer>
        </div>
      );
    } else {
      return (
        <div>
          <NavbarMenu></NavbarMenu>
          <Loader></Loader>
          <Footer></Footer>
        </div>
      );
    }
  }
}
