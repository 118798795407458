import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FiArrowLeft } from 'react-icons/fi';
import { Chats, Footer, NavbarMenu, VideoScheduleCard } from '../../Components';
import { environment } from '../../environment/env';
import './VideoSchedule.css'

const baseURL = environment.hostname;

export default class VideoSchedule extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pendingCount: '',
            chatId: '',
            image: '',
            isLoading: true,
            upcomingCalls: '',
            receivedCalls: '',
            sendCalls: '',
            recentCalls: '',
            activeTab: 'received',
            showVideoList: true,
            showVideoDetails: false,
        }
        this.countPending = this.countPending.bind(this);
    }

    countPending = () => {
        fetch(`${baseURL}/count_pending`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: localStorage.currentUser
            })
        }).then(res => res.json()).then(response => {
            this.setState({ pendingCount: response })
        })
    }

    getChatId = (chatId, image) => {
        fetch(`${baseURL}/schedule_details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: localStorage.currentUser,
                secondid: chatId,
                session_id: localStorage.session
            }),
        }).then(res => res.json()).then(response => {
            this.countPending();
            this.setState({
                chatId: chatId,
                image: image,
                isLoading: false,
                upcomingCalls: response.upcoming,
                receivedCalls: response.received,
                sendCalls: response.send,
                recentCalls: response.received,
                showVideoList: false,
                showVideoDetails: true,
            })
        })
    }

    changeTab = (e, tab) => {
        e.preventDefault()
        let calls = tab === 'received' ? this.state.receivedCalls : this.state.sendCalls
        this.setState({
            activeTab: tab,
            recentCalls: calls
        })
    }

    reShedule = () => {
        this.getChatId(this.state.chatId, this.state.image)
        this.countPending();
    }

    close = () => {
        this.setState({
          chatId: "",
          showVideoList: true,
          showVideoDetails: false,
        });
      };

    render() {
        return (
            <div>
                <NavbarMenu pending={this.state.pendingCount}></NavbarMenu>
                <div className='schedule-screen'>
                    <Row>
                        <Col md={4} style={{left:"50px"}} className={`d-lg-block  ${this.state.showVideoList ? "" : "d-none"}`}>
                            <Chats title='video' chatId={this.getChatId}></Chats>
                        </Col>
                        <Col className={`video-schedule-section h-100 p-0 d-lg-block ${this.state.showVideoDetails ? "" : "d-none"}`}>
                            {this.state.chatId ?
                                <div>

                                    <Row className='m-0 justify-content-center'>
                                        <div className='m-0 px-3 video-schedule-title' style={{flexDirection:'row'}}>
                                        <FiArrowLeft size={26} style={{marginRight:'5px', verticalAlign:'middle'}} onClick={this.close} className="back-chat-arrow" />
                                            Upcoming Calls </div>
                                        {this.state.upcomingCalls?.length ?
                                            this.state.upcomingCalls.map(call =>
                                                <VideoScheduleCard schedule={call} reschedule={this.reShedule} title='upcoming'></VideoScheduleCard>
                                            )
                                            :
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: '25vh' }}>No Upcoming calls</div>
                                        }
                                    </Row>
                                    <Row className='m-0 justify-content-center'>
                                        <div className='m-0 px-3 d-flex align-items-center'>
                                            <div className='video-schedule-title me-2'>Recent Calls</div>
                                            <div className='mx-2' style={{
                                                fontWeight: this.state.activeTab === 'received' ? '500' : 'normal',
                                                cursor: 'pointer'
                                            }} onClick={(e) => this.changeTab(e, 'received')}>Received</div>
                                            <div className='mx-2' style={{
                                                fontWeight: this.state.activeTab === 'send' ? '500' : 'normal',
                                                cursor: 'pointer'
                                            }} onClick={(e) => this.changeTab(e, 'send')}>Send</div>
                                        </div>
                                        {this.state.recentCalls?.length ?
                                            this.state.recentCalls.map(call =>
                                                <VideoScheduleCard schedule={call} title='recent'></VideoScheduleCard>
                                            )
                                            :
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: '25vh' }}>No Recent calls</div>
                                        }
                                    </Row>
                                </div>
                                :
                                <Row className="justify-content-center align-items-center" style={{ minHeight: '65vh' }}>Select a Profile to Display Schedules</Row>
                            }
                        </Col>
                    </Row>
                </div>
                <Footer></Footer>
            </div >
        );
    }
}
