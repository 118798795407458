import React, { Component } from "react";
import {
  Button,
  Col,
  Dropdown,
  Image,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Cropper from "react-easy-crop";
import { Footer, Loader, NavbarMenu } from "..";
import { environment } from "../../environment/env";
import "./ManagePhotos.css";
import uploadImage from "../../assets/uploadimg.png";
import getCroppedImg from "../CropImage/Cropper";

const baseURL = environment.hostname;
const imageURL = environment.imageURL;
const albumURL = environment.albumURL;
let encoded;

export default class ManagePhotos extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      title: "",
      image: null,
      uncroppedFile: null,
      croppedArea: null,
      Crop: { x: 0, y: 0 },
      Zoom: 1,
      isLoading: true,
      face_image: "",
      album2: "",
      album3: "",
      album4: "",
      album5: "",
      album6: "",
      listValue: [],
      albumVisibility: "",
      showCropModal: false,
      loadingPhoto1: false,
      loadingPhoto2: false,
      loadingPhoto3: false,
      loadingPhoto4: false,
      loadingPhoto5: false,
      loadingPhoto6: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let albumApi = fetch(`${baseURL}/user_album`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: localStorage.currentUser,
        profid: localStorage.currentUser,
      }),
    });

    let listValueApi = fetch(`${baseURL}/list_value`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let basicInfoApi = fetch(`${baseURL}/basic_info`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: localStorage.currentUser,
        profid: localStorage.currentUser,
      }),
    });

    Promise.all([albumApi, listValueApi, basicInfoApi])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        var alb_visibility;
        if (localStorage.getItem("manage_photo_album_request") === "1") {
          alb_visibility = "56";
          this.setalbumVisibility({
            id: "56",
            name: "Album Shared Users Only",
          });
        } else {
          alb_visibility = response[2][0]?.album_visibility;
        }
        localStorage.setItem("manage_photo_album_request", "0");

        this.setState({
          face_image: response[0][0]?.album,
          album2: response[0][1]?.album,
          album3: response[0][2]?.album,
          album4: response[0][3]?.album,
          album5: response[0][4]?.album,
          album6: response[0][5]?.album,
          listValue: response[1][0],
          albumVisibility: alb_visibility,
          isLoading: false,
        });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  uploadPhoto = (e, title) => {
    var exts = /(.png|.webp|.jpg|.jpeg)/;
    if (e.target.value.match(exts)) {
    } else {
      this.cancelAllLoading();
      toast.error(
        "Please choose a valid Image file like .png|.webp|.jpg|.jpeg"
      );
    }

    if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.value.match(exts)
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        this.setState({
          title: title,
          uncroppedFile: reader.result,
          showCropModal: true,
          // loadingPhoto1: true, loadingPhoto2: true, loadingPhoto3: true, loadingPhoto4: true, loadingPhoto5: true, loadingPhoto6: true,
        });
      });
    }
    // let file = event.target.files[0];
    // return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //         encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
    //         if ((encoded.length % 4) > 0) {
    //             encoded += '='.repeat(4 - (encoded.length % 4));
    //         }
    //         this.setState({
    //             image: reader.result,
    //             // showCropModal: true,
    //             title: title,
    //         })
    //         this.uploadCroppedImage();
    //         resolve(encoded);

    //     }
    //     reader.onerror = error => reject(error);
    //     this.setState({

    //         loadingPhoto1: false,
    //         loadingPhoto2: false,
    //         loadingPhoto3: false,
    //         loadingPhoto4: false,
    //         loadingPhoto5: false,
    //         loadingPhoto6: false,
    //     })

    // });
  };

  changePhoto = (e, title) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        fetch(`${baseURL}/delete_album`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
            type: title,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            this.setState({
              uncroppedFile: reader.result,
              showCropModal: true,
              title: title,
            });
            // this.uploadCroppedImage();
          });
      });
    }
    // reader.onerror = error => reject(error);
    // });
  };

  deletePhoto = (e, title) => {
    fetch(`${baseURL}/delete_album`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        type: title,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        let tempState = {};
        tempState[title] = "";
        this.setState(tempState);
      });
    e.preventDefault();
  };

  setalbumVisibility = (visibility) => {
    fetch(`${baseURL}/update_profile`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: localStorage.currentUser,
        album_visibility: visibility.id,
        type: "visibility",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          toast.success(
            `Album visiblity set as ${visibility.name} successfully!`
          );
          this.setState({
            albumVisibility: visibility.id,
          });
        } else toast.error("Error occured");
      });
  };

  onCropChange = (Crop) => {
    this.setState({ Crop });
  };

  onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    this.setState({
      croppedArea: croppedAreaPixels,
    });
  };

  onZoomChange = (Zoom) => {
    this.setState({ Zoom });
  };

  uploadCroppedImage = async () => {
    try {
      this.setState({ disabled: true, showCropModal: false });
      const croppedImage = await getCroppedImg(
        this.state.uncroppedFile,
        this.state.croppedArea
      );
      encoded = croppedImage.replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }

      if (this.state.title === "face_image") {
        fetch(`${baseURL}/face_image`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: localStorage.currentUser,
            face_image: encoded,
            session_id: localStorage.session,
          }),
        })
          .then((res) => {
            res.json();
            toast.success("Successfully Uploaded");
          })
          .then((response) => {
            fetch(`${baseURL}/user_album`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                id: localStorage.currentUser,
                profid: localStorage.currentUser,
              }),
            })
              .then((response) => response.json())
              .then((response) => {
                localStorage.setItem("face_image", response[0].album);
                this.setState({
                  showCropModal: false,
                  uncroppedFile: null,
                  croppedArea: null,
                  Zoom: 1,
                  disabled: false,
                  face_image: response[0].album,
                  album2: response[1].album,
                  album3: response[2].album,
                  album4: response[3].album,
                  album5: response[4].album,
                  album6: response[5].album,
                  loadingPhoto1: false,
                  loadingPhoto2: false,
                  loadingPhoto3: false,
                  loadingPhoto4: false,
                  loadingPhoto5: false,
                  loadingPhoto6: false,
                });
              });
          });
      } else {
        fetch(`${baseURL}/album_upload`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: localStorage.currentUser,
            check_session_id: localStorage.session,
            check_user_id: localStorage.currentUser,
            type: this.state.title,
            album: encoded,
          }),
        })
          .then((response) => {
            response.json();
            toast.success("Successfully Uploaded");
          })
          .then((response) => {
            fetch(`${baseURL}/user_album`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                id: localStorage.currentUser,
                profid: localStorage.currentUser,
              }),
            })
              .then((response) => response.json())
              .then((response) => {
                this.setState({
                  showCropModal: false,
                  disabled: false,
                  face_image: response[0].album,
                  album2: response[1].album,
                  album3: response[2].album,
                  album4: response[3].album,
                  album5: response[4].album,
                  album6: response[5].album,
                  loadingPhoto1: false,
                  loadingPhoto2: false,
                  loadingPhoto3: false,
                  loadingPhoto4: false,
                  loadingPhoto5: false,
                  loadingPhoto6: false,
                });
              });
          });
      }
    } catch (e) {
      console.error(e, "--");
    }
  };

  cancelAllLoading() {
    this.setState({
      showCropModal: false,
      loadingPhoto1: false,
      loadingPhoto2: false,
      loadingPhoto3: false,
      loadingPhoto4: false,
      loadingPhoto5: false,
      loadingPhoto6: false,
    });
  }

  render() {
    return (
      <>
        <NavbarMenu></NavbarMenu>
        {!this.state.isLoading ? (
          <div className="manage-photos p-0">
            <Row className="d-flex justify-content-end m-0 mb-3">
              Photo visibility
              <Dropdown className="w-auto">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  style={{ color: "#000" }}
                  className="more-dropdown p-0"
                >
                  {
                    this.state.listValue?.album_visibility?.filter(
                      (opt) => opt.id === this.state.albumVisibility
                    )[0].name
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.listValue?.album_visibility?.map((option) => (
                    <Dropdown.Item
                      onClick={() => this.setalbumVisibility(option)}
                    >
                      {option.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Row>
            <Row className="mx-0 mb-5">
              <Modal show={this.state.showCropModal}>
                <Modal.Body className="p-0">
                  <div
                    className="position-relative"
                    style={{ minHeight: "200px" }}
                  >
                    <Cropper
                      image={this.state.uncroppedFile}
                      crop={this.state.Crop}
                      zoom={this.state.Zoom}
                      aspect="1"
                      onCropChange={this.onCropChange}
                      onZoomChange={this.onZoomChange}
                      onCropComplete={this.onCropComplete}
                    />
                  </div>
                  <div className="d-flex mt-3 justify-content-end">
                    <Button
                      disabled={this.state.disabled}
                      onClick={() => this.cancelAllLoading()}
                      className="cancel-login-button m-2"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={this.state.disabled}
                      onClick={this.uploadCroppedImage}
                      className="register-login-button m-2"
                    >
                      Done
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <Col xs={6} md={4} className="d-flex justify-content-center my-2">
                {this.state.face_image ? (
                  <div className="position-relative album-size">
                    <Image
                      src={imageURL + this.state.face_image}
                      className="album-size"
                      style={{ borderRadius: "1.2vw" }}
                    />
                    <label
                      role="button"
                      className="position-absolute change-photo-icon p-2"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control position-absolute"
                        style={{ visibility: "hidden" }}
                        onChange={(e) => this.changePhoto(e, "face_image")}
                      />
                      <MdOutlinePhotoCamera
                        color="var(--primary-color)"
                        size={24}
                      />
                    </label>
                    <div
                      className="position-absolute delete-photo-icon p-2 cursor-pointer"
                      onClick={(e) => {
                        this.deletePhoto(e, "face_image");
                        this.setState({ loadingPhoto1: false });
                      }}
                    >
                      <IoCloseSharp color="var(--primary-color)" size={24} />
                    </div>
                  </div>
                ) : (
                  <div className="image-slot">
                    <label
                      role="button"
                      className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={(e) => {
                          this.uploadPhoto(e, "face_image");
                          this.setState({ loadingPhoto1: true });
                        }}
                      />
                      {this.state.loadingPhoto1 ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <>
                          <img
                            src={uploadImage}
                            style={{
                              width: "60px",
                              height: "auto",
                              paddingBottom: "0.5rem",
                            }}
                          />
                          <div>Profile Photo</div>
                        </>
                      )}
                    </label>
                  </div>
                )}
              </Col>
              <Col xs={6} md={4} className="d-flex justify-content-center my-2">
                {this.state.album2 ? (
                  <div className="position-relative album-size">
                    <Image
                      src={albumURL + this.state.album2}
                      className="album-size"
                      style={{ borderRadius: "25px" }}
                    />
                    <label
                      role="button"
                      className="position-absolute change-photo-icon p-2"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control position-absolute"
                        style={{ visibility: "hidden" }}
                        onChange={(e) => this.changePhoto(e, "album2")}
                      />
                      <MdOutlinePhotoCamera
                        color="var(--primary-color)"
                        size={24}
                      />
                    </label>
                    <div
                      className="position-absolute delete-photo-icon p-2"
                      onClick={(e) => this.deletePhoto(e, "album2")}
                    >
                      <IoCloseSharp color="var(--primary-color)" size={24} />
                    </div>
                  </div>
                ) : (
                  <div className="image-slot">
                    <label
                      role="button"
                      className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={(e) => {
                          this.uploadPhoto(e, "album2");
                          this.setState({ loadingPhoto2: true });
                        }}
                      />
                      {this.state.loadingPhoto2 ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <>
                          <img
                            src={uploadImage}
                            style={{
                              width: "60px",
                              height: "auto",
                              paddingBottom: "0.5rem",
                            }}
                          />

                          <div>Album 2</div>
                        </>
                      )}
                    </label>
                  </div>
                )}
              </Col>
              <Col xs={6} md={4} className="d-flex justify-content-center my-2">
                {this.state.album3 ? (
                  <div className="position-relative album-size">
                    <Image
                      src={albumURL + this.state.album3}
                      className="album-size"
                      style={{ borderRadius: "25px" }}
                    />
                    <label
                      role="button"
                      className="position-absolute change-photo-icon p-2"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control position-absolute"
                        style={{ visibility: "hidden" }}
                        onChange={(e) => this.changePhoto(e, "album3")}
                      />
                      <MdOutlinePhotoCamera
                        color="var(--primary-color)"
                        size={24}
                      />
                    </label>
                    <div
                      className="position-absolute delete-photo-icon p-2"
                      onClick={(e) => this.deletePhoto(e, "album3")}
                    >
                      <IoCloseSharp color="var(--primary-color)" size={24} />
                    </div>
                  </div>
                ) : (
                  <div className="image-slot">
                    <label
                      role="button"
                      className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={(e) => {
                          this.uploadPhoto(e, "album3");
                          this.setState({ loadingPhoto3: true });
                        }}
                      />
                      {this.state.loadingPhoto3 ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <>
                          <img
                            src={uploadImage}
                            style={{
                              width: "60px",
                              height: "auto",
                              paddingBottom: "0.5rem",
                            }}
                          />

                          <div>Album 3</div>
                        </>
                      )}
                    </label>
                  </div>
                )}
              </Col>

              <Col xs={6} md={4} className="d-flex justify-content-center my-2">
                {
                  this.state.album4 ? (
                    <div className="position-relative album-size">
                      <Image
                        src={albumURL + this.state.album4}
                        className="album-size"
                        style={{ borderRadius: "25px" }}
                      />
                      <label
                        role="button"
                        className="position-absolute change-photo-icon p-2"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control position-absolute"
                          style={{ visibility: "hidden" }}
                          onChange={(e) => this.changePhoto(e, "album4")}
                        />
                        <MdOutlinePhotoCamera
                          color="var(--primary-color)"
                          size={24}
                        />
                      </label>
                      <div
                        className="position-absolute delete-photo-icon p-2"
                        onClick={(e) => this.deletePhoto(e, "album4")}
                      >
                        <IoCloseSharp color="var(--primary-color)" size={24} />
                      </div>
                    </div>
                  ) : (
                    // localStorage.plan != '2' ?
                    <div className="image-slot">
                      <label
                        role="button"
                        className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          onChange={(e) => {
                            this.uploadPhoto(e, "album4");
                            this.setState({ loadingPhoto4: true });
                          }}
                        />
                        {this.state.loadingPhoto4 ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          <>
                            <img
                              src={uploadImage}
                              style={{
                                width: "60px",
                                height: "auto",
                                paddingBottom: "0.5rem",
                              }}
                            />

                            <div>Family photo</div>
                          </>
                        )}
                      </label>
                    </div>
                  )
                  // :
                  // <Link className="image-slot" to='/plans-and-payment'>
                  //     <label role='button' className='image-slot d-flex flex-column justify-content-center align-items-center'>
                  //         <input type="file" className='form-control' disabled />
                  //         <div className='album-font'>Upgrade Plan</div>
                  //         <div style={{ color: '#000' }}>Family</div>
                  //     </label>
                  // </Link>
                }
              </Col>
              <Col xs={6} md={4} className="d-flex justify-content-center my-2">
                {
                  this.state.album5 ? (
                    <div className="position-relative album-size">
                      <Image
                        src={albumURL + this.state.album5}
                        className="album-size"
                        style={{ borderRadius: "25px" }}
                      />
                      <label
                        role="button"
                        className="position-absolute change-photo-icon p-2"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control position-absolute"
                          style={{ visibility: "hidden" }}
                          onChange={(e) => this.changePhoto(e, "album5")}
                        />
                        <MdOutlinePhotoCamera
                          color="var(--primary-color)"
                          size={24}
                        />
                      </label>
                      <div
                        className="position-absolute delete-photo-icon p-2"
                        onClick={(e) => this.deletePhoto(e, "album5")}
                      >
                        <IoCloseSharp color="var(--primary-color)" size={24} />
                      </div>
                    </div>
                  ) : (
                    // localStorage.plan != '2' ?
                    <div className="image-slot">
                      <label
                        role="button"
                        className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          onChange={(e) => {
                            this.uploadPhoto(e, "album5");
                            this.setState({ loadingPhoto5: true });
                          }}
                        />
                        {this.state.loadingPhoto5 ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          <>
                            <img
                              src={uploadImage}
                              style={{
                                width: "60px",
                                height: "auto",
                                paddingBottom: "0.5rem",
                              }}
                            />

                            <div>House photo</div>
                          </>
                        )}
                      </label>
                    </div>
                  )
                  // :
                  // <Link className="image-slot" to='/plans-and-payment'>
                  //     <label role='button' className='image-slot d-flex flex-column justify-content-center align-items-center'>
                  //         <input type="file" className='form-control' disabled />
                  //         <div className='album-font'>Upgrade Plan</div>
                  //         <div style={{ color: '#000' }}>House</div>
                  //     </label>
                  // </Link>
                }
              </Col>
              <Col xs={6} md={4} className="d-flex justify-content-center my-2">
                {
                  this.state.album6 ? (
                    <div className="position-relative album-size">
                      <Image
                        src={albumURL + this.state.album6}
                        className="album-size"
                        style={{ borderRadius: "25px" }}
                      />
                      <label
                        role="button"
                        className="position-absolute change-photo-icon p-2"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control position-absolute"
                          style={{ visibility: "hidden" }}
                          onChange={(e) => this.changePhoto(e, "album6")}
                        />
                        <MdOutlinePhotoCamera
                          color="var(--primary-color)"
                          size={24}
                        />
                      </label>
                      <div
                        className="position-absolute delete-photo-icon p-2"
                        onClick={(e) => this.deletePhoto(e, "album6")}
                      >
                        <IoCloseSharp color="var(--primary-color)" size={24} />
                      </div>
                    </div>
                  ) : (
                    // localStorage.plan != '2' ?
                    <div className="image-slot">
                      <label
                        role="button"
                        className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          onChange={(e) => {
                            this.uploadPhoto(e, "album6");
                            this.setState({ loadingPhoto6: true });
                          }}
                        />
                        {this.state.loadingPhoto6 ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          <>
                            <img
                              src={uploadImage}
                              style={{
                                width: "60px",
                                height: "auto",
                                paddingBottom: "0.5rem",
                              }}
                            />

                            <div>Other photo</div>
                          </>
                        )}
                      </label>
                    </div>
                  )
                  // :
                  // <Link className="image-slot" to='/plans-and-payment'>
                  //     <label role='button' className='image-slot d-flex flex-column justify-content-center align-items-center'>
                  //         <input type="file" className='form-control' disabled />
                  //         <div className='album-font'>Upgrade Plan</div>
                  //         <div style={{ color: '#000' }}>Other</div>
                  //     </label>
                  // </Link>
                }
              </Col>
            </Row>
            <Row className="set-album d-flex justify-content-center mx-0">
              <div className="w-100 d-flex justify-content-center mb-2">
                Problem in upload photos?
              </div>
              <p align="center" className="mt-2">
                Whatsapp your photos (up to 4 Nos) to <span>9645004140</span>.
                Kindly mention your registration number(AEC No) in whatsapp
                message. Or send mail from your registered mail ID to{" "}
                <span>help@click4marry.com</span>
              </p>
            </Row>
          </div>
        ) : (
          <Loader></Loader>
        )}
        <Footer></Footer>
      </>
    );
  }
}
