import React, { Component } from "react";
import { Button, Card, Modal, Row } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import { toast } from "react-toastify";
import { VerifiedPlan } from "../../assets";
import { environment } from "../../environment/env";
import "./Plan.css";

const baseURL = environment.hostname;
export default class Plan extends Component {
  constructor() {
    super();
    this.state = {
      showCouponModal: false,
      code: "",
      discount: 0,
      error: "",
      showPaymentGateway: false,
      showVerifiedPaymentGateway: false,
      showExistingPlanModal: false,
    };
  }

  checkExistingPlan = () => {
    if (localStorage.getItem("plan" == "2")) {
      this.setState({ showCouponModal: true });
    } else {
      this.setState({ showExistingPlanModal: true });
    }
  };

  applyCode = () => {
    fetch(`${baseURL}/couponcode_check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        plan_id: this.props.plan.id,
        session_id: localStorage.session,
        coupon_code: this.state.code,
      }),
    })
      .then((resp) => resp.json())
      .then((res) => {
        if (res.status === 1) {
          toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
          this.setState({
            discount: res.data.amount,
          });
        } else {
          this.setState({
            error: res.message,
          });
        }
      });
  };

  gotoPayment = () => {
    window.location.replace(
      `https://click4marry.com/app/plan/payment_verified_mob_plan?plan=${
        this.props.plan.id
      }&amount=${this.props.plan.amount - this.state.discount}&id=${
        localStorage.currentUser
      }&coupon_code=${this.state.discount != 0 ? this.state.code : ""}`
    );
  };

  gotoPaymentVerified = () => {
    window.location.replace(
      `https://click4marry.com/app/plan/payment_verified_mob?plan=${this.props.plan.id}&amount=${this.props.plan.amount}&id=${localStorage.currentUser}`
    );
  };

  render() {
    return (
      <Card
        className="plan-card d-flex align-items-center mb-3 position-relative"
        style={{
          // backgroundColor: this.props.plan.special === 'Y' ? 'var(--secondary-color)' : '#FFF',
          height: "auto",
        }}
      >
        {this.props?.plan?.verified_badge === "Y" && (
          <div className="position-absolute verifiedPlan12">
            <img
              alt=""
              src={window.innerWidth > 500 ? VerifiedPlan : VerifiedPlan}
              className="d-inline-block  "
            />
          </div>
        )}
        <div
          className="w-100 d-flex flex-column align-items-center"
          style={{ backgroundColor: "#FFF" }}
        >
          {/*  paddingBottom: this.props.plan.special === 'Y' ? '24px' : '0' }}> */}
          <Card.Header
            className="d-flex flex-column align-items-center w-100 pb-4"
            style={{ backgroundColor: "#FFF" }}
          >
            <Card.Title
              align="center"
              style={{
                fontSize: "25px",
                color: "var(--primary-color)",
                // fontSize: '30px', color: this.props.plan.special === 'Y' ? 'var(--primary-color)' : '',
                fontWeight: "700",
                // fontWeight: this.props.plan.special === 'Y' ? '700' : '500'
              }}
            >
              {this.props?.plan?.name}
            </Card.Title>

            <Card.Text
              style={{
                color: "red",
                // color: this.props.plan.special === 'Y' ? 'var(--primary-color)' : ''
              }}
              className="d-flex justify-content-center m-0"
            >
              <del>&#8377;{this.props.plan.actualprice}</del>
            </Card.Text>
            <Card.Text
              style={{
                color: "var(--primary-color)",
                // color: this.props.plan.special === 'Y' ? 'var(--primary-color)' : ''
              }}
              className="plan-price m-0"
            >
              &#8377;{this.props.plan.amount}
            </Card.Text>
            {this.props?.verified ? (
              ""
            ) : (
              <Card.Text
                style={{ color: "#5B6770" }}
                className="d-flex justify-content-center"
              >
                {this.props.plan.duration} days
              </Card.Text>
            )}
          </Card.Header>

          {this.props?.verified ? (
            <Card.Body className="mt-4 p-0 px-3">
              <Card.Text className="mb-0">
                <MdKeyboardArrowRight />
                Get your profile manually verified by our Agents through
                personal visits
              </Card.Text>
              <Card.Text className="mb-0">
                <MdKeyboardArrowRight />
                Add verified badge to your profile to appear in more search
                results
              </Card.Text>
              <Card.Text className="mb-0">
                <MdKeyboardArrowRight />
                Special listing of profiles under verified category
              </Card.Text>
              <Card.Text className="mb-0">
                <MdKeyboardArrowRight />
                Appear on top of daily recommendations
              </Card.Text>
            </Card.Body>
          ) : (
            <Card.Body className="mt-4 px-3">
              <Card.Text className="mb-0">
                <MdKeyboardArrowRight />
                Read {this.props.plan.read_contact} contacts
              </Card.Text>
              <Card.Text>
                <MdKeyboardArrowRight />
                Send / Receive Personalized Messages
              </Card.Text>
              {this.props.plan.video_chat_priority === "Y" ? (
                <Card.Text>
                  <MdKeyboardArrowRight />
                  Connect through Video call
                </Card.Text>
              ) : (
                <></>
              )}
              {this.props.plan.privacy_priority === "Y" ? (
                <Card.Text>
                  <MdKeyboardArrowRight />
                  Secure profile with advanced safety features
                </Card.Text>
              ) : (
                <></>
              )}
              <Card.Text>
                <MdKeyboardArrowRight />
                View Your Profile Viewers
              </Card.Text>
              <Card.Text>
                <MdKeyboardArrowRight />
                Profile is shown in the top few
              </Card.Text>
              <Card.Text>
                <MdKeyboardArrowRight />
                Premium Tag
              </Card.Text>
            </Card.Body>
          )}
          {!this.props.verified ? (
            localStorage.getItem("session") != null &&
            localStorage.getItem("currentUser") != null && (
              <Button
                className="pay-button"
                onClick={() => this.checkExistingPlan()}
              >
                {" "}
                Pay Now
              </Button>
            )
          ) : this.props.plan?.status === 0 ? (
            <Button
              className="pay-button"
              onClick={() => this.gotoPaymentVerified()}
            >
              {" "}
              Pay Now
            </Button>
          ) : (
            ""
          )}
        </div>
        <Modal
          className="plan-modal mod_style"
          show={this.state.showCouponModal}
          onHide={() =>
            this.setState({ showCouponModal: false, showPaymentGateway: false })
          }
        >
          {!this.state.showPaymentGateway ? (
            <Modal.Body className="d-flex flex-column align-items-center .mod_style">
              <Row
                style={{
                  color: "var(--primary-color)",
                  fontWeight: "500",
                  fontSize: "30px",
                  marginTop: "-9px",
                }}
              >
                {this.props.plan.name}
              </Row>
              <Row
                style={{
                  color: "#5B6770",
                  fontWeight: "900",
                  fontSize: "24px",
                }}
              >
                &#8377;{this.props.plan.amount}
              </Row>
              <Row style={{ fontSize: "20px" }}>
                {this.props.plan.duration} days validity
              </Row>
              <Row style={{ fontSize: "20px" }}>
                {this.props.plan.read_contact} contacts read
              </Row>
              <Row className="w-100 justify-content-center mt-3">
                <div className="w-50" style={{ minWidth: "220px" }}>
                  <input
                    placeholder="Enter Promocode"
                    style={{ height: "46px" }}
                    className="me-4 form-control"
                    type="text"
                    value={this.state.code}
                    onChange={(e) => this.setState({ code: e.target.value })}
                  />
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginBottom: "20px",
                    }}
                  >
                    {this.state.error}
                  </div>
                </div>
                <Button className="apply-button" onClick={this.applyCode}>
                  Apply
                </Button>
              </Row>
              <Row
                className="justify-content-start w-120 mt-4 mb-4 row"
                // style={{ fontSize: "20px" }}
              >
                <h6
                // style={{ fontSize: "22px" }}
                >
                  Bill Details
                </h6>
                <Row className="justify-content-between">
                  <div className="w-auto">Plan amount</div>
                  <div className="w-auto">&#8377;{this.props.plan.amount}</div>
                </Row>
                <Row className="justify-content-between">
                  <div className="w-auto">Discount</div>
                  <div className="w-auto">&#8377;{this.state.discount}</div>
                </Row>
                <Row className="justify-content-between">
                  <div className="w-auto">Total</div>
                  <div className="w-auto">
                    &#8377;{this.props.plan.amount - this.state.discount}
                  </div>
                </Row>
              </Row>
              <Row className="w-75 mt-5 justify-content-center">
                <Button
                  className="invite-button"
                  onClick={() => this.gotoPayment()}
                >
                  Continue
                </Button>
                <Button
                  className="cancel-plan-button"
                  onClick={() =>
                    this.setState({
                      showCouponModal: false,
                      showPaymentGateway: false,
                    })
                  }
                >
                  Cancel
                </Button>
              </Row>
            </Modal.Body>
          ) : (
            <Modal.Body className="d-flex flex-column align-items-center px-5">
              <iframe
                className="h-100 w-100"
                style={{ display: this.state.showPaymentGateway ? "" : "none" }}
                src={`https://click4marry.com/app/plan/payment_verified_mob_plan?plan=${
                  this.props.plan.id
                }&amount=${this.props.plan.amount - this.state.discount}&id=${
                  localStorage.currentUser
                }&coupon_code=${
                  this.state.discount != 0 ? this.state.code : ""
                }`}
                title="Payment gateway"
              ></iframe>
            </Modal.Body>
          )}
        </Modal>
        <Modal
          className="plan-modal"
          show={this.state.showVerifiedPaymentGateway}
          onHide={() => this.setState({ showVerifiedPaymentGateway: false })}
        >
          <Modal.Body className="d-flex flex-column align-items-center px-5">
            <iframe
              className="h-100 w-100"
              src={`https://click4marry.com/app/plan/payment_verified_mob?plan=${this.props.plan.id}&amount=${this.props.plan.amount}&id=${localStorage.currentUser}`}
              title="verified payment gateway"
            ></iframe>
          </Modal.Body>
        </Modal>
        <Modal
          className=""
          show={this.state.showExistingPlanModal}
          onHide={() => this.setState({ showExistingPlanModal: false })}
        >
          <Modal.Body className="d-flex flex-column align-items-center px-10">
            <Row
              style={{
                color: "var(--primary-color)",
                fontWeight: "500",
                fontSize: "22px",
              }}
            >
              You have an active plan
            </Row>
            <Row
              style={{
                color: "#5B6770",
                fontWeight: "900",
                fontSize: "18px",
                marginTop: "20px",
              }}
            >
              Benefits of previous plan will be expired when new plan is
              upgraded
            </Row>
            {/* <Row style={{ fontSize: '20px' }}>{this.props.plan.duration} days validity</Row> */}

            <Row className="w-75 mt-5 justify-content-center">
              <Button
                className="invite-button"
                onClick={() =>
                  this.setState({
                    showExistingPlanModal: false,
                    showCouponModal: true,
                  })
                }
              >
                Continue
              </Button>
              <Button
                className="cancel-plan-button"
                onClick={() => this.setState({ showExistingPlanModal: false })}
              >
                Cancel
              </Button>
            </Row>
          </Modal.Body>
        </Modal>
      </Card>
    );
  }
}
