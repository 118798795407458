import React, { Component } from 'react';
import { Card, Col, Dropdown, Form, Image, Row } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';
import { MdOutlineCall, MdOutlineLink, MdOutlineMyLocation } from 'react-icons/md';
import { BsSearch } from 'react-icons/bs';
import { RiArrowDownSLine } from 'react-icons/ri';
import { Footer, Loader, NavbarMenu } from '..';
import { environment } from '../../environment/env';
import './WeddingStores.css'
import { Link } from 'react-router-dom';

const baseURL = environment.hostname;
const storeImageURL = environment.storeImageURL;

export default class WeddingStores extends Component {
    constructor() {
        super()
        this.state = {
            items: [],
            location: '',
            category: '',
            stores: [],
            showSearchInput: false,
            isLoading: true
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        fetch(`${baseURL}/wedding_store`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: localStorage.currentUser
            })
        }).then(res => res.json()).then(response => {


            this.setState({
                items: response.location,
                location: response.location[0].location,
                category: response.location[0].category[0].name,
                isLoading: false

            })
        })
    }

    changeLocation = (value) => {
        this.setState({
            location: value.location,
            category: value.category[0].name
        })
    }
    changeCategory = (value) => {
        this.setState({
            category: value
        })
    }

    searchStore = (e) => {
        fetch(`${baseURL}/wedding_store_search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: localStorage.currentUser,
                keyword: e.target.value
            })
        }).then(res => res.json()).then(res => {
            this.setState({ stores: res })
        })
    }
    render() {
        return (
            <>
                <NavbarMenu></NavbarMenu>
                {!this.state.isLoading ?
                    <div className='wedding-store'>
                        <Row className='justify-content-between align-items-center m-0'>
                            <h3 className='title w-auto'>Wedding Stores</h3>
                            <BsSearch size='22px' color='var(--primary-color)' className='w-auto' onClick={() => this.setState({ showSearchInput: !this.state.showSearchInput })} />
                        </Row>
                        <Form.Control className='mt-1' onKeyUp={(e) => this.searchStore(e)} placeholder='Search stores' hidden={!this.state.showSearchInput} type="text" />

                        {!this.state.showSearchInput ?
                            <>
                                <Row className='request-tabs m-0 mt-2 flex-row-reverse align-items-center'>
                                    <Col className='d-flex justify-content-end'>
                                        <Dropdown drop='start'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className='more-dropdown p-0 d-flex align-items-center'>
                                                <div style={{ color: '#000' }}>{this.state.location}</div><RiArrowDownSLine color='#000' />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {this.state.items.map(item => (
                                                    <Dropdown.Item onClick={() => this.changeLocation(item)}>{item.location}</Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col className='d-flex'>
                                        {this.state.items.map(item => (
                                            item.location === this.state.location ?
                                                item.category.map(categoryItem => (
                                                    <Col md={2} className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            borderRadius: '10px', padding: '12px', height: '42px',
                                                            backgroundColor: categoryItem.name === this.state.category ? 'var(--primary-color)' : '',
                                                            color: categoryItem.name === this.state.category ? '#FFF' : ''

                                                        }} onClick={() => this.changeCategory(categoryItem.name)}>
                                                        {categoryItem.name}
                                                    </Col>

                                                ))
                                                : ''
                                        ))}
                                    </Col>

                                </Row>
                                <Row className='mt-4 mx-0 justify-content-center'>
                                    {this.state.items.map(item => (

                                        item.location === this.state.location ?
                                            item.category.map(categoryItem => (
                                                categoryItem.name === this.state.category ?

                                                    <div className='store-grid'>
                                                        {categoryItem.wedding_stores.map(store => (
                                                            <div className='grid-item'>
                                                                <Card className='d-flex justify-content-center mt-2 mb-2 w-auto p-3 store-card'>
                                                                    <Image src={`${storeImageURL}${store.imageUrl}`} width='100%' height='250px' />
                                                                    <Card.Text align='left' className='mt-3' >{store.store_name}</Card.Text>
                                                                    <Card.Text align='left' className='mt-3 wrap-text-desc' >{store.description}</Card.Text>
                                                                    <Row className='justify-content-between'>
                                                                        {/* <div className='icon-grid'>
                                                                            <MdOutlineCall onClick={() => { window.open(`tel:91${store.phone} `) }} className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px' />
                                                                            {store.phone}
                                                                        </div> */}
                                                                        {/* <div className='icon-grid'>
                                                                            <FaWhatsapp onClick={() => { window.open(`https://wa.me/91${store.whatsapp} `) }} className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px' />
                                                                            {store.whatsapp}
                                                                        </div> */}
                                                                        {/* <div>
                                                                        <a target="blank" href={`tel:91${store.phone} `} className='icon-grid' >
                                                                        <MdOutlineCall className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px'/>
                                                                        {store.phone}
                                                                        </a>
                                                                        <a target="blank" href={`https://wa.me/91${store.whatsapp}`} className='icon-grid' style={{marginLeft:"1rem"}}>
                                                                        <FaWhatsapp className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px'/>
                                                                        {store.whatsapp}
                                                                        </a>
                                                                        </div> */}

                                                                        <Col lg="6" >
                                                                            <p className="links-and-numbers__wedding-store">
                                                                                <a target="blank" href={`tel:91${store.phone} `} className="links-and-numbers__wedding-store">
                                                                                    <MdOutlineCall className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px'/>
                                                                                    {store.phone}
                                                                                </a>
                                                                            </p>
                                                                        </Col>

                                                                        <Col lg="6" >
                                                                            <p className="links-and-numbers__wedding-store">
                                                                                <a target="blank" href={`https://wa.me/91${store.whatsapp} `} className="links-and-numbers__wedding-store">
                                                                                    <FaWhatsapp className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px'/>
                                                                                    {store.whatsapp}
                                                                                </a>
                                                                            </p>
                                                                        </Col>
                                                                        
                                                                        <Col lg="6" >
                                                                            <p className="links-and-numbers__wedding-store">
                                                                                <a target="blank" href={`${store.website} `} className="links-and-numbers__wedding-store">
                                                                                    <MdOutlineLink className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px'/>
                                                                                    {store.website}
                                                                                </a>
                                                                            </p>
                                                                        </Col>
                                                                        <Col lg="6" >
                                                                            <p className="links-and-numbers__wedding-store">
                                                                                <a target="blank" href={`${store.geoUrl}`} >
                                                                                    <MdOutlineMyLocation className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px'/>
                                                                                    {store.geoUrl}
                                                                                </a>
                                                                            </p>
                                                                        </Col>
{/* 
                                                                        <Link target={"_blank"} to>

                                                                        </Link> */}
                                                                        {/* <div className='icon-grid wrap-text '>
                                                                            <MdOutlineLink className='w-auto cursor-pointer me-2' onClick={() => { window.open(store.website, '_blank') }} color='var(--primary-color)' size='20px' />
                                                                            {store.website}
                                                                        </div>
                                                                        <div className='icon-grid wrap-text' onClick={() => { window.open(store.geoUrl, '_blank') }} >
                                                                            <MdOutlineMyLocation onClick={() => { window.open(store.geoUrl, '_blank') }} className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px' />
                                                                            {store.geoUrl}
                                                                        </div> */}
                                                                    </Row>
                                                                </Card>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    : ''
                                            ))
                                            : ''
                                    ))}
                                </Row>
                            </>
                            :
                            <div className='store-grid'>
                                {this.state.stores.length ? this.state.stores?.map(store => (


                                    <div className='grid-item'>
                                        <Card className='d-flex justify-content-center  w-auto p-3 store-card'>
                                            <Image src={`${storeImageURL}${store.imageUrl}`} width='100%' height='250px' />
                                            <Card.Text align='left' className='mt-3' >{store.store_name}</Card.Text>
                                            <Card.Text align='left' className='mt-3 wrap-text-desc' >{store.description}</Card.Text>
                                            <Row className='justify-content-between'>
                                                <div className='icon-grid'>
                                                    <MdOutlineCall onClick={() => { window.open(`tel:91${store.phone} `) }} className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px' />
                                                    {store.phone}
                                                </div>
                                                <div className='icon-grid'>
                                                    <FaWhatsapp onClick={() => { window.open(`https://wa.me/91${store.whatsapp} `) }} className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px' />
                                                    {store.whatsapp}
                                                </div>
                                                <div className='icon-grid wrap-text'>
                                                    <MdOutlineLink className='w-auto cursor-pointer me-2' onClick={() => { window.open(store.website, '_blank') }} color='var(--primary-color)' size='20px' />
                                                    {store.website}
                                                </div>
                                                <div className='icon-grid wrap-text'>
                                                    <MdOutlineMyLocation onClick={() => { window.open(store.geoUrl, '_blank') }} className='w-auto cursor-pointer me-2' color='var(--primary-color)' size='20px' />
                                                    {/* {store.geoUrl} */}
                                                </div>
                                            </Row>
                                        </Card>
                                    </div>





                                )) : ''}

                            </div>}
                    </div>
                    :
                    <Loader></Loader>
                }
                <Footer></Footer>
            </>
        )
    }
}

