import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Modal,
  Form,
  Dropdown,
} from "react-bootstrap";
import { BsCameraVideo, BsChat, BsExclamationOctagon } from "react-icons/bs";
import { GiBodyHeight } from "react-icons/gi";
import {
  MdFavoriteBorder,
  MdMoreVert,
  MdOutlineHighlightOff,
  MdStarBorder,
  MdVerified,
  MdStar,
  MdFavorite,
  MdCircle,
} from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import { VscHeart, VscMortarBoard } from "react-icons/vsc";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import { ageIcon } from "../../assets";
import {
  Footer,
  Loader,
  NavbarMenu,
  NoImage,
  ProfileDetails,
  ProfileMatch,
  ProtectedImage,
  ReportModal,
  UpgradeModal,
} from "../../Components";
import { environment } from "../../environment/env";
import "./UserProfile.css";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

const baseURL = environment.hostname;
let imageURL = environment.imageURL;
let albumURL = environment.albumURL;
let userProfile, image, album, plan;

export default class UserProfile extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      isLoading: true,
      showPhotoModal: false,
      showReportModal: false,
      photo: "",
      showModal: false,
      scheduleTime: null,
      showUpgradeModal: false,
      isShortListed: false,
      isInterested: false,
      showModalRemShortlist: false,
      showModalRemInterest: false,
      showModalIgnoreProf: false,
      isPhotoRequested: false,
      showContactReadModal: false,
      isViewed: 0,
      disableScheduleModal: false,
      meetingExist: false,
      meetingExistMessage: "",
    };
  }

  componentDidMount() {
    this.fetchApi();
  }

  fetchApi = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let basicInfoApi = fetch(`${baseURL}/basic_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.visitingUser,
        profid: localStorage.currentUser,
      }),
    });

    let albumApi = fetch(`${baseURL}/user_album`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        profid: localStorage.visitingUser,
      }),
    });

    let planApi = fetch(`${baseURL}/myplan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let allStatusApi = fetch(`${baseURL}/get_allstatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.visitingUser,
        profid: localStorage.currentUser,
      }),
    });

    let isViewedApi = fetch(`${baseURL}/is_contact_viewed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
        ip: "111.111.1.1",
      }),
    });

    Promise.all([basicInfoApi, albumApi, planApi, allStatusApi, isViewedApi])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        userProfile = response[0][0];
        plan = response[2][0];
        album = response[1];
        if (album[0]?.album) image = imageURL + album[0]?.album;
        else if (
          userProfile?.gender === "Female" ||
          localStorage.gender === "Male"
        )
          image = imageURL + "avatar_Female.png";
        else image = imageURL + "avatar_Male.png";

        album = album.filter(
          (albumItem) =>
            albumItem.album != "" && !albumItem.album?.includes("avatar")
        );
        localStorage.setItem("plan", plan.pid);
        localStorage.setItem("planName", plan.planname);
        this.setState({
          isLoading: false,
          isShortListed: response[3]["shortlist"] === "true",
          isInterested: response[3]["express"] === "true",
          isPhotoRequested:
            response[0][0]?.non_image_status === "1" ? true : false,
          isViewed: response[4],
        });
        this.updateLogin();
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  scheduleVideo = () => {
    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      showPhotoModal: false,
    });
  };

  async updateLogin() {
    await fetch(`${baseURL}/loginupdate`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
      headers: {
        "Content-type": "application/json",
      },
    }).then((response) => {});
  }

  checkScheduleRequest = (e) => {
    e.preventDefault();
    if (this.state.date == null || this.state.date == "") {
      toast.error("Please input date");
      return 1;
    }
    if (this.state.scheduleTime == null) {
      toast.error("Please input time");
      return 1;
    }
    var date1 = new Date();
    var date2 = new Date(this.state.date);
    var diffDays = date2.getDate() - date1.getDate();
    if (diffDays == 0) {
      var timeNowMins = new Date().getHours() * 60 + new Date().getMinutes();

      var timeScheduledMins =
        Number(this.state.scheduleTime.split(":")[0]) * 60 +
        Number(this.state.scheduleTime.split(":")[1]);
      if (timeNowMins > timeScheduledMins) {
        toast.error("Please choose a time not passed");
        return 1;
      }
    }

    this.setState({ disableScheduleModal: true });
    var date2 = this.state.date;

    fetch(`${baseURL}/schedule_create_check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: userProfile.aecid,
        date: `${date2.getFullYear()}-${
          date2.getMonth() + 1 < 10
            ? "0" + (date2.getMonth() + 1)
            : date2.getMonth() + 1
        }-${date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()}`,
        time: this.state.scheduleTime + ":00",
        session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          this.sendScheduleRequest(e);
        } else {
          this.setState({
            meetingExist: true,
            meetingExistMessage: response.message,
          });
          // toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("An error occured");
      });

    this.setState({ disableScheduleModal: false });
  };

  sendScheduleRequest = (e) => {
    this.setState({ disableScheduleModal: true, meetingExist: false });
    var date2 = this.state.date;
    fetch(`${baseURL}/schedule_creation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: userProfile.aecid,
        date: `${date2.getFullYear()}-${
          date2.getMonth() + 1 < 10
            ? "0" + (date2.getMonth() + 1)
            : date2.getMonth() + 1
        }-${date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()}`,
        time: this.state.scheduleTime + ":00",
        session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.setState({ disableScheduleModal: false });
        if (response.status === 1)
          toast.success("Schedule created successfully");
        else toast.error("Schedule creation failed");
        this.setState({
          showModal: false,
        });
      })
      .catch((error) => {
        toast.error("An error occured");
        this.setState({ disableScheduleModal: false });
      });
    e.preventDefault();
  };

  addToShortlist = () => {
    fetch(`${baseURL}/add_shortlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: localStorage.currentUser,
        to: localStorage.visitingUser,
        ip: "111.111.1.1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.sts === 1) {
          this.setState({
            isShortListed: true,
          });
          toast.success("Profile Shortlisted successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (response.sts === 3) {
          this.setState({
            isShortListed: true,
          });
          toast.warning("Profile Already shortlisted", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  sendInterest = () => {
    fetch(`${baseURL}/add_express_interest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: localStorage.visitingUser,
        ip: "111.111.1.1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.sts === 1) {
          this.setState({
            isInterested: true,
          });
          toast.success("Profile Interest send", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (response?.sts === 3) {
          this.setState({
            isInterested: true,
          });
          toast.warning("Profile Already Interested", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  ignore = () => {
    fetch(`${baseURL}/ignore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
        check_session_id: localStorage.session,
        check_user_id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.hideallmodals();
        if (response === "1") {
          toast.success("ignored successfully");
          this.fetchApi();
        } else if (response === "0") toast.info("already ignored");
      });
  };

  removeShortListApi = () => {
    fetch(`${baseURL}/delete_shortlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        to_id: localStorage.visitingUser,
        session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.hideallmodals();
        if (!response.toString().includes("401")) {
          this.setState({
            isShortListed: false,
          });
        }
      });
  };

  removeInterestApi = () => {
    fetch(`${baseURL}/removeinterest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        to: localStorage.visitingUser,
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.hideallmodals();
        if (!response.toString().includes("401")) {
          this.setState({
            isInterested: false,
          });
        }
      });
  };

  showConfirmModal(type) {
    switch (type) {
      case "rem_shortlist":
        this.setState({ showModalRemShortlist: true });
        break;
      case "rem_interest":
        this.setState({ showModalRemInterest: true });
        break;
      case "ignore":
        this.setState({ showModalIgnoreProf: true });
        break;
      default:
        break;
    }
  }

  hideallmodals = () => {
    this.setState({
      showModalRemShortlist: false,
      showModalRemInterest: false,
      showModalIgnoreProf: false,
    });
  };
  checkPlanAndFaceImage(image, album_visibility, gender) {
    if (
      (album_visibility === "55" && localStorage.plan === "2") ||
      album_visibility === "56" ||
      image == null
    ) {
      if (gender === "Male" || localStorage.gender === "Female") {
        return (image = imageURL + "avatar_Male.png");
      } else {
        return (image = imageURL + "avatar_Female.png");
      }
    }
    return imageURL + image;
  }

  checkPlanAndImage(image, album_visibility, gender) {
    if (
      (album_visibility === "55" && localStorage.plan === "2") ||
      album_visibility === "56" ||
      image == null
    ) {
      if (gender === "Male" || localStorage.gender === "Female") {
        return (image = imageURL + "avatar_Male.png");
      } else {
        return (image = imageURL + "avatar_Female.png");
      }
    }
    return albumURL + image;
  }
  changePhotoRequestStatus(context) {
    context.setState({
      isPhotoRequested: true,
    });
  }

  changeRead = () => {
    // TODO important
    isViewed = 2;
  };
  changeRead = () => {
    this.setState({ isViewed: 2 });
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div>
          <NavbarMenu></NavbarMenu>
          <div className="user-profile">
            <Row className="m-0">
              <Col className="p-0 col-auto maximum-width">
                <Row className="m-0 position-relative">
                  <Image
                    src={this.checkPlanAndFaceImage(
                      album[0]?.album,
                      userProfile?.album_visibility,
                      null
                    )}
                    className="userprofile-pic"
                    style={{ height: "none" }}
                    onClick={
                      userProfile?.albumVisibility != "56" &&
                      album[0]?.album != "" &&
                      album[0]?.album != null
                        ? () =>
                            this.setState({
                              showPhotoModal: true,
                              photo: image,
                            })
                        : ""
                    }
                  />
                  {userProfile?.album_visibility === "56" ? (
                    <ProtectedImage
                      from={null}
                      id={userProfile?.id || userProfile?.aecid}
                      requestButton={this.state.isPhotoRequested ? false : true}
                      isProfile={true}
                      updateFunction={this.changePhotoRequestStatus}
                      updateContext={this}
                    ></ProtectedImage>
                  ) : !album[0]?.album ? (
                    <NoImage
                      status={userProfile?.non_image_status}
                      id={userProfile?.id || userProfile?.aecid}
                    ></NoImage>
                  ) : (
                    ""
                  )}
                </Row>
                {userProfile?.album_visibility === "54" ? (
                  <Row className="m-0 mt-3">
                    {album?.map((albumItem, index) =>
                      albumItem?.album != null && albumItem?.album != "" ? (
                        <div className="w-auto position-relative" key={index}>
                          <Image
                            alt="no image"
                            src={
                              index === 0
                                ? this.checkPlanAndFaceImage(
                                    albumItem?.album,
                                    userProfile?.album_visibility
                                  )
                                : this.checkPlanAndImage(
                                    albumItem?.album,
                                    userProfile?.album_visibility
                                  )
                            }
                            className="user-album "
                            onClick={
                              userProfile?.albumVisibility != "56"
                                ? () =>
                                    this.setState({
                                      showPhotoModal: true,
                                      photo:
                                        index === 0
                                          ? imageURL + albumItem?.album
                                          : `${albumURL}${albumItem?.album}`,
                                    })
                                : ""
                            }
                          />
                          {userProfile?.album_visibility === "56" ? (
                            <ProtectedImage
                              from={null}
                              id={userProfile?.id || userProfile?.aecid}
                              requestButton={false}
                            ></ProtectedImage>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : userProfile?.gender === "Male" ||
                        localStorage.gender === "Female" ? (
                        <Image
                          alt="no image"
                          src={imageURL + "avatar_Male.png"}
                          className="user-album"
                          key={index}
                        />
                      ) : (
                        <Image
                          alt="no image"
                          src={imageURL + "avatar_Female.png"}
                          className="user-album"
                          key={index}
                        />
                      )
                    )}
                  </Row>
                ) : (
                  <></>
                )}

                <Modal
                  contentClassName="image-modal__profile"
                  show={this.state.showPhotoModal}
                  // onHide={this.closeModal}
                >
                  <CloseIcon
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "5px",
                      cursor: "pointer",
                    }}
                    onClick={this.closeModal}
                  ></CloseIcon>
                  <Image
                    src={this.state.photo}
                    style={{ padding: "1rem" }}
                    width="100%"
                    height="50%"
                  />
                </Modal>
              </Col>
              <Col>
                <Card className="h-100 user-profile-card">
                  <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Title className="modal-title d-flex justify-content-center">
                      Video meet with this profile
                    </Modal.Title>
                    <Modal.Body className="d-flex justify-content-center pt-1 pb-5">
                      You can make video meeting with this profile
                    </Modal.Body>

                    {this.state.meetingExist ? (
                      <div>
                        {/* already meeting */}
                        <Form
                          onSubmit={this.sendScheduleRequest}
                          onReset={() => this.setState({ meetingExist: false })}
                        >
                          <div
                            className="d-flex"
                            style={{
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            {this.state.meetingExistMessage}
                          </div>
                          <Row className="d-flex justify-content-center">
                            <Button
                              type="reset"
                              className="cancel-request-button mt-5 m-2"
                            >
                              Back
                            </Button>
                            <Button
                              disabled={this.state.disableScheduleModal}
                              type="submit"
                              className="schedule-request-button mt-5 m-2"
                            >
                              Continue Send
                            </Button>
                          </Row>
                        </Form>
                      </div>
                    ) : (
                      // meeting date time
                      <Form
                        onSubmit={this.checkScheduleRequest}
                        onReset={() => this.setState({ showModal: false })}
                      >
                        <div
                          className={window.innerWidth < 425 ? "" : "d-flex"}
                        >
                          <Form.Group
                            className="w-50 d-flex pe-3 align-items-end"
                            style={{
                              marginLeft: window.innerWidth < 425 ? "15px" : "",
                            }}
                          >
                            <Form.Label className="pe-2">Date</Form.Label>
                            <DatePicker
                              className="form-control"
                              minDate={new Date()}
                              dayPlaceholder="dd"
                              monthPlaceholder="mm"
                              yearPlaceholder="yyyy"
                              value={this.state.date}
                              name="date"
                              onChange={(event) =>
                                this.setState({ date: new Date(event) })
                              }
                              format="dd-MM-y"
                            />
                          </Form.Group>
                          <Form.Group
                            className="w-50 d-flex ps-3 align-items-end"
                            style={{
                              marginTop: window.innerWidth < 425 ? "40px" : "",
                              minWidth: "235px",
                            }}
                          >
                            <Form.Label className="pe-2">Time</Form.Label>
                            <Form.Control
                              type="time"
                              for
                              value={this.state.scheduleTime}
                              onChange={(event) =>
                                this.setState({
                                  scheduleTime: event.target.value,
                                })
                              }
                            ></Form.Control>
                          </Form.Group>
                        </div>
                        <Row className="d-flex justify-content-center">
                          <Button
                            type="reset"
                            className="cancel-request-button mt-5 m-2"
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={this.state.disableScheduleModal}
                            type="submit"
                            className="schedule-request-button mt-5 m-2"
                          >
                            Send a Request
                          </Button>
                        </Row>
                      </Form>
                    )}
                  </Modal>
                  <Row className="justify-content-between">
                    <Col xs={10}>
                      <span className="userprofile-card-title">
                        {userProfile.aecid}
                      </span>
                      <MdVerified
                        size="30px"
                        className="mx-2"
                        color={
                          userProfile.verified_badge == "Y"
                            ? "var(--secondary-color)"
                            : "#C4C4C4"
                        }
                      />
                      <MdCircle
                        size="18px"
                        className="mx-1"
                        color={
                          new Date(userProfile.lastlogin).toDateString() !=
                          new Date().toDateString()
                            ? "#C4C4C4"
                            : new Date(userProfile.lastlogin).getHours() !=
                              new Date().getHours()
                            ? "#C4C4C4"
                            : new Date().getMinutes() - 5 <=
                                new Date(userProfile.lastlogin).getMinutes() &&
                              new Date(userProfile.lastlogin).getMinutes() <=
                                new Date().getMinutes()
                            ? "#00BE63"
                            : "#C4C4C4"
                        }
                      />
                      <div>{userProfile.placename}</div>
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <Dropdown drop="start">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="p-0 d-flex"
                          style={{
                            backgroundColor: "#FFF",
                            color: "#FFF",
                            border: "none",
                          }}
                        >
                          <MdMoreVert
                            data-tip
                            data-for="bs-more"
                            color="var(--primary-color)"
                          />
                          <ReactTooltip
                            className="tooltipClass"
                            id="bs-more"
                            place="left"
                            type="light"
                          >
                            <span>More</span>
                          </ReactTooltip>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              this.setState({ showReportModal: true })
                            }
                          >
                            <BsExclamationOctagon className="me-2" />
                            Report this profile
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <ReportModal
                      profile={localStorage.visitingUser}
                      ReportNow={this.state.showReportModal}
                      closeModal={() =>
                        this.setState({ showReportModal: false })
                      }
                      success={() => this.setState({ showReportModal: false })}
                    ></ReportModal>
                  </Row>
                  <Row className="mb-4 mt-4">
                    {userProfile?.qualification != null &&
                    userProfile?.qualification != "" ? (
                      <Col className="col-auto">
                        <VscMortarBoard
                          color="var(--primary-color)"
                          size="20px"
                        />
                        {userProfile?.qualification}
                      </Col>
                    ) : (
                      <></>
                    )}
                    {userProfile?.mstatus != null &&
                    userProfile?.mstatus != "" ? (
                      <Col className="col-auto">
                        <VscHeart size="18px" color="var(--primary-color)" />{" "}
                        {userProfile.mstatus}
                      </Col>
                    ) : (
                      <></>
                    )}
                    {userProfile?.height != null &&
                    userProfile?.height != "" ? (
                      <Col className="col-auto">
                        <GiBodyHeight
                          color="var(--primary-color)"
                          size="18px"
                        />
                        {userProfile?.height ? `${userProfile?.height} CM` : ""}{" "}
                      </Col>
                    ) : (
                      <></>
                    )}
                    {userProfile?.age != null && userProfile?.age != "" ? (
                      <Col className="col-auto">
                        <Image src={ageIcon} />
                        &nbsp;{userProfile.age
                          ? `${userProfile.age} yrs`
                          : ""}{" "}
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <Row className="mb-2">
                    <h5 className="userprofile-card-title">
                      About{" "}
                      {localStorage.getItem("gender") === "Male"
                        ? `Bride`
                        : `Groom`}
                    </h5>
                    <p style={{ color: "#5B6770" }}>
                      {userProfile?.aboutme === null ||
                      userProfile?.aboutme === "" ||
                      userProfile?.aboutme === "" ? (
                        <div style={{ color: "#C4C4C4" }}>
                          No description provided
                        </div>
                      ) : (
                        userProfile.aboutme.replace(/<[^>]+>/g, "")
                      )}
                    </p>
                  </Row>
                  <Row>
                    <Col
                      xs={3}
                      className="d-flex flex-column align-items-center"
                    >
                      {this.state.isShortListed ? (
                        <MdStar
                          size="28px"
                          color="var(--primary-color)"
                          onClick={() => this.showConfirmModal("rem_shortlist")}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <MdStarBorder
                          size="28px"
                          color="var(--primary-color)"
                          onClick={this.addToShortlist}
                          style={{ cursor: "pointer" }}
                        />
                      )}

                      <div className="mt-3"> Shortlist </div>
                    </Col>
                    <Col
                      xs={6}
                      className="d-flex flex-column align-items-center"
                    >
                      {this.state.isInterested ? (
                        <MdFavorite
                          size="28px"
                          color="var(--primary-color)"
                          onClick={() => this.showConfirmModal("rem_interest")}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <MdFavoriteBorder
                          size="28px"
                          color="var(--primary-color)"
                          onClick={this.sendInterest}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <div className="mt-3"> Send Interest </div>
                    </Col>
                    {userProfile.is_ignore !== "true" && (
                      <Col
                        xs={3}
                        className="d-flex flex-column align-items-center"
                      >
                        <MdOutlineHighlightOff
                          size="28px"
                          color="var(--primary-color)"
                          onClick={() => this.showConfirmModal("ignore")}
                          style={{ cursor: "pointer" }}
                        />
                        <div className="mt-3">Ignore </div>
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-4">
                    <h5 className="userprofile-card-title mb-4">Connect</h5>
                    <Row className="justify-content-center">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-evenly",
                        }}
                        className="connect-btns__user-profile"
                      >
                        {localStorage.plan != "2" ? (
                          this.state.isViewed === 2 ? (
                            <div
                              style={{
                                backgroundColor: "var(--primary-color)",
                                padding: "1rem",
                                borderRadius: "0.5rem",
                                marginRight: "1rem",
                                cursor: "pointer",
                                width: "35%",
                                textAlign: "center",
                                minWidth: "90px",
                                alignSelf: "center",
                              }}
                            >
                              <Link
                                style={{ color: "white" }}
                                to={`/messages/${localStorage.visitingUser}`}
                              >
                                <BsChat size="18px" className="me-2" />
                                Chat
                              </Link>
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor: "var(--primary-color)",
                                padding: "1rem",
                                color: "white",
                                borderRadius: "0.5rem",
                                cursor: "pointer",
                                width: "35%",
                                textAlign: "center",
                                minWidth: "90px",
                                alignSelf: "center",
                              }}
                              onClick={() =>
                                this.setState({ showContactReadModal: true })
                              }
                            >
                              <BsChat size="18px" className="me-2" />
                              Chat
                            </div>
                          )
                        ) : (
                          <div
                            style={{
                              backgroundColor: "var(--primary-color)",
                              padding: "1rem",
                              color: "white",
                              borderRadius: "0.5rem",
                              cursor: "pointer",
                              width: "35%",
                              textAlign: "center",
                              minWidth: "90px",
                              alignSelf: "center",
                            }}
                            onClick={() =>
                              this.setState({ showUpgradeModal: true })
                            }
                          >
                            <BsChat size="18px" className="me-2" />
                            Chat
                          </div>
                        )}
                        {plan.video_chat_priority === "Y" ? (
                          this.state.isViewed === 2 ? (
                            <div
                              style={{
                                backgroundColor: "var(--secondary-color)",
                                color: "black",
                                padding: "1rem",
                                borderRadius: "0.5rem",
                                cursor: "pointer",
                                width: "35%",
                                textAlign: "center",
                                minWidth: "100px",
                                alignSelf: "center",
                              }}
                              onClick={() => {
                                this.scheduleVideo();
                                this.setState({ disableScheduleModal: false });
                              }}
                            >
                              <BsCameraVideo size="20px" className="me-2" />
                              Meet
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor: "var(--secondary-color)",
                                color: "black",
                                padding: "1rem",
                                borderRadius: "0.5rem",
                                cursor: "pointer",
                                width: "35%",
                                textAlign: "center",
                                minWidth: "100px",
                                alignSelf: "center",
                              }}
                              onClick={() =>
                                this.setState({ showContactReadModal: true })
                              }
                            >
                              <BsCameraVideo size="20px" className="me-2" />
                              Meet
                            </div>
                          )
                        ) : (
                          <div
                            style={{
                              backgroundColor: "var(--secondary-color)",
                              color: "black",
                              padding: "1rem",
                              borderRadius: "0.5rem",
                              cursor: "pointer",
                              width: "35%",
                              textAlign: "center",
                              minWidth: "100px",
                              alignSelf: "center",
                            }}
                            onClick={() =>
                              this.setState({ showUpgradeModal: true })
                            }
                          >
                            <BsCameraVideo size="20px" className="me-2" />
                            Meet
                          </div>
                        )}
                      </div>
                      <Modal
                        show={this.state.showContactReadModal}
                        onHide={() =>
                          this.setState({ showContactReadModal: false })
                        }
                      >
                        <Modal.Body className="justify-content-center align-items-center d-flex flex-column">
                          <div>
                            Chat can be started only after reading contact
                          </div>
                          <Button
                            className="px-4 mt-3"
                            style={{
                              borderColor: "var(--primary-color)",
                              color: "var(--primary-color)",
                              backgroundColor: "#FFF",
                            }}
                            onClick={() =>
                              this.setState({ showContactReadModal: false })
                            }
                          >
                            Ok
                          </Button>
                        </Modal.Body>
                      </Modal>
                      <UpgradeModal
                        upgradeNow={this.state.showUpgradeModal}
                        closeModal={() =>
                          this.setState({ showUpgradeModal: false })
                        }
                      ></UpgradeModal>
                    </Row>
                  </Row>
                </Card>
              </Col>
            </Row>
            <ProfileDetails
              for="visit"
              onChangeRead={this.changeRead}
            ></ProfileDetails>
            <ProfileMatch
              face_image={album[0]?.album}
              albumVisibility={userProfile?.album_visibility}
            ></ProfileMatch>
            <Row>
              <Col xs={3} className="d-flex flex-column align-items-center">
                {this.state.isShortListed ? (
                  <MdStar
                    size="28px"
                    color="var(--primary-color)"
                    onClick={() => this.showConfirmModal("rem_shortlist")}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <MdStarBorder
                    size="28px"
                    color="var(--primary-color)"
                    onClick={this.addToShortlist}
                    style={{ cursor: "pointer" }}
                  />
                )}

                <div className="mt-3"> Shortlist </div>
              </Col>
              <Col xs={6} className="d-flex flex-column align-items-center">
                {this.state.isInterested ? (
                  <MdFavorite
                    size="28px"
                    color="var(--primary-color)"
                    onClick={() => this.showConfirmModal("rem_interest")}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <MdFavoriteBorder
                    size="28px"
                    color="var(--primary-color)"
                    onClick={this.sendInterest}
                    style={{ cursor: "pointer" }}
                  />
                )}
                <div className="mt-3"> Send Interest </div>
              </Col>
              <Col xs={3} className="d-flex flex-column align-items-center">
                <MdOutlineHighlightOff
                  size="28px"
                  color="var(--primary-color)"
                  onClick={() => this.showConfirmModal("ignore")}
                  style={{ cursor: "pointer" }}
                />
                <div className="mt-3">Ignore </div>
              </Col>
            </Row>
            <Modal
              show={this.state.showModalRemShortlist}
              onHide={() => {}}
              className="logout-modal"
            >
              <Modal.Title>Remove from Shortlist</Modal.Title>
              <Modal.Body>
                Are you sure to remove from shortlist?
                <Modal.Footer>
                  <Button
                    style={{
                      borderColor: "var(--primary-color)",
                      backgroundColor: "#FFF",
                      color: "var(--primary-color)",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                    }}
                    onClick={this.hideallmodals}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      border: "none",
                      backgroundColor: "var(--primary-color)",
                      color: "#FFF",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                    }}
                    onClick={this.removeShortListApi}
                  >
                    Remove
                  </Button>
                </Modal.Footer>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.showModalRemInterest}
              onHide={() => {}}
              className="logout-modal"
            >
              <Modal.Title>Remove from Interest</Modal.Title>
              <Modal.Body>
                Are you sure to remove from Interest?
                <Modal.Footer>
                  <Button
                    style={{
                      borderColor: "var(--primary-color)",
                      backgroundColor: "#FFF",
                      color: "var(--primary-color)",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                    }}
                    onClick={this.hideallmodals}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      border: "none",
                      backgroundColor: "var(--primary-color)",
                      color: "#FFF",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                    }}
                    onClick={this.removeInterestApi}
                  >
                    Remove
                  </Button>
                </Modal.Footer>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.showModalIgnoreProf}
              onHide={() => {}}
              className="logout-modal"
            >
              <Modal.Title>Ignore Profile</Modal.Title>
              <Modal.Body>
                Are you sure to Ignore this profile?
                <Modal.Footer>
                  <Button
                    style={{
                      borderColor: "var(--primary-color)",
                      backgroundColor: "#FFF",
                      color: "var(--primary-color)",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                    }}
                    onClick={this.hideallmodals}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      border: "none",
                      backgroundColor: "var(--primary-color)",
                      color: "#FFF",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                    }}
                    onClick={this.ignore}
                  >
                    Ignore
                  </Button>
                </Modal.Footer>
              </Modal.Body>
            </Modal>
          </div>
          <Footer></Footer>
        </div>
      );
    } else {
      return (
        <div>
          <NavbarMenu></NavbarMenu>
          <Loader></Loader>
        </div>
      );
    }
  }
}
