import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Image,
  Modal,
  Row,
  Container,
  Nav,
  Navbar,
} from "react-bootstrap";
import { premiumSupportImage, QRcode } from "../../assets";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { MdOutlineCall, MdOutlineLocationOn, MdMenu } from "react-icons/md";
import { miniLogo, miniLogoMob } from "../../assets";
import { Footer, Loader, NavbarMenu, Plan } from "../../Components";
import { environment } from "../../environment/env";
import "./Plans&Payment.css";

const baseURL = environment.hostname;
export default class PlansAndPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPremiumPaymentGateway: false,
      isLoading: true,
      premiumPlan: "",
      regularPlans: "",
      verifiedPlans: "",
      currentPlan: "",
      session: localStorage.getItem("session"),
      currentUser: localStorage.getItem("currentUser"),
    };
  }

  componentDidMount() {
    localStorage.setItem("activeLink", "plans");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let regularPlansApi = fetch(`${baseURL}/plan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let verifiedPlansApi = fetch(`${baseURL}/verified_plan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });

    let myPlanApi = fetch(`${baseURL}/myplan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });

    let premiumSupprotPlanApi = fetch(`${baseURL}/premium_plan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    Promise.all([
      regularPlansApi,
      verifiedPlansApi,
      myPlanApi,
      premiumSupprotPlanApi,
    ])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        this.setState({
          regularPlans: response[0],
          verifiedPlans: response[1],
          currentPlan: response[2][0],
          premiumPlan: response[3],
          isLoading: false,
        });
        localStorage.setItem("plan", response[2][0]["pid"]);
      });
  }

  render() {
    return (
      <div>
        {localStorage.getItem("session") != null &&
        localStorage.getItem("currentUser") != null ? (
          <NavbarMenu></NavbarMenu>
        ) : (
          <Container fluid className="register-page pb-5">
            <Row className="navbarmenu">
              <Navbar
                expand="lg"
                className="navbar align-items-start justify-content-between m-0 p-0 mt-4 px-md-4 px-sm-3"
                style={{ flexShrink: "unset", margin: "0 166px 0 0" }}
              >
                <Col className="d-inline-flex">
                  <NavLink to="/" className="brand mt-1">
                    <img
                      alt=""
                      src={window.innerWidth > 500 ? miniLogo : miniLogoMob}
                      width="269"
                      height="71"
                      className="d-inline-block align-top minilogo"
                    />{" "}
                  </NavLink>
                </Col>
                <Nav className="d-flex justify-content-end  row flex-row  mt-3">
                  <Link
                    to="/login"
                    className="login-button-home d-flex justify-content-center align-items-center order-lg-5 col-auto"
                  >
                    LOGIN
                  </Link>
                  <Navbar.Toggle className="col-auto border-0">
                    <MdMenu color="#FFF" size="28px" />{" "}
                  </Navbar.Toggle>
                  <Navbar.Collapse className="home-toggle-menu justify-content-end col-auto align-items-center">
                    <div className="d-flex mt-2 mt-md-3 mt-lg-0 justify-content-end">
                      <Link
                        to="/"
                        className="text-decoration-none px-2 px-lg-3 order-lg-1"
                        style={{ color: "#fff", paddingBottom: "0" }}
                      >
                        HOME
                      </Link>
                      <Link
                        to="/register"
                        className="text-decoration-none px-2 px-lg-3 order-lg-3"
                        style={{ color: "#fff", paddingBottom: "0" }}
                      >
                        REGISTER
                      </Link>
                      <Link
                        to="/contact"
                        className="text-decoration-none px-2 px-lg-3 order-lg-4"
                        style={{ color: "#fff", paddingBottom: "0" }}
                      >
                        CONTACT
                      </Link>
                    </div>
                  </Navbar.Collapse>
                </Nav>
                <Outlet />
              </Navbar>
            </Row>
          </Container>
        )}
        <div className="plans-and-payment">
          {!this.state.isLoading ? (
            <>
              <Row className="upgrade-profile-section m-0 justify-content-center">
                <div className="upgrade-profile-section-head d-flex justify-content-center">
                  Upgrade Your Profile
                </div>
                <p align="center" className="mt-4" style={{ width: "624px" }}>
                  Plan subscription allows you to view the contact details of
                  profiles and chat with contact viewed profiles.
                </p>
                <p align="center">
                  Issues in payment process? Please contact our customer care
                  executive Mob:
                  <span style={{ color: "var(--secondary-color)" }}>
                    {" "}
                    +919645004140
                  </span>
                </p>
              </Row>
              <Row className="justify-content-start plans-row">
                {this.state.regularPlans.map((plan) => (
                  <Col lg={3} md={4} xs={12}>
                    <Plan verified={false} plan={plan}></Plan>
                  </Col>
                ))}
              </Row>
              <Row className="justify-content-start plans-row">
                <div
                  class="plan-card d-flex align-items-center mb-3 card Qrcodecard Qr_bg "
                  style={{ height: "auto" }}
                >
                  <div
                    class="w-100  d-flex flex-column "
                    // style={{ backgroundColor: "rgb(255, 255, 255)" }}
                  >
                    <div class="col-lg-11 col-md-12 px-3 mx-auto">
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <h4
                            style={{
                              color: "#000",
                              fontSize: " 45px",
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            <img
                              alt=""
                              src={window.innerWidth > 500 ? QRcode : QRcode}
                              // style={{
                              //   width: "30%",
                              //   float: "left",
                              //   marginRight: "20px ",
                              // }}
                              className="d-inline-block p-4 img-fluid"
                            />
                          </h4>
                        </div>
                        <div className="col-lg-8">
                          <p
                            className="text_c "
                            // style={{
                            //   color: "#000",
                            //   fontSize: "18px",
                            //   // paddingLeft: 20,
                            //   padding: "20px",
                            //   // marginTop: "20%",
                            //   textAlign: "left",
                            //   // marginLeft: "20px",
                            // }}
                          >
                            Quick and easy payment methods. We understand that
                            you don't have time to wait for it, so we have made
                            it as easy as possible for you to pay. Scan and use
                            the below options
                          </p>
                          <ul
                            className="qrcodelist p-0 text_c "
                            // style={{ listStyleType: " disc", marginLeft: "250px" }}
                          >
                            <li>Net banking</li>
                            <li>
                              UPI transaction (Gpay, Paytm, PhonePe, Amazon-Pay,
                              and BHIM-PAY)
                            </li>
                            <li>Debit card and Credit card</li>
                            <li>QR Code payment</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              {/* <Row className="premium-support-plan mx-0">
                <Col md={4} xs={12}>
                  <Image src={premiumSupportImage} className="premium-img" />
                </Col>
                <Col md={8} xs={12}>
                  <div
                    style={{
                      fontSize: "25px",
                      color: "var(--primary-color)",
                      fontWeight: "700",
                    }}
                  >
                    {this.state.premiumPlan.name}
                  </div>
                  <div
                    className="plan-price"
                    style={{
                      marginBottom: "27px",
                      color: "var(--primary-color)",
                    }}
                  >
                    &#8377;{this.state.premiumPlan.amount}
                  </div>

                  <p>
                    Connect with our relationship manager, who drives you
                    through the different procedure- profile creation, arranging
                    meetings, and to get the match finalised.{" "}
                  </p>
                  <div>• Works on your profile to ensure it gets noticed</div>
                  <div>
                    • Understands qualities that you are looking for in your
                    desired partner
                  </div>
                  <div>
                    • Sends Interests to handpicked profiles matching your
                    criteria
                  </div>
                  <div>
                    • Recommends matching profiles through your profile
                    dashboard
                  </div>
                  <div>
                    • Contacts profiles shortlisted by you and arrange meetings
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="pay-button"
                      onClick={() =>
                        this.setState({ showPremiumPaymentGateway: true })
                      }
                    >
                      Pay Now
                    </Button>
                  </div>
                </Col>
                <Modal
                  className="plan-modal"
                  show={this.state.showPremiumPaymentGateway}
                  onHide={() =>
                    this.setState({ showPremiumPaymentGateway: false })
                  }
                >
                  <Modal.Body className="d-flex flex-column align-items-center px-5">
                    <iframe
                      className="h-100 w-100"
                      src={`https://click4marry.com/app/plan/payment_verified_mob_plan?plan=${this.state.premiumPlan.id}&amount=${this.state.premiumPlan.amount}&id=${localStorage.currentUser}`}
                      title="premium payment gateway"
                    ></iframe>
                  </Modal.Body>
                </Modal>
              </Row> */}
              {localStorage.getItem("session") != null &&
                localStorage.getItem("currentUser") != null && (
                  <Row className="plan-status-section">
                    <Col md={7} className="mb-4 mb-md-0">
                      <Card className="plan-status h-100">
                        <Card.Title style={{ color: "var(--primary-color)" }}>
                          Current Plan Status
                        </Card.Title>
                        <Card.Body>
                          <Row>
                            <Col xs={6} className="px-0">
                              Plan
                            </Col>
                            <Col xs={6}>{this.state.currentPlan.planname}</Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="px-0">
                              Total contacts offered
                            </Col>
                            <Col xs={6}>
                              {this.state.currentPlan.contacts_allowed} contacts
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="px-0">
                              Total contacts viewed
                            </Col>
                            <Col xs={6}>
                              {this.state.currentPlan.contacts_allowed -
                                this.state.currentPlan.balancecontact}{" "}
                              contacts
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="px-0">
                              Remaining contacts you can view
                            </Col>
                            <Col xs={6}>
                              {this.state.currentPlan.balancecontact} contacts
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="px-0">
                              Total Validity of Plan
                            </Col>
                            <Col xs={6}>
                              {this.state.currentPlan.duration} days
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="px-0">
                              Days remaining
                            </Col>
                            <Col xs={6}>
                              {this.state.currentPlan.balancedays} days
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end">
                            <Link
                              className="pay-button"
                              style={{
                                width: "200px",
                                height: "50px",
                                color: "white",
                                textAlign: "center",
                                paddingTop: "15px",
                              }}
                              to="/plan-history"
                            >
                              View Plan History
                            </Link>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={5}>
                      <Card className="plan-status h-100">
                        <Card.Title style={{ color: "var(--primary-color)" }}>
                          Direct Payment - Axis Bank
                        </Card.Title>
                        <div>MADHYAMAM CAPITAL ENTERPRISES LLP</div>
                        <div> BANK: Axis Bank</div>
                        <div> BRANCH: MALAPARAMBA </div>
                        <div> ACCOUNT NUMBER : 919020089183671</div>
                        {/* <div> CIF NUMBER : 190000453267</div> */}
                        <div> IFSC CODE : UTIB0002988</div>
                        {/* <div> SWIFT CODE : HDFCINBB</div>
                                        <div> MICR CODE : 673240004</div> */}

                        <Card.Title
                          style={{
                            color: "var(--primary-color)",
                            marginTop: "20px",
                          }}
                        >
                          Direct Payment - Federal Bank
                        </Card.Title>
                        <div>MADHYAMAM CAPITAL ENTERPRISES LLP</div>
                        <div> BANK: Federal Bank</div>
                        <div> BRANCH: Nadakkavu West </div>
                        <div> ACCOUNT NUMBER : 13000200020381</div>
                        {/* <div> CIF NUMBER : 190000453267</div> */}
                        <div> IFSC CODE : FDRL0001300</div>
                        {/* <div> SWIFT CODE : HDFCINBB</div>
                                        <div> MICR CODE : 673240004</div> */}
                      </Card>
                    </Col>
                  </Row>
                )}
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
