import React, { Component } from 'react'

export default class Loader extends Component {
    render() {
        return (
            <div style={{ height:"100vh"}} className='d-flex justify-content-center align-items-center'>
                <div className="spinner-border" style={{color: 'var(--primary-color)'}} role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        )
    }
}
