import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  RiLinksLine,
  RiFacebookCircleFill,
  RiWhatsappLine,
  RiMapPinLine,
} from "react-icons/ri";
import {
  AiOutlineMail,
  AiFillInstagram,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { BsTelegram } from "react-icons/bs";
import { miniLogo, miniLogoMob, playStore, appStore } from "../../assets/index";
import { BackToTop } from "../index";
import WhatsappChat from "../WhatsappChat/WhatsappChat";
import "./Footer.css";
import { Link } from "react-router-dom";
import { environment } from "../../environment/env";
import { MdOutlineCall } from "react-icons/md";

const playStoreURL = environment.playStoreURL;
const appStoreURL = environment.appStoreURL;
const termsconditionsURL = environment.termsURL;

export default function Footer() {
  let installPlayStore = () => {
    window.open(playStoreURL, "_blank");
  };

  let installAppStore = () => {
    window.open(appStoreURL, "_blank");
  };

  return (
    <div>
      <BackToTop></BackToTop>
      <WhatsappChat></WhatsappChat>
      <Container fluid className="footer">
        <Row>
          <Col
            lg={5}
            className="p-0 d-flex justify-content-start flex-column pe-5"
          >
            <Link to="/">
              <Image
                src={window.innerWidth > 500 ? miniLogo : miniLogoMob}
                width="267px"
                height="71px"
              />
            </Link>
            <p className="details p-0" align="left">
              Click4marry.com is a subsidiary concern of Madhyamam Daily, owned
              by Ideal Publications Trust. The easy process of registration and
              a user friendly platform to find most suitable match from
              thousands of trusted and authentic matrimonial profiles make
              Click4marry.com a leading matchmaking site. Click4marry generally
              serves Indian community with a particular focus on Keralites. It
              also serves Kerala expats living abroad, giving special focus on
              Malayalis in the GCC countries.
            </p>
            <Row className="align-items-center m-0">
              <h6 className="mobileApp d-flex justify-content-start p-0">
                DOWNLOAD MOBILE APPLICATION
              </h6>
              <Row className="m-2 p-2 align-items-center d-flex mobileAppBox">
                <Col
                  className="play-store-icon col-auto me-3"
                  onClick={installPlayStore}
                >
                  <Image width="auto" height="46px" src={playStore} />
                </Col>
                <Col
                  className="p-0 d-flex col-auto"
                  onClick={installAppStore}
                  style={{ cursor: "pointer" }}
                >
                  <Image width="auto" height="46px" src={appStore} />
                </Col>
              </Row>
            </Row>
          </Col>
          <Col lg={3} className="quick-links">
            <h6 className="subhead d-flex">QUICK LINKS</h6>
            <Link
              style={{ marginBottom: "12px", alignItems: "center" }}
              to="/feedback"
              className="d-flex text-decoration-none"
            >
              <Col className="d-flex col-auto pe-2">
                <RiLinksLine size="22px" color="#FFF" />{" "}
              </Col>
              <Col className="d-flex p-0 quick-link-label">Feedback</Col>
            </Link>
            {/* <Link style={{ marginBottom: '12px', alignItems: 'center' }} to='/wedding-stores' className='d-flex text-decoration-none'>
                            <Col className="d-flex col-auto pe-2"><RiLinksLine size='22px' color="#FFF" /> </Col>
                            <Col className="d-flex p-0 quick-link-label">Marriage Stores</Col> TODO: Wedding Stores
                        </Link> */}
            <Link
              style={{ marginBottom: "12px", alignItems: "center" }}
              to="/plans-and-payment"
              className="d-flex text-decoration-none"
            >
              <Col className="d-flex col-auto pe-2">
                <RiLinksLine size="22px" color="#FFF" />{" "}
              </Col>
              <Col className="d-flex p-0 quick-link-label">
                Membership Plans
              </Col>
            </Link>
            <a
              style={{ marginBottom: "12px", alignItems: "center" }}
              href={termsconditionsURL}
              rel="noreferrer noopener"
              target="_blank"
              className="d-flex text-decoration-none"
            >
              <Col className="d-flex col-auto pe-2">
                <RiLinksLine size="22px" color="#FFF" />{" "}
              </Col>
              <Col className="d-flex p-0 quick-link-label">
                Terms and Conditions
              </Col>
            </a>

            <Row className="social-media">
              {/* <h6 className="d-flex">GET IN TOUCH WITH US</h6> */}
              <Col xs={1} className="pe-4">
                <RiFacebookCircleFill
                  size="26px"
                  onClick={() =>
                    window.open("https://www.facebook.com/click4marry")
                  }
                  style={{ cursor: "pointer" }}
                />
              </Col>
              {/* <Col xs={1} className='pe-4'><AiFillGooglePlusCircle size="25px" /></Col> */}
              {/* <Col xs={1} className='pe-4'><AiFillTwitterCircle size="25px" /></Col> */}
              <Col xs={1} className="pe-4">
                <AiFillInstagram
                  size="25px"
                  onClick={() =>
                    window.open("https://www.instagram.com/click4marry/")
                  }
                  style={{ cursor: "pointer" }}
                />
              </Col>
              <Col xs={1} className="pe-4">
                <AiFillTwitterCircle
                  size="25px"
                  onClick={() =>
                    window.open("https://twitter.com/clickformarry")
                  }
                  style={{ cursor: "pointer" }}
                />
              </Col>
              <Col xs={1} className="pe-4">
                <BsTelegram
                  size="25px"
                  onClick={() => window.open("https://t.me/clickformarry")}
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </Row>
          </Col>
          <Col className="quick-links">
            <h6 className="subhead d-flex">GET IN TOUCH</h6>
            <Row
              onClick={() => {
                window.open(`tel:+919645004140`);
              }}
              style={{ marginBottom: "12px", cursor: "pointer" }}
            >
              <Col className="col-auto">
                <MdOutlineCall size="26px" />
              </Col>
              <Col className="d-flex p-0 quick-link-label">+919645004140</Col>
            </Row>
            <Row
              style={{ marginBottom: "12px", cursor: "pointer" }}
              onClick={() => {
                window.open(`https://wa.me/9645004140`);
              }}
            >
              <Col className="col-auto">
                <RiWhatsappLine size="26px" />
              </Col>
              <Col className="d-flex p-0 quick-link-label"> +919645004140</Col>
            </Row>
            <Row
              style={{ marginBottom: "12px", cursor: "pointer" }}
              onClick={() => window.open(`mailto:help@click4marry.com`)}
            >
              <Col className="col-auto">
                <AiOutlineMail size="26px" />
              </Col>
              <Col className="d-flex p-0 quick-link-label">
                help@click4marry.com
              </Col>
            </Row>
            <Row style={{ marginBottom: "12px" }}>
              <Col className="col-auto">
                <RiMapPinLine size="26px" />
              </Col>
              <Col className="d-flex p-0 quick-link-label">
                <p align="left">
                  PB No. 1708,
                  <br />
                  Silver Hills PO,
                  <br />
                  Calicut -12,
                  <br />
                  0495 2731500{" "}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="copyright d-flex justify-content-center">
        © {new Date().getFullYear()} Click4Marry | All rights reserved
      </div>
      {/* <div className="copyright d-flex justify-content-center">
        © 2022 Click4Marry | All rights reserved
      </div> */}
    </div>
  );
}
