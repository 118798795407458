import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function NavigateToProfile() {

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.currentUser && localStorage.session) navigate('/profile')
  }, [])

  return (
    <div>
    </div>
  )
}