import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Footer, Loader, NavbarMenu } from '../../Components';
import { environment } from '../../environment/env';
import './SuccessStories.css'

const storyImageURL = environment.storyImageURL;
const baseURL = environment.hostname;
let successStories, story

export default function SuccessStories(props) {
    const [isLoading, setIsLoading] = useState(true)

    let storyId = window.location.hash.slice(18)
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        fetch(`${baseURL}/success_stories_list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: localStorage.currentUser
            }),
        }).then(res => res.json()).then(response => {
            successStories = response
            story = successStories.find(item => item.id === storyId)
            setIsLoading(false)
        })
    }, [])


    return (
        <div>
            <NavbarMenu></NavbarMenu>
            {!isLoading ?
                <div className='success-stories-body'>
                    <h4 className='title'>{story?.head || 'Success Story'}</h4>
                    <div className='d-flex flex-column align-items-center'>
                        <Image width='60%' src={storyImageURL + story?.image_url} alt="wedding" style={{ borderRadius: '10px',marginTop:'20px' }} />

                        <div className='d-flex w-100 align-items-left flex-column mt-3'>
                            {story?.date ? <div style={{ color: '#C4C4C4' }}>Date: {story?.date}</div> : ''}
                            <p align='left' className='m-0 mt-3'>{story?.body}</p>

                        </div>
                    </div>
                </div >
                :
                <Loader></Loader>
            }
            <Footer></Footer>
        </div >
    )
}