import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  ProfileComponent,
  Footer,
  NavbarMenu,
  Loader,
  NoData,
} from "../../Components";
import { environment } from "../../environment/env";
import "./Request.css";

export default function Request(props) {
  const { page } = useParams();
  const baseURL = environment.hostname;
  const profiles = useLocation().state?.profiles;
  const [requestRecieved, setRequestRecieved] = useState(
    profiles ? profiles : null
  );
  const [contentRequestRecieved, setContentRequestRecieved] = useState("");
  const [currentActivityTab, setCurrentActivityTab] = useState("Pending");
  const [currentRequestTab, setCurrentRequestTab] = useState("Recieved");
  const [pendingCount, setPendingCount] = useState("");

  let showRequestRecieved = () => {
    setCurrentRequestTab("Recieved");
  };
  let showRequestSend = () => {
    setCurrentRequestTab("Send");
  };
  let showPendingRequests = () => {
    setCurrentActivityTab("Pending");
  };
  let showAcceptedRequests = () => {
    setCurrentActivityTab("Accepted");
  };
  let showDeclinedRequests = () => {
    setCurrentActivityTab("Declined");
  };
  let showAllRequests = () => {
    setCurrentActivityTab("All");
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getRequests();

    updateLogin();
    localStorage.setItem("activeLink", "request");
  }, [currentActivityTab, currentRequestTab, props.page]);

  let getRequests = () => {
    fetch(`${baseURL}/count_pending`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setPendingCount(response);
      });
    let mounted = true,
      status;
    //interest requests
    if (props?.page === "interest") {
      if (currentRequestTab === "Recieved") {
        if (currentActivityTab === "Pending") status = 3;
        else if (currentActivityTab === "Accepted") status = 1;
        else if (currentActivityTab === "Declined") status = 2;
        else if (currentActivityTab === "All") status = 0;
        fetch(`${baseURL}/express_interest_recieve`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
            status: status,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (mounted) {
              setRequestRecieved(response);
            }
          });
        return () => (mounted = false);
      } else if (currentRequestTab === "Send") {
        if (currentActivityTab === "Pending") status = 3;
        else if (currentActivityTab === "Accepted") status = 1;
        else if (currentActivityTab === "Declined") status = 2;
        else if (currentActivityTab === "All") status = 0;
        fetch(`${baseURL}/express_interest_send`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
            status: status,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (mounted) {
              setRequestRecieved(response);
            }
          });
        return () => (mounted = false);
      }
      //photo request
    } else if (props?.page === "album") {
      if (currentRequestTab === "Recieved") {
        if (currentActivityTab === "Pending") status = 3;
        else if (currentActivityTab === "Accepted") status = 1;
        else if (currentActivityTab === "Declined") status = 2;
        else if (currentActivityTab === "All") status = 0;
        let albumRequestApi = fetch(`${baseURL}/album_request_recieve`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
            status: status,
          }),
        });

        let contentRequestApi = fetch(`${baseURL}/content_request_recieve`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
            status: status,
          }),
        });

        Promise.all([albumRequestApi, contentRequestApi])
          .then((values) => Promise.all(values.map((value) => value.json())))
          .then((response) => {
            if (mounted) {
              setRequestRecieved(response[0]);
              setContentRequestRecieved(response[1]);
            }
          });
        return () => (mounted = false);
      } else if (currentRequestTab === "Send") {
        if (currentActivityTab === "Pending") status = 3;
        else if (currentActivityTab === "Accepted") status = 1;
        else if (currentActivityTab === "Declined") status = 2;
        else if (currentActivityTab === "All") status = 0;
        let albumRequestApi = fetch(`${baseURL}/album_request_send`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
            status: status,
          }),
        });

        let contentRequestApi = fetch(`${baseURL}/content_request_send`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
            status: status,
          }),
        });

        Promise.all([albumRequestApi, contentRequestApi])
          .then((values) => Promise.all(values.map((value) => value.json())))
          .then((response) => {
            if (mounted) {
              setRequestRecieved(response[0]);
              setContentRequestRecieved(response[1]);
            }
          });
        return () => (mounted = false);
      }
    } else {
      setRequestRecieved(profiles);
    }
  };

  let updateLogin = () => {
    fetch(`${baseURL}/loginupdate`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
      headers: {
        "Content-type": "application/json",
      },
    }).then((response) => {});
  };

  if (requestRecieved || profiles || contentRequestRecieved) {
    return (
      <div>
        <NavbarMenu pending={pendingCount}></NavbarMenu>
        <div className="request-body">
          {/* <Link
                        to={useLocation().state?.from}
                        style={{padding:'10px 10px'}}>
                        Back
                    </Link> */}
          <h5 className="title" style={{ padding: "10px 10px" }}>
            {useLocation().state?.title}
          </h5>
          {props.page ? (
            <Row className="request-tabs m-0">
              <Col
                md={2}
                xs={6}
                onClick={showRequestRecieved}
                style={{
                  cursor: "pointer",
                  borderRadius: "10px",
                  padding: "12px",
                  height: "42px",
                  backgroundColor:
                    currentRequestTab === "Recieved"
                      ? "var(--primary-color)"
                      : "#fff",
                  color: currentRequestTab === "Recieved" ? "#FFF" : "#000",
                }}
                className=" d-flex justify-content-center align-items-center"
              >
                {props.page === "interest"
                  ? "Interest Received"
                  : "Request Received"}
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center"
                style={{
                  cursor: "pointer",
                  borderRadius: "10px",
                  padding: "12px",
                  height: "42px",
                  backgroundColor:
                    currentRequestTab === "Send"
                      ? "var(--primary-color)"
                      : "#fff",
                  color: currentRequestTab === "Send" ? "#FFF" : "#000",
                }}
                md={2}
                xs={6}
                onClick={showRequestSend}
              >
                {props.page === "interest" ? "Interest Send" : "Request send"}
              </Col>
            </Row>
          ) : (
            ""
          )}
          {props.page ? (
            <Row className="activity-tabs align-items-center">
              <div
                className="tab w-auto py-1"
                style={{
                  borderRadius: "13px",
                  backgroundColor:
                    currentActivityTab === "Pending" ? "#5B6770" : "white",
                  color: currentActivityTab === "Pending" ? "white" : "black",
                }}
                onClick={showPendingRequests}
              >
                Pending
              </div>
              <div
                className="tab w-auto py-1"
                style={{
                  borderRadius: "13px",
                  backgroundColor:
                    currentActivityTab === "Accepted" ? "#5B6770" : "white",
                  color: currentActivityTab === "Accepted" ? "white" : "black",
                }}
                onClick={showAcceptedRequests}
              >
                Accepted
              </div>
              <div
                md={1}
                xs={3}
                className="tab w-auto py-1"
                style={{
                  borderRadius: "13px",
                  backgroundColor:
                    currentActivityTab === "Declined" ? "#5B6770" : "white",
                  color: currentActivityTab === "Declined" ? "white" : "black",
                }}
                onClick={showDeclinedRequests}
              >
                Declined
              </div>
              {/* <div md={1} xs={2} className='tab w-auto py-1' style={{
                                borderRadius: '13px',
                                backgroundColor: currentActivityTab === 'All' ? '#5B6770' : 'white',
                                color: currentActivityTab === 'All' ? 'white' : 'black'
                            }} onClick={showAllRequests}>All</div> */}
            </Row>
          ) : (
            ""
          )}
          <Row>
            {/* {page ? <Row className='m-0 title mb-5'>{page}</Row> : ''} */}
            <Row className="m-0">
              {requestRecieved?.length ? (
                <>
                  {props?.page === "album" ? (
                    <h5 className="title">Album Requests</h5>
                  ) : (
                    ""
                  )}
                  {requestRecieved.map((profile) => (
                    <Col md={6} xs={12} className="interest-profile">
                      <ProfileComponent
                        changed={getRequests}
                        card="true"
                        tab={currentRequestTab}
                        activityTab={currentActivityTab}
                        profile={profile}
                        page={props?.page}
                      ></ProfileComponent>
                    </Col>
                  ))}
                </>
              ) : (
                ""
              )}

              {contentRequestRecieved.contact?.length ? (
                <>
                  <h5 className="title">Contact Info Requests</h5>
                  {contentRequestRecieved.contact.map((profile) => (
                    <Col md={6} xs={12} className="interest-profile">
                      <ProfileComponent
                        changed={getRequests}
                        type="contact"
                        page="content"
                        card="true"
                        tab={currentRequestTab}
                        activityTab={currentActivityTab}
                        profile={profile}
                      ></ProfileComponent>
                    </Col>
                  ))}
                </>
              ) : (
                ""
              )}

              {!requestRecieved?.length &&
              !contentRequestRecieved?.contact?.length ? (
                <NoData></NoData>
              ) : (
                ""
              )}
            </Row>
          </Row>
        </div>
        <Footer></Footer>
      </div>
    );
  } else {
    return (
      <div>
        <NavbarMenu></NavbarMenu>
        <Loader></Loader>
        <Footer></Footer>
      </div>
    );
  }
}
