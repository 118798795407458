import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function NavigateTo() {

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.currentUser && localStorage.session) navigate('/matching')

  }, [])

  return (
    <div></div>
  )
}
