import React from "react";
import { Button } from "react-bootstrap";
import { BsShieldLockFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { environment } from "../../environment/env";
import "./ProtectedImage.css";

const baseURL = environment.hostname;
export default function ProtectedImage1(props) {
  let sendImageRequest = (e) => {
    e.stopPropagation();
    fetch(`${baseURL}/add_album_request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: props?.id,
        ip: "111.111.1.1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (props.updateFunction != null) {
          props.updateFunction(props.updateContext);
        }
        addNonImageStatus();
      });
  };

  let addNonImageStatus = () => {
    fetch(`${baseURL}/noimage_request_activity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        to_id: props?.id,
        session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response == true) {
          toast.success("Album request send successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error("Failed to send album request", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  return (
    <div
      className="protected-image1"
        
    >
      <BsShieldLockFill color="var(--secondary-color)" size="28px" />
      <div
        style={{
          backgroundColor: "rgb(0,0,0,0.1)",
          padding: "8px",
          borderRadius: "25px",
        }}
        className="mt-2"
      >
        Protected
      </div>
      {props.requestButton ? (
        <Button
          className="mt-2 protected-image-button "
          onClick={(e) => sendImageRequest(e)}
        >
          Request
        </Button>
      ) : props.isProfile == true ? (
        <div
          style={{
            color: "#676767",
            padding: "8px",
            borderRadius: "10px",
            marginTop: "10px",
          }}
        >
          Request Already Send
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
