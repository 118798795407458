import React from 'react'
import { Footer, NavbarMenu } from '../../Components'
import {useEffect,useState} from 'react'
import { environment } from '../../environment/env';
import '../Requests/Request.css'
import {Link} from 'react-router-dom'
function NotificationHisMob() {
    const baseURL = environment.hostname;
    const [isNotificationHistory,setIsNotificationHistory] =useState([])

    useEffect(() => {
        
        fetch(`${baseURL}/get_notification_history`,{
          method:"POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id:localStorage.currentUser,
          })
        }).then(res => res.json())
        .then((response) => {

          setIsNotificationHistory(response)

        })
      },[])
  return (
    <div>
        <NavbarMenu/>
        <div className='request-body'>
        <h5 className='title' style={{ padding: '10px 10px' }}>Notifications</h5>

            <center>
            <ul style={{listStyleType:"none",padding:"0.5rem"}}>        
            {isNotificationHistory.map((item) => (
                <li style={{padding:"10px 20px",borderBottom:" 1px solid #f1f2f4",alignItems:"center",backgroundColor: item.read_status === 0 ? "#e5e5e5" : "white"}}>
                    <div style={{margin:" 0 15px"}}>
                        <div style={{color:"grey",fontSize:"12px",textAlign:"left"}}>
                            
                            {item.date}
                        </div>
                        <div style={{marginTop:"5px",fontSize:"14px",textAlign:"left"}}>
                            {item.message}
                        </div>
                        {item.image != null ? 
                            <div>
                                <img src="{item.image}" alt="image" style={{width:"200px"}}></img>
                            </div> : <></>}
                                  
                        {item.type.includes('plan') ? 
                        <div align="left" style={{marginTop:"10px"}}>
                          <Link 
                            className='button'
                            to="/plans-and-payment"
                            >
                              View plans</Link>
                        </div>: <></>}


                    </div>
                </li>))}
                </ul>
                </center>
        </div>

        <Footer></Footer>
            

    </div>
  )
}

export default NotificationHisMob