import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Footer, Loader, NavbarMenu, ProfileAlbumCard, ProfileDescriptionCard, ProfileDetails } from '../../Components'
import MyProfileCard from '../../Components/MyProfileCard/MyProfileCard'
import { environment } from '../../environment/env'
import './MyProfile.css'

const baseURL = environment.hostname

export default class ProfileView extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            myProfile: ''
        };
    }


    componentDidMount() {
        this._isMounted = true;
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        this.getInfo()
    }
    getInfo = () => {
        let basicInfoApi = fetch(`${baseURL}/basic_info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: localStorage.currentUser,
                profid: localStorage.currentUser
            })
        });

        let physicalApi = fetch(`${baseURL}/get_physical`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                profid: localStorage.currentUser,
                id: localStorage.currentUser
            }),
        });

        let jobEducationApi = fetch(`${baseURL}/job_education`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                profid: localStorage.currentUser,
                id: localStorage.currentUser
            }),
        });

        Promise.all([basicInfoApi, physicalApi, jobEducationApi])
            .then(values => Promise.all(values.map(value => value.json())))
            .then(response => {
                let profile = response[0][0]
                let height = response[1][0].height
                let course = response[2][0].qual
                profile.height = height || ''
                profile.course = course || ''
                this.setState({
                    isLoading: false,
                    myProfile: profile
                })
                this.updateLogin()
            })
            .catch((err)=>{
                localStorage.clear()
                window.location.assign('/#/login')

            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    edit = () => {
        this.getInfo()
    }

    async updateLogin() {
        await fetch(`${baseURL}/loginupdate`, {
            method: 'POST',
            body: JSON.stringify({
                id: localStorage.currentUser
            }),
            headers: {
                "Content-type": "application/json"
            }
        }).then(response => {
        })
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <div>
                    <NavbarMenu></NavbarMenu>
                    <div className='profile p-0'>
                        <Row className='m-0'>
                            <Col md={4} xs={12} className='p-0 m-0 pe-3'>
                                <MyProfileCard myProfile={this.state.myProfile}></MyProfileCard>
                            </Col>
                            <Col md={8} xs={12} className='px-0 px-lg-2'>
                                <Row className='m-0 mt-lg-0 mt-4 '>
                                    <ProfileDescriptionCard myProfile={this.state.myProfile} edit={this.edit}></ProfileDescriptionCard>
                                </Row>
                                <Row className='m-0 mt-4'>
                                    <ProfileAlbumCard></ProfileAlbumCard>
                                </Row>
                            </Col>
                        </Row>
                        <ProfileDetails edit={true}></ProfileDetails>
                    </div>
                    <Footer></Footer>
                </div>
            )
        }

        else {
            return (
                <div>
                    <NavbarMenu></NavbarMenu>
                    <Loader></Loader>
                    <Footer></Footer>
                </div>
            )
        }
    }
}