import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function ConfirmModal(props) {
    return (
        <Modal show={props.confirmNow} onHide={(e) => props.close(e)} className="d-flex flex-column align-items-center">
            <Modal.Title className='title d-flex justify-content-center'>{props.action} {props.actionOn}?</Modal.Title>
            <Modal.Body>
                Do you want to {props.action} {props.id}?
                <div className='d-flex justify-content-between mt-4'>
                    <Button className='w-auto' style={{ borderColor: 'var(--primary-color)', backgroundColor: '#FFF', color: 'var(--primary-color)' }}
                        onClick={(e) => props.close(e)}>Cancel</Button>
                    <Button className='w-auto' style={{ borderColor: 'var(--primary-color)', backgroundColor: 'var(--primary-color)', color: '#FFF' }}
                        onClick={(e) => props.clickEvent(e)}>{props.action}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
