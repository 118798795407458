import React, { useState } from "react";
import {
  Card,
  Col,
  Image,
  Row,
  ProgressBar,
  Modal,
  Button,
  NavLink,
} from "react-bootstrap";
import {
  MdVerified,
  MdOutlineLocationOn,
  MdOutlineAddPhotoAlternate,
} from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { environment } from "../../environment/env";
import "./MyProfileCard.css";

let image, weddingImageToDisplay;
let imageURL = environment.imageURL;
const baseURL = environment.hostname;

export default function MyProfileCard(props) {
  const navigate = useNavigate();
  const [showAddStoryModal, setShowAddStoryModal] = useState(false);
  const [weddingPhoto, setWeddingPhoto] = useState("");
  const [body, setBody] = useState("");
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  let logout = () => {
    localStorage.clear();
    navigate("/");
  };

  let uploadWeddingPhoto = (event) => {
    var exts = /(.png|.webp|.jpg|.jpeg)/;
    if (event.target.value.match(exts)) {
      let file = event.target.files[0];
      weddingImageToDisplay = URL.createObjectURL(file);
      setWeddingPhoto(file);
    } else {
      toast.error("Please choose  valid Image file like .png|.webp|.jpg|.jpeg");
    }
  };

  let addStory = () => {
    if (!weddingPhoto) toast.info("Upload wedding photo");
    else if (!body) toast.info("Enter a note");
    else {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(weddingPhoto);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          fetch(`${baseURL}/success_stories`, {
            method: "POST",
            body: JSON.stringify({
              id: localStorage.currentUser,
              image: encoded,
              body: body,
              session_id: localStorage.session,
            }),
            headers: {
              "Content-type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === 0)
                toast.info("success story already submitted");
              else if (response.status === 1)
                toast.success("Success story submitted successfully");
              else toast.error("success story submission failed");
              weddingImageToDisplay = "";
              setWeddingPhoto("");
              setShowAddStoryModal(false);
            });
        };
        reader.onerror = (error) => reject(error);
      });
    }
  };

  let deletePhoto = () => {
    weddingImageToDisplay = "";
    setWeddingPhoto("");
  };

  if (props?.myProfile?.face_image)
    image = imageURL + props?.myProfile?.face_image;
  // else if (localStorage.face_image)
  //     image = imageURL + localStorage.face_image;
  else if (localStorage.gender === "Female")
    image = imageURL + "avatar_Female.png";
  else image = imageURL + "avatar_Male.png";

  return (
    <Card className="d-flex align-items-center profile-card mx-0">
      <Image
        className="myprofile-image"
        roundedCircle
        width={props?.dashboard ? "149px" : "80%"}
        src={image}
      />
      <Row className="profileName mx-0">
        <Col className="p-0 d-flex align-items-center">
          {props?.myProfile?.aecid}
          <MdVerified
            size="26px"
            className="mx-1"
            color={
              props?.myProfile?.verified_badge == "Y"
                ? "var(--secondary-color)"
                : "#C4C4C4"
            }
          />
        </Col>
      </Row>
      <Card.Body className="d-flex flex-column align-items-center w-100">
        <Row
          className="align-items-center age-and-place justify-content-center"
          style={{ width: "100%", textAlign: "center" }}
        >
          {props?.myProfile?.age} years old | {props?.myProfile?.placename}
        </Row>
        <Row className="w-100 justify-content-center">
          <ProgressBar
            now={props?.myProfile?.registration_completion}
            variant="success"
          ></ProgressBar>
        </Row>
        <p>Profile completion {props?.myProfile?.registration_completion}%</p>
        {props?.myProfile?.registration_completion < 50 ? (
          <p align="center" className="profile-card-para p-0">
            Complete your profile and get maximum interested profiles
          </p>
        ) : (
          <></>
        )}
        <Row className="profile-card-plan w-100 justify-content-between">
          <div className="w-auto">
            <Link
              to="/plan-history"
              className="text-decoration-none w-auto profile-card-plan"
            >
              Plan - {localStorage.planName}
            </Link>
          </div>
          <div
            className="w-auto "
            onClick={() => setShowAddStoryModal(true)}
            style={{ cursor: "pointer" }}
          >
            Add Success Story
          </div>
        </Row>
        <Modal
          show={showAddStoryModal}
          // onHide={() => {
          //   // setWeddingPhoto("");
          //   // setBody("");
          //   setShowAddStoryModal(false);
          // }}
        >
          <CloseIcon
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              setWeddingPhoto("");
              setBody("");
              setShowAddStoryModal(false);
            }}
          ></CloseIcon>
          <Modal.Title>Add Your Success Story</Modal.Title>
          <Modal.Body className="d-flex flex-column align-items-center">
            {weddingPhoto ? (
              <div
                style={{ width: "100%", height: "316px" }}
                className="position-relative"
              >
                <Image
                  src={weddingImageToDisplay}
                  height="316px"
                  width="100%"
                  className="wedding-image-to-display"
                  style={{ borderRadius: "10px" }}
                />
                <div
                  className="position-absolute delete-photo-icon p-0 cursor-pointer"
                  onClick={deletePhoto}
                >
                  <IoCloseSharp color="var(--primary-color)" size={24} />
                </div>
              </div>
            ) : (
              <div
                className="image-slot w-100"
                style={{ borderRadius: "10px" }}
              >
                <label
                  role="button"
                  className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={(e) => uploadWeddingPhoto(e)}
                  />
                  <MdOutlineAddPhotoAlternate
                    color="var(--primary-color)"
                    size={50}
                  />
                  <div>Add your wedding album</div>
                </label>
              </div>
            )}
            <input
              type="textarea"
              className="form-control w-100 mt-3"
              style={{ height: "246px" }}
              placeholder="Note"
              onChange={(e) => setBody(e.target.value)}
            />
            <Button
              className="w-100 mt-3"
              style={{
                backgroundColor: "#FFF",
                borderColor: "var(--primary-color)",
                color: "var(--primary-color)",
              }}
              onClick={addStory}
            >
              Submit
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          show={showLogoutModal}
          // onHide={() => setShowLogoutModal(false)}
          className="logout-modal"
        >
          <Modal.Title className="d-flex flex-column align-items-center">
            Logout
          </Modal.Title>
          <Modal.Body className="d-flex flex-column align-items-center">
            Are you sure to logout?
            <Row
              className="w-100 mt-3"
              style={{ justifyContent: "space-evenly" }}
            >
              <Button
                className="w-auto"
                style={{
                  borderColor: "var(--primary-color)",
                  backgroundColor: "#FFF",
                  color: "var(--primary-color)",
                }}
                onClick={() => setShowLogoutModal(false)}
              >
                Cancel
              </Button>
              <Button
                className="w-auto"
                style={{
                  border: "none",
                  backgroundColor: "var(--primary-color)",
                  color: "#FFF",
                }}
                onClick={logout}
              >
                Logout
              </Button>
            </Row>
          </Modal.Body>
        </Modal>
        <Row className="profile-card-plan w-100 justify-content-between  mt-2">
          <Link
            to="/profile-settings"
            className="text-decoration-none w-auto profile-card-plan"
          >
            Profile Settings
          </Link>
        </Row>

        <Button
          className="width size text-decoration-none d-flex justify-content-center align-items-center mt-3"
          onClick={() => setShowLogoutModal(true)}
          style={{
            fontSize: "16px",
            background: "var(--primary-color)",
            borderRadius: "10px",
            border: "none",
            color: "white",
            height: "36px",
            width: "136px",
          }}
        >
          Logout
        </Button>
      </Card.Body>
    </Card>
  );
}
