import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { BsX, BsSearch } from "react-icons/bs";
import { Loader } from "..";
import { environment } from "../../environment/env";
import UpgradeModal from "../Modal/UpgradeModal";
import "./Chats.css";
import { MdCircle } from "react-icons/md";

const baseURL = environment.hostname;
const imageURL = environment.imageURL;
let chatListTemp,
  searchValue = "";

export default function Chats(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [chatList, setChatList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedChat, setSelectedChat] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("activeLink", "chat");
    if (props?.title === "message") {
      fetch(`${baseURL}/mymessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: localStorage.currentUser,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          chatListTemp = response.filter((res) => res.last_message != "");
          setChatList(chatListTemp);
          setIsLoading(false);
        });
    } else if (props?.title === "video") {
      fetch(`${baseURL}/schedule_list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: localStorage.currentUser,
          session_id: localStorage.session,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 1) {
            chatListTemp = response.data;
            setChatList(chatListTemp);
            setIsLoading(false);
          }
        });
    }
    updateLogin();
  }, [isLoading, props?.title]);

  let sendChatId = (event, index) => {
    if (localStorage.plan != "2") {
      setSelectedChat(chatList[index].id);
      if (props?.title === "message") {
        fetch(`${baseURL}/mymessage`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            chatListTemp = response.filter((res) => res.last_message != "");
            setChatList(chatListTemp);
          });
      }
      props.chatId(
        chatList[index].id,
        chatList[index].face_image,
        chatList[index].album_visibility
      );
    } else {
      setShowUpgradeModal(true);
    }
    event.preventDefault();
  };

  let redirect = (e, tab) => {
    if (tab === "chats" && location?.pathname != "/messages") {
      navigate("/messages");
    } else if (tab === "video" && location?.pathname != "/video-schedule") {
      navigate("/video-schedule");
    }
    e.preventDefault();
  };

  let search = (e) => {
    searchValue = e.target.value;
    if (searchValue === "") {
      setChatList(chatListTemp);
    } else {
      setChatList(
        chatListTemp.filter((chat) =>
          chat.id.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  };

  let openConfirmModal = (e, id) => {
    e.stopPropagation();
    setShowConfirmModal(true);
    setDeleteId(id);
  };

  let closeConfirmModal = (e) => {
    e?.stopPropagation();
    setShowConfirmModal(false);
    setDeleteId("");
  };

  let deleteChat = (e) => {
    e.stopPropagation();
    fetch(`${baseURL}/del_chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        myid: localStorage.currentUser,
        id: deleteId,
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    }).then((res) => {
      setShowConfirmModal(false);
      setDeleteId("");
      if (selectedChat === deleteId) props.chatId("", "");
      fetch(`${baseURL}/mymessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: localStorage.currentUser,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          chatListTemp = response.filter((res) => res.last_message != "");
          setChatList(chatListTemp);
        });
    });
  };

  let planCheckMessage = (msg) => {
    if (localStorage.plan === "2") {
      return "";
    } else {
      if (/.jpg|.png|.jpeg/.test(msg)) {
        return "Image";
      }
      return msg;
    }
  };

  let updateLogin = () => {
    fetch(`${baseURL}/loginupdate`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
      headers: {
        "Content-type": "application/json",
      },
    }).then((response) => {});
  };

  function checkPlanAndImage(imageP, album_visibility, gender) {
    var image = imageURL + imageP;
    if (
      (album_visibility == "55" && localStorage.plan === "2") ||
      album_visibility == "56" ||
      imageP == null
    ) {
      if (gender === "Male" || localStorage.gender === "Female") {
        image = imageURL + "avatar_Male.png";
      } else {
        image = imageURL + "avatar_Female.png";
      }
    }
    return image;
  }

  return (
    <Card className="chats-card">
      <Row className="chat-tabs m-0">
        <Col
          className="d-flex justify-content-center pt-2 pb-2 border-right"
          style={{
            cursor: "pointer",
            backgroundColor:
              props.title === "message" ? "var(--primary-color)" : "",
            color: props.title === "message" ? "#fff" : "",
            border: "solid 1px var(--primary-color)",
          }}
          onClick={(e) => redirect(e, "chats")}
        >
          Chats
        </Col>
        <Col
          className="d-flex justify-content-center pt-2 pb-2 border-left"
          style={{
            cursor: "pointer",
            backgroundColor:
              props.title === "video" ? "var(--primary-color)" : "",
            color: props.title === "video" ? "#fff" : "",
            border: "solid 1px var(--primary-color)",
          }}
          onClick={(e) => redirect(e, "video")}
        >
          Video
        </Col>
      </Row>
      <Row className="m-0 mt-3 form-control">
        <span className="w-auto">
          {" "}
          <BsSearch color="#C4C4C4" />{" "}
        </span>
        <input
          type="text"
          className="w-75"
          style={{ border: "none", color: "#C4C4C4" }}
          value={searchValue}
          onChange={search}
          placeholder={
            props.title === "message"
              ? "Search for Chat"
              : "Search in Video meets"
          }
        />
      </Row>
      {!isLoading ? (
        chatList?.length ? (
          chatList.map((profile, index) => (
            <Row
              className="m-0 mt-3"
              onClick={(event) => sendChatId(event, index)}
              key={index}
            >
              <Card
                className={`chat-card m-0 ${
                  profile?.id === selectedChat ? "selected-chat" : ""
                }`}
              >
                <Row>
                  <Col
                    md={3}
                    xs={3}
                    className="col-lg-auto position-relative"
                    onClick={() => {
                      if (profile?.id === selectedChat) {
                        localStorage.setItem("visitingUser", profile?.id);
                        navigate("/profile");
                      }
                    }}
                  >
                    <Image
                      roundedCircle
                      src={checkPlanAndImage(
                        profile.face_image,
                        profile?.album_visibility,
                        null
                      )}
                      width="46px"
                      height="46px"
                    />
                    <MdCircle
                      style={{
                        position: "absolute",
                        right: "8px",
                        bottom: "0px",
                      }}
                      size="20px"
                      color={
                        new Date(
                          profile?.lastlogin_new + " UTC"
                        ).toDateString() != new Date().toDateString()
                          ? "#C4C4C4"
                          : new Date(
                              profile?.lastlogin_new + " UTC"
                            ).getHours() != new Date().getHours()
                          ? "#C4C4C4"
                          : new Date().getMinutes() - 5 <=
                              new Date(
                                profile?.lastlogin_new + " UTC"
                              ).getMinutes() &&
                            new Date(
                              profile?.lastlogin_new + " UTC"
                            ).getMinutes() <= new Date().getMinutes()
                          ? "#00BE63"
                          : "#C4C4C4"
                      }
                    />
                  </Col>
                  <Col md={8} xs={7}>
                    <Row className="d-flex justify-content-between">
                      <Row
                        className="w-auto chat-card-txt-left"
                        style={{
                          color:
                            profile?.id === selectedChat
                              ? "#FFF"
                              : "var(--primary-color)",
                        }}
                      >
                        {profile?.id}
                      </Row>
                      {props.title === "message" ? (
                        <div
                          className="w-auto"
                          style={{
                            fontSize: "13px",
                            color:
                              profile?.id === selectedChat
                                ? "#FFF"
                                : "var(--primary-color)",
                          }}
                        >
                          {new Date(
                            profile.last_message_date_new +
                              " UTC".replace(/-/g, "/")
                          ).toDateString() === new Date().toDateString()
                            ? new Date(
                                profile.last_message_date_new +
                                  " UTC".replace(/-/g, "/")
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                            : new Date(
                                profile.last_message_date_new +
                                  " UTC".replace(/-/g, "/")
                              ).toDateString()}
                        </div>
                      ) : (
                        ""
                      )}
                    </Row>
                    <Row className="d-flex justify-content-between">
                      <Row
                        className="message-preview chat-card-txt-left"
                        style={{
                          color: profile?.id === selectedChat ? "#C4C4C4" : "",
                        }}
                      >
                        {profile.last_message
                          ? planCheckMessage(profile.last_message)
                          : profile?.last_scheduled
                          ? ` ${new Date(
                              profile?.last_scheduled
                            ).toDateString()}`
                          : ""}
                      </Row>
                    </Row>
                  </Col>
                  <Col md={1} xs={1} style={{ alignSelf: "center" }}>
                    {(profile.pending_acpt_count &&
                      profile.pending_acpt_count != 0) ||
                    (profile.views &&
                      profile.views != 0 &&
                      profile.views != "" &&
                      profile.views != "0") ? (
                      <div className="pending-count pl-2 d-flex align-items-center  justify-content-center w-auto">
                        {selectedChat === profile?.id
                          ? ""
                          : profile.pending_acpt_count || profile.views}
                      </div>
                    ) : (
                      <div
                        className="w-auto d-flex flex-column justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={
                          props.title === "message"
                            ? (e) => openConfirmModal(e, profile?.id)
                            : ""
                        }
                      >
                        {props.title === "message" ? (
                          <BsX
                            className="w-auto"
                            color="var(--primary-color)"
                            size="22px"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </Card>
              <Modal
                show={showConfirmModal}
                onHide={(e) => closeConfirmModal(e)}
                className="d-flex flex-column align-items-center"
              >
                <Modal.Title className="title d-flex justify-content-center">
                  Delete Chat?
                </Modal.Title>
                <Modal.Body>
                  Do you want to delete {deleteId}?
                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      className="w-auto"
                      style={{
                        borderColor: "var(--primary-color)",
                        backgroundColor: "#FFF",
                        color: "var(--primary-color)",
                      }}
                      onClick={closeConfirmModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="w-auto"
                      style={{
                        borderColor: "var(--primary-color)",
                        backgroundColor: "var(--primary-color)",
                        color: "#FFF",
                      }}
                      onClick={deleteChat}
                    >
                      Delete
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </Row>
          ))
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            No chats to display
          </div>
        )
      ) : (
        <Loader></Loader>
      )}

      <UpgradeModal
        upgradeNow={showUpgradeModal}
        closeModal={() => setShowUpgradeModal(false)}
      ></UpgradeModal>
    </Card>
  );
}
