import React from "react";
import { toast } from "react-toastify";
import "../../Components/Profile/Profile.css";
import { environment } from "../../environment/env";

function IgnoredProfiles({ profiles }) {
  let showProfile = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    localStorage.setItem("visitingUser", id);
    navigate("/profile");
  };

  let baseURL = environment.hostname;

  // let removeIgnoredUsers = (e,Id)=>{
  //     e.stopPropagation()
  //     fetch(`${baseURL}/remove_ignored_profile`, {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //             user_id: localStorage.getItem("currentUser") ,
  //             to_id: Id,

  //         }),
  //     }).then(res => res.json()).then(response => {
  //             console.log(Id,"iddddd");
  //             console.log(response,"response in remove ignore profile");
  //             toast.success('Ignored Profile removed successfully', { position: toast.POSITION.TOP_CENTER })
  //     })
  //     .catch((err)=>{
  //         console.log(err,"err in remove profile api");
  //     })
  // }
  return (
    <Card className="profile-card-component"></Card>
    // <Card className='profile-card-component' >
    //             <Row className='mx-0'>
    //                 <Col className='ps-0 position-relative'  onClick={(e) => showProfile(e, props?.profile?.id)}>
    //                     <Image src={image} className='profile-card-component-image' style={{ borderRadius: '15px' }} />
    //                     {props?.profile?.album_visibility === '56' ?
    //                         <ProtectedImage id={props?.profile?.id} requestButton={false}></ProtectedImage>
    //                         : !props?.profile?.face_image ? <></> : ''}
    //                 </Col>
    //                 <Col className="p-0 mx-4 col-4 d-flex justify-content-between flex-column"  onClick={(e) => showProfile(e, props?.profile?.id)}>
    //                     <Row className='name'>
    //                         <Col className="p-0 d-flex align-items-center">
    //                             {props?.profile?.id}
    //                             <MdVerified size="30px" className="mx-2" color={props?.profile?.verified_status === '1' ? 'var(--secondary-color)' : "#C4C4C4"} />
    //                             <MdCircle size="18px" className="mx-1" color={new Date(props?.profile?.lastlogin).toDateString() != new Date().toDateString() ? '#C4C4C4' :
    //                                 new Date(props?.profile?.lastlogin).getHours() != new Date().getHours() ? '#C4C4C4' :
    //                                 new Date().getMinutes() - 5 <= new Date(props?.profile?.lastlogin).getMinutes() && new Date(props?.profile?.lastlogin).getMinutes() <= new Date().getMinutes() ? '#00BE63' : '#C4C4C4'} />
    //                         </Col>
    //                     </Row>
    //                     <Row className='non-card-component-details'>{props?.profile?.occu}&nbsp;-&nbsp;{props?.profile?.qual}</Row>
    //                     <Row className='non-card-component-details'>{props?.profile?.age ? `${props?.profile?.age} yrs` : ''}&nbsp; &nbsp;{props?.profile?.height ? `${props.profile?.height} CM` : ''}</Row>
    //                     <Row className='non-card-component-details'>{props?.profile?.marital_web}</Row>
    //                     <Row className='non-card-component-details'>{props?.profile?.place}</Row>

    //                 </Col>

    //                 <Col>
    //                 <p onClick={(e)=>removeIgnoredUsers(e, props?.profile?.id)}> remove</p>
    //                 </Col>

    //             </Row>
    //         </Card>
  );
}

export default IgnoredProfiles;
