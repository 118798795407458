import React, { useEffect } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { miniLogo, miniLogoMob } from "../../assets";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { MdMenu } from "react-icons/md";
import "./Register.css";
import { Footer, RegisterCard } from "../../Components";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Register(props) {
  let location = useLocation();
  const query = useQuery();
  const ref = query.get("ref");
  const code = query.get("code");
  let navigate = useNavigate();
  let [isRegister, setIsRegister] = React.useState(false);
  useEffect(() => {
    if (localStorage.currentUser && localStorage.session) {
      if (
        localStorage.getItem("register_complete_status") == null ||
        localStorage.getItem("register_complete_status") == ""
      ) {
        navigate("/matching");
      }
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [navigate]);

  let startRegister = () => {
    setIsRegister(true);
  };

  return (
    <div>
      <Container fluid className="register-page">
        <Row className="navbarmenu">
          <Navbar
            expand="lg"
            className="navbar align-items-start justify-content-between m-0 p-0 mt-4 px-md-4 px-sm-3"
            style={{ flexShrink: "unset", margin: "0 166px 0 0" }}
          >
            <div className="d-inline-flex">
              <NavLink to="/" className="brand mt-1">
                <img
                  alt=""
                  src={window.innerWidth > 500 ? miniLogo : miniLogoMob}
                  width="269"
                  height="71"
                  className="d-inline-block align-top minilogo"
                />{" "}
              </NavLink>
            </div>
            <Nav className="d-flex justify-content-end  row flex-row  mt-3">
              {isRegister == false ? (
                <Link
                  to="/login"
                  className="login-button-home d-flex justify-content-center align-items-center order-lg-5 col-auto"
                >
                  LOGIN
                </Link>
              ) : (
                <div></div>
                // <div className="login-button-home d-flex justify-content-center align-items-center order-lg-5 col-auto">
                //   LOGIN
                // </div>
              )}
              <Navbar.Toggle className="col-auto border-0">
                <MdMenu color="#FFF" size="28px" />{" "}
              </Navbar.Toggle>
              <Navbar.Collapse className="home-toggle-menu justify-content-end col-auto align-items-center">
                <div className="d-flex mt-2 mt-md-3 mt-lg-0 justify-content-end">
                  {isRegister == false ? (
                    <Link
                      to={
                        ref
                          ? `/?ref=${ref}`
                          : "/" || code
                          ? `/?code=${code}`
                          : "/"
                      }
                      className="text-decoration-none px-2 px-lg-3 order-lg-1"
                      style={{
                        color: "#fff",
                        paddingBottom: "0",
                        cursor: "pointer",
                      }}
                    >
                      HOME
                    </Link>
                  ) : (
                    <div
                      className="text-decoration-none px-2 px-lg-3 order-lg-1"
                      style={{ color: "#fff", paddingBottom: "0" }}
                    >
                      {/* HOME */}
                    </div>
                  )}
                  {isRegister == false ? (
                    <Link
                      to={
                        ref
                          ? `/register?ref=${ref}`
                          : "/register" || code
                          ? `/register?code=${code}`
                          : "/register"
                      }
                      className="text-decoration-none px-2 px-lg-3 order-lg-3"
                      style={{
                        fontWeight: "700",
                        color: "#fff",
                        paddingBottom: "0",
                        cursor: "pointer",
                      }}
                    >
                      REGISTER
                    </Link>
                  ) : (
                    <div
                      className="text-decoration-none px-2 px-lg-3 order-lg-3"
                      style={{
                        fontWeight: "700",
                        color: "#fff",
                        paddingBottom: "0",
                      }}
                    >
                      {/* REGISTER */}
                    </div>
                  )}
                  {isRegister == false ? (
                    <Link
                      to="/contact"
                      className="text-decoration-none px-2 px-lg-3 order-lg-4"
                      style={{
                        color: "#fff",
                        paddingBottom: "0",
                        cursor: "pointer",
                      }}
                    >
                      CONTACT
                    </Link>
                  ) : (
                    <div
                      className="text-decoration-none px-2 px-lg-3 order-lg-4"
                      style={{ color: "#fff", paddingBottom: "0" }}
                    >
                      {/* CONTACT */}
                    </div>
                  )}
                </div>
              </Navbar.Collapse>
            </Nav>
            <Outlet />
          </Navbar>
        </Row>
        <RegisterCard
          values={location?.state?.values}
          startRegister={startRegister}
          refer={code}
          referral={ref}
        ></RegisterCard>
      </Container>
      <Footer></Footer>
    </div>
  );
}
