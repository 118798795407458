import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { MdMenu } from "react-icons/md";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { Field, Formik, useFormik } from "formik";
import { Footer } from "../../Components";
import { banner2, miniLogo, miniLogoMob } from "../../assets";
import "./Login.css";
import { environment } from "../../environment/env";
import { toast } from "react-toastify";

let baseURL = environment.hostname;

const validate = (values) => {
  const errors = {};
  if (!values.phone) {
    errors.phone = "Please enter Mobile Number or AEC ID";
  }
  if (!values.password) {
    errors.password = "Please enter Password";
  }
  return errors;
};

export default function Login() {
  const [title, setTitle] = useState("Login");
  const [otp, setOtp] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [prefixList, setPrefixList] = useState([]);
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [userName, setUserName] = useState("");

  useEffect((e) => {
    if (localStorage.currentUser && localStorage.session)
      return navigate("/matching");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetch(`${baseURL}/prefixlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((res) => {
        setPrefixList(res);
      });
  }, []);

  let register = () => {
    navigate("/register");
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
      country_code: "+91",
      ip: "1.1.1.1",
    },
    validate,
    onSubmit: async (values) => {
      if (title === "Login") {
        await fetch(`${baseURL}/loginchecknew`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response[0].status === 1) {
              //verify OTP
              localStorage.setItem("currentUser", response[0]?.id);
              localStorage.setItem("gender", response[0]?.gender);
              localStorage.setItem("face_image", response[0]?.face_image);
              localStorage.setItem("code", response[0]?.refer_code);

              localStorage.setItem("user_name", response[0]?.name);
              localStorage.setItem("time_diff", response[0]?.timezone);

              fetch(`${baseURL}/login_with_otp`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  phone: formik.values.phone,
                  countrycode: formik.values.country_code,
                }),
              })
                .then((res) => res.json())
                .then((res) => {
                  if (res.data.id) {
                    localStorage.setItem("currentUser", res.data.id);
                    setTitle("OTP verification");
                  }
                  <Outlet></Outlet>;
                });
            } else if (response[0].status === 2) {
              //Login
              localStorage.setItem("currentUser", response[0]?.id);
              localStorage.setItem("session", response[0]?.session_id);
              localStorage.setItem("gender", response[0]?.gender);
              localStorage.setItem("code", response[0]?.refer_code);
              localStorage.setItem("user_name", response[0]?.name);
              localStorage.setItem("plan", response[0]?.pid);
              localStorage.setItem("time_diff", response[0]?.timezone);
              if (response[0].face_image)
                localStorage.setItem("face_image", response[0]?.face_image);
              if (
                response[0]?.reg_completion_status == "completed" ||
                response[0]?.reg_completion_status == ""
              ) {
                navigate("/matching");
              } else {
                localStorage.setItem(
                  "register_complete_status",
                  response[0]?.reg_completion_status
                );
                navigate("/register");
              }
            } else if (response[0].status === 3) {
              toast.error("invalid username/password", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (response[0].status === 4) {
              toast.error("Profile deleted by Click4Marry", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (response[0].status === 5) {
              setShowModal(true);
              setMessageType("deleted");
              setUserName(response[0].name);

              // toast.error(`Dear ${response[0].name}, your profile has been deleted from Click4Marry.)
              //   Kindly contact at +91 9645005674 to reactivate your profile or mail to help@click4marry.com`)
            } else if (response[0].status === 6) {
              setShowModal(true);
              setMessageType("disabled");
              setUserName(response[0].name);

              // toast.error(`Dear ${response[0].name}, your profile has been disabled from Click4Marry.)
              //   Kindly contact at +91 9645005674 to reactivate your profile or mail to help@click4marry.com`)
            }
          });
      }
    },
  });

  let OTPLogin = (forValue) => {
    if (forValue === "password") {
      setResetPassword(true);
      setTitle("Forgot Password");
    } else if (forValue === "OTP") {
      setResetPassword(false);
      setTitle("Login with OTP");
    }
  };

  let getOTP = (values) => {
    fetch(`${baseURL}/login_with_otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: values.phone,
        countrycode: formik.values.country_code,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 1) {
          if (res.data.id) {
            localStorage.setItem("currentUser", res.data.id);
            if (res.data.phone) formik.values.phone = res.data.phone.toString();
            setTitle("OTP verification");
          }
        } else {
          toast.error(res.message, { position: toast.POSITION.TOP_CENTER });
        }
      });
  };

  let changePassword = (values, { isSubmitting, resetForm }) => {
    fetch(`${baseURL}/resetpass`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        npass: values.newPassword,
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === 1) {
          toast.success("Password resetted successfully!");
          navigate("/matching");
        } else {
          toast.error("Couldn't reset password");
          isSubmitting = false;
          resetForm({});
        }
      });
  };

  let resend = () => {
    fetch(`${baseURL}/otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setOtp("");
      });
  };

  let verify = () => {
    fetch(`${baseURL}/register_otp_verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        otp: otp,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 1) {
          localStorage.setItem("session", response?.data[0]?.session_id);
          localStorage.setItem("gender", response?.data[0]?.gender);
          localStorage.setItem("face_image", response.data[0]?.face_image);
          localStorage.setItem("code", response?.data[0]?.refer_code);
          localStorage.setItem("user_name", response?.data[0]?.name);
          localStorage.setItem("time_diff", response?.data[0]?.timezone);
          if (resetPassword) setTitle("Reset Password");
          else navigate("/matching");
        } else if (response.status === 0) {
          toast.error("Incorrect OTP");
        } else {
          toast.error("An error occured.Please try again");
        }
      });
  };

  let renderButton = (buttonProps) => {
    return (
      <button
        {...buttonProps}
        style={{
          color: "var(--primary-color)",
          border: "none",
          textDecoration: "underline",
          backgroundColor: "#FFF",
        }}
      >
        {buttonProps.remainingTime != 0
          ? `Please wait for ${buttonProps.remainingTime} sec`
          : "Resend"}
      </button>
    );
  };
  let renderTime = () => React.Fragment;

  let changeOtpValue = (e) => {
    if (e != "") {
      var regex = /[0-9]$/;
      if (regex.test(e)) {
        setOtp(e);
      }
    } else {
      setOtp(e);
    }
  };

  return (
    <div id="login-page">
      <Container fluid className="login-page">
        <Row className="navbarmenu">
          <Navbar
            expand="lg"
            className="navbar align-items-start justify-content-between m-0 p-0 mt-4 px-md-4 px-sm-3"
            style={{ flexShrink: "unset", margin: "0 166px 0 0" }}
          >
            <div className="d-inline-flex">
              <NavLink to="/" className="brand mt-1">
                <img
                  alt=""
                  src={window.innerWidth > 500 ? miniLogo : miniLogoMob}
                  width="269"
                  height="71"
                  className="d-inline-block align-top minilogo"
                />{" "}
              </NavLink>
            </div>
            <Nav className="d-flex justify-content-end  row flex-row mt-3">
              <Link
                to="/login"
                className="login-button-home d-flex justify-content-center align-items-center order-lg-5 col-auto"
              >
                LOGIN
              </Link>
              <Navbar.Toggle className="col-auto border-0">
                <MdMenu color="#FFF" size="28px" />{" "}
              </Navbar.Toggle>
              <Navbar.Collapse className="home-toggle-menu justify-content-end col-auto align-items-center">
                <div className="d-flex mt-2 mt-md-3 mt-lg-0 justify-content-end">
                  <Link
                    to="/"
                    className="text-decoration-none px-2 px-lg-3 order-lg-1"
                    style={{ color: "#fff", paddingBottom: "0" }}
                  >
                    HOME
                  </Link>
                  <Link
                    to="/register"
                    className="text-decoration-none px-2 px-lg-3 order-lg-3"
                    style={{ color: "#fff", paddingBottom: "0" }}
                  >
                    REGISTER
                  </Link>
                  <Link
                    to="/contact"
                    className="text-decoration-none px-2 px-lg-3 order-lg-4"
                    style={{ color: "#fff", paddingBottom: "0" }}
                  >
                    CONTACT
                  </Link>
                </div>
              </Navbar.Collapse>
            </Nav>
            <Outlet />
          </Navbar>
        </Row>
        <Row>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            className="logout-modal"
          >
            {/* <Modal.Title className='d-flex flex-column align-items-center'>Logout</Modal.Title> */}

            <Modal.Body className="d-flex flex-column align-items-center">
              <p>
                Dear {userName}, your profile has been {messageType} from
                Click4Marry. Kindly contact at{" "}
                <span
                  style={{ color: "#1a0dab", cursor: "pointer" }}
                  onClick={() => {
                    window.open(`tel: +919645004140 `);
                  }}
                >
                  +919645004140
                </span>{" "}
                to reactivate your profile or mail to{" "}
                <span
                  style={{ color: "#1a0dab", cursor: "pointer" }}
                  onClick={() => {
                    window.open(`mailto: help@click4marry.com `);
                  }}
                >
                  {" "}
                  help@click4marry.com
                </span>
              </p>
              <Row className="justify-content-between w-100 mt-3">
                <Button
                  className="w-auto"
                  style={{
                    borderColor: "var(--primary-color)",
                    backgroundColor: "#FFF",
                    color: "var(--primary-color)",
                  }}
                  onClick={() => setShowModal(false)}
                >
                  Okay
                </Button>
                {/* <Button className='w-auto' style={{ border: 'none', backgroundColor: 'var(--primary-color)', color: '#FFF' }} onClick={logout}>Logout</Button> */}
              </Row>
            </Modal.Body>
          </Modal>

          <Col
            md={6}
            sm={12}
            className="p-0 position-relative"
            style={{ display: window.innerWidth < 1000 ? "none" : "" }}
          >
            <Image src={banner2} className="login-banner" />
            <div className="banner-text">
              <h1 className="banner-head">
                Bond of <br />
                safety in Love
              </h1>
              <p className="banner-para">
                The best place to meet with <br /> your future partner
              </p>
              <h6 className="banner-login">{title}</h6>
            </div>
          </Col>
          <Col className="p-0">
            <Card className="p-0 m-0 login-card">
              {
                //Login screen
                title === "Login" ? (
                  <Card.Body className="login-body" id="login-section">
                    <Card.Title className="login-title">
                      Let's get started
                    </Card.Title>
                    <Form onSubmit={formik.handleSubmit}>
                      <Form.Group style={{ marginBottom: "19px" }}>
                        <Form.Label className="label" htmlFor="phone">
                          Mobile Number or AEC ID
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Select
                            className="px-3 form-control form-control-color w-auto country_style"
                            name="country_code"
                            as="select"
                            onChange={formik.handleChange}
                            value={formik.values.country_code}
                            style={{ minWidth: "90px", height: "49px" }}
                            disabled={isNaN(formik.values.phone)}
                          >
                            {prefixList?.map((prefix, i) => (
                              <option
                                key={i}
                                value={prefix.id}
                                selected={
                                  formik.values.country_code == prefix.id
                                }
                              >
                                {prefix.id}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            className="field"
                            name="phone"
                            type="text"
                            maxLength={10}
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                          />
                        </div>
                        {formik.errors.phone && formik.touched.phone ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "5px",
                            }}
                          >
                            {formik.errors.phone}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group style={{ marginBottom: "36px" }}>
                        <Form.Label className="label" htmlFor="password">
                          Password
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            className="field"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                          />
                          {showPassword ? (
                            <BsEyeSlash
                              color="#5B6770"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "15px",
                              }}
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <BsEye
                              color="#5B6770"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "15px",
                              }}
                              onClick={() => setShowPassword(true)}
                            />
                          )}
                        </div>
                        {formik.errors.password && formik.touched.password ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "5px",
                            }}
                          >
                            {formik.errors.password}
                          </div>
                        ) : null}
                        <div className="justify-content-end d-flex">
                          <Form.Text
                            className="w-auto otp-button"
                            onClick={() => OTPLogin("password")}
                          >
                            Forgot Password?
                          </Form.Text>
                        </div>
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Text
                          className="otp-button"
                          style={{ marginLeft: "5%" }}
                          onClick={() => OTPLogin("OTP")}
                        >
                          Login with OTP
                        </Form.Text>
                        <Button
                          type="submit"
                          className="login-button d-flex justify-content-center align-items-center"
                        >
                          Login
                        </Button>
                      </div>
                      <Form.Text className="d-flex justify-content-center create-account">
                        Don’t have an account?
                        <span
                          onClick={register}
                          style={{
                            color: "var(--primary-color)",
                            cursor: "pointer",
                          }}
                        >
                          &nbsp; Register Free
                        </span>
                      </Form.Text>
                    </Form>
                  </Card.Body>
                ) : // OTP verfication screen
                title === "OTP verification" ? (
                  <Card.Body className="login-body d-flex justify-content-center flex-column px-0 align-items-center">
                    <Card.Title className="login-title">{title}</Card.Title>
                    <Card.Text style={{ color: "#000" }}>
                      We will send you a one time password to this mobile number
                    </Card.Text>
                    <Card.Text style={{ color: "#000" }}>
                      Enter the OTP send to ********
                      {formik.values.phone.slice(8)}
                    </Card.Text>

                    <Form.Control
                      className="mt-1"
                      style={{
                        width: "30%",
                        minWidth: "130px",
                        textAlign: "center",
                      }}
                      onInput={(e) => {
                        changeOtpValue(e.target.value);
                      }}
                      placeholder="Enter OTP"
                      type="text"
                      value={otp}
                      maxlength="6"
                    />

                    {/* <OTPInput
                                                
                                                value={otp}
                                                onChange={((event) => {
                                                    setOtp(event)
                                                })}
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                                inputClassName='otp'
                                            /> */}
                    <div
                      className="d-flex"
                      style={{ color: "#5B6770", marginTop: "30px" }}
                    >
                      Didn't you recieve the OTP?
                      <ResendOTP
                        onResendClick={resend}
                        renderButton={renderButton}
                        renderTime={renderTime}
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: "20px",
                        marginTop: "32px",
                        fontWeight: "600",
                      }}
                      className="w-100 form-text d-flex justify-content-around align-items-center"
                    >
                      <span
                        style={{ cursor: "pointer", marginLeft: "5%" }}
                        onClick={() => {
                          setOtp("");
                          setTitle(
                            resetPassword ? "Forgot Password" : "Login with OTP"
                          );
                        }}
                      >
                        Back
                      </span>
                      <Button
                        onClick={verify}
                        className="login-otp-verify-button"
                      >
                        Verify
                      </Button>
                    </div>
                  </Card.Body>
                ) : //Login with OTP screen
                title === "Login with OTP" || title === "Forgot Password" ? (
                  <Card.Body className="login-body" id="login-section">
                    <Card.Title className="login-title">{title}</Card.Title>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        phone: "",
                        country_code: "+91",
                        ip: "1.1.1.1",
                      }}
                      onSubmit={getOTP}
                      validate={(values) => {
                        const errors = {};
                        if (!values.phone) {
                          errors.phone = "Enter Phone number or AEC ID";
                        }
                        return errors;
                      }}
                    >
                      {({
                        resetForm,
                        handleSubmit,
                        errors,
                        touched,
                        values,
                        isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit} className="">
                          <div
                            style={{ marginBottom: "18px" }}
                            className="form-group mb-3"
                          >
                            <label className="label form-label">
                              Mobile number or AEC ID
                            </label>
                            <div className="d-flex">
                              <Form.Select
                                className="px-3 form-control form-control-color w-auto country_style"
                                name="country_code"
                                as="select"
                                onChange={formik.handleChange}
                                value={formik.values.country_code}
                                disabled={isNaN(values.phone)}
                                style={{ minWidth: "90px", height: "49px" }}
                              >
                                {prefixList?.map((prefix, i) => (
                                  <option
                                    key={i}
                                    value={prefix.id}
                                    selected={
                                      formik.values.country_code == prefix.id
                                    }
                                  >
                                    {prefix.id}
                                  </option>
                                ))}
                              </Form.Select>
                              <Field
                                className="field form-control"
                                name="phone"
                                maxLength={10}
                              />
                            </div>
                            <div
                              style={{
                                color: "red",
                                fontSize: "14px",
                                marginTop: "5px",
                              }}
                            >
                              {errors.phone && touched.phone
                                ? errors.phone
                                : ""}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-5">
                            <Form.Text
                              className="otp-button"
                              style={{ marginLeft: "5%" }}
                              onClick={() => {
                                setTitle("Login");
                              }}
                            >
                              Back
                            </Form.Text>
                            <Button
                              type="submit"
                              className="login-button d-flex justify-content-center align-items-center"
                            >
                              Send OTP
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                ) : (
                  //Reset password
                  <Card.Body className="login-body d-flex justify-content-center flex-column px-0 align-items-center">
                    <Card.Title className="login-title">{title}</Card.Title>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{ newPassword: "", confirmPassword: "" }}
                      onSubmit={changePassword}
                      validate={(values) => {
                        const errors = {};
                        if (!values.newPassword) {
                          errors.newPassword = "Enter your new password";
                        }
                        if (values.newPassword.length < 6) {
                          errors.newPassword = "Enter minimum 6 characters!";
                        }
                        if (values.newPassword.length > 15) {
                          errors.newPassword = "Enter maximum 15 characters!";
                        }
                        if (!values.confirmPassword) {
                          errors.confirmPassword = "Confirm your new password";
                        }

                        if (values.confirmPassword != values.newPassword) {
                          errors.confirmPassword = "Password does not match";
                        }

                        return errors;
                      }}
                    >
                      {({
                        resetForm,
                        handleSubmit,
                        errors,
                        touched,
                        values,
                        isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit} className="w-75">
                          <div
                            style={{ marginBottom: "18px" }}
                            className="form-group mb-3"
                          >
                            <label className="label form-label">
                              New Password
                            </label>
                            <Field
                              className="field form-control"
                              type="password"
                              name="newPassword"
                            />
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.newPassword && touched.newPassword
                                ? errors.newPassword
                                : ""}
                            </div>
                          </div>
                          <div
                            style={{ marginBottom: "18px" }}
                            className="form-group mb-3"
                          >
                            <label className="label form-label">
                              Confirm Password
                            </label>
                            <Field
                              className="field form-control"
                              type="password"
                              name="confirmPassword"
                            />
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.confirmPassword && touched.confirmPassword
                                ? errors.confirmPassword
                                : ""}
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <Button
                              className="otp-verify-button"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Change Password
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                )
              }
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </div>
  );
}
