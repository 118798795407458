import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { environment } from "../../../environment/env";
import "./NotificationHistory.css";

function NotificationHistory() {
  const baseURL = environment.hostname;
  const imageUrl = environment.notificationImageUrl;
  const [isNotificationHistory, setIsNotificationHistory] = useState([]);

  useEffect(() => {
    fetch(`${baseURL}/get_notification_history`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setIsNotificationHistory(response);
      });
  }, []);

  return (
    <div className="notification_dd">
      <ul className="notification_ul">
        {isNotificationHistory.length ? (
          <>
            {isNotificationHistory.map((item) => (
              <li
                style={{
                  backgroundColor: item.read_status === 0 ? "e5e5e5" : "white",
                }}
              >
                <div className="notify_data">
                  <div className="date">
                    {new Date(item.date.replace(/-/g, "/")).toDateString() ===
                    new Date().toDateString()
                      ? new Date(
                          item.date.replace(/-/g, "/")
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : new Date(item.date.replace(/-/g, "/")).toDateString()}
                  </div>
                  <div className="sub_title">{item.message}</div>
                  {item.image != null ? (
                    <div>
                      <a href={`${imageUrl}${item.image}`} target="_blank">
                        <img
                          src={`${imageUrl}${item.image}`}
                          alt="image"
                          style={{ width: "200px" }}
                        ></img>
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}

                  {item.type.includes("plan") ? (
                    <div style={{ marginTop: "10px" }}>
                      <Link className="button" to="/plans-and-payment">
                        View plans
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            ))}
          </>
        ) : (
          <>
            <div></div>
          </>
        )}
      </ul>
    </div>
  );
}

export default NotificationHistory;
