import React, { useEffect } from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineCall, MdOutlineLocationOn, MdMenu } from "react-icons/md";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { miniLogo, miniLogoMob } from "../../assets";
import { FeedbackComponent, Footer } from "../../Components";
import "./Contact.css";

export default function Contact() {
  let navigate = useNavigate();
  // useEffect((e) => {
  //     if (localStorage.currentUser && localStorage.session) navigate('/matching')
  // }, [navigate])

  let goback = () => {
    navigate("/");
  };

  return (
    <div>
      <Container fluid className="register-page pb-5">
        <Row className="navbarmenu">
          <Navbar
            expand="lg"
            className="navbar align-items-start justify-content-between m-0 p-0 mt-4 px-md-4 px-sm-3"
            style={{ flexShrink: "unset", margin: "0 166px 0 0" }}
          >
            <Col className="d-inline-flex">
              <NavLink to="/" className="brand mt-1">
                <img
                  alt=""
                  src={window.innerWidth > 500 ? miniLogo : miniLogoMob}
                  width="269"
                  height="71"
                  className="d-inline-block align-top minilogo"
                />{" "}
              </NavLink>
            </Col>
            <Nav className="d-flex justify-content-end  row flex-row  mt-3">
              <Link
                to="/login"
                className="login-button-home d-flex justify-content-center align-items-center order-lg-5 col-auto"
              >
                LOGIN
              </Link>
              <Navbar.Toggle className="col-auto border-0">
                <MdMenu color="#FFF" size="28px" />{" "}
              </Navbar.Toggle>
              <Navbar.Collapse className="home-toggle-menu justify-content-end col-auto align-items-center">
                <div className="d-flex mt-2 mt-md-3 mt-lg-0 justify-content-end">
                  <Link
                    to="/"
                    className="text-decoration-none px-2 px-lg-3 order-lg-1"
                    style={{ color: "#fff", paddingBottom: "0" }}
                  >
                    HOME
                  </Link>
                  <Link
                    to="/register"
                    className="text-decoration-none px-2 px-lg-3 order-lg-3"
                    style={{ color: "#fff", paddingBottom: "0" }}
                  >
                    REGISTER
                  </Link>
                  <Link
                    to="/contact"
                    className="text-decoration-none px-2 px-lg-3 order-lg-4"
                    style={{
                      fontWeight: "700",
                      color: "#fff",
                      paddingBottom: "0",
                    }}
                  >
                    CONTACT
                  </Link>
                </div>
              </Navbar.Collapse>
            </Nav>
            <Outlet />
          </Navbar>
        </Row>
        <Row style={{ color: "#000" }} className="position-relative">
          <Card className="contact-card">
            <Card.Title
              style={{
                color: "var(--primary-color)",
                fontSize: "24px",
                marginBottom: "30px",
              }}
            >
              Contact Us
            </Card.Title>
            <div className="d-flex" style={{ marginBottom: "31px" }}>
              <MdOutlineLocationOn
                color="var(--primary-color)"
                size="24px"
                className="mx-2"
              />
              <div className="mx-2">
                PB No. 1708, <br />
                Silver Hills PO, <br />
                Calicut -12
              </div>
            </div>
            <div className="d-flex" style={{ marginBottom: "31px" }}>
              <MdOutlineCall
                color="var(--primary-color)"
                size="22px"
                className="mx-2"
              />
              <div
                className="mx-2"
                onClick={() => {
                  window.open(`tel:+919645004140`);
                }}
              >
                +919645004140
                <br />
                <span
                  onClick={() => {
                    window.open(`tel:0495 2731500`);
                  }}
                >
                  {" "}
                  0495 2731500
                </span>
              </div>
            </div>
            <div
              className="d-flex"
              style={{ marginBottom: "31px" }}
              onClick={() => {
                window.open(`https://wa.me/+919645004140} `);
              }}
            >
              <FaWhatsapp
                color="var(--primary-color)"
                size="22px"
                className="mx-2"
              />
              <div className="mx-2">+919645004140</div>
            </div>
            <div
              className="d-flex"
              style={{ marginBottom: "31px" }}
              onClick={() => window.open(`mailto:help@click4marry.com`)}
            >
              <AiOutlineMail
                color="var(--primary-color)"
                size="20px"
                className="mx-2"
              />
              <div className="mx-2">help@click4marry.com</div>
            </div>
          </Card>
          <FeedbackComponent page="contact" navg={goback}></FeedbackComponent>
        </Row>
      </Container>
      <Footer></Footer>
    </div>
  );
}
