import { environment } from "../environment/env";

const baseURL = environment.hostname;
let saveKeywordSearch = async (values) => {
  return await fetch(`${baseURL}/keyword_search_query`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  });
};

let saveIdSearch = async (values) => {
  return await fetch(`${baseURL}/id_search_query`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  });
};

let saveAdvancedSearch = async (values) => {
  return await fetch(`${baseURL}/advance_search_query`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  });
};

let deleteSavedSearchService = async (values) => {
  return await fetch(`${baseURL}/delete_saved_search_list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  });
};

export {
  deleteSavedSearchService,
  saveIdSearch,
  saveKeywordSearch,
  saveAdvancedSearch,
};
