import { environment } from "../environment/env"

const baseURL = environment.hostname

let removeAlbumRequestService = async (profile, tab) => {
    return await fetch(`${baseURL}/removerequest`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: tab === 'Recieved' ? profile : localStorage.currentUser,
            to: tab === 'Recieved' ? localStorage.currentUser : profile,
            check_user_id: localStorage.currentUser,
            check_session_id: localStorage.session
        }),
    })
}

let removeContentRequestService = async (profile, type, tab) => {
    return await fetch(`${baseURL}/removecontentrequest`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: tab === 'Recieved' ? profile : localStorage.currentUser,
            to: tab === 'Recieved' ? localStorage.currentUser : profile,
            check_user_id: localStorage.currentUser,
            check_session_id: localStorage.session,
            type: type
        }),
    })
}

let acceptRejectAlbumRequestService = async (profile, status) => {
    return await fetch(`${baseURL}/album_status`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            to: localStorage.currentUser,
            from: profile,
            status: status
        }),
    })
}

let acceptRejectContentRequestService = async (profile, status, type) => {
    return await fetch(`${baseURL}/content_status`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            to: localStorage.currentUser,
            from: profile,
            status: status,
            type: type
        }),
    })
}


export {
    removeAlbumRequestService,
    removeContentRequestService,
    acceptRejectAlbumRequestService,
    acceptRejectContentRequestService
}