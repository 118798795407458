import React, { useState } from "react";
import { Button, Card, Col, Form, Image, Modal, Row } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { GiBodyHeight } from "react-icons/gi";
import { VscMortarBoard, VscHeart } from "react-icons/vsc";
import { toast } from "react-toastify";
import { ageIcon } from "../../assets";
import { environment } from "../../environment/env";
import "./ProfileDescriptionCard.css";
import ReactTooltip from "react-tooltip";

const baseURL = environment.hostname;

export default function ProfileDescriptionCard(props) {
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [aboutMe, setAboutMe] = useState(props?.myProfile?.aboutme || "");

  let HandleAbout = (e) => {
    //;
    let value = e.target.value;
    let forbiddenChars = /[><~|]/;
    let regex = /[><~|]/g;
    if (regex.test(value)) {
      value = value.replace(regex, "");
      setAboutMe(value);
    }
  };

  let saveEditedValue = () => {
    fetch(`${baseURL}/update_profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        about_me: aboutMe,
        info_lock: "1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
        type: "profile",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          props.edit();
          setShowAboutModal(false);
        } else
          toast.error("an error occured while editing", {
            position: toast.POSITION.TOP_CENTER,
          });
        setShowAboutModal(false);
      });
  };
  return (
    <Card className="profile-description-card">
      <Card.Title className="profile-description-name mb-1">
        {" "}
        {props?.myProfile?.name}{" "}
      </Card.Title>
      <Card.Body className="p-0 pt-3">
        <Row className="mb-4">
          {props?.myProfile?.course != null &&
          props?.myProfile?.course != "" ? (
            <Col xs={6} md={6} lg={3} className="mb-2">
              <VscMortarBoard color="var(--primary-color)" size="20px" />{" "}
              {props?.myProfile?.course}
            </Col>
          ) : (
            <></>
          )}
          {props?.myProfile?.mstatus != null &&
          props?.myProfile?.mstatus != "" ? (
            <Col xs={6} md={6} lg={4} className="mb-2">
              <VscHeart size="18px" color="var(--primary-color)" />{" "}
              {props?.myProfile?.mstatus}
            </Col>
          ) : (
            <></>
          )}
          {props?.myProfile?.height != null &&
          props?.myProfile?.height != "" ? (
            <Col xs={6} md={6} lg={3} className="mb-2">
              <GiBodyHeight color="var(--primary-color)" size="18px" />{" "}
              {props.myProfile?.height ? `${props.myProfile?.height} CM` : ""}{" "}
            </Col>
          ) : (
            <></>
          )}
          {props?.myProfile?.age != null && props?.myProfile?.age != "" ? (
            <Col xs={6} md={6} lg={2} className="mb-2">
              <Image src={ageIcon} />
              &nbsp;
              {props?.myProfile?.age ? `${props?.myProfile?.age} yrs` : ""}{" "}
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <p className="profile-description-para position-relative">
          {props?.myProfile?.aboutme ? (
            props?.myProfile?.aboutme
          ) : (
            <div style={{ color: "#C4C4C4" }}>No description provided</div>
          )}
          <BiEdit
            color="var(--secondary-color)"
            size={24}
            style={{ position: "absolute", right: "-24px", top: 0 }}
            onClick={() => setShowAboutModal(true)}
            data-tip
            data-for="edit-aboutme"
          />
          <ReactTooltip
            className="tooltipClass"
            id="edit-aboutme"
            place="bottom"
            type="light"
          >
            <span>Edit</span>
          </ReactTooltip>
        </p>
      </Card.Body>
      <Modal show={showAboutModal} onHide={() => setShowAboutModal(false)}>
        <Form.Label>
          About {localStorage.getItem("gender") === "Male" ? `Groom` : `Bride`}
        </Form.Label>
        <textarea
          className="form-control"
          value={aboutMe}
          onChange={(e) => setAboutMe(e.target.value)}
        />
        <div className="d-flex justify-content-end mt-4">
          <Button
            classname="w-auto px-2"
            style={{ backgroundColor: "var(--primary-color)", color: "#fff" }}
            onClick={saveEditedValue}
          >
            Save
          </Button>
        </div>
      </Modal>
    </Card>
  );
}
