import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import Select from "react-select";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import makeAnimated from "react-select/animated";
import {
  Footer,
  Loader,
  NavbarMenu,
  NoData,
  RoundedProfile,
  SearchByCard,
} from "../../Components";
import { environment } from "../../environment/env";
import { deleteSavedSearchService } from "../../Services/SaveSearch";
import "./Search.css";

const baseURL = environment.hostname;
const animatedComponents = makeAnimated();
let listValues,
  ReligionAPI,
  ListValueAPI,
  // matchingProfilesApi,
  matchingProfilesToDisplay;
let ageOptions = [],
  heightOptions = [],
  weightOptions = [];

export default function Search() {
  let selectHandle = (e) => {
    setSelectCountry(e);
    getStateDatas(e);
    // added to check the 'e' parameter
    var newArray = e.map((item) => item.value);
    var finalString = newArray.join();
    // console.log(finalString, "123*****////+++");
    setSelectedCountry(finalString);
  };
  // let selectHandle = (n) => {
  //   getStateDatas(n);
  //   var finalString = n.join();
  //   console.log(finalString, "*****////+++");
  //   setSelectedCountry(finalString);
  // };
  let location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [ageFrom, setAgeFrom] = useState("18");
  const [ageTo, setAgeTo] = useState("60");
  const [heightFrom, setHeightFrom] = useState("135");
  const [heightTo, setHeightTo] = useState("215");
  const [weightFrom, setWeightFrom] = useState("35");
  const [weightTo, setWeightTo] = useState("120");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [physicalStatus, setPhysicalStatus] = useState([]);
  const [familyStatus, setFamilyStatus] = useState([]);
  const [bodyType, setBodyType] = useState([]);
  const [complexions, setComplexions] = useState([]);
  const [diets, setDiets] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [religions, setReligions] = useState([]);
  const [matchingProfiles, setMatchingProfiles] = useState("");
  const [castes, setCastes] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [sectors, setsectors] = useState([]);
  const [selectedReligion, setSelectedReligion] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  const [districts, setDistricts] = useState([]);
  const [searchOption, setSearchOption] = useState("Advanced Search");
  const [activeTab, setActiveTab] = useState("search");
  const [keyword, setKeyword] = useState("");
  const [searchId, setSearchId] = useState("AEC");
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [showMultipleSelect, setShowMultipleSelect] = useState(false);
  const [selectedField, setSelectedField] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [options, setOptions] = useState([]);
  const [income, setIncome] = useState([]);
  const [selectedIncome, setSelectedIncome] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [checked, setChecked] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    ageOptions = [];
    for (let i = 18; i <= 60; i++) {
      ageOptions.push(i);
    }
    heightOptions = [];
    for (let i = 135; i <= 215; i++) {
      heightOptions.push(i);
    }
    weightOptions = [];
    for (let i = 35; i <= 120; i++) {
      weightOptions.push(i);
    }

    ReligionAPI = fetch(`${baseURL}/religion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    ListValueAPI = fetch(`${baseURL}/list_value`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    // matchingProfilesApi = fetch(`${baseURL}/matching`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     id: localStorage.currentUser,
    //     filter: "0",
    //   }),
    // });

    let savedSearchListApi = fetch(`${baseURL}/saved_search_lists`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
      }),
    });

    let stateApi = fetch(`${baseURL}/getstate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    Promise.all([
      ReligionAPI,
      ListValueAPI,
      // matchingProfilesApi,
      savedSearchListApi,
      stateApi,
    ])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        console.log(response, "checkresponse");
        setReligions(response[0]);
        listValues = response[1][0];
        setMatchingProfiles(response[2].result_matches);
        setSavedSearchList(response[3].data);
        setStates(response[4]);
        let temp;
        if (location?.state?.data.marital_status)
          temp = listValues?.marital_status.map((status) =>
            location?.state?.data?.marital_status
              .split("~")
              .find((item) => item === status.id)
              ? { ...status, selected: true }
              : { ...status, selected: false }
          );
        else
          temp = listValues?.marital_status.map((status) => ({
            ...status,
            selected: false,
          }));
        setMaritalStatus(temp);

        if (location?.state?.data?.ageFrom)
          setAgeFrom(location?.state?.data.ageFrom);
        if (location?.state?.data?.ageTo) setAgeTo(location?.state?.data.ageTo);

        if (location?.state?.data?.religion)
          changeInput(location?.state?.data?.religion);

        if (location?.state?.data?.city)
          setSelectedState(location?.state?.data?.city);

        if (location?.state?.data.country_living)
          temp = listValues?.country.map((list) =>
            location?.state?.data?.country_living
              .split("~")
              .find((item) => item === list.id)
              ? { ...list, selected: true }
              : { ...list, selected: false }
          );
        else
          temp = listValues?.country.map((item) => ({
            ...item,
            selected: false,
          }));
        setCountries(temp);

        if (location?.state?.data?.qualification)
          temp = listValues?.qualification.map((item) =>
            location?.state?.data?.qualification
              .split("~")
              .find((qual) => qual === item.id)
              ? { ...item, selected: true }
              : { ...item, selected: false }
          );
        else
          temp = listValues?.qualification.map((item) => ({
            ...item,
            selected: false,
          }));
        setQualifications(temp);

        if (location?.state?.data?.occupation)
          temp = listValues?.occupation.map((item) =>
            location?.state?.data?.occupation
              .split("~")
              .find((occu) => occu === item.id)
              ? { ...item, selected: true }
              : { ...item, selected: false }
          );
        else
          temp = listValues.occupation.map((item) => ({
            ...item,
            selected: false,
          }));
        setOccupations(temp);

        if (location?.state?.data?.job_sector)
          temp = listValues?.job_sector.map((item) =>
            location?.state?.data?.job_sector
              .split("~")
              .find((sector) => sector === item.id)
              ? { ...item, selected: true }
              : { ...item, selected: false }
          );
        else
          temp = listValues.job_sector.map((item) => ({
            ...item,
            selected: false,
          }));
        setsectors(temp);

        temp = listValues.income.map((item) => ({ ...item, selected: false }));
        if (location.state?.data?.annual_income)
          setSelectedIncome(location.state.data?.annual_income);
        setIncome(temp);

        if (location?.state?.data?.physical_status)
          temp = listValues?.physical_status.map((item) =>
            location?.state?.data?.physical_status
              .split("~")
              .find((status) => status === item.id)
              ? { ...item, selected: true }
              : { ...item, selected: false }
          );
        else
          temp = listValues.physical_status.map((item) => ({
            ...item,
            selected: false,
          }));
        setPhysicalStatus(temp);

        if (location?.state?.data?.family_status)
          temp = listValues?.family_status.map((item) =>
            location?.state?.data?.family_status
              .split("~")
              .find((status) => status === item.id)
              ? { ...item, selected: true }
              : { ...item, selected: false }
          );
        else
          temp = listValues.family_status.map((item) => ({
            ...item,
            selected: false,
          }));
        setFamilyStatus(temp);

        if (location?.state?.data?.body_type)
          temp = listValues?.body_type.map((item) =>
            location?.state?.data?.body_type
              .split("~")
              .find((type) => type === item.id)
              ? { ...item, selected: true }
              : { ...item, selected: false }
          );
        else
          temp = listValues.body_type.map((item) => ({
            ...item,
            selected: false,
          }));
        setBodyType(temp);

        if (location?.state?.data?.heightFrom)
          setHeightFrom(location?.state?.data?.heightFrom);
        if (location?.state?.data?.heightTo)
          setHeightTo(location?.state?.data?.heightTo);

        if (location?.state?.data?.weightFrom)
          setWeightFrom(location?.state?.data?.weightFrom);
        if (location?.state?.data?.weightTo)
          setWeightTo(location?.state?.data?.weightTo);

        if (location?.state?.data?.complexion)
          temp = listValues?.complexion.map((item) =>
            location?.state?.data?.complexion
              .split("~")
              .find((complex) => complex === item.id)
              ? { ...item, selected: true }
              : { ...item, selected: false }
          );
        else
          temp = listValues.complexion.map((item) => ({
            ...item,
            selected: false,
          }));
        setComplexions(temp);

        if (location?.state?.data?.diet)
          temp = listValues?.diet.map((list) =>
            location?.state?.data?.diet
              .split("~")
              .find((item) => item === list.id)
              ? { ...list, selected: true }
              : { ...list, selected: false }
          );
        else
          temp = listValues.diet.map((item) => ({ ...item, selected: false }));
        setDiets(temp);

        if (location?.state?.data?.photo === "Yes") setSelectedPhoto("Yes");
        if (location?.state?.data?.photo === "No") setSelectedPhoto("No");

        setIsLoading(false);
      });

    let last_search = localStorage.getItem("last_search");
    if (last_search != "" || last_search != null) {
      setSearchOption(last_search);
    }
  }, [isLoading]);
  matchingProfilesToDisplay = matchingProfiles?.slice(0, 5);

  let changeInput = (event) => {
    fetch(`${baseURL}/caste`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        religion_id: event?.target?.value || event,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setSelectedReligion(event?.target?.value || event);
        let temp = response;
        let temp2;
        if (location?.state?.data?.caste)
          temp2 = temp.map((list) =>
            location?.state?.data?.caste
              .split("~")
              .find((item) => item === list.id)
              ? { ...list, selected: true }
              : { ...list, selected: false }
          );
        else temp2 = temp.map((item) => ({ ...item, selected: false }));
        setCastes(temp2);
      });
  };

  let getDistrictDatas = (id) => {
    fetch(`${baseURL}/getdistrict`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state_id: id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setDistricts(res);
      });
  };
  let getStateDatas = (id) => {
    if (id.length >= 1) {
      fetch(`${baseURL}/getstate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          country_id: id[0]?.value,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setStates(res);
        });
    } else {
      setSelectedCountry("");
      setSelectedState("");
    }
  };

  let search = (event, id) => {
    event.preventDefault();
    navigate("/search/results", {
      state: {
        marital_status: maritalStatus
          .filter((list) => list.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        ageFrom: ageFrom,
        ageTo: ageTo,
        religion: selectedReligion,
        caste: castes
          .filter((list) => list.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        city: selectedState,
        place: selectedDistrict,
        country_living: selectedCountry,
        qualification: qualifications
          .filter((qual) => qual.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        occupation: occupations
          .filter((occu) => occu.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        job_sector: sectors
          .filter((sector) => sector.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        annual_income: selectedIncome,
        physical_status: physicalStatus
          .filter((status) => status.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        family_status: familyStatus
          .filter((status) => status.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        body_type: bodyType
          .filter((status) => status.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        heightFrom: heightFrom,
        heightTo: heightTo,
        weightFrom: weightFrom,
        weightTo: weightTo,
        complexion: complexions
          .filter((complexion) => complexion.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        diet: diets
          .filter((diet) => diet.selected)
          ?.map((sel) => sel.id)
          .join("~"),
        photo: selectedPhoto,
      },
    });
    <Outlet></Outlet>;
  };

  let searchBy = (value) => {
    localStorage.setItem("last_search", value);

    setSearchOption(value);
  };

  let searchByKeyword = (e) => {
    e.preventDefault();
    navigate("/search/results", {
      state: {
        keyword: keyword,
      },
    });
    <Outlet></Outlet>;
  };

  let searchById = (e) => {
    e.preventDefault();
    navigate("/search/results", {
      state: {
        searchId: searchId,
      },
    });
    <Outlet></Outlet>;
  };
  // const v =
  //   selectedCountry.length &&
  //   selectedCountry.filter((item) => item.value == 104);
  // console.log(v, v[0], "selected1234");

  let getSavedSearchResult = (e, savedSearchId) => {
    e.preventDefault();
    navigate("/search/results", {
      state: {
        saved_search_id: savedSearchId,
      },
    });
    <Outlet></Outlet>;
  };

  let deleteSavedSearch = async (e, id) => {
    e.stopPropagation();
    await deleteSavedSearchService({
      id: localStorage.currentUser,
      search_id: id,
      session_id: localStorage.session,
    })
      .then((res) => res.json())
      .then((res) => {
        setSavedSearchList(savedSearchList.filter((list) => list.id != id));
      });
  };

  const validationForm = (e) => {
    const isWhitespace = (e.target.value || "").trim().length === 0;
    if (isWhitespace) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else if (e.target.value.length === 0) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
    }
    setSearchId(e.target.value);
  };

  return (
    <div>
      <NavbarMenu></NavbarMenu>
      <div className="basic-search">
        <Row className="flex-md-row" style={{ marginBottom: "52px" }}>
          <Col
            lg={4}
            xs={12}
            style={{
              height: window.innerWidth > 990 && "500px",
              marginTop: window.innerWidth > 990 ? "" : "24px",
              marginBottom: "1rem",
            }}
          >
            <SearchByCard searchBy={searchBy}></SearchByCard>
          </Col>
          <Col lg={8} xs={12}>
            <Card className="search-tab">
              <Row className="align-items-center">
                <Col
                  className={`${
                    activeTab === "search"
                      ? "basic-search-button"
                      : "unselect-width"
                  } d-flex align-items-center justify-content-center col-auto`}
                  onClick={() => setActiveTab("search")}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  {searchOption}
                </Col>
                <Col
                  className={`${
                    activeTab === "save"
                      ? "basic-search-button"
                      : "unselect-width"
                  } d-flex align-items-center justify-content-center col-auto`}
                  onClick={() => setActiveTab("save")}
                  style={{ cursor: "pointer" }}
                >
                  Saved Searches
                </Col>
              </Row>
            </Card>
            {activeTab === "search" ? (
              <Card className="search-card">
                {
                  searchOption === "Keyword Search" ? (
                    <Form onSubmit={searchByKeyword}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Search like Name,Address,Qualification,Caste... etc"
                        pattern="[A-Za-z0-9 ]{1,15}"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                      ></Form.Control>
                      <Row className="justify-content-end m-0">
                        <Button
                          type="submit"
                          className="btn search-button d-flex justify-content-center align-items-center"
                        >
                          <BsSearch color="#FFF" className="me-2" />
                          Search
                        </Button>
                      </Row>
                    </Form>
                  ) : searchOption === "Id Search" ? (
                    <Form validated={validated} onSubmit={searchById}>
                      <Form.Control
                        required
                        pattern="[AEC]+[0-9]{1,15}"
                        type="text"
                        placeholder="Search Id"
                        value={searchId}
                        onChange={validationForm}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid" role="alert">
                        Enter a valid Id!
                      </Form.Control.Feedback>
                      <Row className="justify-content-end m-0">
                        <Button
                          type="submit"
                          className="btn search-button d-flex justify-content-center align-items-center"
                        >
                          <BsSearch color="#FFF" className="me-2" />
                          Search
                        </Button>
                      </Row>
                    </Form>
                  ) : (
                    //  !isLoading ? (
                    <>
                      <Form onSubmit={search}>
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Marital Status
                          </label>
                          <Form.Control
                            value={
                              maritalStatus
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? maritalStatus
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(maritalStatus);
                              setShowMultipleSelect(true);
                              setSelectedName("marital_status");
                              setSelectedField("Marital Status");
                            }}
                          />
                        </div>
                        <Row>
                          <Col>
                            <FormGroup className="search-group">
                              <FormLabel className="search-label">
                                Age from
                              </FormLabel>
                              <Form.Select
                                type="select"
                                onChange={(e) => {
                                  e.preventDefault();
                                  setAgeFrom(e.target.value);
                                }}
                              >
                                {ageOptions.map((age) => (
                                  <option
                                    value={age}
                                    hidden={
                                      ageTo == "" ? age <= ageTo : age >= ageTo
                                    }
                                    selected={age === parseInt(ageFrom)}
                                  >
                                    {age}
                                  </option>
                                ))}
                              </Form.Select>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="search-group">
                              <FormLabel className="search-label">
                                Age to
                              </FormLabel>
                              <Form.Select
                                type="select"
                                onChange={(e) => setAgeTo(e.target.value)}
                              >
                                <option selected></option>
                                {ageOptions.map((age) => (
                                  <option
                                    value={age}
                                    hidden={age <= ageFrom}
                                    selected={parseInt(ageTo) === age}
                                  >
                                    {age}
                                  </option>
                                ))}
                              </Form.Select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup className="search-group">
                          <FormLabel className="search-label">
                            Religion
                          </FormLabel>
                          <Form.Select type="select" onChange={changeInput}>
                            <option
                              disabled
                              hidden
                              selected={!selectedReligion}
                            >
                              Any
                            </option>
                            {religions?.map((religion) => (
                              <option
                                value={religion.id}
                                selected={selectedReligion === religion.id}
                              >
                                {religion.name}
                              </option>
                            ))}
                          </Form.Select>
                        </FormGroup>
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Caste
                          </label>
                          <Form.Control
                            value={
                              castes
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? castes
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(castes);
                              setShowMultipleSelect(true);
                              setSelectedName("caste");
                              setSelectedField("Caste");
                            }}
                          />
                        </div>
                        <FormGroup className="search-group">
                          <FormLabel className="search-label">
                            Country
                          </FormLabel>
                          <Select
                            isSearchable={true}
                            closeMenuOnSelect={false}
                            closeMenuOnScroll={false}
                            blurInputOnSelect={false}
                            // defaultValue={[colourOptions[2], colourOptions[3]]}
                            isMulti
                            name="colors"
                            components={animatedComponents}
                            placeholder="Any"
                            options={countries?.map((itemCountry) => ({
                              value: itemCountry.id,
                              label: itemCountry.name,
                            }))}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={
                              (e) => {
                                selectHandle(e);
                              }
                              //   (e) => {
                              //   getStateDatas(e);
                              //   setSelectedCountry(e);
                              //   // //;
                              // }
                            }
                          />
                          {/* {console.log(selectedCountry, "selectedCountry")} */}
                          {/* <Select
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          closeMenuOnScroll={false}
                          blurInputOnSelect={false}
                          placeholder="Any"
                          isMulti
                          value={selectedCountry}
                          options={countries}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["id"]}
                          components={animatedComponents}
                          onChange={(e) => {
                            getStateDatas(e.target.value);
                            setSelectedCountry(e.target.value);
                          }}
                        /> */}
                          {/* <Form.Select
                          type="select"
                          onChange={(e) => {
                            getStateDatas(e.target.value);
                            setSelectedCountry(e.target.value);
                          }}
                        >
                          <option disabled hidden selected={!selectedCountry}>
                            Any
                          </option>
                          {countries?.map((itemCountry) => (
                            <option
                              value={itemCountry.id}
                              selected={selectedCountry === itemCountry.id}
                            >
                              {itemCountry.name}
                            </option>
                          ))}
                        </Form.Select> */}
                        </FormGroup>
                        <FormGroup className="search-group">
                          <FormLabel className="search-label">State</FormLabel>
                          <Form.Select
                            type="select"
                            onChange={(e) => {
                              getDistrictDatas(e.target.value);
                              setSelectedState(e.target.value);
                            }}
                          >
                            <option selected={!selectedState}>Any</option>
                            {selectCountry.length < 2 &&
                              states?.map((state) => (
                                <option
                                  value={state.id}
                                  selected={selectedState === state.id}
                                >
                                  {state.name}
                                </option>
                              ))}
                          </Form.Select>
                        </FormGroup>

                        {selectedState != "" &&
                          selectedState != "Any" &&
                          selectCountry.length < 2 && (
                            <FormGroup className="search-group">
                              <FormLabel className="search-label">
                                District
                              </FormLabel>
                              <Form.Select
                                type="select"
                                onChange={(e) =>
                                  setSelectedDistrict(e.target.value)
                                }
                              >
                                <option
                                  disabled
                                  hidden
                                  selected={!selectedDistrict}
                                >
                                  Any
                                </option>
                                {districts?.map((district) => (
                                  <option
                                    value={district.id}
                                    selected={selectedDistrict === district.id}
                                  >
                                    {district.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </FormGroup>
                          )}

                        {/* <div
                        style={{ marginBottom: "18px" }}
                        className="form-group mb-3"
                      >
                        <label className="form-label search-label">
                          Country Living
                        </label>
                        <Form.Control
                          value={
                            countries
                              .filter((opt) => opt?.selected === true)
                              .map((option) => option.name).length != 0
                              ? countries
                                  .filter((opt) => opt?.selected === true)
                                  .map((option) => option.name)
                              : "Any"
                          }
                          onClick={() => {
                            setOptions(countries);
                            setShowMultipleSelect(true);
                            setSelectedName("country_living");
                            setSelectedField("Country Living");
                          }}
                        />
                      </div> */}
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Qualification
                          </label>
                          <Form.Control
                            value={
                              qualifications
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? qualifications
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(qualifications);
                              setShowMultipleSelect(true);
                              setSelectedName("qualification");
                              setSelectedField("Qualifications");
                            }}
                          />
                        </div>
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Occupation
                          </label>
                          <Form.Control
                            value={
                              occupations
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? occupations
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(occupations);
                              setShowMultipleSelect(true);
                              setSelectedName("occupation");
                              setSelectedField("Occupation");
                            }}
                          />
                        </div>
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Sector
                          </label>
                          <Form.Control
                            value={
                              sectors
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? sectors
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(sectors);
                              setShowMultipleSelect(true);
                              setSelectedName("job_sector");
                              setSelectedField("Sector");
                            }}
                          />
                        </div>
                        <FormGroup className="search-group">
                          <FormLabel className="search-label">
                            Annual Income
                          </FormLabel>
                          <Form.Select
                            type="select"
                            onChange={(e) => setSelectedIncome(e.target.value)}
                          >
                            <option selected={!selectedIncome}>Any</option>
                            {income?.map((item) => (
                              <option
                                value={item.id}
                                selected={selectedIncome === item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Form.Select>
                        </FormGroup>
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Physical Status
                          </label>
                          <Form.Control
                            value={
                              physicalStatus
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? physicalStatus
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(physicalStatus);
                              setShowMultipleSelect(true);
                              setSelectedName("physical_status");
                              setSelectedField("Physical Status");
                            }}
                          />
                        </div>
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Family Status
                          </label>
                          <Form.Control
                            value={
                              familyStatus
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? familyStatus
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(familyStatus);
                              setShowMultipleSelect(true);
                              setSelectedName("family_status");
                              setSelectedField("Family Status");
                            }}
                          />
                        </div>
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Body Type
                          </label>
                          <Form.Control
                            value={
                              bodyType
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? bodyType
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(bodyType);
                              setShowMultipleSelect(true);
                              setSelectedName("body_type");
                              setSelectedField("Body Type");
                            }}
                          />
                        </div>
                        <Row>
                          <Col>
                            <FormGroup className="search-group">
                              <FormLabel className="search-label">
                                Height(cm) from
                              </FormLabel>
                              <Form.Select
                                type="select"
                                onChange={(e) => setHeightFrom(e.target.value)}
                              >
                                {heightOptions.map((height) => (
                                  <option
                                    value={height}
                                    hidden={
                                      heightTo == ""
                                        ? height <= heightTo
                                        : height >= heightTo
                                    }
                                    selected={height === parseInt(heightFrom)}
                                  >
                                    {height} cm
                                  </option>
                                ))}
                              </Form.Select>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="search-group">
                              <FormLabel className="search-label">
                                Height(cm) to
                              </FormLabel>
                              <Form.Select
                                type="select"
                                onChange={(e) => setHeightTo(e.target.value)}
                              >
                                {heightOptions.map((height) => (
                                  <option
                                    value={height}
                                    hidden={height <= heightFrom}
                                    selected={height === parseInt(heightTo)}
                                  >
                                    {height} cm
                                  </option>
                                ))}
                              </Form.Select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup className="search-group">
                              <FormLabel className="search-label">
                                Weight(kg) from
                              </FormLabel>
                              <Form.Select
                                type="select"
                                onChange={(e) => setWeightFrom(e.target.value)}
                              >
                                <option disabled hidden selected></option>
                                {weightOptions.map((weight) => (
                                  <option
                                    value={weight}
                                    hidden={
                                      weightTo == ""
                                        ? weight <= weightTo
                                        : weight >= weightTo
                                    }
                                    selected={weight === parseInt(weightFrom)}
                                  >
                                    {weight} kg
                                  </option>
                                ))}
                              </Form.Select>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="search-group">
                              <FormLabel className="search-label">
                                Weight(kg) to
                              </FormLabel>
                              <Form.Select
                                type="select"
                                onChange={(e) => setWeightTo(e.target.value)}
                              >
                                <option disabled hidden selected></option>
                                {weightOptions.map((weight) => (
                                  <option
                                    value={weight}
                                    hidden={weight <= weightFrom}
                                    selected={weight === parseInt(weightTo)}
                                  >
                                    {weight} kg
                                  </option>
                                ))}
                              </Form.Select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Complexion
                          </label>
                          <Form.Control
                            value={
                              complexions
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? complexions
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(complexions);
                              setShowMultipleSelect(true);
                              setSelectedName("complexion");
                              setSelectedField("Complexion");
                            }}
                          />
                        </div>
                        <div
                          style={{ marginBottom: "18px" }}
                          className="form-group mb-3"
                        >
                          <label className="form-label search-label">
                            Diet
                          </label>
                          <Form.Control
                            value={
                              diets
                                .filter((opt) => opt?.selected === true)
                                .map((option) => option.name).length != 0
                                ? diets
                                    .filter((opt) => opt?.selected === true)
                                    .map((option) => option.name)
                                : "Any"
                            }
                            onClick={() => {
                              setOptions(diets);
                              setShowMultipleSelect(true);
                              setSelectedName("diet");
                              setSelectedField("Diet");
                            }}
                          />
                        </div>
                        <FormGroup className="search-group">
                          <FormLabel className="search-label">Photo</FormLabel>
                          <Form.Select
                            type="select"
                            onChange={(e) => setSelectedPhoto(e.target.value)}
                          >
                            <option value="" selected={!selectedPhoto}>
                              Any
                            </option>
                            <option
                              value="Yes"
                              selected={selectedPhoto === "Yes"}
                            >
                              Yes
                            </option>
                            <option
                              value="No"
                              selected={selectedPhoto === "No"}
                            >
                              No
                            </option>
                          </Form.Select>
                        </FormGroup>
                        <Row className="justify-content-end m-0">
                          <Button
                            type="submit"
                            className="btn search-button d-flex justify-content-center align-items-center"
                          >
                            <BsSearch color="#FFF" className="me-2" />
                            Search
                          </Button>
                        </Row>
                      </Form>

                      <Modal
                        show={showMultipleSelect}
                        onHide={() => setShowMultipleSelect(false)}
                      >
                        <div>{selectedField}</div>
                        <div>
                          <Form.Check
                            hidden
                            style={{ color: "#5B6770" }}
                            name={selectedName}
                            id={selectedName}
                            // checked={options?.every((option) => option.selected)}
                            checked={options?.every(
                              (option) => option.selected
                            )}
                            onChange={(e) => {
                              options?.map((opt) => opt.selected);
                              setChecked(!checked);
                            }}
                          />
                          {options?.map((option) => (
                            <Form.Check
                              style={{ color: "#5B6770" }}
                              name={selectedName}
                              label={option?.name}
                              id={selectedName}
                              checked={option.selected}
                              onChange={(e) => {
                                options.filter(
                                  (opt) => opt.id === option.id
                                )[0].selected = !option.selected;
                                setChecked(!checked);
                              }}
                            />
                          ))}
                          <div
                            className="position-sticky d-flex justify-content-between align-items-center pt-4 pb-4"
                            style={{
                              bottom: "0",
                              zIndex: "2",
                              backgroundColor: "white",
                            }}
                          >
                            <Button
                              className="px-5"
                              style={{
                                backgroundColor: "var(--primary-color)",
                                border: "none",
                              }}
                              onClick={() => setShowMultipleSelect(false)}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    </>
                  )
                  // ) : (
                  //   <Loader></Loader>
                  // )
                }
              </Card>
            ) : (
              <Card className="search-card">
                {savedSearchList?.length ? (
                  <>
                    {/* <h5 className='title'>saved searches</h5> */}
                    {savedSearchList?.map((list) => (
                      <div
                        className="mt-1 mb-1 d-flex justify-content-between p-2"
                        style={{
                          color: "#3d3c3c",
                          borderBottom: "1px solid #C4C4C4",
                        }}
                        onClick={(e) => getSavedSearchResult(e, list.id)}
                      >
                        <div style={{ cursor: "pointer" }}>{list.tag}</div>
                        <MdClose
                          color="#C4C4C4"
                          onClick={(e) => deleteSavedSearch(e, list.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    style={{
                      minHeight: "75%",
                      color: "#C4C4C4",
                      fontSize: "14px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    No saved items to display
                  </div>
                )}
              </Card>
            )}
          </Col>
        </Row>
        {/* <Row>
          <h4 className="title" style={{ marginBottom: "42px" }}>
            Matching Profiles
          </h4>
          {!isLoading ? (
            <Row
              className={`horizontal-scroll mx-0 ${
                matchingProfiles?.length > 5 ? "justify-content-between" : ""
              }  `}
            >
              {matchingProfiles?.length ? (
                matchingProfilesToDisplay?.map((profile, index) => (
                  <div
                    className={`d-flex flex-column ${
                      matchingProfiles?.length > 5 ? "p-md-0" : "px-2"
                    }  px-3 w-auto`}
                    key={index}
                  >
                    <RoundedProfile profile={profile}></RoundedProfile>
                  </div>
                ))
              ) : (
                <NoData></NoData>
              )}
            </Row>
          ) : (
            <Loader></Loader>
          )}
        </Row> */}
      </div>
      <Footer></Footer>
    </div>
  );
}
