import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { environment } from "../../environment/env";
import UpgradeModal from "../Modal/UpgradeModal";

const baseURL = environment.hostname;
export default function NoImage(props) {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  let sendImageRequestMessage = (e) => {
    e.stopPropagation();
    fetch(`${baseURL}/add_album_request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: props?.id,
        ip: "111.111.1.1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        addNonImageStatus();
        window.location.reload(true);
      });
  };

  let addNonImageStatus = () => {
    fetch(`${baseURL}/noimage_request_activity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        to_id: props?.id,
        session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response)
          toast.success("Album request send successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
        else if (response.sts === 3)
          toast.info("album request already send", {
            position: toast.POSITION.TOP_CENTER,
          });
        else
          toast.error("Failed to send album request", {
            position: toast.POSITION.TOP_CENTER,
          });
      });
  };

  return (
    <>
      {/* <UpgradeModal
        upgradeNow={showUpgradeModal}
        closeModal={() => setShowUpgradeModal(false)}
      ></UpgradeModal> */}
      <div className="protected-image">
        {props.status != "1" ? (
          <>
            <div style={{ backgroundColor: "rgb(0,0,0,0.2)" }}>No Image</div>
            <Button
              className="mt-2 protected-image-button "
              onClick={(e) => sendImageRequestMessage(e)}
            >
              Request
            </Button>
          </>
        ) : (
          <div style={{ backgroundColor: "rgb(0,0,0,0.2)" }}>
            Image request sent
          </div>
        )}
      </div>
    </>
  );
}
