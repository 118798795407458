import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { FiCamera } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { environment } from "../../environment/env";
import "./ProfileAlbumCard.css";

let baseURL = environment.hostname;
let imageURL = environment.imageURL;
let albumURL = environment.albumURL;

export default function ProfileAlbumCard() {
  const ref = useRef(null);
  const [album, setAlbum] = useState([0, 0, 0, 0, 0, 0]);
  useEffect(() => {
    fetch(`${baseURL}/user_album`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: localStorage.currentUser,
        profid: localStorage.currentUser,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let resAlbum = response.length ? response : [0, 0, 0, 0, 0, 0];
        setAlbum(resAlbum);
      });
  }, []);

  const scroll = (scrollOffset) => {
    return (ref.current.scrollLeft += scrollOffset);
  };
  return (
    <Card className="profile-album">
      <div className="d-flex justify-content-end mb-4">
        <Link className="manage-photo-button btn mb-2" to="album-upload">
          <FiCamera color="#fff" className="me-2" />
          Manage Photos{" "}
        </Link>
      </div>
      <Row>
        {album.filter((item) => item.album != "" && item != 0).length > 3 ? (
          <Col className="d-flex align-items-center col-auto">
            <div
              className="scroll-album d-flex justify-content-center align-items-center"
              onClick={() => scroll(-200)}
            >
              <MdKeyboardArrowLeft color="#FFF" size="24px" />
            </div>
          </Col>
        ) : (
          <>
            <div style={{ color: "rgb(196, 196, 196)", textAlign: "center" }}>
              No image uploaded
            </div>
          </>
        )}

        <Col
          className="d-flex horizontal-scroll mx-0"
          style={{ scrollBehavior: "smooth" }}
          ref={ref}
        >
          {album?.map((image, i) => {
            return image?.album ? (
              <div className="d-flex flex-column px-2 w-auto">
                <Image
                  src={
                    i === 0
                      ? `${imageURL}${image.album}`
                      : `${albumURL}${image.album}`
                  }
                  height="187px"
                  width="187px"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            ) : (
              <></>
            );
          })}
        </Col>
        {album.filter((item) => item.album != "" && item != 0).length > 3 ? (
          <Col className="d-flex align-items-center col-auto">
            <div
              className="scroll-album d-flex justify-content-center align-items-center"
              onClick={() => scroll(200)}
            >
              <MdKeyboardArrowRight color="#FFF" size="24px" />
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Card>
  );
}
