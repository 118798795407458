import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { MdDomainVerification, MdMenu } from "react-icons/md";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { SiOpenstreetmap } from "react-icons/si";
import {
  appStore,
  getAppImage1,
  getAppImage2,
  getAppImage3,
  homeIMage1,
  homeImage2,
  homeImage3,
  homeImage4,
  meetingIcon,
  miniLogo,
  miniLogoMob,
  playStore,
  secureIcon,
  smartMatchIcon,
  verifiedIcon,
  getAppBanner,
} from "../../assets";
import Footer from "../../Components/Footer/Footer";
import { Field, Formik } from "formik";
import { environment } from "../../environment/env";
import { Loader } from "../../Components";
import "./Home.css";
import { Box } from "@mui/system";
import { AiOutlineClose } from "react-icons/ai";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const baseURL = environment.hostname;
const playStoreURL = environment.playStoreURL;
const appStoreURL = environment.appStoreURL;
let listValue, prefixList;
let HomeBanner = environment.homeBanner;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);

  let navigate = useNavigate();
  const query = useQuery();
  const ref = query.get("ref");
  const code = query.get("code");
  console.log(ref, "ref7", code, "code44");

  const [hidePopUpModal, setHidePopUpModal] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    hasOneDayPassed();

    const code = query.get("code");
    const ref = query.get("ref");
    console.log(code, "--------------with code");

    if (code) {
      localStorage.setItem("recievedCode", code);
    } else {
      localStorage.setItem("recievedCode", ref);
    }
    if (localStorage.currentUser && localStorage.session) navigate("/matching");

    let listValueApi = fetch(
      `${baseURL}/list_value`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
      []
    );

    let prefixListApi = fetch(`${baseURL}/prefixlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    Promise.all([listValueApi, prefixListApi])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        listValue = response[0][0];
        prefixList = response[1];
        setIsLoading(false);
      });
  }, [navigate]);

  function hasOneDayPassed() {
    var date = new Date().toLocaleDateString();

    if (localStorage.getItem("today") == date) {
      setHidePopUpModal(false);
    } else {
      localStorage.setItem("today", date);
      setHidePopUpModal(window.innerWidth < 576 ? true : false);
    }
  }

  let quickRegister = (values) => {
    navigate(
      ref
        ? `/register?ref=${ref}`
        : "/register" || code
        ? `/register?code=${code}`
        : "/register",
      {
        state: { values: values, values: values },
      }
    );
    navigate(
      ref
        ? `/register?ref=${ref}`
        : "/register" || code
        ? `/register?code=${code}`
        : "/register",
      {
        state: { values: values, values: values },
      }
    );
  };

  let installPlayStore = () => {
    window.open(playStoreURL, "_blank");
  };

  let installAppStore = () => {
    window.open(appStoreURL, "_blank");
  };

  let showAboutUS = () => {
    let element = document.getElementById("about-us");
    element.scrollIntoView(true);
  };

  return (
    <div>
      <Container fluid className="p-0">
        <div className="register-section">
          <Row className="navbarmenu m-0">
            <Navbar
              expand="lg"
              className="align-items-start justify-content-between navbar-margin p-0 d-flex"
              style={{ maxWidth: "100%", margin: "0" }}
            >
              <Col className="d-inline-flex" md={3} xs={5} sm={6}>
                <NavLink to="/" className="brand mt-3">
                  <img
                    className="d-inline-block align-top minilogo-home"
                    alt="logo"
                    src={window.innerWidth > 500 ? miniLogo : miniLogoMob}
                  />
                </NavLink>
              </Col>
              <Col md={9} xs={7} sm={6}>
                <Row className="d-none d-lg-block">
                  <Nav className="me-auto d-flex justify-content-end align-items-center mt-2">
                    <Link className="px-0 pe-3 pt-0" to="#link">
                      <Image
                        width="auto"
                        height="36px"
                        src={playStore}
                        onClick={installPlayStore}
                      />
                    </Link>
                    <Link className="px-0 pt-0" to="#link">
                      <Image
                        width="auto"
                        height="36px"
                        src={appStore}
                        onClick={installAppStore}
                      />
                    </Link>
                  </Nav>
                </Row>
                <Nav className="me-auto d-flex justify-content-end  mt-4 row flex-row">
                  <Link
                    to="/login"
                    className="login-button-home d-flex justify-content-center align-items-center order-lg-5 col-auto"
                  >
                    LOGIN
                  </Link>
                  <Navbar.Toggle className=" col-auto border-0">
                    <MdMenu color="#FFF" size="28px" />{" "}
                  </Navbar.Toggle>
                  <Navbar.Collapse className="home-toggle-menu justify-content-end col-auto align-items-center">
                    <div className="d-flex mt-2 mt-md-3 mt-lg-0 justify-content-end">
                      <Link
                        to={
                          ref
                            ? `/?ref=${ref}`
                            : "/" || code
                            ? `/?code=${code}`
                            : "/"
                        }
                        className="text-decoration-none px-2 px-lg-3 order-lg-1"
                        style={{
                          fontWeight: "700",
                          color: "#fff",
                          paddingBottom: "0",
                          cursor: "pointer",
                        }}
                      >
                        HOME
                      </Link>
                      <Link
                        to=""
                        onClick={showAboutUS}
                        className="text-decoration-none px-2 px-lg-3 order-lg-2"
                        style={{ color: "#fff", paddingBottom: "0" }}
                      >
                        ABOUT US
                      </Link>
                      <Link
                        to={
                          ref
                            ? `/register?ref=${ref}`
                            : "/register" || code
                            ? `/register?code=${code}`
                            : "/register"
                        }
                        className="text-decoration-none px-2 px-lg-3 order-lg-3"
                        style={{ color: "#fff", paddingBottom: "0" }}
                      >
                        REGISTER
                      </Link>
                      <Link
                        to="/contact"
                        className="text-decoration-none px-2 px-lg-3 order-lg-4"
                        style={{ color: "#fff", paddingBottom: "0" }}
                      >
                        CONTACT
                      </Link>
                    </div>
                  </Navbar.Collapse>
                </Nav>
                <Outlet />
              </Col>
            </Navbar>
          </Row>
          <Row className="m-0">
            <Col
              md={(3, { order: "first" })}
              xs={(12, { order: "last" })}
              className="register p-0"
            >
              <h1 className="register-heading d-none d-md-block">
                Meet Your<br></br> Soulmate
              </h1>
              <Card className="register-now-card">
                <Card.Body>
                  {!isLoading ? (
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        profile_for: "",
                        name: "",
                        gender: "",
                        country_code: "+91",
                        phone: "",
                        password: "",
                        refer: ref,
                        refrral: code,
                      }}
                      // quickRegister(values
                      onSubmit={(values) => quickRegister(values)}
                      validate={(values) => {
                        const errors = {};
                        if (!values?.profile_for)
                          errors.profile_for = "Please select Profile For";
                        let regexname = /^[a-z A-Z]*\.?[a-z A-Z]*$/;
                        if (!regexname.test(values?.name))
                          errors.name = `Provide a valid name`;
                        let kerName = values.name;
                        if (kerName.replaceAll(" ", "").length < 3)
                          errors.name = `Provide a valid name`;
                        if (!values?.name) errors.name = "Please enter Name";
                        if (!values?.gender)
                          errors.gender = "Please select Gender";
                        // {
                        //   console.log(
                        //     prefixList.filter(
                        //       (value) => values.country_code === value.id
                        //     )[0].phoneno_length
                        //   );
                        // }
                        let regex = /^[0-9]*$/;
                        if (
                          regex.test(values?.phone) &&
                          values?.phone.length <
                            prefixList.filter(
                              (value) => values.country_code === value.id
                            )[0].phoneno_length
                        )
                          errors.phone = "Provide a valid mobile number";
                        if (!values?.phone)
                          errors.phone = "Please enter Phone Number";
                        if (values?.phone) {
                          let regex = /^[0-9\b]+$/;
                          if (!regex.test(values.phone))
                            errors.phone = "Provide a valid Phone number";
                        }
                        if (values?.password.length < 6)
                          errors.password =
                            "Password must contain 6 or more characters";
                        if (values?.password.length > 15) {
                          errors.password = "Enter Maximum 15 characters!!";
                        }
                        if (!values?.password)
                          errors.password = "Please enter Password";
                        return errors;
                      }}
                    >
                      {({
                        isSubmitting,
                        dirty,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        values,
                      }) => (
                        <Form className="register-form" onSubmit={handleSubmit}>
                          <Field
                            className="form-field form-select"
                            as="select"
                            name="profile_for"
                            style={{ color: "#5B6770", marginBottom: "5px" }}
                          >
                            <option default disabled value="">
                              Profile for
                            </option>
                            {listValue?.profile_created_for.map((item, i) => (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              height: "25px",
                            }}
                          >
                            {errors?.profile_for && touched?.profile_for
                              ? errors.profile_for
                              : ""}
                          </div>
                          <Field
                            className="form-field form-control"
                            type="text"
                            name="name"
                            placeholder="Name of Bride/Groom"
                            maxLength={30}
                            style={{ color: "#5B6770", marginBottom: "5px" }}
                          />
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              height: "25px",
                            }}
                          >
                            {errors.name && touched.name ? errors.name : ""}
                          </div>
                          <Field
                            className="form-field form-select"
                            as="select"
                            name="gender"
                            style={{ color: "#5B6770", marginBottom: "5px" }}
                          >
                            <option default disabled value="">
                              Gender
                            </option>
                            {listValue?.gender.map((item, i) => (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              height: "25px",
                            }}
                          >
                            {errors.gender && touched.gender
                              ? errors.gender
                              : ""}
                          </div>
                          <div
                            className="d-flex"
                            style={{ marginBottom: "5px" }}
                          >
                            <Field
                              className="form-control form-control-color form-field"
                              name="country_code"
                              as="select"
                              style={{
                                color: "#5B6770",
                                marginBottom: "0px",
                                width: "70px",
                              }}
                            >
                              {prefixList?.map((prefix, i) => (
                                <option key={i} value={prefix.id}>
                                  {prefix.id}
                                </option>
                              ))}
                            </Field>
                            <Field
                              className="form-field form-control ms-1"
                              type="text"
                              name="phone"
                              placeholder="Mobile number"
                              maxLength={10}
                              style={{ marginBottom: "0px" }}
                            />
                          </div>
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              height: "25px",
                            }}
                          >
                            {errors.phone && touched.phone ? errors.phone : ""}
                          </div>
                          <div className="position-relative">
                            <Field
                              className="form-field form-control"
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              name="password"
                              maxLength={30}
                              style={{ color: "#5B6770", marginBottom: "5px" }}
                            />
                            {showPassword ? (
                              <BsEyeSlash
                                color="#5B6770"
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "12px",
                                }}
                                onClick={() => setShowPassword(false)}
                              />
                            ) : (
                              <BsEye
                                color="#5B6770"
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "12px",
                                }}
                                onClick={() => setShowPassword(true)}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              height: "25px",
                            }}
                          >
                            {errors.password && touched.password
                              ? errors.password
                              : ""}
                          </div>
                          <div className="d-flex justify-content-end">
                            <Button
                              className="register-button px-4"
                              type="submit"
                            >
                              Register Free
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div
                      style={{ height: "100px" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div
                        className="spinner-border"
                        style={{ color: "var(--primary-color)" }}
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={4} xs={12} className="px-0 position-relative">
              <Image
                className="banner ban-imge"
                src={HomeBanner + listValue?.banners[0]?.image}
              />
            </Col>
          </Row>
        </div>
        <div className="cards-section">
          <Row className="horizontal-scroll justify-content-between p-3">
            {/* <Col> */}
            <Card className="card-section me-4">
              <Card.Body className="px-0 d-flex align-items-center flex-column">
                <Card.Img
                  height="80px"
                  style={{ width: "79px" }}
                  src={secureIcon}
                />
                <Card.Text className="mt-3" align="center">
                  Most Secure
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="card-section me-4">
              <Card.Body className="px-0 d-flex align-items-center flex-column">
                <Card.Img
                  height="80px"
                  style={{ width: "79px" }}
                  src={verifiedIcon}
                />
                <Card.Text className="mt-3" align="center">
                  Verified Profile
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="card-section me-4">
              <Card.Body className="px-0 d-flex align-items-center flex-column">
                <Card.Img
                  height="80px"
                  style={{ width: "79px" }}
                  src={meetingIcon}
                />
                <Card.Text className="mt-3" align="center">
                  Online Meeting
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="card-section">
              <Card.Body className="px-0 d-flex align-items-center flex-column">
                <Card.Img
                  height="80px"
                  style={{ width: "79px" }}
                  src={smartMatchIcon}
                />
                <Card.Text className="mt-3" align="center">
                  Smart Matches
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
          <Row
            className="section-3 justify-content-start w-120 mt-4 mb-4 row"
            id="about-us"
          >
            <Col className="d-flex flex-column align-items-start">
              <p className="order-2 order-md-1 section-para">
                Select a better life partner from Millions of Matches
              </p>
              <h1 className="headHome">
                Click on Click4Marry
                <br /> to Find Your Best Match
              </h1>
              <p
                align="left"
                style={{ color: "#5B6770" }}
                className="order-3 section-para"
              >
                Click4marry.com: is built on trust and technology that
                facilitate an easy and safer process of matchmaking. Enhanced
                Profile Moderation facility helps to filter undesirable profiles
                to ensure the serious match seekers find the authentic match
                profiles <br />
                Personality Matching Test ensures finding suitable match of his
                or her choices and preferences to mitigate the chances of
                occurring undesirable after-marriage issues
                <br />
                Register for FREE and find your perfect life partner today!
              </p>
            </Col>
            <Col
              xs={"auto"}
              className="d-flex justify-content-end align-items-center px-0"
            >
              <Image src={homeImage2} className="home-banner2" />
            </Col>
          </Row>
        </div>
        <Row className="section-4 mx-0" style={{ marginBottom: "93px" }}>
          <div
            className="d-flex flex-column align-items-center"
            style={{ marginBottom: "56px" }}
          >
            <p style={{ fontSize: "20px", color: "var(--primary-color)" }}>
              CLICK4MARRY.COM
            </p>
            <h3>Perfect Matrimony </h3>
            <p style={{ color: "#5B6770" }}>
              Begin the journey of your partner search with us..!!
            </p>
          </div>
          <Row>
            <Col lg={6} className="position-relative">
              <Image
                src={homeImage4}
                style={{
                  width: "100%",
                  borderTopRightRadius: "26px",
                  borderBottomRightRadius: "26px",
                }}
              />
            </Col>
            <Col
              lg={6}
              className="perfect-matrimony d-flex flex-column justify-content-between pt-md-0 pt-5"
            >
              <Row className="mt-md-3 mt-lg-0 mt-0">
                <Col className="col-auto">
                  <div
                    className="d-flex justify-content-center align-items-center w-auto p-4"
                    style={{
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "50px",
                    }}
                  >
                    <VscWorkspaceTrusted
                      color="var(--secondary-color)"
                      className="perfect-matrimony-icon"
                    />
                  </div>
                </Col>
                <Col>
                  <h2
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                      color: "var(--primary-color)",
                    }}
                  >
                    Most trusted matrimony
                  </h2>
                  <div style={{ color: "#5B6770" }}>
                    Follow the most secured practices to keep the data safe and
                    restrict access to contact details and photos
                  </div>
                </Col>
              </Row>
              <Row className="mt-md-3 mt-lg-0 mt-0">
                <Col className="col-auto">
                  <div
                    className="d-flex justify-content-center align-items-center w-auto p-4"
                    style={{
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "50px",
                    }}
                  >
                    <MdDomainVerification
                      color="var(--secondary-color)"
                      className="perfect-matrimony-icon"
                    />
                  </div>
                </Col>
                <Col>
                  <h2
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                      color: "var(--primary-color)",
                    }}
                  >
                    100% manually verified profiles
                  </h2>
                  <div style={{ color: "#5B6770" }}>
                    Each profiles are manually screened and verified by our
                    experts & Special listing of profiles verified by our agents
                    through personal visits.
                  </div>
                </Col>
              </Row>
              <Row className="mt-md-3 mt-lg-0 mt-0">
                <Col className="col-auto">
                  <div
                    className="d-flex justify-content-center align-items-center w-auto p-4"
                    style={{
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "50px",
                    }}
                  >
                    <SiOpenstreetmap
                      color="var(--secondary-color)"
                      className="perfect-matrimony-icon"
                    />
                  </div>
                </Col>
                <Col>
                  <h2
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                      color: "var(--primary-color)",
                    }}
                  >
                    Find your best match{" "}
                  </h2>
                  <div style={{ color: "#5B6770" }}>
                    Find your best match from your preferred city from thousands
                    of keralites and keralites living in Middle east
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>

        {/* <Modal
          className="popup-modal__mobile-window"
          show={hidePopUpModal}
          onHide={() => setHidePopUpModal(false)}
        >
          <Image
            src={getAppBanner}
            className="popup-alert-image__modal"
            style={{
              width: "100%",
              height: "460px",
              opacity: "0.49",
              borderRadius: "0.5rem",
            }}
          />
          <Box sx={{ position: "absolute", top: "2%" }}>
            <Box sx={{ padding: "1rem" }}>
              <AiOutlineClose
                onClick={() => setHidePopUpModal(false)}
                size="25px"
                color="white"
                style={{ position: "absolute", right: "5px", top: "0px" }}
              />
              <h4 style={{ color: "var(--primary-color)" }}>
                Get The Mobile App Today
              </h4>
              <h6 style={{ color: "var(--primary-color)" }}>
                Stay up-to-date with your partner search using our Click4marry
                App
              </h6>
              <p align="left" style={{ color: "#5B6770" }}>
                Get access to exclusive features that are only available on the
                mobile app. We keep introducing new app-only features to make it
                more intuitive & user-friendly.
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Image
                  src={playStore}
                  onClick={installPlayStore}
                  className="me-3 cursor-pointer get-app-playstore"
                />
                <Image
                  src={appStore}
                  onClick={installAppStore}
                  className="cursor-pointer get-app-appstore"
                />
              </div>
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                marginBottom: "5rem",
              }}
            >
              <Image
                src={getAppImage1}
                style={{ width: "30%", height: "auto" }}
              />
              <Image
                src={getAppImage2}
                style={{ width: "30%", height: "auto" }}
              />
              <Image
                src={getAppImage3}
                style={{ width: "30%", height: "auto" }}
              />
            </Box>
          </Box>
        </Modal> */}

        <div className="position-relative">
          <Row className="home-get-app mx-0"></Row>
          <Row
            className="position-absolute mx-0 ps-md-3 ps-0 ps-lg-0 set-width"
            style={{ top: "0", height: "100%" }}
          >
            <Col
              lg={{ order: "last" }}
              className="get-app pb-0"
              style={{ paddingLeft: "10px" }}
            >
              <h4 className="get-app-head">Get The Mobile App Today</h4>
              <h6
                className="mb-4 mt-3"
                style={{ color: "var(--primary-color)" }}
              >
                Stay up-to-date with your partner search using our Click4marry
                App
              </h6>
              <p
                align="left"
                style={{ color: "#5B6770", paddingRight: "120px" }}
              >
                Get access to exclusive features that are only available on the
                mobile app. We keep introducing new app-only features to make it
                more intuitive & user-friendly.
              </p>
              <div className="d-flex mt-md-5 align-items-center">
                <Image
                  src={playStore}
                  onClick={installPlayStore}
                  className="me-3 cursor-pointer get-app-playstore"
                />
                <Image
                  src={appStore}
                  onClick={installAppStore}
                  className="cursor-pointer get-app-appstore"
                />
              </div>
            </Col>
            <Col
              lg={{ order: "first" }}
              className="d-flex align-items-center col-6"
            >
              <Image src={getAppImage1} className="get-app-image1" />
              <Image
                src={getAppImage2}
                className="position-relative get-app-image2"
              />
              <Image
                src={getAppImage3}
                className="position-relative get-app-image3"
              />
            </Col>
          </Row>
        </div>

        <div
          style={{ backgroundColor: "#662666" }}
          className="p-5 register-free-footer__home"
        >
          <Col lg="8" md="7" sm="12">
            <h4
              style={{
                color: "var(--secondary-color)",
                fontSize: "45px",
                fontWeight: "400",
              }}
            >
              YOU WILL DECIDE WHO CAN SEE YOU
            </h4>
            <p style={{ color: "#FFF", fontSize: "18px", marginTop: "15px" }}>
              You are free to handle you profiles, you can decide who will be
              seeing your profile. Considering your privacy and confidentiality,
              your prior consent will be sought out before sharing profiles and
              details with matching profiles.
            </p>
            <p style={{ color: "#FFF", fontSize: "18px", paddingLeft: "20px" }}>
              • Protect your photos{" "}
            </p>
            <p style={{ color: "#FFF", fontSize: "18px", paddingLeft: "20px" }}>
              • Restrict the access to your contact details{" "}
            </p>
            <p style={{ color: "#FFF", fontSize: "18px", paddingLeft: "20px" }}>
              • You can also set category of people with whom you wish to share
              your profile
            </p>
            <div className="mt-5 mb-5">
              <Link
                to="/register"
                className="d-flex justify-content-center p-3"
                style={{
                  backgroundColor: "var(--secondary-color)",
                  border: "none",
                  color: "var(--primary-color)",
                  width: "224px",
                  borderRadius: "100px",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
              >
                Register Free
              </Link>
            </div>
          </Col>
          <Col lg="5" md="5" sm="12">
            <Image
              src={homeImage3}
              style={{ width: "75%", padding: "0", margin: "auto" }}
            />
          </Col>
        </div>
      </Container>
      <Footer></Footer>
    </div>
  );
}
