import { environment } from "../environment/env"

const baseURL = environment.hostname

let reportProfileService = async (profile,reason,remark) => {
    return await fetch(`${baseURL}/reportprofile`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uid: localStorage.currentUser,
            to_id: profile,
            reason: reason,
            remark: remark,
            check_user_id: localStorage.currentUser,
            check_session_id: localStorage.session
        }),
    })
}

export default reportProfileService