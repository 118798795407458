export const environment = {
  production: false,
  hostname: "https://click4marry.com/app/api/webservice_api",
  imageURL: "https://click4marry.com/app/uploads/face_orig/",
  albumURL: "https://click4marry.com/app/uploads/album/",
  storeImageURL: "https://click4marry.com/app/uploads/store/",
  storyImageURL: "https://click4marry.com/app/uploads/stories/",
  chatImageURL: "https://click4marry.com/app/uploads/chat/",
  playStoreURL:
    "https://play.google.com/store/apps/details?id=com.app.click4marry",
  appStoreURL: " https://apps.apple.com/in/app/click4marry/id1613457079",
  homeBanner: "https://click4marry.com/app/uploads/banners/",
  termsURL: "https://click4marry.com/app/home/privacy",
  notificationImageUrl: "https://click4marry.com/app/uploads/notification/",
};
