import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FeedbackComponent, Footer, NavbarMenu } from '../../Components'

export default function Feedback() {
    let navigate = useNavigate()

    useEffect(() => {
        if (!localStorage.currentUser && !localStorage.session) navigate('/contact')
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])

let goback= () => {
    navigate('/')
}

    return (
        <div>
            <NavbarMenu></NavbarMenu>
            <div className='d-flex justify-content-center' style={{ marginTop: '155px', marginBottom: '155px' }}>
                <FeedbackComponent navg={goback} ></FeedbackComponent>
            </div>
            <Footer></Footer>
        </div>
    )
}
