import React, { Component } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { filterIcon } from "../../assets";
import "./Filter.css";

export default class Filter extends Component {
  render() {
    return (
      <Card className="filter-card m-0">
        <Row className="card-head">
          <Col xs={1}>
            <Image src={filterIcon} />
          </Col>
          <Col>
            <Card.Title style={{ fontSize: "1.5rem" }}>Filters</Card.Title>
          </Col>
          <Col className="clear-filter d-flex justify-content-end">
            Clear All
          </Col>
        </Row>
        <Card.Body className="p-0 filter-options">
          <h5 className="filter-label">Photo Status</h5>
          <div className="pb-4">
            <Form.Check type="checkbox" label="All" id="all" />
            <Form.Check
              type="checkbox"
              label="Profile with photos only"
              id="with-photos"
              onChange={(e) =>
                this.props.filter(
                  "Photo status",
                  "face_image",
                  e.target.checked
                )
              }
            />
          </div>
          <h5 className="filter-label">Physical Status</h5>
          <div className="pb-4">
            Age
            <div className="d-flex justify-content-around mt-2 mb-3">
              <Form.Control type="number" className="w-25" />
              to
              <Form.Control type="number" className="w-25" />
            </div>
            Height
            <div className="d-flex justify-content-around mt-2 mb-3">
              <Form.Control type="number" className="w-25" />
              to
              <Form.Control type="number" className="w-25" />
            </div>
            Weight
            <div className="d-flex justify-content-around mt-2 mb-3">
              <Form.Control type="number" className="w-25" />
              to
              <Form.Control type="number" className="w-25" />
            </div>
          </div>
          <h5 className="filter-label">Education</h5>
          <div className="pb-4">
            <Form.Check
              type="checkbox"
              label="Bachelors (Arts)"
              id="arts"
              onChange={(e) =>
                this.props.filter(
                  "Education",
                  "qual",
                  e.target.checked,
                  "Bachelors (Arts)"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Bachelors (Commerce)"
              id="Commerce"
              onChange={(e) =>
                this.props.filter(
                  "Education",
                  "qual",
                  e.target.checked,
                  "Bachelors (Commerce)"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Bachelors (Computers)"
              id="Computers"
              onChange={(e) =>
                this.props.filter(
                  "Education",
                  "qual",
                  e.target.checked,
                  "Bachelors (Computers)"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Bachelors (Engineering)"
              id="Engineering"
              onChange={(e) =>
                this.props.filter(
                  "Education",
                  "qual",
                  e.target.checked,
                  "Bachelors (Engineering)"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Bachelors (Islamics)"
              id="Islamics"
              onChange={(e) =>
                this.props.filter(
                  "Education",
                  "qual",
                  e.target.checked,
                  "Bachelors (Islamics)"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Bachelors (Legal)"
              id="Legal"
              onChange={(e) =>
                this.props.filter(
                  "Education",
                  "qual",
                  e.target.checked,
                  "Bachelors (Legal)"
                )
              }
            />
          </div>
          <h5 className="filter-label">Job/Occupation</h5>
          <div className="pb-4">
            <Form.Check
              type="checkbox"
              label="Accounts/Finance"
              id="Accounts/Finance"
              onChange={(e) =>
                this.props.filter(
                  "Job/Occupation",
                  "occu",
                  e.target.checked,
                  "Accounts/Finance"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Actor"
              id="Actor"
              onChange={(e) =>
                this.props.filter(
                  "Job/Occupation",
                  "occu",
                  e.target.checked,
                  "Actor"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Administrative"
              id="Administrative"
              onChange={(e) =>
                this.props.filter(
                  "Job/Occupation",
                  "occu",
                  e.target.checked,
                  "Administrative"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Advertising / PR Professional"
              id="Advertising / PR Professional"
              onChange={(e) =>
                this.props.filter(
                  "Job/Occupation",
                  "occu",
                  e.target.checked,
                  "Advertising / PR Professional"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Advocate"
              id="Advocate"
              onChange={(e) =>
                this.props.filter(
                  "Job/Occupation",
                  "occu",
                  e.target.checked,
                  "Advocate"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Agriculture"
              id="Agriculture"
              onChange={(e) =>
                this.props.filter(
                  "Job/Occupation",
                  "occu",
                  e.target.checked,
                  "Agriculture"
                )
              }
            />
          </div>
          <h5 className="filter-label">Family Status</h5>
          <div className="pb-4">
            <Form.Check
              type="checkbox"
              label="Lower Middle Class"
              id="Lower Middle Class"
              onChange={(e) =>
                this.props.filter(
                  "Family Status",
                  "family",
                  e.target.checked,
                  "Lower Middle Class"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Middle Class"
              id="Middle Class"
              onChange={(e) =>
                this.props.filter(
                  "Family Status",
                  "family",
                  e.target.checked,
                  "Middle Class"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Upper Middle Class"
              id="Upper Middle Class"
              onChange={(e) =>
                this.props.filter(
                  "Family Status",
                  "family",
                  e.target.checked,
                  "Upper Middle Class"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Rich"
              id="Rich"
              onChange={(e) =>
                this.props.filter(
                  "Family Status",
                  "family",
                  e.target.checked,
                  "Rich"
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="Poor"
              id="Poor"
              onChange={(e) =>
                this.props.filter(
                  "Family Status",
                  "family",
                  e.target.checked,
                  "Poor"
                )
              }
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              style={{
                backgroundColor: "var(--primary-color)",
                border: "none",
              }}
            >
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
