import React from 'react';
import { FaUserEdit } from "react-icons/fa";
import './Edit.css'


export default function Edit(props) {
    let sendEditDetails = (event) => {
        props.edit(props.title);
        event.preventDefault();
    }
    return <div className='edit w-auto' onClick={sendEditDetails}>
        <FaUserEdit color='var(--secondary-color)' size="17.5"></FaUserEdit>
        <span style={{ marginLeft: '5px' }}>Edit</span>
    </div>
}
