import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Footer, Loader, NavbarMenu } from '../../Components'
import { environment } from '../../environment/env'
import './PlanHistory.css'

const baseURL = environment.hostname;

export default function PlanHistory() {
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        fetch(`${baseURL}/planhistory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: localStorage.currentUser
            })
        }).then(res => res.json()).then(res => {
            setPlans(res)
            setLoading(false)
        })
    }, [loading])
    return (
        <div>
            <NavbarMenu></NavbarMenu>
            <div className='plan-history'>
                {!loading ?
                    <>
                        {plans.map((plan, i) => (
                            <Card key={i} className='history-card mb-4'>
                                <Row>
                                    <Col className='d-flex flex-column justify-content-around col-lg-3 col-12 mb-3' style={{ fontSize: '24px', fontWeight: '800' }}>
                                        <div>&#8377;{plan.pay_amount}</div>
                                        <div>{plan.planname}</div>
                                    </Col>
                                    <Col style={{ color: '#5B6770' }} className='col-lg-3 col-12 mb-3'>
                                        <h6 style={{ color: '#000' }}>Validity</h6>
                                        <div>Duration: {plan.duration}</div>
                                        <div>Balance: {plan.balancedays}</div>
                                    </Col>
                                    <Col style={{ color: '#5B6770' }} className='col-lg-3 col-12 mb-3'>
                                        <h6 style={{ color: '#000' }}>Contact</h6>
                                        <div>Allowed: {plan.contacts_allowed}</div>
                                        <div>Viewed: {plan.contacts_allowed - plan.balancecontact}</div>
                                        <div>Balance: {plan.balancecontact}</div>
                                    </Col>
                                    <Col style={{ color: '#5B6770' }} className='col-lg-3 col-12'>
                                        <h6 style={{ color: '#000' }}>Status</h6>
                                        <div>Activated on: {plan.pdate}</div>
                                        {/* <div>Expiry on: {new Date(plan.pdate)}</div> */}
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                    </>
                    :
                    <Loader />
                }

            </div>
            <Footer></Footer>
        </div>
    )
}
