import { Field, Formik, useField, useFormikContext } from "formik";
import React, { Component } from "react";
import { Button, Card, Col, Form, Image, Modal, Row } from "react-bootstrap";
import SocialButton from "../SocialButton/SocialButton";
import OTPInput, { ResendOTP } from "otp-input-react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import makeAnimated from "react-select/animated";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import Select from "react-select";
import { environment } from "../../environment/env";
import NavigateTo from "../Navigate/NavigateTo";
import { toast } from "react-toastify";
import { banner2 } from "../../assets";
import { Loader } from "..";
import "./RegisterCard.css";

const animatedComponents = makeAnimated();

const baseURL = environment.hostname;
let newArray = [];
let listValues,
  countries,
  states,
  districts,
  l_countries,
  l_states,
  l_districts,
  p_states,
  p_districts,
  religions,
  prefixList,
  filteredReligions,
  allegiances;
let ageOptions = [],
  heightOptions = [],
  weightOptions = [],
  siblingOptions = [],
  brothersMarriedOptions = [],
  sistersMarriedOptions = [],
  registeredValues = {};

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(props);

  return (
    <DatePicker
      {...props}
      dayPlaceholder="dd"
      monthPlaceholder="mm"
      yearPlaceholder="yyyy"
      format="dd-MM-yyyy"
      minDate={
        new Date(
          new Date().getFullYear() - 100,
          new Date().getMonth(),
          new Date().getDate()
        )
      }
      maxDate={
        new Date(
          new Date().getFullYear() - 18,
          new Date().getMonth(),
          new Date().getDate()
        )
      }
      selected={
        field.value
          ? new Date(field.value)
          : new Date(
              new Date().getFullYear() - 18,
              new Date().getMonth(),
              new Date().getDate()
            )
      }
      // selected={field.value ? new Date(field.value) : null}
      value={(field.value && new Date(field.value)) || null}
      onChange={(date) => {
        if (
          date &&
          date.getFullYear() <= new Date().getFullYear() - 18 &&
          date.getFullYear() >= new Date().getFullYear() - 100
        ) {
          setFieldValue(field.name, date);
          helpers.setValue(date);
        } else {
          toast.error("Age must be between 18 and current year");
          helpers.setValue(null);
        }
      }}
      readOnly
      inputValueFormat="dd-MM-yyyy"
    />
  );
};

// const DatePickerField = ({ ...props }) => {
//   const { setFieldValue } = useFormikContext();
//   const [field] = useField(props);

//   return (
//     <DatePicker
//       {...props}
//       dayPlaceholder="dd"
//       monthPlaceholder="mm"
//       yearPlaceholder="yyyy"
//       format="dd-MM-yyyy"
//       maxDate={new Date()}
//       selected={
//         field.value
//           ? new Date(field.value)
//           : new Date(
//               new Date().getFullYear() - 18,
//               new Date().getMonth(),
//               new Date().getDate()
//             )
//       }
//       value={(field.value && new Date(field.value)) || null}
//       onChange={(date) => {
//         if (date && date.getFullYear() <= new Date().getFullYear() - 18) {
//           setFieldValue(field.name, date);
//         } else {
//           toast.error("Age must be 18 or above");
//         }
//       }}
//     />
//   );
// };

// const DatePickerField = ({ ...props }) => {
//   const { setFieldValue } = useFormikContext();
//   const [field] = useField(props);
//   return (
//     <DatePicker
//       {...props}
//       dayPlaceholder="dd"
//       monthPlaceholder="mm"
//       yearPlaceholder="yyyy"
//       format="dd-MM-y"
//       maxDate={
//         new Date(
//           new Date().getFullYear() - 18,
//           new Date().getMonth(),
//           new Date().getDate()
//         )
//       }
//       value={(field.value && new Date(field.value)) || null}
//       onChange={(val) => {
//         setFieldValue(field.name, val);
//       }}
//     />
//   );
// };

export default class RegisterCard extends Component {
  filterSelecter(id, regil) {
    // //;
    let x = regil.map((item) => ({
      ...item,
      selected: item.id === id,
    }));
    let y = x.filter((item) => item.selected === true)[0];
    if (y?.name == regil[1].name) {
      this.getOptions(y, "religion", y);
    }
    return x;
  }

  _isMounted = false;

  constructor(props) {
    super(props);
    ageOptions = [];
    for (let i = 18; i <= 100; i++) {
      ageOptions.push(i);
    }
    heightOptions = [];
    for (let i = 135; i <= 215; i++) {
      heightOptions.push(i);
    }
    weightOptions = [];
    for (let i = 35; i <= 120; i++) {
      weightOptions.push(i);
    }

    siblingOptions = [];
    for (let i = 0; i <= 20; i++) {
      siblingOptions.push(i);
    }

    this.state = {
      showInstaModal: false,
      socialLink: false,
      ageFrom: 18,
      ageTo: 100,
      heightFrom: 135,
      heightTo: 215,
      weightFrom: 35,
      weightTo: 120,
      showPassword: false,
      sameAddress: false,
      isEmailExist: false,
      sameWhatsapp: false,
      hideAllegiance: true,
      submitting: false,
      navigate: false,
      showSuccessComplete: false,
      otp: "",
      isLoading: true,
      title: "Free Registration",
      physicallyChallenged: false,
      initialValues: {
        profile_for:
          this.props?.values?.profile_for || registeredValues.profile_for || "",
        name: this.props?.values?.name || registeredValues.name || "",
        gender: this.props?.values?.gender || registeredValues.gender || "",
        phone: this.props?.values?.phone || registeredValues.phone || "",
        password:
          this.props?.values?.password || registeredValues.password || "",
        refer_code: this.props?.values?.refer || this.props.refer || "",
        referral_code: this.props?.values?.refrral || this.props.referral || "",
      },
      names: ["profile_for", "name", "gender", "phone", "password"],
      fields: [
        {
          label: "Profile For *",
          required: true,
          type: "select",
          listValue: "profile_created_for",
        },
        {
          label: "Name of Bride/Groom *",
          required: true,
          type: "text",
          validation: "text",
        },
        {
          label: "Gender *",
          required: true,
          type: "select",
          listValue: "gender",
        },
        { label: "Mobile Number *", required: true },
        { label: "Password *", required: true, type: "password" },
        { label: "I agree to the Terms & Conditions", type: "checkbox" },
      ],
      mobile: "",
      isFatherAlive: null,
      isMotherAlive: null,
      noOfBrothers: "",
      noOfBrothersMarried: null,
      noOfSisters: "",
      noOfSistersMarried: null,
      isOrphan: null,
      maritialSelected: "",
    };
    var selectedHobbies = [];
  }

  componentDidMount() {
    this._isMounted = true;

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (this.props.values) this.next(this.props.values);

    let listValueApi = fetch(`${baseURL}/list_value`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let countryApi = fetch(`${baseURL}/get_country`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let stateApi = fetch(`${baseURL}/getstate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let districtApi = fetch(`${baseURL}/getdistrict`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let religionApi = fetch(`${baseURL}/religion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let prefixListApi = fetch(`${baseURL}/prefixlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let allegianceApi = fetch(`${baseURL}/allegiance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    Promise.all([
      listValueApi,
      countryApi,
      stateApi,
      districtApi,
      religionApi,
      prefixListApi,
      allegianceApi,
    ])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        listValues = response[0][0];
        countries = response[1];
        states = response[2];
        districts = response[3];
        l_countries = countries;
        l_states = states;
        l_districts = districts;
        p_states = states;
        p_districts = districts;
        religions = response[4];
        prefixList = response[5];
        allegiances = response[6];
        this.setState({
          isLoading: false,
        });

        var mother_occupation_list = [];

        mother_occupation_list.push({
          id: "90",
          parent_id: "21",
          name: "Not working",
        });
        for (var i = 0; i < listValues.occupation.length; i++) {
          if (listValues.occupation[i].id != "90") {
            mother_occupation_list.push(listValues.occupation[i]);
          }
        }

        listValues["mother_occupation"] = mother_occupation_list;

        // set Doesnt matter to first
        var sortedSmokeStatus = [];
        sortedSmokeStatus.push(...listValues.smoke_status);
        var otherIndexSmokeStatus = sortedSmokeStatus.findIndex((x) =>
          x.name.toString().toLowerCase().includes("matter")
        );
        if (otherIndexSmokeStatus > -1) {
          sortedSmokeStatus.splice(otherIndexSmokeStatus, 1);
          sortedSmokeStatus.unshift(
            listValues.smoke_status[otherIndexSmokeStatus]
          );
        }
        listValues["smoke_status"] = sortedSmokeStatus;

        // set Doesnt matter to first
        var sorteddrinkStatus = [];
        sorteddrinkStatus.push(...listValues.drink_status);
        var otherIndexDrinkStatus = sorteddrinkStatus.findIndex((x) =>
          x.name.toString().toLowerCase().includes("matter")
        );
        if (otherIndexDrinkStatus > -1) {
          sorteddrinkStatus.splice(otherIndexDrinkStatus, 1);
          sorteddrinkStatus.unshift(
            listValues.drink_status[otherIndexDrinkStatus]
          );
        }
        listValues["drink_status"] = sorteddrinkStatus;

        // set other to last
        var sortedQualification = [];
        sortedQualification.push(...listValues.qualification);
        var otherIndexQualification = sortedQualification.findIndex((x) =>
          x.name.toString().toLowerCase().includes("other")
        );
        if (otherIndexQualification > -1) {
          sortedQualification.splice(otherIndexQualification, 1);
          sortedQualification.push(
            listValues.qualification[otherIndexQualification]
          );
        }
        listValues["qualification"] = sortedQualification;

        // set other to last
        var sortedjob = [];
        sortedjob.push(...listValues.occupation);
        var otherIndexjobs = sortedjob.findIndex((x) =>
          x.name.toString().toLowerCase().includes("other")
        );
        if (otherIndexjobs > -1) {
          sortedjob.splice(otherIndexjobs, 1);
          sortedjob.push(listValues.occupation[otherIndexjobs]);
        }
        listValues["occupation"] = sortedjob;

        // set other to last
        var sortedmotheroccupation = [];
        sortedmotheroccupation.push(...listValues.mother_occupation);
        var otherIndexmotheroccupation = sortedmotheroccupation.findIndex((x) =>
          x.name.toString().toLowerCase().includes("other")
        );
        if (otherIndexmotheroccupation > -1) {
          sortedmotheroccupation.splice(otherIndexmotheroccupation, 1);
          sortedmotheroccupation.push(
            listValues.mother_occupation[otherIndexmotheroccupation]
          );
        }
        listValues["mother_occupation"] = sortedmotheroccupation;

        this.setState({});

        this.handleIncompleteRegistration();
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  next = (values) => {
    this.props.startRegister();
    this.setState({ submitting: true });
    if (this.state.title === "Free Registration") {
      switch (values.profile_for) {
        case "107":
          values.createdBy = "60";
          break;
        case "108":
          values.createdBy = "61";
          break;
        case "111":
          values.createdBy = "61";
          break;
        case "112":
          values.createdBy = "65";
          break;
        case "113":
          values.createdBy = "65";
          break;
        case "114":
          values.createdBy = "64";
          break;
        case "115":
          values.createdBy = "62";
          break;
        default:
          values.createdBy = "1";
          break;
      }

      if (!values.country_code) values.country_code = "+91";
      values.platform = "0";
      fetch(`${baseURL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          country_code: values.country_code,
          platform: "0",
          profile_for: values.profile_for,
          profile_created_by: values.createdBy,
          name: values.name,
          gender: values.gender,
          phone: values.phone,
          password: values.password,
          refer_code: this.props.refer || "",
          referral_code: this.props.referral || "",
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ submitting: false });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          if (response.data.id) {
            this.state.names?.map(
              (name) => (registeredValues[name] = values[name])
            );
            localStorage.setItem("currentUser", response.data.id);
            localStorage.setItem("code", response?.data?.refer_code);
            localStorage.setItem("user_name", response?.data?.name);
            this.setState({
              title: "OTP Verification",
              mobile: values.phone,
            });
          } else if (response.status === 0) {
            toast.error(response.message);
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          this.setState({ submitting: false });
          toast.error("An error occured");
        });
    } else if (this.state.title === "Basic Information") {
      if (this.state.isEmailExist) {
        toast.error("Email id already exists");
        this.setState({ submitting: false });
        return;
      }

      values.id = localStorage.currentUser;
      values.session = localStorage.session;
      values.mother_tongue = "";
      values.marital_status = this.state.maritialSelected;
      try {
        let formatted_date =
          values.dob.getFullYear() +
          "-" +
          (values.dob.getMonth() + 1) +
          "-" +
          values.dob.getDate();
        values.dob = formatted_date;
      } catch (e) {}

      fetch(`${baseURL}/basic_info_register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((response) => {
          //;
          this.setState({ submitting: false });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });

          if (response.status === 1) {
            this.state.names?.map(
              (name) => (registeredValues[name] = values[name])
            );
            this.setState({
              title: "Contact Information",
              initialValues: {
                care_of: registeredValues?.care_of || "",
                mother_name: registeredValues?.mother_name || "",
                house: registeredValues?.house || "",
                street: registeredValues?.street || "",
                panchayath: registeredValues?.panchayath || "",
                country: registeredValues?.country || "",
                state: registeredValues?.state || "",
                district: registeredValues?.district || "",
                landmark: registeredValues?.landmark || "",
                pincode: registeredValues?.pincode || "",
                whatsapp: registeredValues?.whatsapp || "",
                facebook_id: registeredValues?.facebook_id || "",
                instagramme_id: registeredValues?.instagramme_id || "",
                phone: registeredValues?.phone || "",
                alternate_number: registeredValues?.alternate_number || "",
                contact_person: registeredValues?.contact_person || "",
                l_house: registeredValues?.l_house || "",
                l_street: registeredValues?.l_street || "",
                l_panchayath: registeredValues?.l_panchayath || "",
                l_country: registeredValues?.l_country || "",
                l_state: registeredValues?.l_state || "",
                l_district: registeredValues?.l_district || "",
                l_landmark: registeredValues?.l_landmark || "",
                l_pincode: registeredValues?.l_pincode || "",
              },
              names: [
                "care_of",
                "mother_name",
                "house",
                "street",
                "panchayath",
                "country",
                "state",
                "district",
                "landmark",
                "pincode",
                "whatsapp",
                "facebook_id",
                "instagramme_id",
                // "phone",
                "alternate_number",
                "contact_person",
                "l_house",
                "l_street",
                "l_panchayath",
                "l_country",
                "l_state",
                "l_district",
                "l_landmark",
                "l_pincode",
              ],
              fields: [
                {
                  label: "Father's Name *",
                  required: true,
                  type: "text",
                  validation: "text",
                },
                { label: "Mother's Name *", type: "text", validation: "text" },

                {
                  label: "House Name/House Number *",
                  required: true,
                  type: "text",
                },
                { label: "Street *", required: true, type: "text" },
                { label: "Muncipality/Panchayath", type: "text" },
                {
                  label: "Country *",
                  required: true,
                  type: "select",
                  options: countries,
                },
                {
                  label: "State *",
                  required: true,
                  type: "select",
                  options: states,
                },
                {
                  label: "District *",
                  required: true,
                  type: "select",
                  options: districts,
                },
                { label: "Landmark ", type: "text" },
                {
                  label: "Pin *",
                  required: true,
                  type: "text",
                  validation: "number",
                },
                { label: "Whatsapp", type: "text" },
                { label: "Facebook", type: "text" },
                { label: "Instagram", type: "text" },
                // { label: "Mobile *", required: true, type: "text" },
                { label: "Alternative number", type: "text" },
                {
                  label: "Contact person",
                  type: "select",
                  listValue: "contact_person",
                },
                {
                  label: "House Name/House Number *",
                  required: true,
                  type: "text",
                  hidden: this.state.sameAddress,
                },
                {
                  label: "Street *",
                  required: true,
                  type: "text",
                  hidden: this.state.sameAddress,
                },

                {
                  label: "Muncipality/Panchayath",
                  type: "text",
                  hidden: this.state.sameAddress,
                },
                {
                  label: "Country *",
                  required: true,
                  type: "select",
                  options: l_countries,
                  hidden: this.state.sameAddress,
                },
                {
                  label: "State *",
                  required: true,
                  type: "select",
                  options: l_states,
                  hidden: this.state.sameAddress,
                },
                {
                  label: "District *",
                  required: true,
                  type: "select",
                  options: l_districts,
                  hidden: this.state.sameAddress,
                },
                {
                  label: "Landmark ",
                  // required: true,
                  type: "text",
                  hidden: this.state.sameAddress,
                },
                {
                  label: "Pin",
                  required: true,
                  type: "text",
                  validation: "number",
                  hidden: this.state.sameAddress,
                },
              ],
            });
          } else if (response?.status === 0) {
            toast.error(response?.message);
            this.setState({ submitting: true });
          }
        })
        .catch((error) => toast.error("An error occured in API"));
    } else if (this.state.title === "Contact Information") {
      if (this.state.sameAddress) {
        let living_country = countries.find(
          (country) => country.id === values.country
        ).name;
        let living_state = states.find(
          (state) => state.id === values.state
        ).name;
        let living_district = districts.find(
          (district) => district.id === values.district
        ).name;
        values.living_address = `${values.house}, ${values.street}, ${values.panchayath}, ${living_country}, ${living_state}, ${living_district}, ${values.landmark}, ${values.pincode}`;
      } else {
        let living_country = l_countries.find(
          (country) => country.id === values.l_country
        ).name;
        let living_state = l_states.find(
          (state) => state.id === values.l_state
        ).name;
        let living_district = l_districts.find(
          (district) => district.id === values.l_district
        ).name;
        values.living_address = `${values.l_house}, ${values.l_street}, ${values.l_panchayath}, ${living_country}, ${living_state}, ${living_district}, ${values.l_landmark}, ${values.l_pincode}`;
      }
      values.id = localStorage.currentUser;
      values.session = localStorage.session;
      values.country_code = "+91";
      fetch(`${baseURL}/contact_info_register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((response) => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.setState({ submitting: false });
          if (response.status === 1) {
            this.state.names?.map(
              (name) => (registeredValues[name] = values[name])
            );
            this.setState({
              title: "Physical Info",
              initialValues: {
                height: registeredValues?.height || "",
                weight: registeredValues?.weight || "",
                body_type: registeredValues?.body_type || "",
                physical_status: registeredValues?.physical_status || "",
                complexion: registeredValues?.complexion || "",
                blood: registeredValues?.blood || "",
              },
              names: [
                "height",
                "weight",
                "body_type",
                "physical_status",
                "complexion",
                "blood",
              ],
              fields: [
                {
                  label: "Height *",
                  required: true,
                  type: "select",
                  options: heightOptions,
                },
                {
                  label: "Weight *",
                  required: true,
                  type: "select",
                  options: weightOptions,
                },
                { label: "Body type", type: "select", listValue: "body_type" },
                // {
                //   label: "Physically Challenged",
                //   type: "select",
                //   listValue: ["Yes", "No"],
                // },
                {
                  label: "Physical status",
                  type: "select",
                  listValue: "physical_status",
                },
                {
                  label: "Complexion",
                  type: "select",
                  listValue: "complexion",
                },
                { label: "Blood", type: "select", listValue: "blood_group" },
              ],
            });
          }
        });
    } else if (this.state.title === "Physical Info") {
      if (this.state.physicallyChallenged === false) {
        values.physical_status = "30";
      }

      values.id = localStorage.currentUser;
      values.session = localStorage.session;
      fetch(`${baseURL}/Physical_info_register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((response) => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.setState({ submitting: false });
          if (response.status === 1) {
            this.state.names?.map(
              (name) => (registeredValues[name] = values[name])
            );
            this.setState({
              title: "Job and Education",
              initialValues: {
                qualification: registeredValues?.qualification || "",
                course: registeredValues?.course || "",
                institute: registeredValues?.institute || "",
                additional_qual: registeredValues?.additional_qual || "",
                occupation: registeredValues?.occupation || "",
                sector: registeredValues?.sector || "",
                company: registeredValues?.company || "",
                job_details: registeredValues?.job_details || "",
                annual_income: registeredValues?.annual_income || "",
              },
              names: [
                "qualification",
                "course",
                "institute",
                "additional_qual",
                "occupation",
                "sector",
                "company",
                "job_details",
                "annual_income",
              ],
              fields: [
                {
                  label: "Qualification *",
                  required: true,
                  type: "select",
                  listValue: "qualification",
                },
                {
                  label: "Course *",
                  required: true,
                  options: registeredValues?.qualification
                    ? this.getOptions(
                        registeredValues?.qualification,
                        "qualification",
                        undefined
                      )
                    : [],
                  type: "select",
                },
                { label: "Institute/College", validation: "nondigits" },
                { label: "Additional qualifications if any" },
                {
                  label: "Occupation *",
                  required: true,
                  type: "select",
                  listValue: "mother_occupation",
                },
                {
                  label: "Employed in",
                  required: false,
                  type: "select",
                  listValue: "job_sector",
                },
                { label: "Company/Institution" },
                { label: "Job Details " },
                { label: "Annual Income", type: "select", listValue: "income" },
              ],
            });
          }
        });
    } else if (this.state.title === "Job and Education") {
      values.id = localStorage.currentUser;
      values.session = localStorage.session;

      fetch(`${baseURL}/job_info_register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ submitting: false });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          if (response.status === 1) {
            this.state.names?.map(
              (name) => (registeredValues[name] = values[name])
            );
            this.setState({
              title: "Thoughts/Lifestyle",
              initialValues: {
                religion: registeredValues?.religion || "",
                sub_group: registeredValues?.sub_group || "",
                allegiance: registeredValues?.allegiance || "",
                religiousness: registeredValues?.religiousness || "",
                hobbies: registeredValues?.hobbies || "",
                diet: registeredValues?.diet || "",
                drink: registeredValues?.drink || "",
                smoke: registeredValues?.smoke || "",
              },
              names: [
                "religion",
                "sub_group",
                "allegiance",
                "religiousness",
                "hobbies",
                "diet",
                "drink",
                "smoke",
              ],
              fields: [
                {
                  label: "Religion *",
                  required: true,
                  type: "select",
                  options: religions,
                },
                {
                  label: "Subgroup *",
                  required: true,
                  type: "select",
                  options: registeredValues?.religion
                    ? this.getOptions(
                        registeredValues?.religion_id,
                        "religion",
                        undefined
                      )
                    : [],
                },
                {
                  label: "Allegiance",
                  hidden: this.state.hideAllegiance,
                  type: "select",
                  options: allegiances,
                },
                {
                  label: "Religiousness",
                  type: "select",
                  listValue: "religiousness",
                },
                {
                  label: "Hobbies",
                  type: "select",
                  listValue: "hobby",
                  multipleSelect: true,
                },
                { label: "Diet", type: "select", listValue: "diet" },
                { label: "Drink", type: "select", listValue: "drink_status" },
                { label: "Smoke", type: "select", listValue: "smoke_status" },
              ],
            });
            let vall = registeredValues?.religion;
            let et = { target: { value: vall } };
            this.getOptions(et, "religion");
          }
        });
    } else if (this.state.title === "Thoughts/Lifestyle") {
      values.hobbies = this.bodyValue?.hobbies?.join("~");
      values.id = localStorage.currentUser;
      values.session = localStorage.session;
      localStorage.setItem("temp_reg_relegion", values.religion);

      fetch(`${baseURL}/thoughts_info_register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ submitting: false });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          if (response.status === 1) {
            this.state.names?.map(
              (name) => (registeredValues[name] = values[name])
            );

            this.setState({
              title: "Family Details",
              initialValues: {
                family_type: registeredValues?.family_type || "",
                family_status: registeredValues?.family_status || "",
                father_alive: registeredValues?.father_alive || "",
                father_occupation: registeredValues?.father_occupation || "",
                mother_alive: registeredValues?.mother_alive || "",
                mother_occupation: registeredValues?.mother_occupation || "",
                numbrother: registeredValues?.numbrother || "",
                numbrothers_married:
                  registeredValues?.numbrothers_married || "",
                num_sisters: registeredValues?.num_sisters || "",
                numsisters_married: registeredValues?.numsisters_married || "",
                isorphan: registeredValues?.isorphan || "",
              },
              names: [
                "family_type",
                "family_status",
                "father_alive",
                "father_occupation",
                "mother_alive",
                "mother_occupation",
                "numbrother",
                "numbrothers_married",
                "num_sisters",
                "numsisters_married",
                "isorphan",
              ],
              fields: [
                {
                  label: "Family Type",
                  type: "select",
                  listValue: "family_type",
                },
                {
                  label: "Family Status",
                  type: "select",
                  listValue: "family_status",
                },
                {
                  label: "Father Alive ",
                  type: "select",
                  options: ["Yes", "No"],
                },
                {
                  label: "Father's Occupation",
                  type: "select",
                  listValue: "mother_occupation",
                },
                {
                  label: "Mother Alive ",
                  type: "select",
                  options: ["Yes", "No"],
                },
                {
                  label: "Mother's Occupation ",
                  type: "select",
                  listValue: "mother_occupation",
                },
                {
                  label: "Number of Brothers",
                  type: "select",
                  options: siblingOptions,
                },
                {
                  label: "Number of Brothers Married",
                  type: "select",
                  options: siblingOptions,
                },
                {
                  label: "Number of Sisters",
                  type: "select",
                  options: siblingOptions,
                },
                {
                  label: "Number of Sisters Married",
                  type: "select",
                  options: siblingOptions,
                },
                { label: "Is Orphan", type: "select", options: ["Yes", "No"] },
              ],
            });
          }
        });
    } else if (this.state.title === "Family Details") {
      values.numbrother = this.state.noOfBrothers;
      // values.numbrothers_married = this.state.noOfBrothersMarried;
      values.num_sisters = this.state.noOfSisters;
      // values.numsisters_married = this.state.noOfSistersMarried;
      values.father_alive = this.state.isFatherAlive;
      values.mother_alive = this.state.isMotherAlive;
      values.isorphan = this.state.isOrphan;

      values.id = localStorage.currentUser;
      values.session = localStorage.session;

      fetch(`${baseURL}/family_info_register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ submitting: false });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          if (response.status === 1) {
            this.state.names?.map(
              (name) => (registeredValues[name] = values[name])
            );

            var partnerReligionList = [];
            partnerReligionList.push({ id: "", name: "Any" });
            religions.map((item) => {
              if (item.id == localStorage.getItem("temp_reg_relegion")) {
                partnerReligionList.push(item);
              }
            });

            this.setState({
              title: "Partner Preference",
              initialValues: {
                ageFrom: registeredValues?.ageFrom || "",
                ageTo: registeredValues?.ageTo || "",
                religion: registeredValues?.religion || "",
                subgroup: registeredValues?.subgroup || "",
                country_living: registeredValues?.country_living || "",
                state: registeredValues?.state || "",
                district: registeredValues?.district || "",
                qualification: registeredValues?.qualification || "",
                annual_income: registeredValues?.annual_income || "",
                occupation: registeredValues?.occupation || "",
                job_sector: registeredValues?.job_sector || "",
                marital_status: registeredValues?.marital_status || "",
                physical_status: registeredValues?.physical_status || "",
                complexion: registeredValues?.complexion || "",
                body_type: registeredValues?.body_type || "",
                diet: registeredValues?.diet || "",
                heightFrom: registeredValues?.heightFrom || "",
                heightTo: registeredValues?.heightTo || "",
                weightFrom: registeredValues?.weightFrom || "",
                weightTo: registeredValues?.weightTo || "",
                family_status: registeredValues?.family_status || "",
                smoke: registeredValues?.smoke || "",
                drink: registeredValues?.drink || "",
              },
              names: [
                "ageFrom",
                "ageTo",
                "religion",
                "subgroup",
                "country_living",
                "state",
                "district",
                "qualification",
                "annual_income",
                "occupation",
                "job_sector",
                "marital_status",
                "physical_status",
                "complexion",
                "body_type",
                "diet",
                "heightFrom",
                "heightTo",
                "weightFrom",
                "weightTo",
                "family_status",
                "smoke",
                "drink",
              ],
              fields: [
                { label: "Age From", type: "select", options: ageOptions },
                { label: "Age To", type: "select", options: ageOptions },
                {
                  label: "Religion",
                  type: "select",
                  options: partnerReligionList,
                },
                { label: "Subgroup", type: "select", options: [] },
                { label: "Country Living", type: "select", options: countries },
                { label: "State", type: "select", options: states },
                {
                  label: "District/Place",
                  type: "select",
                  options: districts,
                },
                {
                  label: "Qualification",
                  type: "select",
                  listValue: "qualification",
                },
                {
                  label: "Annual Income ",
                  type: "select",
                  listValue: "income",
                },
                {
                  label: "Occupation",
                  type: "select",
                  listValue: "mother_occupation",
                },
                {
                  label: "Sector",
                  type: "select",
                  listValue: "job_sector",
                },
                {
                  label: "Marital Status",
                  type: "select",
                  listValue: "marital_status",
                },
                {
                  label: "Physical Status",
                  type: "select",
                  listValue: "physical_status",
                },
                {
                  label: "Complexion",
                  type: "select",
                  listValue: "complexion",
                },
                { label: " Body Type", type: "select", listValue: "body_type" },
                { label: "Diet", type: "select", listValue: "diet" },
                {
                  label: "Height(cm) From",
                  type: "select",
                  options: heightOptions,
                },
                {
                  label: "Height(cm) To",
                  type: "select",
                  options: heightOptions,
                },
                {
                  label: "Weight(kg) From",
                  type: "select",
                  options: weightOptions,
                },
                {
                  label: "Weight(kg) To",
                  type: "select",
                  options: weightOptions,
                },
                {
                  label: "Family Status",
                  type: "select",
                  listValue: "family_status",
                },
                { label: "Smoke", type: "select", listValue: "smoke_status" },
                { label: "Drink", type: "select", listValue: "drink_status" },
              ],
            });
          }
        });
    } else if (this.state.title === "Partner Preference") {
      values.id = localStorage.currentUser;
      values.session = localStorage.session;

      let body = {};
      body.id = localStorage.currentUser;
      body.session = localStorage.session;
      body.age =
        values.ageFrom && values.ageTo
          ? `${values.ageFrom}-${values.ageTo}`
          : values.ageFrom
          ? `${values.ageFrom}-60`
          : values.ageTo
          ? `18-${values.ageTo}`
          : "18-60";
      body.religion = values.religion ? values.religion : "";
      body.subgroup = values.subgroup ? values.subgroup.join("~") : "";
      body.country_living = values.country_living
        ? values.country_living.join("~")
        : "";
      body.state = values.state ? values.state.join("~") : "";
      body.district = values.district ? values.district.join("~") : "";
      body.qualification = values.qualification
        ? values.qualification.join("~")
        : "";
      body.annual_income = values.annual_income
        ? values.annual_income.join("~")
        : "";
      body.occupation = values.occupation ? values.occupation.join("~") : "";
      body.job_sector = values.job_sector ? values.job_sector.join("~") : "";
      body.marital_status = values.marital_status
        ? values.marital_status.join("~")
        : "";
      body.physical_status = values.physical_status
        ? values.physical_status.join("~")
        : "";
      body.complexion = values.complexion ? values.complexion.join("~") : "";
      body.body_type = values.body_type ? values.body_type.join("~") : "";
      body.diet = values.diet ? values.diet.join("~") : "";
      body.height =
        values.heightFrom && values.heightTo
          ? `${values.heightFrom}-${values.heightTo}`
          : values.heightFrom
          ? `${values.heightFrom}-215`
          : values.heightTo
          ? `135-${values.heightTo}`
          : "135-215";
      body.weight =
        values.weightFrom && values.weightTo
          ? `${values.weightFrom}-${values.weightTo}`
          : values.weightFrom
          ? `${values.weightFrom}-120`
          : values.weightTo
          ? `35-${values.weightTo}`
          : "35-120";
      body.family_status = values.family_status
        ? values.family_status.join("~")
        : "";
      body.smoke = values.smoke ? values.smoke : "";
      body.drink = values.drink ? values.drink : "";

      fetch(`${baseURL}/partner_info_register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ submitting: false });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          if (response.status === 1) {
            this.state.names?.map(
              (name) => (registeredValues[name] = values[name])
            );
            this.setState({ showSuccessComplete: true });
            // this.setState({ navigate: true });
            this.clearAllRegisterData();
            localStorage.removeItem("temp_reg_relegion");
          }
        })
        .catch((error) => {
          toast.error("An eror occured in API");
        });
    }
  };

  previous = () => {
    if (this.state.title === "Contact Information") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState({
        title: "Basic Information",
        initialValues: {
          dob: registeredValues?.dob || "",
          marital_status: registeredValues?.marital_status || "",
          num_children: registeredValues?.num_children || "",
          email: registeredValues?.email || "",
        },
        names: ["dob", "marital_status", "num_children", "email"],
        fields: [
          { label: "D.O.B *", required: true, type: "date" },
          {
            label: "Marital Status *",
            required: true,
            type: "select",
            listValue: "marital_status",
          },
          { label: "Children", type: "text" },
          { label: "Email id", type: "text" },
        ],
      });
    } else if (this.state.title === "Physical Info") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState({
        title: "Contact Information",
        initialValues: {
          care_of: registeredValues?.care_of || "",
          mother_name: registeredValues?.mother_name || "",
          house: registeredValues?.house || "",
          street: registeredValues?.street || "",
          panchayath: registeredValues?.panchayath || "",
          country: registeredValues?.country || "",
          state: registeredValues?.state || "",
          district: registeredValues?.district || "",
          landmark: registeredValues?.landmark || "",
          pincode: registeredValues?.pincode || "",
          whatsapp: registeredValues?.whatsapp || "",
          facebook_id: registeredValues?.facebook_id || "",
          instagramme_id: registeredValues?.instagramme_id || "",
          phone: registeredValues?.phone || "",
          alternate_number: registeredValues?.alternate_number || "",
          country_code_watsapp: registeredValues?.country_code_watsapp || "",
          country_code_land: registeredValues?.country_code_land || "",
          contact_person: registeredValues?.contact_person || "",
          l_house: registeredValues?.l_house || "",
          l_street: registeredValues?.l_street || "",
          l_panchayath: registeredValues?.l_panchayath || "",
          l_country: registeredValues?.l_country || "",
          l_state: registeredValues?.l_state || "",
          l_district: registeredValues?.l_district || "",
          l_landmark: registeredValues?.l_landmark || "",
          l_pincode: registeredValues?.l_pincode || "",
        },
        names: [
          "care_of",
          "mother_name",
          "house",
          "street",
          "panchayath",
          "country",
          "state",
          "district",
          "landmark",
          "pincode",
          "whatsapp",
          "facebook_id",
          "instagramme_id",
          // "phone",
          "alternate_number",
          "contact_person",
          "l_house",
          "l_street",
          "l_panchayath",
          "l_country",
          "l_state",
          "l_district",
          "l_landmark",
          "l_pincode",
        ],
        fields: [
          {
            label: "Father's Name *",
            required: true,
            type: "text",
            validation: "text",
          },
          { label: "Mother's Name*", type: "text", validation: "text" },

          { label: "House Name/House Number *", required: true, type: "text" },
          { label: "Street *", required: true, type: "text" },
          { label: "Muncipality/Panchayath", type: "text" },
          {
            label: "Country *",
            required: true,
            type: "select",
            options: countries,
          },
          { label: "State *", required: true, type: "select", options: states },
          {
            label: "District *",
            required: true,
            type: "select",
            options: districts,
          },
          { label: "Landmark ", type: "text" },
          {
            label: "Pin *",
            required: true,
            type: "text",
            validation: "number",
          },
          { label: "Whatsapp", type: "text" },
          { label: "Facebook ID", type: "text" },
          { label: "Instagram ID", type: "text" },
          // { label: "Mobile *", required: true, type: "text" },
          { label: "Alternative number", type: "text" },
          {
            label: "Contact person",
            type: "select",
            listValue: "contact_person",
          },
          {
            label: "House Name/House Number *",
            required: true,
            type: "text",
            hidden: this.state.sameAddress,
          },
          {
            label: "Street *",
            required: true,
            type: "text",
            hidden: this.state.sameAddress,
          },

          {
            label: "Muncipality/Panchayath",
            type: "text",
            hidden: this.state.sameAddress,
          },
          {
            label: "Country *",
            required: true,
            type: "select",
            options: l_countries,
            hidden: this.state.sameAddress,
          },
          {
            label: "State *",
            required: true,
            type: "select",
            options: l_states,
            hidden: this.state.sameAddress,
          },
          {
            label: "District *",
            required: true,
            type: "select",
            options: l_districts,
            hidden: this.state.sameAddress,
          },
          {
            label: "Landmark ",
            // required: true,
            type: "text",
            hidden: this.state.sameAddress,
          },
          {
            label: "Pin *",
            required: true,
            type: "text",
            validation: "number",
            hidden: this.state.sameAddress,
          },
        ],
      });
    } else if (this.state.title === "Job and Education") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState({
        title: "Physical Info",
        initialValues: {
          height: registeredValues?.height || "",
          weight: registeredValues?.weight || "",
          body_type: registeredValues?.body_type || "",
          physical_status: registeredValues?.physical_status || "",
          complexion: registeredValues?.complexion || "",
          blood: registeredValues?.blood || "",
        },
        names: [
          "height",
          "weight",
          "body_type",
          "physical_status",
          "complexion",
          "blood",
        ],
        fields: [
          {
            label: "Height *",
            required: true,
            type: "select",
            options: heightOptions,
          },
          {
            label: "Weight *",
            required: true,
            type: "select",
            options: weightOptions,
          },
          { label: "Body type", type: "select", listValue: "body_type" },
          {
            label: "Physical status",
            type: "select",
            listValue: "physical_status",
          },
          { label: "Complexion", type: "select", listValue: "complexion" },
          { label: "Blood", type: "select", listValue: "blood_group" },
        ],
      });
    } else if (this.state.title === "Thoughts/Lifestyle") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState({
        title: "Job and Education",
        initialValues: {
          qualification: registeredValues?.qualification || "",
          course: registeredValues?.course || "",
          institute: registeredValues?.institute || "",
          additional_qual: registeredValues?.additional_qual || "",
          occupation: registeredValues?.occupation || "",
          sector: registeredValues?.sector || "",
          company: registeredValues?.company || "",
          job_details: registeredValues?.job_details || "",
          annual_income: registeredValues?.annual_income || "",
        },
        names: [
          "qualification",
          "course",
          "institute",
          "additional_qual",
          "occupation",
          "sector",
          "company",
          "job_details",
          "annual_income",
        ],
        fields: [
          {
            label: "Qualification *",
            required: true,
            type: "select",
            listValue: "qualification",
          },
          {
            label: "Course *",
            required: true,
            options: registeredValues?.qualification
              ? this.getOptions(
                  registeredValues?.qualification,
                  "qualification",
                  undefined
                )
              : [],
            type: "select",
          },
          { label: "Institute/College", validation: "nondigits" },
          { label: "Additional qualifications if any" },
          {
            label: "Occupation *",
            required: true,
            type: "select",
            listValue: "mother_occupation",
          },
          {
            label: "Employed in",
            required: false,
            type: "select",
            listValue: "job_sector",
          },
          { label: "Company/Institution" },
          { label: "Job Details " },
          { label: "Annual Income", type: "select", listValue: "income" },
        ],
      });
    } else if (this.state.title === "Family Details") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState({
        title: "Thoughts/Lifestyle",
        initialValues: {
          religion: registeredValues?.religion || "",
          sub_group: registeredValues?.sub_group || "",
          allegiance: registeredValues?.allegiance || "",
          religiousness: registeredValues?.religiousness || "",
          hobbies: registeredValues?.hobbies?.split("~") || "",
          diet: registeredValues?.diet || "",
          drink: registeredValues?.drink || "",
          smoke: registeredValues?.smoke || "",
        },
        names: [
          "religion",
          "sub_group",
          "allegiance",
          "religiousness",
          "hobbies",
          "diet",
          "drink",
          "smoke",
        ],
        fields: [
          {
            label: "Religion *",
            required: true,
            type: "select",
            options: religions,
          },
          {
            label: "Subgroup *",
            required: true,
            type: "select",
            options: [],
          },
          {
            label: "Allegiance",
            hidden: this.state.hideAllegiance,
            type: "select",
            options: allegiances,
          },
          {
            label: "Religiousness",
            type: "select",
            listValue: "religiousness",
          },
          {
            label: "Hobbies",
            type: "select",
            listValue: "hobby",
            multipleSelect: true,
          },
          { label: "Diet", type: "select", listValue: "diet" },
          { label: "Drink", type: "select", listValue: "drink_status" },
          { label: "Smoke", type: "select", listValue: "smoke_status" },
        ],
      });
      let vall = registeredValues?.religion;
      let et = { target: { value: vall } };
      this.getOptions(et, "religion");
    } else if (this.state.title === "Partner Preference") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState({
        title: "Family Details",
        initialValues: {
          family_type: registeredValues?.family_type || "",
          family_status: registeredValues?.family_status || "",
          father_alive: registeredValues?.father_alive || "",
          father_occupation: registeredValues?.father_occupation || "",
          mother_alive: registeredValues?.mother_alive || "",
          mother_occupation: registeredValues?.mother_occupation || "",
          numbrother: registeredValues?.numbrother || "",
          numbrothers_married: registeredValues?.numbrothers_married || "",
          num_sisters: registeredValues?.num_sisters || "",
          numsisters_married: registeredValues?.numsisters_married || "",
          isorphan: registeredValues?.isorphan || "",
        },
        names: [
          "family_type",
          "family_status",
          "father_alive",
          "father_occupation",
          "mother_alive",
          "mother_occupation",
          "numbrother",
          "numbrothers_married",
          "num_sisters",
          "numsisters_married",
          "isorphan",
        ],
        fields: [
          { label: "Family Type", type: "select", listValue: "family_type" },
          {
            label: "Family Status",
            type: "select",
            listValue: "family_status",
          },
          { label: "Father Alive ", type: "select", options: ["Yes", "No"] },
          {
            label: "Father's Occupation",
            type: "select",
            listValue: "mother_occupation",
          },
          { label: "Mother Alive ", type: "select", options: ["Yes", "No"] },
          {
            label: "Mother's Occupation",
            type: "select",
            listValue: "mother_occupation",
          },
          {
            label: "Number of Brothers",
            type: "select",
            options: siblingOptions,
          },
          {
            label: "Number of Brothers Married",
            type: "select",
            options: siblingOptions,
          },
          {
            label: "Number of Sisters",
            type: "select",
            options: siblingOptions,
          },
          {
            label: "Number of Sisters Married",
            type: "select",
            options: siblingOptions,
          },
          { label: "Is Orphan", type: "select", options: ["Yes", "No"] },
        ],
      });
    }
  };

  resend = () => {
    fetch(`${baseURL}/otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          otp: "",
        });
      });
  };

  verify = () => {
    fetch(`${baseURL}/register_otp_verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        otp: this.state.otp,
        type: "register",
        refer_code: localStorage.recievedCode || "",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        if (response.status === 1) {
          localStorage.setItem("session", response?.data[0].session_id);
          localStorage.setItem("gender", response?.data[0].gender);
          localStorage.setItem("code", response?.data[0]?.refer_code);
          localStorage.setItem("user_name", response?.data[0]?.name);

          this.setState({
            title: "Basic Information",
            initialValues: {
              dob: registeredValues?.dob || "",
              marital_status: registeredValues?.marital_status || "",
              num_children: registeredValues?.num_children || "",
              email: registeredValues?.email || "",
            },
            names: ["dob", "marital_status", "num_children", "email"],
            fields: [
              { label: "D.O.B *", required: true, type: "date" },
              {
                label: "Marital Status *",
                required: true,
                type: "select",
                listValue: "marital_status",
              },
              { label: "Children", type: "text" },
              { label: "Email id", type: "text" },
            ],
          });
        } else if (response.status === 0) {
          toast.error("Incorrect OTP");
        } else {
          toast.error("An error occured.Please try again");
        }
      });
  };

  renderButton = (buttonProps) => {
    return (
      <button
        {...buttonProps}
        style={{
          color: "var(--primary-color)",
          border: "none",
          textDecoration: "underline",
          backgroundColor: "#FFF",
        }}
      >
        {buttonProps.remainingTime != 0
          ? `00:${buttonProps.remainingTime}`
          : "Resend"}
      </button>
    );
  };
  renderTime = () => React.Fragment;

  getOptions = (e, name, values) => {
    if (name === "qualification") {
      if (values) values.qualification = e.target.value;
      fetch(`${baseURL}/getcourse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: e.target?.value || e,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (this.state.title === "Job and Education") {
            this.setState({
              fields: [
                {
                  label: "Qualification *",
                  required: true,
                  type: "select",
                  listValue: "qualification",
                },
                {
                  label: "Course *",
                  required: true,
                  options: res,
                  type: "select",
                },
                { label: "Institute/College" },
                { label: "Additional qualifications if any" },
                {
                  label: "Occupation *",
                  required: true,
                  type: "select",
                  listValue: "mother_occupation",
                },
                {
                  label: "Employed in",
                  required: false,
                  type: "select",
                  listValue: "job_sector",
                },
                { label: "Company/Institution" },
                { label: "Job Details " },
                { label: "Annual Income", type: "select", listValue: "income" },
              ],
            });
          }
        });
    } else if (name === "religion") {
      if (
        this.state.title === "Thoughts/Lifestyle" ||
        this.state.title == "Family Details"
      ) {
        let hide = e?.target?.value === "5" || e === "5" ? true : true; // Allegiance removed
        if (values) values.religion = e?.target?.value || e;
        fetch(`${baseURL}/caste`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
            religion_id: e.target.value || e,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            this.setState({
              hideAllegiance: hide,
              fields: [
                {
                  label: "Religion *",
                  required: true,
                  type: "select",
                  options: religions,
                },
                {
                  label: "Subgroup *",
                  required: true,
                  type: "select",
                  options: res,
                },
                {
                  label: "Allegiance",
                  hidden: hide,
                  type: "select",
                  options: allegiances,
                },
                {
                  label: "Religiousness",
                  type: "select",
                  listValue: "religiousness",
                },
                {
                  label: "Hobbies",
                  type: "select",
                  listValue: "hobby",
                  multipleSelect: true,
                },
                { label: "Diet", type: "select", listValue: "diet" },
                { label: "Drink", type: "select", listValue: "drink_status" },
                { label: "Smoke", type: "select", listValue: "smoke_status" },
              ],
            });
          });
      } else if (this.state.title === "Partner Preference") {
        if (registeredValues && registeredValues.religion) {
          this.bodyValue[name] = values.id;
        }
        //;
        fetch(`${baseURL}/caste`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.currentUser,
            religion_id: values.id,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            //;

            this.setState({
              fields: [
                { label: "Age From", type: "select", options: ageOptions },
                { label: "Age To", type: "select", options: ageOptions },
                { label: "Religion", type: "select", options: religions },
                { label: "Subgroup", type: "select", options: res },
                {
                  label: "Country Living",
                  type: "select",
                  options: countries,
                },
                { label: "State", type: "select", options: states },
                {
                  label: "District/Place",
                  type: "select",
                  options: districts,
                },
                {
                  label: "Qualification",
                  type: "select",
                  listValue: "qualification",
                },
                {
                  label: "Annual Income ",
                  type: "select",
                  listValue: "income",
                },
                {
                  label: "Occupation",
                  type: "select",
                  listValue: "mother_occupation",
                },
                {
                  label: "Sector",
                  type: "select",
                  listValue: "job_sector",
                },
                {
                  label: "Marital Status",
                  type: "select",
                  listValue: "marital_status",
                },
                {
                  label: "Physical Status",
                  type: "select",
                  listValue: "physical_status",
                },
                {
                  label: "Complexion",
                  type: "select",
                  listValue: "complexion",
                },
                {
                  label: " Body Type",
                  type: "select",
                  listValue: "body_type",
                },
                { label: "Diet", type: "select", listValue: "diet" },
                {
                  label: "Height(cm) From",
                  type: "select",
                  options: heightOptions,
                },
                {
                  label: "Height(cm) To",
                  type: "select",
                  options: heightOptions,
                },
                {
                  label: "Weight(kg) From",
                  type: "slect",
                  options: weightOptions,
                },
                {
                  label: "Weight(kg) To",
                  type: "select",
                  options: weightOptions,
                },
                {
                  label: "Family Status",
                  type: "select",
                  listValue: "family_status",
                },
                { label: "Smoke", type: "select", listValue: "smoke_status" },
                { label: "Drink", type: "select", listValue: "drink_status" },
              ],
            });
          });
      }
    }
  };
  changeDistricts = (e, name, values) => {
    values.state = e?.target?.value || e;
    fetch(`${baseURL}/getdistrict`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state_id: e.target?.value || e,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        let same = this.state.sameAddress;
        districts = res;
        if (values.country != 104) {
          values.district = res[0].id;
        }
        this.setState({
          sameAddress: same,
          fields: [
            {
              label: "Father's Name *",
              required: true,
              type: "text",
              validation: "text",
            },
            { label: "Mother's Name *", type: "text", validation: "text" },

            {
              label: "House Name/House Number *",
              required: true,
              type: "text",
            },
            { label: "Street *", required: true, type: "text" },
            { label: "Muncipality/Panchayath", type: "text" },
            {
              label: "Country *",
              required: true,
              type: "select",
              options: countries,
            },
            {
              label: "State *",
              required: true,
              type: "select",
              options: states,
            },
            {
              label: "District *",
              required: true,
              type: "select",
              options: res,
            },
            { label: "Landmark ", type: "text" },
            {
              label: "Pin *",
              required: true,
              type: "text",
              validation: "number",
            },
            { label: "Whatsapp", type: "text" },
            { label: "Facebook ID", type: "text" },
            { label: "Instagram ID", type: "text" },
            // { label: "Mobile *", required: true, type: "text" },
            { label: "Alternative number", type: "text" },
            {
              label: "Contact person",
              type: "select",
              listValue: "contact_person",
            },
            {
              label: "House Name/House Number *",
              required: true,
              type: "text",
              hidden: same,
            },

            { label: "Street *", required: true, type: "text", hidden: same },

            { label: "Muncipality/Panchayath", type: "text", hidden: same },
            {
              label: "Country *",
              required: true,
              type: "select",
              options: l_countries,
              hidden: same,
            },
            {
              label: "State *",
              required: true,
              type: "select",
              options: l_states,
              hidden: same,
            },
            {
              label: "District *",
              required: true,
              type: "select",
              options: l_districts,
              hidden: same,
            },
            { label: "Landmark", type: "text", hidden: same },
            {
              label: "Pin *",
              required: true,
              type: "text",
              validation: "number",
              hidden: same,
            },
          ],
        });
      });
  };

  changeLDistricts = (e, name, values) => {
    values.l_state = e?.target?.value || e;
    fetch(`${baseURL}/getdistrict`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state_id: e.target?.value || e,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        let same = this.state.sameAddress;
        l_districts = res;
        if (values.l_country != 104) {
          values.l_district = res[0].id;
        }
        this.setState({
          sameAddress: same,
          fields: [
            {
              label: "Father's Name *",
              required: true,
              type: "text",
              validation: "text",
            },
            { label: "Mother's Name *", type: "text", validation: "text" },

            {
              label: "House Name/House Number *",
              required: true,
              type: "text",
            },
            { label: "Street *", required: true, type: "text" },
            { label: "Muncipality/Panchayath", type: "text" },
            {
              label: "Country *",
              required: true,
              type: "select",
              options: countries,
            },
            {
              label: "State *",
              required: true,
              type: "select",
              options: states,
            },
            {
              label: "District *",
              required: true,
              type: "select",
              options: districts,
            },
            { label: "Landmark ", type: "text" },
            {
              label: "Pin *",
              required: true,
              type: "text",
              validation: "number",
            },
            { label: "Whatsapp", type: "text" },
            { label: "Facebook ID", type: "text" },
            { label: "Instagram ID", type: "text" },
            // { label: "Mobile *", required: true, type: "text" },
            { label: "Alternative number", type: "text" },
            {
              label: "Contact person",
              type: "select",
              listValue: "contact_person",
            },
            {
              label: "House Name/House Number *",
              required: true,
              type: "text",
              hidden: same,
            },

            { label: "Street *", required: true, type: "text", hidden: same },

            { label: "Muncipality/Panchayath", type: "text", hidden: same },
            {
              label: "Country *",
              required: true,
              type: "select",
              options: l_countries,
              hidden: same,
            },
            {
              label: "State *",
              required: true,
              type: "select",
              options: l_states,
              hidden: same,
            },
            {
              label: "District *",
              required: true,
              type: "select",
              options: res,
              hidden: same,
            },
            { label: "Landmark", type: "text", hidden: same },
            {
              label: "Pin *",
              required: true,
              type: "text",
              validation: "number",
              hidden: same,
            },
          ],
        });
      });
  };

  changePstates = (e) => {
    fetch(`${baseURL}/getstate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country_id: e.target?.value || e,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        p_states = res;
        this.setState({});
      });
  };

  changePDistricts = (e) => {
    fetch(`${baseURL}/getdistrict`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state_id: e.target?.value || e,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        p_districts = res;
        this.setState({});
      });
  };

  getStatesByCountryPerm(countryId, values) {
    values.country = countryId;
    fetch(`${baseURL}/getstate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country_id: countryId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        states = res;
        let same = this.state.sameAddress;
        this.setState({
          sameAddress: same,
          fields: [
            {
              label: "Father's Name *",
              required: true,
              type: "text",
              validation: "text",
            },
            { label: "Mother's Name *", type: "text", validation: "text" },

            {
              label: "House Name/House Number *",
              required: true,
              type: "text",
            },
            { label: "Street *", required: true, type: "text" },
            { label: "Muncipality/Panchayath", type: "text" },
            {
              label: "Country *",
              required: true,
              type: "select",
              options: countries,
            },
            {
              label: "State *",
              required: true,
              type: "select",
              options: res,
            },
            {
              label: "District *",
              required: true,
              type: "select",
              options: districts,
            },
            { label: "Landmark", type: "text" },
            {
              label: "Pin *",
              required: true,
              type: "text",
              validation: "number",
            },
            { label: "Whatsapp", type: "text" },
            { label: "Facebook ID", type: "text" },
            { label: "Instagram ID", type: "text" },
            // { label: "Mobile *", required: true, type: "text" },
            { label: "Alternative number", type: "text" },
            {
              label: "Contact person",
              type: "select",
              listValue: "contact_person",
            },
            {
              label: "House Name/House Number *",
              required: true,
              type: "text",
              hidden: same,
            },
            { label: "Street *", required: true, type: "text", hidden: same },

            { label: "Muncipality/Panchayath", type: "text", hidden: same },
            {
              label: "Country *",
              required: true,
              type: "select",
              options: l_countries,
              hidden: same,
            },
            {
              label: "State *",
              required: true,
              type: "select",
              options: l_states,
              hidden: same,
            },
            {
              label: "District *",
              required: true,
              type: "select",
              options: l_districts,
              hidden: same,
            },
            { label: "Landmark", type: "text", hidden: same },
            {
              label: "Pin *",
              required: true,
              type: "text",
              validation: "number",
              hidden: same,
            },
          ],
        });
      });
  }

  getStatesByCountryLiving(countryId, values) {
    values.l_country = countryId;
    fetch(`${baseURL}/getstate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country_id: countryId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        l_states = res;
        let same = this.state.sameAddress;
        this.setState({
          sameAddress: same,
          fields: [
            {
              label: "Father's Name *",
              required: true,
              type: "text",
              validation: "text",
            },
            { label: "Mother's Name *", type: "text", validation: "text" },

            {
              label: "House Name/House Number *",
              required: true,
              type: "text",
            },
            { label: "Street *", required: true, type: "text" },
            { label: "Muncipality/Panchayath", type: "text" },
            {
              label: "Country *",
              required: true,
              type: "select",
              options: countries,
            },
            {
              label: "State *",
              required: true,
              type: "select",
              options: states,
            },
            {
              label: "District *",
              required: true,
              type: "select",
              options: districts,
            },
            { label: "Landmark", type: "text" },
            {
              label: "Pin *",
              required: true,
              type: "text",
              validation: "number",
            },
            { label: "Whatsapp", type: "text" },
            { label: "Facebook ID", type: "text" },
            { label: "Instagram ID", type: "text" },
            // { label: "Mobile *", required: true, type: "text" },
            { label: "Alternative number", type: "text" },
            {
              label: "Contact person",
              type: "select",
              listValue: "contact_person",
            },
            {
              label: "House Name/House Number *",
              required: true,
              type: "text",
              hidden: same,
            },
            { label: "Street *", required: true, type: "text", hidden: same },

            { label: "Muncipality/Panchayath", type: "text", hidden: same },
            {
              label: "Country *",
              required: true,
              type: "select",
              options: countries,
              hidden: same,
            },
            {
              label: "State *",
              required: true,
              type: "select",
              options: res,
              hidden: same,
            },
            {
              label: "District *",
              required: true,
              type: "select",
              options: l_districts,
              hidden: same,
            },
            { label: "Landmark", type: "text", hidden: same },
            {
              label: "Pin *",
              required: true,
              type: "text",
              validation: "number",
              hidden: same,
            },
          ],
        });
      });
  }

  bodyValue = {};
  selectMultiple = (values, index) => {
    this.bodyValue[this.state.names[index]] = values?.map((value) => value.id);

    if (index == "4" && this.state.title == "Partner Preference") {
      if (values.length == 1) {
        this.changePstates(values[0].id);
      } else {
        p_states = [];
        this.setState({});
      }
    }

    if (index == "5" && this.state.title == "Partner Preference") {
      if (values.length == 1) {
        this.changePDistricts(values[0].id);
      } else {
        p_districts = [];
        this.setState({});
      }
    }
  };

  selectSingle = (e, index) => {
    this.bodyValue[this.state.names[index]] = e.target.value;
  };

  handleFbLogin = (user, values) => {
    fetch(
      `https://graph.facebook.com/me?fields=link,name&access_token=${user._token.accessToken}`
    )
      .then((res) => res.json())
      .then((res) => {
        values.facebook_id = res.link;
        this.setState({
          socialLink: true,
        });
      });
  };

  handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  handleInstaLogin = (values) => {};

  handleSameWhatsapp = (e) => {
    registeredValues.whatsapp = e.target.checked ? registeredValues.phone : "";
    this.setState({
      sameWhatsapp: e.target.checked,
    });
  };

  async checkEmailAlreadyExist(e) {
    fetch(`${baseURL}/email_exist_new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e,
        id: localStorage.getItem("currentUser"),
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response === true) {
          this.setState({ isEmailExist: true });
        } else {
          this.setState({ isEmailExist: false });
        }
      });
  }

  changeNumBrothers = (num) => {
    brothersMarriedOptions = [];
    for (let i = 0; i <= num; i++) {
      brothersMarriedOptions.push(i);
    }
    this.setState({ noOfBrothers: num });
  };

  changeNumSisters = (num) => {
    sistersMarriedOptions = [];
    for (let i = 0; i <= num; i++) {
      sistersMarriedOptions.push(i);
    }
    this.setState({ noOfSisters: num });
  };

  maritialStatusChange(stts) {
    let ivs = this.state.initialValues;
    ivs["marital_status"] = stts;
    this.setState({ maritialSelected: stts, initialValues: ivs });
  }

  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  clearAllRegisterData() {
    ageOptions = [];
    heightOptions = [];
    weightOptions = [];
    siblingOptions = [];
    brothersMarriedOptions = [];
    sistersMarriedOptions = [];
    registeredValues = {};

    ageOptions = [];
    for (let i = 18; i <= 100; i++) {
      ageOptions.push(i);
    }
    heightOptions = [];
    for (let i = 135; i <= 215; i++) {
      heightOptions.push(i);
    }
    weightOptions = [];
    for (let i = 35; i <= 120; i++) {
      weightOptions.push(i);
    }

    siblingOptions = [];
    for (let i = 0; i <= 20; i++) {
      siblingOptions.push(i);
    }

    this.state = {
      showInstaModal: false,
      socialLink: false,
      ageFrom: 18,
      ageTo: 100,
      heightFrom: 135,
      heightTo: 215,
      weightFrom: 35,
      weightTo: 120,
      showPassword: false,
      sameAddress: false,
      isEmailExist: false,
      sameWhatsapp: false,
      hideAllegiance: true,
      submitting: false,
      navigate: false,
      showSuccessComplete: false,
      otp: "",
      isLoading: true,
      title: "Free Registration",
      physicallyChallenged: false,
      initialValues: {
        profile_for:
          this.props?.values?.profile_for || registeredValues.profile_for || "",
        name: this.props?.values?.name || registeredValues.name || "",
        gender: this.props?.values?.gender || registeredValues.gender || "",
        phone: this.props?.values?.phone || registeredValues.phone || "",
        password:
          this.props?.values?.password || registeredValues.password || "",
      },
      names: ["profile_for", "name", "gender", "phone", "password"],
      fields: [
        {
          label: "Profile For *",
          required: true,
          type: "select",
          listValue: "profile_created_for",
        },
        {
          label: "Name of Bride/Groom *",
          required: true,
          type: "text",
          validation: "text",
        },
        {
          label: "Gender *",
          required: true,
          type: "select",
          listValue: "gender",
        },
        { label: "Mobile Number *", required: true },
        { label: "Password *", required: true, type: "password" },
        { label: "I agree to the Terms & Conditions", type: "checkbox" },
      ],
      mobile: "",
      isFatherAlive: null,
      isMotherAlive: null,
      noOfBrothers: "",
      noOfBrothersMarried: null,
      noOfSisters: "",
      noOfSistersMarried: null,
      isOrphan: null,
      maritialSelected: "",
    };
    var selectedHobbies = [];
  }

  handleIncompleteRegistration = () => {
    if (
      localStorage.getItem("register_complete_status") != null &&
      localStorage.getItem("register_complete_status") != ""
    ) {
      var regSts = localStorage.getItem("register_complete_status");

      if (regSts == "basic_info") {
        this.setState({ title: "Contact Information" });
        this.previous();
      } else if (regSts == "contact_info") {
        this.setState({ title: "Physical Info" });
        this.previous();
      } else if (regSts == "physical_info") {
        this.setState({ title: "Job and Education" });
        this.previous();
      } else if (regSts == "education_info") {
        this.setState({ title: "Thoughts/Lifestyle" });
        this.previous();
      } else if (regSts == "religion_info") {
        this.setState({ title: "Family Details" });
        this.previous();
      }
      // else if(regSts == 'family_info'){
      //   this.setState({title:'Partner Preference'});
      //   this.previous();
      // }
      localStorage.removeItem("register_complete_status");
    }
  };

  changeOtpValue = (e) => {
    if (e != "") {
      var regex = /[0-9]$/;
      if (regex.test(e)) {
        this.setState({
          otp: e,
        });
      }
    } else {
      this.setState({
        otp: e,
      });
    }
  };

  cancelCourse = () => {
    this.setState({
      values: {
        profile_for: "",
        name: "",
        gender: "",
        password: "",
      },
      names: {
        profile_for: "",
        name: "",
        gender: "",
        password: "",
      },
      initialValues: {
        profile_for: null,
        name: null,
        gender: null,
        phone: null,
        password: null,
      },

      registeredValues: {
        profile_for: null,
        name: null,
        gender: null,
        phone: null,
        password: null,
      },
    });
    window.location.reload(true);
  };

  render() {
    return (
      <div>
        {this.state.title != "OTP Verification" &&
        this.state.title != "Partner Preference" ? (
          <Row style={{}}>
            <Col md={6} xs={12} className="p-0 position-relative login-bg">
              <div className="banner-text">
                <h1 className="banner-head">
                  Bond of <br />
                  safety in Love
                </h1>
                <p className="banner-para">
                  The best place to meet with <br /> your future partner
                </p>
                <h6 className="banner-register">{this.state.title}</h6>
              </div>
            </Col>
            <Col className="p-0" md={6} xs={12} style={{ height: "100%" }}>
              <Card className="p-0 m-0 register-card">
                {!this.state.isLoading ? (
                  <Card.Body className="register-body">
                    <Formik
                      enableReinitialize={true}
                      initialValues={this.state.initialValues}
                      onSubmit={(values) => this.next(values)}
                      validate={(values) => {
                        const errors = {};

                        this.state.names?.map((name, index) => {
                          try {
                            if (values[name] && values[name].includes('"')) {
                              values[name] = values[name].replace('"', "");
                            }
                            if (values[name] && values[name].includes("'")) {
                              values[name] = values[name].replace("'", "");
                            }
                          } catch (e) {}
                          if (name == "marital_status") {
                            values["marital_status"] =
                              this.state.maritialSelected;
                          }
                          if (name == "whatsapp") {
                            if (this.state.sameWhatsapp) {
                              values["whatsapp"] = values["phone"];
                            }
                          }
                          if (
                            !values[name] &&
                            this.state.fields[index]?.required &&
                            !this.state.fields[index]?.hidden
                          ) {
                            return (errors[name] = "Required");
                          }
                          if (values[name] && name === "email") {
                            let regex =
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            if (!regex.test(values[name])) {
                              this.setState({ isEmailExist: false });
                              return (errors[name] =
                                "Provide a valid email address");
                            } else {
                              this.checkEmailAlreadyExist(values[name]);
                            }
                          }
                          if (
                            parseInt(values.numbrother) <
                            parseInt(values.numbrothers_married)
                          ) {
                            return (errors.num_brothers_married = `No of Brother's Married Should not greater than no of brothers`);
                          }

                          if (
                            parseInt(values.num_sisters) <
                            parseInt(values.numsisters_married)
                          ) {
                            return (errors.num_sisters_married = `No of Sisters's Married Should not greater than no of Sisters`);
                          }
                          if ("physical_status" in values) {
                            if (
                              (values.physical_status == "" ||
                                values.physical_status == "30") &&
                              this.state.physicallyChallenged
                            ) {
                              return (errors["physical_status"] = "Required");
                            }
                          }

                          if (
                            values[name] &&
                            (name === "phone" ||
                              name === "whatsapp" ||
                              name === "alternate_number")
                          ) {
                            //;
                            // //;
                            // let regex = /^[1-9][0-9]$/;
                            let regex = /^[0-9]*$/;
                            let xx = prefixList.filter(
                              (value) =>
                                (values?.country_code === undefined
                                  ? "+91"
                                  : values?.country_code) === value.id
                            )[0]?.phoneno_length;
                            if (
                              !/^[0-9]*$/.test(values[name]) ||
                              values[name]?.length < xx
                            )
                              if (name == "whatsapp") {
                                errors.whatsapp =
                                  "Provide a valid mobile number";
                              } else if (name == "alternate_number") {
                                errors.alternate_number =
                                  "Provide a valid mobile number";
                              } else {
                                errors.phone = "Provide a valid mobile number";
                              }
                            // return (errors[name] =
                            //   "Provide a valid mobile number");
                          }
                          if (
                            values[name] &&
                            name === "password" &&
                            values[name].length < 6
                          )
                            return (errors[name] =
                              " Password must contain 6 or more characters");
                          if (
                            values[name] &&
                            this.state.fields[index]?.validation &&
                            this.state.fields[index]?.validation === "number"
                          ) {
                            var regex =
                              values.country_code === "+973"
                                ? /^[0-9]{7,14}$/
                                : /^[0-9]{9,14}$/;
                            if (
                              this.state.fields[index]?.label.includes("Pin")
                            ) {
                              regex = /^[0-9]{6}$/;
                            }
                            if (!regex.test(values[name]))
                              // errors.pin = `Provide a valid ${name}`;
                              return (errors[name] = `Provide a valid ${name}`);
                          }
                          if (
                            values[name] &&
                            this.state.fields[index]?.validation &&
                            this.state.fields[index]?.validation === "text"
                          ) {
                            let regex = /^[a-z A-Z]*\.?[a-z A-Z]*$/;
                            if (!regex.test(values[name]))
                              return (errors[name] = `Provide a valid name`);
                            let kerName = values[name];
                            if (kerName.replaceAll(" ", "").length < 3)
                              errors[name] = `Provide a valid name`;
                          }
                          if (
                            values[name] &&
                            this.state.fields[index]?.validation &&
                            this.state.fields[index]?.validation === "nondigit"
                          ) {
                            let regex = /[^0-9]/g;
                            // let regex = /^[0-9]*$/;
                            if (!regex.test(values[name]))
                              errors.alternate_number = ` ${name}`;
                            return (errors[name] = `Provide a valid ${name}`);
                          }

                          return 0;
                        });
                        return errors;
                      }}
                    >
                      {({
                        isSubmitting,
                        dirty,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        values,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <div
                            style={{ marginBottom: "20px", fontWeight: "600" }}
                            className="form-text"
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={this.previous}
                            >
                              {this.state?.title != "Free Registration" &&
                              this.state.title != "Basic Information"
                                ? "Back"
                                : ""}
                            </span>
                          </div>
                          {this.state?.fields?.map((field, index) => (
                            <>
                              {this.state.names[index] === "l_house" ? (
                                <div>
                                  <Row
                                    className="m-0 pt-2"
                                    style={{ color: "#5B6770" }}
                                  >
                                    Permanent address
                                  </Row>
                                  <Form.Check
                                    className="mb-2 ms-3"
                                    style={{
                                      color: "#c4c4c4",
                                      fontSize: "14px",
                                    }}
                                    height="14px"
                                    width="14px"
                                    checked={this.state.sameAddress}
                                    value={this.state.sameAddress}
                                    name="address"
                                    label="Same as living address"
                                    onChange={() => {
                                      let same = !this.state.sameAddress;
                                      this.setState({
                                        sameAddress: same,
                                        fields: [
                                          {
                                            label: "Father's Name *",
                                            required: true,
                                            type: "text",
                                            validation: "text",
                                          },
                                          {
                                            label: "Mother's Name *",
                                            type: "text",
                                            validation: "text",
                                          },

                                          {
                                            label: "House Name/House Number *",
                                            required: true,
                                            type: "text",
                                          },
                                          {
                                            label: "Street *",
                                            required: true,
                                            type: "text",
                                          },
                                          {
                                            label: "Muncipality/Panchayath",
                                            type: "text",
                                          },
                                          {
                                            label: "Country *",
                                            required: true,
                                            type: "select",
                                            options: countries,
                                          },
                                          {
                                            label: "State *",
                                            required: true,
                                            type: "select",
                                            options: states,
                                          },
                                          {
                                            label: "District *",
                                            required: true,
                                            type: "select",
                                            options: districts,
                                          },
                                          {
                                            label: "Landmark",
                                            // required: true,
                                            type: "text",
                                          },
                                          {
                                            label: "Pin *",
                                            required: true,
                                            type: "text",
                                            validation: "number",
                                          },
                                          { label: "Whatsapp", type: "text" },
                                          { label: "Facebook", type: "text" },
                                          { label: "Instagram", type: "text" },
                                          // {
                                          //   label: "Mobile *",
                                          //   required: true,
                                          //   type: "text",
                                          // },
                                          {
                                            label: "Alternative number",
                                            type: "text",
                                          },
                                          {
                                            label: "Contact person",
                                            type: "select",
                                            listValue: "contact_person",
                                          },
                                          {
                                            label: "House Name/House Number *",
                                            required: true,
                                            type: "text",
                                            hidden: same,
                                          },
                                          {
                                            label: "Street *",
                                            required: true,
                                            type: "text",
                                            hidden: same,
                                          },

                                          {
                                            label: "Muncipality/Panchayath",
                                            type: "text",
                                            hidden: same,
                                          },
                                          {
                                            label: "Country *",
                                            required: true,
                                            type: "select",
                                            options: l_countries,
                                            hidden: same,
                                          },
                                          {
                                            label: "State *",
                                            required: true,
                                            type: "select",
                                            options: l_states,
                                            hidden: same,
                                          },
                                          {
                                            label: "District *",
                                            required: true,
                                            type: "select",
                                            options: l_districts,
                                            hidden: same,
                                          },
                                          {
                                            label: "Landmark",
                                            // required: true,
                                            type: "text",
                                            hidden: same,
                                          },
                                          {
                                            label: "Pin *",
                                            required: true,
                                            type: "text",
                                            validation: "number",
                                            hidden: same,
                                          },
                                        ],
                                      });
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                style={{ marginBottom: "18px" }}
                                key={index}
                                className={`form-group mb-3 ${
                                  field.hidden ? "d-none" : "d-block"
                                }`}
                              >
                                {(field.label === "Is Orphan" &&
                                  this.state.isFatherAlive === "Yes") ||
                                (field.label === "Father's Occupation" &&
                                  this.state.isFatherAlive === "No") ||
                                (field.label === "Mother's Occupation " &&
                                  this.state.isMotherAlive === "No") ||
                                (field.label === "Number of Brothers Married" &&
                                  this.state.noOfBrothers === "0") ||
                                (field.label === "Number of Sisters Married" &&
                                  this.state.noOfSisters === "0") ||
                                (this.state.names[index] == "district" &&
                                  values.country != 104) ||
                                (this.state.names[index] == "l_district" &&
                                  values.l_country != 104) ||
                                (field.label === "Children" &&
                                  (this.state.maritialSelected == "83" ||
                                    this.state.maritialSelected == "27" ||
                                    this.state.maritialSelected == "")) ||
                                field.label === "Physical status" ? (
                                  <></>
                                ) : field.label ===
                                  "I agree to the Terms & Conditions" ? (
                                  <>
                                    <input
                                      style={{ marginLeft: "0.5rem" }}
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={true}
                                    />
                                    <span className="i-agree-text">
                                      {" "}
                                      I agree to the
                                    </span>{" "}
                                    <a
                                      href="https://click4marry.com/app/home/privacy"
                                      target="blank"
                                    >
                                      Terms & Conditions
                                    </a>
                                  </>
                                ) : (
                                  <label className="label form-label">
                                    {field.label}
                                  </label>
                                )}

                                {(() => {
                                  switch (field.type) {
                                    case "select":
                                      if (
                                        this.state.names[index] ===
                                          "qualification" ||
                                        (this.state.names[index] ===
                                          "religion" &&
                                          (this.state.title ===
                                            "Thoughts/Lifestyle" ||
                                            this.state.title ===
                                              "Partner Preference"))
                                      )
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                            onChange={(e) =>
                                              this.getOptions(
                                                e,
                                                this.state.names[index],
                                                values
                                              )
                                            }
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field?.listValue]
                                              ? listValues[
                                                  field?.listValue
                                                ]?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id}
                                                  >
                                                    {opt.name}
                                                  </option>
                                                ))
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                  </option>
                                                ))}
                                          </Field>
                                        );
                                      else if (field.multipleSelect)
                                        return (
                                          <Select
                                            isSearchable={false}
                                            closeMenuOnSelect={false}
                                            closeMenuOnScroll={false}
                                            blurInputOnSelect={false}
                                            isMulti
                                            placeholder=""
                                            className="multiple-select"
                                            style={{ color: "#000" }}
                                            inputf
                                            value={this.selectedHobbies}
                                            options={
                                              field?.options ||
                                              listValues[field?.listValue]
                                            }
                                            getOptionLabel={(options) =>
                                              options["name"]
                                            }
                                            getOptionValue={(options) =>
                                              options["id"]
                                            }
                                            components={animatedComponents}
                                            onChange={(values) => {
                                              this.selectMultiple(
                                                values,
                                                index
                                              );
                                              this.selectedHobbies = values;
                                              this.setState({});
                                            }}
                                          />
                                        );
                                      else if (
                                        field.label === "State *" &&
                                        field.hidden != false
                                      ) {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                            onChange={(e) =>
                                              this.changeDistricts(
                                                e,
                                                this.state.names[index],
                                                values
                                              )
                                            }
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]
                                              ? listValues[
                                                  field.listValue
                                                ]?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id}
                                                  >
                                                    {opt.name}
                                                  </option>
                                                ))
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                    {field.label === "Height *"
                                                      ? " cm"
                                                      : field.label ===
                                                        "Weight *"
                                                      ? " kg"
                                                      : ""}
                                                  </option>
                                                ))}
                                          </Field>
                                        );
                                      } else if (field.label === "Smoke") {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]
                                              ? listValues[
                                                  field.listValue
                                                ]?.map(
                                                  (opt, i) =>
                                                    opt.id != "119" && (
                                                      <option
                                                        key={i}
                                                        value={opt.id}
                                                      >
                                                        {opt.name}
                                                      </option>
                                                    )
                                                )
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                    {field.label === "Height *"
                                                      ? " cm"
                                                      : field.label ===
                                                        "Weight *"
                                                      ? " kg"
                                                      : ""}
                                                  </option>
                                                ))}
                                          </Field>
                                        );
                                      } else if (field.label === "Drink") {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                            // onChange={(e) => this.selectSingle(e, index)}
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]
                                              ? listValues[
                                                  field.listValue
                                                ]?.map(
                                                  (opt, i) =>
                                                    opt.id != "118" && (
                                                      <option
                                                        key={i}
                                                        value={opt.id}
                                                      >
                                                        {opt.name}
                                                      </option>
                                                    )
                                                )
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                    {field.label === "Height *"
                                                      ? " cm"
                                                      : field.label ===
                                                        "Weight *"
                                                      ? " kg"
                                                      : ""}
                                                  </option>
                                                ))}
                                          </Field>
                                        );
                                      } else if (
                                        field.label == "Father Alive "
                                      ) {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={field.name}
                                            as="select"
                                            value={this.state.isFatherAlive}
                                            onChange={(e) =>
                                              this.setState({
                                                isFatherAlive: e.target.value,
                                              })
                                            }
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                              selected={true}
                                            ></option>

                                            {field.options?.map((opt) => (
                                              <option value={opt}>{opt}</option>
                                            ))}
                                          </Field>
                                        );
                                      } else if (
                                        field.label == "Mother Alive "
                                      ) {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={field.name}
                                            as="select"
                                            value={this.state.isMotherAlive}
                                            onChange={(e) =>
                                              this.setState({
                                                isMotherAlive: e.target.value,
                                              })
                                            }
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                              selected={true}
                                            ></option>

                                            {field.options?.map((opt) => (
                                              <option value={opt}>{opt}</option>
                                            ))}
                                          </Field>
                                        );
                                      } else if (field.label === "Is Orphan") {
                                        return (
                                          this.state.isFatherAlive != "Yes" && (
                                            <Field
                                              className="field form-select"
                                              name={field.name}
                                              as="select"
                                              value={this.state.isOrphan}
                                              onChange={(e) =>
                                                this.setState({
                                                  isOrphan: e.target.value,
                                                })
                                              }
                                            >
                                              <option
                                                default
                                                value=""
                                                disabled
                                                selected={true}
                                              ></option>

                                              {field.options?.map((opt) => (
                                                <option value={opt}>
                                                  {opt}
                                                </option>
                                              ))}
                                            </Field>
                                          )
                                        );
                                      } else if (
                                        field.label === "Father's Occupation"
                                      ) {
                                        return (
                                          this.state.isFatherAlive != "No" && (
                                            <Field
                                              className="field form-select"
                                              name={"father_occupation"}
                                              as="select"
                                            >
                                              <option
                                                default
                                                value=""
                                                // selected={true}
                                              ></option>

                                              {listValues[field.listValue]
                                                ? listValues[
                                                    field.listValue
                                                  ]?.map((opt, i) => (
                                                    <option
                                                      // onClick={()=>console.log(field,"fieldd")}
                                                      key={i}
                                                      value={opt.id}
                                                    >
                                                      {opt.name}
                                                    </option>
                                                  ))
                                                : field.options?.map(
                                                    (opt, i) => (
                                                      <option
                                                        key={i}
                                                        value={opt.id || opt}
                                                      >
                                                        {opt.name || opt}\
                                                      </option>
                                                    )
                                                  )}
                                            </Field>
                                          )
                                        );
                                      } else if (
                                        field.label === "Mother's Occupation "
                                      ) {
                                        return (
                                          this.state.isMotherAlive != "No" && (
                                            <Field
                                              className="field form-select"
                                              name={"mother_occupation"}
                                              as="select"
                                            >
                                              <option
                                                default
                                                value=""
                                                // selected={true}
                                              ></option>

                                              {listValues[field.listValue]
                                                ? listValues[
                                                    field.listValue
                                                  ]?.map((opt, i) => (
                                                    <option
                                                      key={i}
                                                      value={opt.id}
                                                    >
                                                      {opt.name}
                                                    </option>
                                                  ))
                                                : field.options?.map(
                                                    (opt, i) => (
                                                      <option
                                                        key={i}
                                                        value={opt.id || opt}
                                                      >
                                                        {opt.name || opt}\
                                                      </option>
                                                    )
                                                  )}
                                            </Field>
                                          )
                                        );
                                      } else if (
                                        field.label === "Physical status"
                                      ) {
                                        return (
                                          <>
                                            <label
                                              style={{
                                                color: "#5b6770",
                                                paddingBottom: "3px",
                                              }}
                                            >
                                              Physically Challenged
                                            </label>
                                            <select
                                              className="field form-select"
                                              onChange={(e) => {
                                                this.setState({
                                                  physicallyChallenged:
                                                    e.target.value == "Yes"
                                                      ? true
                                                      : false,
                                                });
                                              }}
                                            >
                                              <option
                                                selected
                                                disabled
                                              ></option>

                                              <option
                                                value="No"
                                                selected={
                                                  this.state
                                                    .physicallyChallenged ==
                                                  false
                                                }
                                              >
                                                No
                                              </option>
                                              <option
                                                value="Yes"
                                                selected={
                                                  this.state
                                                    .physicallyChallenged ==
                                                  true
                                                }
                                              >
                                                Yes
                                              </option>
                                            </select>
                                            {this.state
                                              .physicallyChallenged && (
                                              <>
                                                <label
                                                  style={{
                                                    color: "#5b6770",
                                                    paddingBottom: "3px",
                                                    paddingTop: "15px",
                                                  }}
                                                >
                                                  Physical Status
                                                </label>
                                                <Field
                                                  className="field form-select"
                                                  name="physical_status"
                                                  as="select"
                                                >
                                                  <option disabled></option>

                                                  {listValues[
                                                    field.listValue
                                                  ] &&
                                                    listValues[
                                                      field.listValue
                                                    ]?.map(
                                                      (opt, i) =>
                                                        opt.id != "30" && (
                                                          <option
                                                            key={i}
                                                            value={opt.id}
                                                          >
                                                            {opt.name}
                                                          </option>
                                                        )
                                                    )}
                                                </Field>
                                              </>
                                            )}
                                          </>
                                        );
                                      } else if (
                                        this.state.names[index] === "l_state"
                                      ) {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                            onChange={(e) =>
                                              this.changeLDistricts(
                                                e,
                                                this.state.names[index],
                                                values
                                              )
                                            }
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]
                                              ? listValues[
                                                  field.listValue
                                                ]?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id}
                                                  >
                                                    {opt.name}
                                                  </option>
                                                ))
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                  </option>
                                                ))}
                                          </Field>
                                        );
                                      } else if (
                                        this.state.names[index] === "numbrother"
                                      ) {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                            value={this.state.noOfBrothers}
                                            onChange={(e) => {
                                              this.changeNumBrothers(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {siblingOptions.map((opt, i) => (
                                              <option
                                                key={i}
                                                value={opt.id || opt}
                                              >
                                                {opt.name || opt}
                                              </option>
                                            ))}
                                          </Field>
                                        );
                                      } else if (
                                        this.state.names[index] ===
                                        "numbrothers_married"
                                      ) {
                                        return (
                                          this.state.noOfBrothers != "0" && (
                                            <Field
                                              className="field form-select"
                                              name={this.state.names[index]}
                                              as="select"
                                            >
                                              <option
                                                default
                                                disabled
                                                value=""
                                              ></option>
                                              {brothersMarriedOptions.map(
                                                (opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                  </option>
                                                )
                                              )}
                                            </Field>
                                          )
                                        );
                                      } else if (
                                        this.state.names[index] ===
                                        "num_sisters"
                                      ) {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                            value={this.state.noOfSisters}
                                            onChange={(e) => {
                                              this.changeNumSisters(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {siblingOptions.map((opt, i) => (
                                              <option
                                                key={i}
                                                value={opt.id || opt}
                                              >
                                                {opt.name || opt}
                                              </option>
                                            ))}
                                          </Field>
                                        );
                                      } else if (
                                        this.state.names[index] ===
                                        "numsisters_married"
                                      ) {
                                        return (
                                          this.state.noOfSisters != "0" && (
                                            <Field
                                              className="field form-select"
                                              name={this.state.names[index]}
                                              as="select"
                                            >
                                              <option
                                                default
                                                disabled
                                                value=""
                                              ></option>
                                              {sistersMarriedOptions.map(
                                                (opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                  </option>
                                                )
                                              )}
                                            </Field>
                                          )
                                        );
                                      } else if (
                                        this.state.names[index] ===
                                        "marital_status"
                                      ) {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                            value={this.state.maritialSelected}
                                            onChange={(e) => {
                                              this.maritialStatusChange(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]?.map(
                                              (opt, i) => (
                                                <option key={i} value={opt.id}>
                                                  {opt.name}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                        );
                                      } else if (
                                        this.state.names[index] == "country"
                                      ) {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                            onChange={(e) =>
                                              this.getStatesByCountryPerm(
                                                e.target.value,
                                                values
                                              )
                                            }
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]
                                              ? listValues[
                                                  field.listValue
                                                ]?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id}
                                                  >
                                                    {opt.name}
                                                  </option>
                                                ))
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                  </option>
                                                ))}
                                          </Field>
                                        );
                                      } else if (
                                        this.state.names[index] == "l_country"
                                      ) {
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                            onChange={(e) =>
                                              this.getStatesByCountryLiving(
                                                e.target.value,
                                                values
                                              )
                                            }
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]
                                              ? listValues[
                                                  field.listValue
                                                ]?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id}
                                                  >
                                                    {opt.name}
                                                  </option>
                                                ))
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                  </option>
                                                ))}
                                          </Field>
                                        );
                                      } else if (
                                        this.state.names[index] == "district"
                                      ) {
                                        if (values.country != 104) {
                                          return <></>;
                                        }
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]
                                              ? listValues[
                                                  field.listValue
                                                ]?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id}
                                                  >
                                                    {opt.name}
                                                  </option>
                                                ))
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                  </option>
                                                ))}
                                          </Field>
                                        );
                                      } else if (
                                        this.state.names[index] == "l_district"
                                      ) {
                                        if (values.l_country != 104) {
                                          return <></>;
                                        }
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]
                                              ? listValues[
                                                  field.listValue
                                                ]?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id}
                                                  >
                                                    {opt.name}
                                                  </option>
                                                ))
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                  </option>
                                                ))}
                                          </Field>
                                        );
                                      } else
                                        return (
                                          <Field
                                            className="field form-select"
                                            name={this.state.names[index]}
                                            as="select"
                                          >
                                            <option
                                              default
                                              disabled
                                              value=""
                                            ></option>
                                            {listValues[field.listValue]
                                              ? listValues[
                                                  field.listValue
                                                ]?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id}
                                                  >
                                                    {opt.name}
                                                  </option>
                                                ))
                                              : field.options?.map((opt, i) => (
                                                  <option
                                                    key={i}
                                                    value={opt.id || opt}
                                                  >
                                                    {opt.name || opt}
                                                    {field.label === "Height *"
                                                      ? " cm"
                                                      : field.label ===
                                                        "Weight *"
                                                      ? " kg"
                                                      : ""}
                                                  </option>
                                                ))}
                                          </Field>
                                        );

                                    case "password":
                                      return (
                                        <div className="position-relative">
                                          <Field
                                            className="field form-control"
                                            type={
                                              this.state.showPassword
                                                ? "text"
                                                : "password"
                                            }
                                            name={this.state.names[index]}
                                          />
                                          {this.state.showPassword ? (
                                            <BsEyeSlash
                                              color="#5B6770"
                                              style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "15px",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  showPassword: false,
                                                })
                                              }
                                            />
                                          ) : (
                                            <BsEye
                                              color="#5B6770"
                                              style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "15px",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  showPassword: true,
                                                })
                                              }
                                            />
                                          )}
                                        </div>
                                      );

                                    case "date":
                                      return (
                                        <DatePickerField
                                          className="field form-control"
                                          name={this.state.names[index]}
                                        />
                                      );

                                    default:
                                      if (
                                        field.label === "Mobile Number *" ||
                                        field.label === "Whatsapp"
                                      ) {
                                        return (
                                          <div>
                                            {field.label === "Whatsapp" && (
                                              <Form.Check
                                                className="mb-2 ms-3"
                                                style={{
                                                  color: "#c4c4c4",
                                                  fontSize: "14px",
                                                }}
                                                height="14px"
                                                maxLength={10}
                                                width="14px"
                                                checked={
                                                  this.state.sameWhatsapp
                                                }
                                                value={this.state.sameWhatsapp}
                                                name="whatsAppSame"
                                                onChange={(e) =>
                                                  this.handleSameWhatsapp(e)
                                                }
                                                label="Same as registered number"
                                              />
                                            )}
                                            {field.label === "Whatsapp" ? (
                                              !this.state.sameWhatsapp ? (
                                                <div className="d-flex">
                                                  <Field
                                                    autocomplete={false}
                                                    className="form-control form-control-color mob-prefix"
                                                    name="country_code_watsapp"
                                                    maxLength={15}
                                                    as="select"
                                                    disabled={
                                                      field.label ===
                                                      "Mobile Number *"
                                                    }
                                                  >
                                                    {prefixList?.map(
                                                      (prefix, i) => (
                                                        <option
                                                          key={i}
                                                          value={prefix.id}
                                                        >
                                                          {prefix.id}
                                                        </option>
                                                      )
                                                    )}
                                                  </Field>
                                                  <Field
                                                    className="field form-control mob"
                                                    type="text"
                                                    maxLength={15}
                                                    name={
                                                      this.state.names[index]
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                <></>
                                              )
                                            ) : (
                                              <div className="d-flex">
                                                <Field
                                                  autocomplete={false}
                                                  className="form-control form-control-color mob-prefix"
                                                  name="country_code"
                                                  as="select"
                                                  value={values.country_code}
                                                >
                                                  {prefixList?.map(
                                                    (prefix, i) => (
                                                      <option
                                                        key={i}
                                                        value={prefix.id}
                                                      >
                                                        {prefix.id}
                                                      </option>
                                                    )
                                                  )}
                                                </Field>
                                                <Field
                                                  className="field form-control mob"
                                                  type="text"
                                                  maxLength={15}
                                                  name={this.state.names[index]}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        );
                                      } else if (
                                        field.label === "Facebook ID-"
                                      ) {
                                        return !values?.facebook_id ? (
                                          <SocialButton
                                            className="field form-control"
                                            scope={[
                                              "user_link",
                                              "public_profile",
                                            ]}
                                            provider="facebook"
                                            appId="728735384954500"
                                            onLoginSuccess={(user) =>
                                              this.handleFbLogin(user, values)
                                            }
                                            onLoginFailure={
                                              this.handleSocialLoginFailure
                                            }
                                          ></SocialButton>
                                        ) : (
                                          <Field
                                            className="field form-control"
                                            type="text"
                                            name={this.state.names[index]}
                                            value={values.facebook_id}
                                            disabled
                                          />
                                        );
                                      } else if (field.label === "Pin *") {
                                        return (
                                          <Field
                                            className="field form-control"
                                            disabled={field.disabled}
                                            type="text"
                                            name={this.state.names[index]}
                                            maxLength={6}
                                            minLength={6}
                                          />
                                        );
                                      } else if (field.label === "Children") {
                                        return (
                                          this.state.maritialSelected != "83" &&
                                          this.state.maritialSelected != "27" &&
                                          this.state.maritialSelected != "" && (
                                            <Field
                                              className="field form-control"
                                              disabled={field.disabled}
                                              type="text"
                                              name={this.state.names[index]}
                                              maxlength={2}
                                            />
                                          )
                                        );
                                      } else if (field.name === "numbrother") {
                                        return (
                                          <input
                                            className="field form-control"
                                            onChange={(e) =>
                                              this.setState({
                                                noOfBrothers: e.target.value,
                                              })
                                            }
                                            value={this.state.noOfBrothers}
                                            type="text"
                                            name={field.name}
                                          />
                                        );
                                      } else if (
                                        field.name === "numbrothers_married"
                                      ) {
                                        return (
                                          <input
                                            className="field form-control"
                                            onChange={(e) =>
                                              this.setState({
                                                noOfBrothersMarried:
                                                  e.target.value,
                                              })
                                            }
                                            value={
                                              this.state.noOfBrothersMarried
                                            }
                                            type="text"
                                            name={field.name}
                                          />
                                        );
                                      } else if (field.name === "num_sisters") {
                                        return (
                                          <input
                                            className="field form-control"
                                            onChange={(e) =>
                                              this.setState({
                                                noOfSisters: e.target.value,
                                              })
                                            }
                                            value={this.state.noOfSisters}
                                            type="text"
                                            name={field.name}
                                          />
                                        );
                                      } else if (
                                        field.name === "numsisters_married"
                                      ) {
                                        return (
                                          <input
                                            className="field form-control"
                                            onChange={(e) =>
                                              this.setState({
                                                noOfSistersMarried:
                                                  e.target.value,
                                              })
                                            }
                                            value={
                                              this.state.noOfSistersMarried
                                            }
                                            type="text"
                                            name={field.name}
                                          />
                                        );
                                      } else if (
                                        field.label ===
                                        "I agree to the Terms & Conditions"
                                      ) {
                                        return <></>;
                                      } else if (
                                        field.label == "Alternative number"
                                      ) {
                                        return (
                                          <div className="d-flex">
                                            <Field
                                              autocomplete={false}
                                              className="form-control form-control-color mob-prefix"
                                              name="country_code_land"
                                              as="select"
                                            >
                                              {prefixList?.map((prefix, i) => (
                                                <option
                                                  key={i}
                                                  value={prefix.id}
                                                >
                                                  {prefix.id}
                                                </option>
                                              ))}
                                            </Field>
                                            <Field
                                              className="field form-control"
                                              disabled={field.disabled}
                                              type="text"
                                              name={this.state.names[index]}
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <Field
                                              className="field form-control"
                                              disabled={field.disabled}
                                              type="text"
                                              name={this.state.names[index]}
                                            />
                                            {field.label === "Email id" &&
                                              this.state.isEmailExist && (
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  Email already exists
                                                </div>
                                              )}
                                          </>
                                        );
                                      }
                                  }
                                })()}
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errors[this.state.names[index]] &&
                                  touched[this.state.names[index]]
                                    ? errors[this.state.names[index]]
                                    : ""}
                                </div>
                                <Modal
                                  show={this.state.showInstaModal}
                                  onHide={() =>
                                    this.setState({ showInstaModal: false })
                                  }
                                >
                                  <Modal.Body className="d-flex flex-column align-items-center px-5">
                                    <iframe
                                      className="h-100 w-100"
                                      src={
                                        "https://www.instagram.com/oauth/authorize?client_id=497528055363661&redirect_uri=https://click4marry.com/app/Coupon_code/instagramme_login&scope=user_profile&response_type=code"
                                      }
                                      title="instagram-login"
                                      target="_parent"
                                    ></iframe>
                                  </Modal.Body>
                                </Modal>
                              </div>
                            </>
                          ))}
                          <div className="d-flex justify-content-between align-items-center">
                            <span onClick={this.cancelCourse}>
                              <Link className="back-button" to="/login">
                                {this.state.title === "Free Registration"
                                  ? "Back to login"
                                  : ""}
                              </Link>
                            </span>
                            <Button
                              className="register-login-button"
                              type="submit"
                              disabled={this.state.submitting}
                            >
                              {this.state.title === "Free Registration"
                                ? "Register Free"
                                : "Next"}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                ) : (
                  <Loader></Loader>
                )}
              </Card>
            </Col>
          </Row>
        ) : this.state.title != "Partner Preference" ? (
          <Row style={{ height: "683px" }}>
            <Col md={6} xs={12} className="p-0 position-relative">
              <Image src={banner2} className="register-banner" />
              <div className="banner-text">
                <h1 className="banner-head">
                  Bond of <br />
                  safety in Love
                </h1>
                <p className="banner-para">
                  The best place to meet with <br /> your future partner
                </p>
                <h6 className="banner-register">{this.state.title}</h6>
              </div>
            </Col>
            <Col className="p-0" md={6} xs={12} style={{ height: "100%" }}>
              <Card className="p-2 m-0 register-card" style={{ color: "#000" }}>
                <Card.Title
                  className="d-flex justify-content-center"
                  style={{ marginTop: "132px" }}
                >
                  {this.state.title}
                </Card.Title>
                <Card.Title className="d-flex justify-content-center">
                  Your ID is{" "}
                  <span
                    style={{
                      color: "var(--primary-color)",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    {" "}
                    {localStorage.getItem("currentUser")}
                  </span>
                </Card.Title>
                <Card.Body className="register-body d-flex justify-content-center flex-column px-0 align-items-center">
                  <Card.Text>
                    We have send you a one time password to this mobile number
                  </Card.Text>
                  <Card.Text>
                    Enter the OTP send to ********{this.state.mobile.slice(8)}
                  </Card.Text>

                  <Form.Control
                    className="mt-1 otpfreeregi"
                    style={{
                      width: "30%",
                      minWidth: "130px",
                      textAlign: "center",
                    }}
                    onInput={(e) => {
                      this.changeOtpValue(e.target.value);
                    }}
                    placeholder="Enter OTP"
                    type="text"
                    value={this.state.otp}
                    maxlength="6"
                  />

                  {/* <OTPInput
                    autoFocus
                    value={this.state.otp}
                    onChange={(event) => {
                      this.setState({
                        otp: event,
                      });
                    }}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    inputClassName="otp"
                  /> */}
                  <div
                    className="d-flex"
                    style={{ color: "#5B6770", marginTop: "30px" }}
                  >
                    Didn't you recieve the OTP?
                    <ResendOTP
                      onResendClick={this.resend}
                      renderButton={this.renderButton}
                      renderTime={this.renderTime}
                    />
                  </div>
                  <div
                    style={{
                      marginBottom: "20px",
                      marginTop: "32px",
                      fontWeight: "600",
                    }}
                    className="w-100 form-text d-flex justify-content-around align-items-center"
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        width: "20%",
                        marginRight: "5px",
                      }}
                      onClick={() => {
                        this.setState({
                          otp: "",
                          title: "Free Registration",
                          initialValues: {
                            profile_for:
                              this.props?.values?.profile_for ||
                              registeredValues.profile_for ||
                              "",
                            name:
                              this.props?.values?.name ||
                              registeredValues.name ||
                              "",
                            gender:
                              this.props?.values?.gender ||
                              registeredValues.gender ||
                              "",
                            phone:
                              this.props?.values?.phone ||
                              registeredValues.phone ||
                              "",
                            password:
                              this.props?.values?.password ||
                              registeredValues.password ||
                              "",
                          },
                        });
                      }}
                    >
                      Back
                    </div>
                    <Button
                      onClick={this.verify}
                      className="otp-verify-button"
                      style={{ width: "80%" }}
                    >
                      Verify
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row style={{}}>
            <Col md={6} xs={12} className="p-0 position-relative">
              <Image src={banner2} className="register-banner" />
              <div className="banner-text">
                <h1 className="banner-head">
                  Bond of <br />
                  safety in Love
                </h1>
                <p className="banner-para">
                  The best place to meet with <br /> your future partner
                </p>
                <h6 className="banner-register">{this.state.title}</h6>
              </div>
            </Col>
            <Col className="p-0" md={6} xs={12} style={{ height: "100%" }}>
              <Card className="p-0 m-0 register-card">
                {!this.state.isLoading ? (
                  <Card.Body className="register-body">
                    <div
                      style={{
                        marginBottom: "20px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={this.previous}
                      className="form-text"
                    >
                      Back
                    </div>
                    {this.state.fields?.map((field, index) => (
                      <div style={{ color: "#000" }} className="mb-3 d-flex">
                        {field.label === "Age From" ? (
                          <div style={{ width: "100%" }}>
                            <label className="label form-label">
                              {field.label}
                            </label>
                            <select
                              onChange={(e) => {
                                this.selectSingle(e, index);
                                this.setState({ ageFrom: e.target.value });
                              }}
                              className="form-control"
                            >
                              <option selected value="">
                                Any
                              </option>
                              {field.options?.map((option) => (
                                <option
                                  value={option}
                                  hidden={
                                    this.state.ageTo == ""
                                      ? option <= this.state.ageTo
                                      : option >= this.state.ageTo
                                  }
                                >
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : field.label === "Age To" ? (
                          <div style={{ width: "100%" }}>
                            <label className="label form-label">
                              {field.label}
                            </label>
                            <select
                              onChange={(e) => {
                                this.selectSingle(e, index);
                                this.setState({ ageTo: e.target.value });
                              }}
                              className="form-control"
                            >
                              <option selected value="">
                                Any
                              </option>
                              {field.options?.map((option) => (
                                <option
                                  value={option}
                                  hidden={option <= this.state.ageFrom}
                                >
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : field.label === "Height(cm) From" ? (
                          <div style={{ width: "100%" }}>
                            <label className="label form-label">
                              {field.label}
                            </label>
                            <select
                              onChange={(e) => {
                                this.selectSingle(e, index);
                                this.setState({ heightFrom: e.target.value });
                                if (this.state.heightFrom == "") {
                                  this.setState({ heightTo: "" });
                                }
                              }}
                              className="form-control"
                            >
                              <option selected value="">
                                Any
                              </option>
                              {field.options?.map((option) => (
                                <option
                                  value={option}
                                  hidden={
                                    this.state.heightTo == ""
                                      ? option <= this.state.heightTo
                                      : option >= this.state.heightTo
                                  }
                                >
                                  {option} cm
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : field.label === "Height(cm) To" ? (
                          <div style={{ width: "100%" }}>
                            <label className="label form-label">
                              {field.label}
                            </label>
                            <select
                              disabled={this.state.heightFrom == ""}
                              onChange={(e) => {
                                this.selectSingle(e, index);
                                this.setState({ heightTo: e.target.value });
                              }}
                              className="form-control"
                            >
                              <option selected value="">
                                Any
                              </option>
                              {field.options?.map((option) => (
                                <option
                                  value={option}
                                  hidden={option <= this.state.heightFrom}
                                >
                                  {option} cm
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : field.label === "Weight(kg) From" ? (
                          <div style={{ width: "100%" }}>
                            <label className="label form-label">
                              {field.label}
                            </label>
                            <select
                              onChange={(e) => {
                                this.selectSingle(e, index);
                                this.setState({ weightFrom: e.target.value });
                              }}
                              className="form-control"
                            >
                              <option selected value="">
                                Any
                              </option>
                              {field.options?.map((option) => (
                                <option
                                  value={option}
                                  hidden={
                                    this.state.weightTo == ""
                                      ? option <= this.state.weightTo
                                      : option >= this.state.weightTo
                                  }
                                >
                                  {option} kg
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : field.label === "Weight(kg) To" ? (
                          <div style={{ width: "100%" }}>
                            <label className="label form-label">
                              {field.label}
                            </label>
                            <select
                              onChange={(e) => {
                                this.selectSingle(e, index);
                                this.setState({ weightTo: e.target.value });
                              }}
                              className="form-control"
                            >
                              <option selected value="">
                                Any
                              </option>
                              {field.options?.map((option) => (
                                <option
                                  value={option}
                                  hidden={option <= this.state.weightFrom}
                                >
                                  {option} kg
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : field.label === "Smoke" ||
                          field.label === "Drink" ? (
                          <div style={{ width: "100%" }}>
                            <label className="label form-label">
                              {field.label}
                            </label>
                            <select
                              onChange={(e) => this.selectSingle(e, index)}
                              className="form-control"
                            >
                              {/* <option selected value=''>Any</option> */}
                              {listValues[field?.listValue]?.map((option) =>
                                field.label === "Smoke"
                                  ? option.id != "114" && (
                                      <option value={option.id}>
                                        {option.name}
                                      </option>
                                    )
                                  : field.label === "Drink"
                                  ? option.id != "115" && (
                                      <option value={option.id}>
                                        {option.name}
                                      </option>
                                    )
                                  : null
                              )}
                            </select>
                          </div>
                        ) : (
                          <div className="w-100">
                            {field.label == "District/Place" &&
                              this.bodyValue["country_living"] == 104 && (
                                <label className="label form-label">
                                  {field.label}
                                </label>
                              )}
                            {field.label != "District/Place" && (
                              <label className="label form-label">
                                {field.label}
                              </label>
                            )}
                            {this.state.names[index] === "religion" ? (
                              <Select
                                isSearchable={false}
                                selected={
                                  registeredValues?.religion === "any"
                                    ? listValues[field?.listValue]
                                    : religions.filter(
                                        (e) =>
                                          e.id === registeredValues?.religion
                                      )
                                }
                                options={[
                                  { id: "any", name: "Any" },
                                  religions.filter(
                                    (e) => e.id === registeredValues?.religion
                                  )[0],
                                ]}
                                defaultValue={
                                  this.filterSelecter(
                                    registeredValues?.religion,
                                    field?.options ||
                                      listValues[field?.listValue]
                                  ).filter(
                                    (e) => e.id === registeredValues?.religion
                                  )[0]
                                }
                                getOptionLabel={(options) => options["name"]}
                                getOptionValue={(options) => options["id"]}
                                components={animatedComponents}
                                onChange={(value) => {
                                  this.getOptions(
                                    value,
                                    this.state.names[index],
                                    value
                                  );
                                }}
                              />
                            ) : this.state.names[index] == "country_living" ? (
                              <Select
                                isSearchable={
                                  window.innerWidth < 425 ? false : true
                                }
                                closeMenuOnSelect={false}
                                closeMenuOnScroll={false}
                                blurInputOnSelect={false}
                                isMulti
                                placeholder={
                                  this.state.title === "Partner Preference"
                                    ? "Any"
                                    : ""
                                }
                                options={
                                  field?.options || listValues[field?.listValue]
                                }
                                getOptionLabel={(options) => options["name"]}
                                getOptionValue={(options) => options["id"]}
                                components={animatedComponents}
                                onChange={(values) =>
                                  this.selectMultiple(values, index)
                                }
                              />
                            ) : this.state.names[index] == "state" ? (
                              <Select
                                isSearchable={
                                  window.innerWidth < 425 ? false : true
                                }
                                closeMenuOnSelect={false}
                                closeMenuOnScroll={false}
                                blurInputOnSelect={false}
                                isMulti
                                placeholder={
                                  this.state.title === "Partner Preference"
                                    ? "Any"
                                    : ""
                                }
                                options={p_states}
                                getOptionLabel={(options) => options["name"]}
                                getOptionValue={(options) => options["id"]}
                                components={animatedComponents}
                                onChange={(values) =>
                                  this.selectMultiple(values, index)
                                }
                              />
                            ) : this.state.names[index] == "district" ? (
                              this.bodyValue["country_living"] != 104 ? (
                                <></>
                              ) : (
                                <Select
                                  isSearchable={
                                    window.innerWidth < 425 ? false : true
                                  }
                                  closeMenuOnSelect={false}
                                  closeMenuOnScroll={false}
                                  blurInputOnSelect={false}
                                  isMulti
                                  placeholder={
                                    this.state.title === "Partner Preference"
                                      ? "Any"
                                      : ""
                                  }
                                  options={p_districts}
                                  getOptionLabel={(options) => options["name"]}
                                  getOptionValue={(options) => options["id"]}
                                  components={animatedComponents}
                                  onChange={(values) =>
                                    this.selectMultiple(values, index)
                                  }
                                />
                              )
                            ) : (
                              <Select
                                isSearchable={
                                  window.innerWidth < 425 ? false : true
                                }
                                closeMenuOnSelect={false}
                                closeMenuOnScroll={false}
                                blurInputOnSelect={false}
                                isMulti
                                placeholder={
                                  this.state.title === "Partner Preference"
                                    ? "Any"
                                    : ""
                                }
                                options={
                                  field?.options?.filter(
                                    (option) => option.id != 28
                                  ) ||
                                  listValues[field?.listValue].filter(
                                    (option) => option.id != 28
                                  )
                                }
                                // options={
                                //   field?.options || listValues[field?.listValue]
                                // }
                                getOptionLabel={(options) => options["name"]}
                                getOptionValue={(options) => options["id"]}
                                components={animatedComponents}
                                onChange={(values) => {
                                  //;
                                  this.selectMultiple(values, index);
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        onClick={() => this.next(this.bodyValue)}
                        className="register-login-button"
                        Sub
                        Group
                        type="submit"
                        disabled={this.state.submitting}
                      >
                        Next
                      </Button>
                    </div>
                    {this.state.navigate ? <NavigateTo></NavigateTo> : ""}
                  </Card.Body>
                ) : (
                  <Loader></Loader>
                )}
              </Card>
            </Col>
          </Row>
        )}

        {
          <Modal
            show={this.state.showSuccessComplete}
            className="d-flex flex-column align-items-center"
            style={{ zIndex: "10000" }}
          >
            <Modal.Title
              className="title d-flex justify-content-center"
              style={{ textAlign: "center" }}
            >
              You have completed registration successfully
            </Modal.Title>
            <Modal.Body>
              <span style={{ fontSize: "18px", color: "#8b8484" }}>
                Your ID is
              </span>{" "}
              <span style={{ fontSize: "18px", color: "var(--primary-color)" }}>
                {localStorage.currentUser}
              </span>
            </Modal.Body>
            <Link
              onClick={(e) => {
                e.stopPropagation();
              }}
              to="/myprofile/album-upload"
              className="btn"
              style={{
                color: "var(--primary-color)",
                width: "auto",
                border: "1px solid var(--primary-color)",
                margin: "10px 10px",
                padding: "10px",
              }}
            >
              Upload Photo
            </Link>
            <div
              onClick={() => {
                this.setState({ navigate: true });
              }}
              className="btn"
              style={{
                backgroundColor: "var(--primary-color)",
                color: "#FFF",
                width: "auto",
                border: "none",
                margin: "10px 10px",
                padding: "10px",
              }}
            >
              Continue to Home
            </div>
          </Modal>
        }
      </div>
    );
  }
}
