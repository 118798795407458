import { Field, Formik } from "formik";
import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import { BsX } from "react-icons/bs";
import SocialButton from "../SocialButton/SocialButton";
import { environment } from "../../environment/env";

const baseURL = environment.hostname;
const animatedComponents = makeAnimated();
let initialValues = {};
let bodyValue = {};
let newArray = [];

export default class EditDetails extends Component {
  constructor() {
    super();
    this.state = {
      changeFbId: false,
      changedQualification: false,
      showMultiSelect: false,
      selectedField: "",
      selectedName: "",
      options: [],
      checked: false,
      noValue: "Any",
      isVisibleAllegience: false,
      isFatherAlive: "",
      isMotherAlive: "",
      selectedFatherOccupation: "",
      selectedMotherOccupation: "",
      noOfBrothers: null,
      noOfBrothersMarried: null,
      noOfSisters: null,
      noOfSistersMarried: null,
      isEmailExist: false,
      selectedPartnerCountry_living: [],
      selectedPartnerQualification: [],
      selectedPartnerOccupation: [],
      selectedPartnerSector: [],
      selectedPartnerMarritialStatus: [],
      selectedPartnerPhysicalStatus: [],
      selectedPartnerComplexion: [],
      selectedPartnerBodytype: [],
      selectedPartnerDiet: [],
      selectedPartnerFamilyType: [],
      selectedPartnerStates: [],
      selectedPartnerDistricts: [],
      optionsPartnerDistricts: [],
      selectedPartnerCast: [],
      selectedHobbies: [],
      selectedCountry: "",
      selectedState: "",
      selectedDistrict: "",

      statesList: [],
      selectedWhatsappCountryCode: "+91",
      selectedAlternateCountryCode: "+91",
    };
  }

  componentDidMount() {
    // prefixListApi = fetch(`${baseURL}/prefixlist`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    if (
      this.props.editInfo &&
      this.props.editInfo[6] &&
      this.props.editInfo[6].listValue
    ) {
      const selectedStateObject = this.props.editInfo[6].listValue.find(
        (item) => item.name == this.props.editInfo[6].value
      );
      this.setState({
        selectedState:
          selectedStateObject === undefined ? 0 : selectedStateObject.id,
      });
    }
    // const selectedDistrictObject = this.props.editInfo[7].listValue.find(
    //   (item) => item.name == this.props.editInfo[7].value
    // );
    // this.setState({
    //   selectedDistrict:
    //     selectedDistrictObject === undefined ? 0 : selectedDistrictObject.id,
    // });
    // //;
    if (this.props.title === "Family Details") {
      this.setState({ isFatherAlive: this.props.editInfo[2].value });
      this.setState({ isMotherAlive: this.props.editInfo[4].value });
      this.setState({ selectedFatherOccupation: this.props.editInfo[3].value });
      this.setState({ selectedMotherOccupation: this.props.editInfo[5].value });
      this.setState({ noOfBrothers: this.props.editInfo[6].value });
      this.setState({ noOfBrothersMarried: this.props.editInfo[7].value });
      this.setState({ noOfSisters: this.props.editInfo[8].value });
      this.setState({ noOfSistersMarried: this.props.editInfo[9].value });

      // this.setState({ selectedDistrict: this.props?.editInfo[7].value });
    }
    // //;

    this.props.editInfo.map((info) => {
      return info.key
        ? (initialValues[info.name] =
            info.listValue &&
            info.listValue.filter((list) => info.value === list.id).length != 0
              ? info.listValue.filter((list) => info.value === list.id)[0].id
              : info.value)
        : "";
    });

    // newArray = this.props.editInfo[9].listValue?.map((option) => {
    //   if (option.id === "28") {
    //     option.income = "Any";
    //     option.name = "Any";
    //   }
    //   return option;
    // });
    // });
    if (
      this.props.editInfo &&
      this.props.editInfo[9] &&
      this.props.editInfo[9].listValue
    ) {
      newArray = this.props.editInfo[9].listValue?.filter(
        (item) => item.id !== "28"
      );
    }

    this.setState({
      selectedWhatsappCountryCode: this.props.countrycodes.selectedWhatsapp,
      selectedAlternateCountryCode: this.props.countrycodes.selectedAlternate,
    });

    if (this.props.title === "Partner Preference") {
      this.props.editInfo[1].listValue.splice(0, 0, { name: "Any", id: "" });
      this.props.editInfo[2].listValue = this.props.editInfo[2]?.listValue?.map(
        (list) => {
          return initialValues.subgroup
            ?.split(" ,")
            .find((sub) => sub.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        }
      );
      let temp = [];
      temp = this.props.editInfo[2].listValue?.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerCast: temp });

      this.props.editInfo[3].listValue = this.props.editInfo[3]?.listValue?.map(
        (list) => {
          return initialValues.country_living
            ?.split(" ,")
            .find((country) => country.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        }
      );
      temp = [];
      temp = this.props.editInfo[3].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerCountry_living: temp });
      if (temp.length == 1) {
        this.getStatesPartner(temp, false);
      }

      this.props.editInfo[4].listValue = this.props.editInfo[4]?.listValue?.map(
        (list) => {
          return initialValues.state
            ?.split(" ,")
            .find((state) => state.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        }
      );
      temp = [];
      temp = this.props.editInfo[4].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerStates: temp });
      if (temp.length == 1) {
        this.getDistricts(temp, false);
      } else {
      }

      // this.props.editInfo[5].listValue = this.props.editInfo[5]?.listValue?.map(
      //   (list) => {
      //     return initialValues.district
      //       ?.split(" ,")
      //       .find((district) => district.trimRight() === list.name)
      //       ? { ...list, selected: true }
      //       : { ...list, selected: false };
      //   }
      // );
      temp = [];
      temp = this.props.editInfo[5].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({
        selectedPartnerDistricts: temp,
        optionsPartnerDistricts: this.props.editInfo[5].listValue,
      });

      this.props.editInfo[6].listValue = this.props.editInfo[6]?.listValue?.map(
        (list) => {
          return initialValues.qualification
            ?.split(" ,")
            .find((job) => job.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        }
      );
      temp = [];
      temp = this.props.editInfo[6].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerQualification: temp });

      this.props.editInfo[7].listValue = this.props.editInfo[7]?.listValue?.map(
        (list) => {
          return initialValues.occupation
            ?.split(" ,")
            .find((qual) => qual.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        }
      );
      temp = [];
      temp = this.props.editInfo[7].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerOccupation: temp });

      this.props.editInfo[8].listValue = this.props.editInfo[8]?.listValue?.map(
        (list) => {
          return initialValues.job_sector
            ?.split(" ,")
            .find((sector) => sector.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        }
      );
      temp = [];
      temp = this.props.editInfo[8].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerSector: temp });
      // this.props.editInfo[9].listValue = this.props.editInfo[9]?.listValue?.map(list => {
      //     return initialValues.income?.split(' ,').find(sector => sector.trimRight() === list.name) ?
      //         ({ ...list, selected: true }) : ({ ...list, selected: false })
      // })

      this.props.editInfo[10].listValue =
        this.props.editInfo[10]?.listValue?.map((list) => {
          return initialValues.marital_status
            ?.split(" ,")
            .find((marital) => marital.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        });
      temp = [];
      temp = this.props.editInfo[10].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerMarritialStatus: temp });

      this.props.editInfo[11].listValue =
        this.props.editInfo[11]?.listValue?.map((list) => {
          return initialValues.physical_status
            ?.split(" ,")
            .find((physical) => physical.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        });
      temp = [];
      temp = this.props.editInfo[11].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerPhysicalStatus: temp });

      this.props.editInfo[12].listValue =
        this.props.editInfo[12]?.listValue?.map((list) => {
          return initialValues.complexion
            ?.split(" ,")
            .find((complex) => complex.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        });
      temp = [];
      temp = this.props.editInfo[12].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerComplexion: temp });

      this.props.editInfo[13].listValue =
        this.props.editInfo[13]?.listValue?.map((list) => {
          return initialValues.body_type
            ?.split(" ,")
            .find((body) => body.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        });
      temp = [];
      temp = this.props.editInfo[13].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerBodytype: temp });

      this.props.editInfo[14].listValue =
        this.props.editInfo[14]?.listValue?.map((list) => {
          return initialValues.diet
            ?.split(" ,")
            .find((dt) => dt.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        });
      temp = [];
      temp = this.props.editInfo[14].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerDiet: temp });

      this.props.editInfo[17].listValue =
        this.props.editInfo[17]?.listValue?.map((list) => {
          return initialValues.family_status
            ?.split(" ,")
            .find((family) => family.trimRight() === list.name)
            ? { ...list, selected: true }
            : { ...list, selected: false };
        });
      temp = [];
      temp = this.props.editInfo[17].listValue.filter(
        (item) => item.selected == true
      );
      this.setState({ selectedPartnerFamilyType: temp });

      this.props.editInfo[18].listValue = this.props.editInfo[18]?.listValue;
      this.props.editInfo[19].listValue = this.props.editInfo[19]?.listValue;

      if (initialValues.religion != "Any") {
        let rel = this.props.editInfo[1].listValue.find(
          (list) => list.name === initialValues.religion
        );
        this.getOptions(rel, "religion", "");
      }
    } else if (this.props.title === "Thoughts/Lifestyle") {
      if (initialValues.hobbies != "") {
        this.props.editInfo[5].listValue =
          this.props.editInfo[5]?.listValue?.map((list) => {
            return initialValues.hobbies.find(
              (hobby) => hobby?.trimRight() === list.id
            )
              ? { ...list, selected: true }
              : { ...list, selected: false };
          });
        let templ = [];
        templ = this.props.editInfo[5].listValue.filter(
          (item) => item.selected == true
        );
        this.setState({ selectedHobbies: templ });
      }
    } else if (this.props?.editInfo[0].title == "Permanent Address") {
      this.setState({ selectedCountry: this.props?.editInfo[5].value });

      this.getStatesByCountry(this.props?.editInfo[5].value, false);
    }
    this.setVisibilityAllegience();
  }
  componentWillUnmount() {
    initialValues = {};
  }

  edit = (values) => {
    // //;
    values.cityId = values.city;
    let updateValue = {};
    updateValue.uid = localStorage.currentUser;
    updateValue.ip = "111.111.1.1";
    updateValue.check_user_id = localStorage.currentUser;
    updateValue.check_session_id = localStorage.session;
    updateValue.type = "profile";

    values.id = localStorage.currentUser;
    values.ip = "111.111.1.1";
    values.check_user_id = localStorage.currentUser;
    values.check_session_id = localStorage.session;
    values.type = "profile";

    values.countrycode_whatsapp = this.state.selectedWhatsappCountryCode;
    values.countrycode_land = this.state.selectedAlternateCountryCode;

    if (this.state.isEmailExist) {
      values.email = this.props.editInfo[21].value;
    }
    if (this.props?.editInfo[0].title == "Permanent Address") {
      values.country = this.state.selectedCountry;
    }

    if (values.city != null) {
      var elementPos = this.state.statesList
        .map(function (x) {
          return x.id;
        })
        .indexOf(values.city);
      if (elementPos == -1) {
        values.city = this.state.statesList[0].id;
      }
    }
    if (values.place != null) {
      var elementPos = this.props.editInfo[7].listValue
        .map(function (x) {
          return x.id;
        })
        .indexOf(values.place);
      if (elementPos == -1) {
        values.place = this.props.editInfo[7].listValue[0].id;
      }
    }

    if (this.props.title === "Family Details") {
      values.num_brothers = this.state.noOfBrothers;
      values.num_brothers_married = this.state.noOfBrothersMarried;
      values.num_sisters = this.state.noOfSisters;
      values.num_sisters_married = this.state.noOfSistersMarried;
    }

    if (this.props.title === "Thoughts/Lifestyle") {
      values.hobby = this.state.selectedHobbies?.map((sel) => sel.id).join("~");
    }
    if (this.props.title === "Partner Preference") {
      bodyValue.subgroup = this.state.selectedPartnerCast?.map((sel) => sel.id);
      bodyValue.country_living = this.state.selectedPartnerCountry_living?.map(
        (sel) => sel.id
      );
      bodyValue.state = this.state.selectedPartnerStates?.map((sel) => sel.id);
      bodyValue.district =
        bodyValue.state.length > 1
          ? []
          : this.state.selectedPartnerDistricts.map((sel) => sel.id);

      bodyValue.qualification = this.state.selectedPartnerQualification?.map(
        (sel) => sel.id
      );
      bodyValue.occupation = this.state.selectedPartnerOccupation?.map(
        (sel) => sel.id
      );
      bodyValue.job_sector = this.state.selectedPartnerSector?.map(
        (sel) => sel.id
      );
      bodyValue.marital_status = this.state.selectedPartnerMarritialStatus?.map(
        (sel) => sel.id
      );
      bodyValue.physical_status = this.state.selectedPartnerPhysicalStatus?.map(
        (sel) => sel.id
      );
      bodyValue.complexion = this.state.selectedPartnerComplexion?.map(
        (sel) => sel.id
      );
      bodyValue.body_type = this.state.selectedPartnerBodytype?.map(
        (sel) => sel.id
      );
      bodyValue.diet = this.state.selectedPartnerDiet?.map((sel) => sel.id);
      bodyValue.family_status = this.state.selectedPartnerFamilyType?.map(
        (sel) => sel.id
      );

      if (bodyValue.ageFrom || bodyValue.ageTo)
        updateValue.age =
          bodyValue.ageFrom && bodyValue.ageTo
            ? `${bodyValue.ageFrom}-${bodyValue.ageTo}`
            : bodyValue.ageFrom
            ? `${bodyValue.ageFrom}-60`
            : `18-${bodyValue.ageTo}`;
      if (bodyValue.religion) {
        updateValue.religion = bodyValue.religion;
      } else {
        updateValue.religion = "";
      }
      updateValue.caste =
        bodyValue.subgroup && bodyValue.subgroup?.length != 0
          ? bodyValue.subgroup.join("~")
          : "";
      updateValue.country_living =
        bodyValue.country_living.length != 0
          ? bodyValue.country_living.join("~")
          : "";
      updateValue.city =
        bodyValue.state.length != 0 ? bodyValue.state.join("~") : "";
      updateValue.place =
        bodyValue.district.length != 0 ? bodyValue.district.join("~") : "";
      updateValue.occupation =
        bodyValue.occupation.length != 0 ? bodyValue.occupation.join("~") : "";
      updateValue.qualification =
        bodyValue.qualification.length != 0
          ? bodyValue.qualification.join("~")
          : "";
      if (bodyValue.income) {
        updateValue.annual_income = bodyValue.income;
      }
      updateValue.job_sector =
        bodyValue.job_sector.length != 0 ? bodyValue.job_sector.join("~") : "";
      updateValue.marital_status =
        bodyValue.marital_status.length != 0
          ? bodyValue.marital_status.join("~")
          : "";
      updateValue.physical_status =
        bodyValue.physical_status.length != 0
          ? bodyValue.physical_status.join("~")
          : "";
      updateValue.complexion =
        bodyValue.complexion.length != 0 ? bodyValue.complexion.join("~") : "";
      updateValue.body_type =
        bodyValue.body_type.length != 0 ? bodyValue.body_type.join("~") : "";
      updateValue.diet =
        bodyValue.diet.length != 0 ? bodyValue.diet.join("~") : "";
      updateValue.body_type =
        bodyValue.body_type.length != 0 ? bodyValue.body_type.join("~") : "";
      if (bodyValue.heightFrom || bodyValue.heightTo)
        updateValue.height =
          bodyValue.heightFrom && bodyValue.heightTo
            ? `${bodyValue.heightFrom}-${bodyValue.heightTo}`
            : bodyValue.heightFrom
            ? `${bodyValue.heightFrom}-215`
            : `135-${bodyValue.heightTo}`;
      if (bodyValue.weightFrom || bodyValue.weightTo)
        updateValue.weight =
          bodyValue.weightFrom && bodyValue.weightTo
            ? `${bodyValue.weightFrom}-${bodyValue.weightTo}`
            : bodyValue.weightFrom
            ? `${bodyValue.weightFrom}-120`
            : `35-${bodyValue.weightTo}`;
      updateValue.family_status =
        bodyValue.family_status.length != 0
          ? bodyValue.family_status.join("~")
          : "";
      if (bodyValue.smoke) updateValue.smoke = bodyValue.smoke;
      if (bodyValue.drink) updateValue.drink = bodyValue.drink;
      fetch(`${baseURL}/save_partner_preference`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateValue),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            this.props.close("true");
          } else
            toast.error("an error occured while editing", {
              position: toast.POSITION.TOP_CENTER,
            });
          this.props.close("true");
        });
    } else {
      // //;
      fetch(`${baseURL}/update_profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            this.props.close("true");
            this.setState({ selectedState: values.cityId });
          } else
            toast.error("an error occured while editing", {
              position: toast.POSITION.TOP_CENTER,
            });
          this.props.close("true");
        })
        .catch((error) => toast.error("An Error occured while editing"));
    }
  };
  getOptions = (e, key, values) => {
    if (key === "religion_id") {
      if (e.target.value == "5") {
        this.setState({ isVisibleAllegience: true });
      } else {
        this.setState({ isVisibleAllegience: false });
      }
    }

    if (key === "qualification") {
      if (values) values.qualification = e.target.value;
      fetch(`${baseURL}/getcourse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: e.target?.value || e,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          initialValues.course = "";
          this.props.editInfo.filter(
            (info) => info.name === "course"
          )[0].listValue = res;
          this.setState({
            changedQualification: true,
          });
        });
    } else if (key === "religion_id") {
      if (values) values.religion_id = e.target.value;
      fetch(`${baseURL}/caste`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: localStorage.currentUser,
          religion_id: e.target?.value || e,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          initialValues.caste_id = "";
          this.props.editInfo.filter(
            (info) => info.name === "caste_id"
          )[0].listValue = res;
          this.setState({
            changedQualification: true,
          });
        });
    } else if (
      this.props.title === "Partner Preference" &&
      key === "religion"
    ) {
      bodyValue.religion = e.id;
      initialValues.religion = e.name;
      fetch(`${baseURL}/caste`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: localStorage.currentUser,
          religion_id: e.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          initialValues.subgroup = "";
          this.props.editInfo.filter(
            (info) => info.name === "subgroup"
          )[0].listValue = res;
          this.setState({
            changedQualification: true,
          });
        });
    } else if (key === "city") {
      values.city = e.target.value;
      fetch(`${baseURL}/getdistrict`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          state_id: e.target?.value || e,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.props.editInfo.filter(
            (info) => info.name === "place"
          )[0].listValue = res;
          this.setState({
            changedQualification: true,
          });
        });
    }
  };

  getStatesPartner(e, isclear) {
    if (e.length == 1) {
      fetch(`${baseURL}/getstate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          country_id: e[0].id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (isclear) {
            this.props.editInfo[4].listValue = res;
            this.setState({
              // optionsPartnerDistricts:res,
              selectedPartnerStates: [],
            });
          } else {
            this.props.editInfo[4].listValue = res?.map((list) => {
              return initialValues.state
                ?.split(" ,")
                .find((item) => item.trimRight() === list.name)
                ? { ...list, selected: true }
                : { ...list, selected: false };
            });
            let temp = [];
            temp = this.props.editInfo[4].listValue.filter(
              (item) => item.selected == true
            );
            this.setState({ selectedPartnerStates: temp });
            if (temp.length == 1) {
              this.getDistricts(temp, false);
            }
          }
        });
    } else {
      this.props.editInfo[4].listValue = [];
      this.setState({
        selectedPartnerDistricts: [],
        optionsPartnerDistricts: [],
        selectedPartnerStates: [],
      });
    }
  }

  getDistricts(e, isclear) {
    if (e.length == 1) {
      fetch(`${baseURL}/getdistrict`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          state_id: e[0].id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (isclear) {
            this.props.editInfo[7].listValue[0].name == "Not working"
              ? ""
              : (this.props.editInfo[7].listValue = res);
            this.setState({
              optionsPartnerDistricts: res,
              selectedPartnerDistricts: [],
            });
          } else {
            this.props.editInfo[5].listValue = res?.map((list) => {
              return initialValues.district
                ?.split(" ,")
                .find((district) => district.trimRight() === list.name)
                ? { ...list, selected: true }
                : { ...list, selected: false };
            });
            let temp = [];
            temp = this.props.editInfo[5].listValue.filter(
              (item) => item.selected == true
            );
            this.setState({
              selectedPartnerDistricts: temp,
              optionsPartnerDistricts: this.props.editInfo[5].listValue,
            });
          }
        });
    } else {
      this.setState({
        selectedPartnerDistricts: [],
        optionsPartnerDistricts: [],
      });
    }
  }

  getStatesByCountry(countryId, isClear) {
    fetch(`${baseURL}/getstate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country_id: countryId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        // //;
        this.setState({ statesList: res });
        if (isClear) {
          this.getDistricts([{ id: res[0].id }], true);
        }
      });
  }

  selectSingle = (e, name) => {
    bodyValue[name] = e.target.value;
  };

  selectMultiple = (values, name) => {
    bodyValue[name] = values.map((value) => value.id);
  };

  setVisibilityAllegience = () => {
    this.setState({
      isVisibleAllegience: this.props?.editInfo[1].value === "5",
    });
  };

  handleFbLogin = async (user, field) => {
    await fetch(
      `https://graph.facebook.com/me?fields=link,name&access_token=${user._token.accessToken}`
    )
      .then((res) => res.json())
      .then((res) => {
        field.value = res.link;
        this.setState({
          changeFbId: false,
        });
      });
  };

  handleRefresh = (value, key) => {
    if (key === "father_alive") {
      if (value === "Yes") {
        initialValues.father_alive = "Yes";
        this.setState({ isFatherAlive: "Yes" });
      } else {
        initialValues.father_alive = "No";
        this.setState({ isFatherAlive: "No" });
        initialValues.father_occupation = "";
        this.setState({ selectedFatherOccupation: "" });
      }
    } else if (key === "mother_alive") {
      if (value === "Yes") {
        initialValues.mother_alive = "Yes";
        this.setState({ isMotherAlive: "Yes" });
      } else {
        initialValues.mother_alive = "No";
        this.setState({ isMotherAlive: "No" });
        initialValues.mother_occupation = "";
        this.setState({ selectedMotherOccupation: "" });
      }
    }
  };

  handleOccupation = (value, key) => {
    if (key == "father_occupation") {
      initialValues.father_occupation = value;
    } else if (key == "mother_occupation") {
      initialValues.mother_occupation = value;
    }
  };

  async checkEmailAlreadyExist(e) {
    if (this.props.editInfo[21].value == e) {
      this.setState({ isEmailExist: false });
    } else {
      fetch(`${baseURL}/email_exist_new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: e,
          id: localStorage.getItem("currentUser"),
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response === true) {
            this.setState({ isEmailExist: true });
          } else {
            this.setState({ isEmailExist: false });
          }
        });
    }
  }

  render() {
    return !this.state.showMultiSelect ? (
      <div>
        <Formik
          enableReinitialize={true}
          validate={(values) => {
            if (this.props.title === "Family Details") {
              values.num_brothers = this.state.noOfBrothers;
              values.num_brothers_married = this.state.noOfBrothersMarried;
              values.num_sisters = this.state.noOfSisters;
              values.num_sisters_married = this.state.noOfSistersMarried;
            }
            let errors = {};

            this.props.editInfo?.map((info, index) => {
              // //;
              if (values[info.name] && info.name === "phone") {
                // //;
                if (info.disabled == "true") {
                  // //;
                }
              }
              if (values[info.name] && info.name === "email") {
                let regex =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!regex.test(values[info.name])) {
                  this.setState({ isEmailExist: false });
                  return (errors[info.name] = "Provide a valid email address");
                }
                // else {
                //   this.checkEmailAlreadyExist(values[info.name]);
                // }
              }
              // if (values[info.name] && info.name === "facebook_id") {
              //   //;
              //   let regex =
              //     /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
              //   if (!regex.test(values[info.name])) {
              //     return (errors[info.name] = `Provide a valid Facebook ID`);
              //   }
              // }
              // if (values[info.name] && info.name === "instagramme_id") {
              //   let regex =
              //     /^\s*(http\:\/\/)?instagram\.com\/[a-z\d-_]{1,255}\s*$/i;
              //   if (!regex.test(values[info.name])) {
              //     return (errors[info.name] = `Provide a valid Instagram ID`);
              //   }
              // }
              if (values[info.name] && info.name === "name") {
                // //;
                let regex = /^[a-z A-Z]*\.?[a-z A-Z]*$/;
                if (!regex.test(values[info.name])) {
                  return (errors[info.name] = `Provide a valid ${info.name}`);
                }
                // if (regex.test(values[info.name]))
                //   return (errors[info.name] = `Provide a valid ${info.name}`);
                // let kerName = values[info.name];
                // if (kerName.replaceAll(" ", "").length < 3)
                //   return (errors[info.name] = `Provide a valid ${info.name}`);
              }
              try {
                if (values[info.name] && values[info.name].includes('"')) {
                  values[info.name] = values[info.name].replace('"', "");
                }
                if (values[info.name] && values[info.name].includes("'")) {
                  values[info.name] = values[info.name].replace("'", "");
                }
              } catch (e) {}
              if (values[info.name] && info.name === "land") {
                //;
                let regex = /^[1-9][0-9]{9,14}$/;
                let xx = this.props.countrycodes.list.filter(
                  (value) =>
                    (this.state.selectedAlternateCountryCode === undefined
                      ? "+91"
                      : this.state.selectedAlternateCountryCode) === value.id
                )[0]?.phoneno_length;
                if (
                  !/^[0-9]*$/.test(values[info.name]) ||
                  values[info.name]?.length < xx
                )
                  errors[info.name] = "Provide a valid mobile number";
              }

              if (
                values[info.name] &&
                info.name === "alternate_number"
                //   info.name === "land")
              ) {
                //;
                let regex = /^[1-9][0-9]{9,14}$/;
                let xx = this.props.countrycodes.list.filter(
                  (value) =>
                    (this.state.selectedAlternateCountryCode === undefined
                      ? "+91"
                      : this.state.selectedAlternateCountryCode) === value.id
                )[0]?.phoneno_length;
                if (
                  !/^[0-9]*$/.test(values[info.name]) ||
                  values[info.name]?.length < xx
                )
                  errors[info.name] = "Provide a valid mobile number";
              }
              if (
                values[info.name] &&
                info.name === "whatsappno"
                //  ||
                //   info.name === "alternate_number" ||
                //   info.name === "land")
              ) {
                //;
                let regex = /^[1-9][0-9]{9,14}$/;
                let xx = this.props.countrycodes.list.filter(
                  (value) =>
                    (this.state.selectedWhatsappCountryCode === undefined
                      ? "+91"
                      : this.state.selectedWhatsappCountryCode) === value.id
                )[0]?.phoneno_length;
                if (
                  !/^[0-9]*$/.test(values[info.name]) ||
                  values[info.name]?.length < xx
                )
                  errors[info.name] = "Provide a valid mobile number";
              }

              // return (errors[name] =
              //   "Provide a valid mobile number");
              //   if (!regex.test(values[info.name]))
              //     return (errors[info.name] = "Provide a valid mobile number");
              // }

              if (
                parseInt(values.num_brothers) <
                parseInt(values.num_brothers_married)
              ) {
                return (errors.num_brothers_married = `No of Brother's Married Should not greater than no of brothers`);
              }

              if (
                parseInt(values.num_sisters) <
                parseInt(values.num_sisters_married)
              ) {
                return (errors.num_sisters_married = `No of Sisters's Married Should not greater than no of Sisters`);
              }

              if (
                values[info.name] &&
                info?.validation &&
                info?.validation === "number"
              ) {
                let regex = /^[0-9\b]+$/;
                if (info.name == "pin") {
                  regex = /^[1-9][0-9]{5}$/;
                }
                if (!regex.test(values[info.name]))
                  return (errors[info.name] = `Provide a valid number`);
              }
              if (
                values[info.name] &&
                info?.validation &&
                info?.validation === "text"
              ) {
                let regex = /^[a-z A-Z]*\.?[a-z A-Z]*$/;
                if (!regex.test(values[info.name]))
                  return (errors[info.name] = `Provide a valid name`);
                let kerName = values[info.name];
                if (kerName.replaceAll(" ", "") < 6)
                  return (errors[info.name] = `Provide a valid name`);
              }
              if (
                values[info.name] &&
                info?.validation &&
                info?.validation === "nondigit"
              ) {
                let regex = /\D/g;
                if (!regex.test(values[info.name]))
                  return (errors[info.name] = `Provide a valid ${info.name}`);
              }
              if (values.pin) {
                if (values.pin.toString().length < 6)
                  return (errors.pin = `Pincode must be 6 digit`);
              }
              return 0;
            });
            return errors;
          }}
          initialValues={initialValues}
          onSubmit={(values) => this.edit(values)}
        >
          {({
            isSubmitting,
            dirty,
            handleChange,
            handleSubmit,
            errors,
            touched,
            values,
          }) => (
            <Form
              onSubmit={handleSubmit}
              onReset={() => {
                this.props.close("true");
              }}
            >
              {this.props.title != "Partner Preference" ? (
                this.props?.editInfo?.map((field, index) =>
                  field.key ? (
                    <div
                      style={{ marginBottom: "18px" }}
                      className="form-group mb-3"
                    >
                      {field.key === "Allegiance" || // Allegiance removed
                      (field.name === "is_orphan" &&
                        this.state.isFatherAlive !== "No") ||
                      (field.name === "father_occupation" &&
                        this.state.isFatherAlive !== "Yes") ||
                      (field.name === "place" &&
                        this.state.selectedCountry != 104) ||
                      (field.name === "mother_occupation" &&
                        this.state.isMotherAlive !== "Yes") ? (
                        <></>
                      ) : (
                        <label className="label form-label">{field.key}</label>
                      )}

                      {(() => {
                        switch (field.type) {
                          case "select":
                            if (
                              field.name === "qualification" ||
                              field.name === "religion_id"
                            )
                              return (
                                <Field
                                  className="field form-select"
                                  name={field.name}
                                  as="select"
                                  onChange={(e) =>
                                    this.getOptions(e, field.name, values)
                                  }
                                >
                                  <option default disabled value=""></option>
                                  {field.listValue?.map((opt) => (
                                    <option
                                      onClick={(e) =>
                                        this.setState({
                                          isVisibleAllegience:
                                            field.name === "religion_id" &&
                                            e.target.value == 5
                                              ? true //true to show allegiance
                                              : false,
                                        })
                                      }
                                      value={opt.id || opt}
                                    >
                                      {opt.name || opt}
                                    </option>
                                  ))}
                                </Field>
                              );
                            else if (field.name === "country") {
                              return (
                                <Field
                                  className="field form-select"
                                  name={field.name}
                                  as="select"
                                  value={this.state.selectedCountry}
                                  // required
                                  onChange={(e) => {
                                    this.setState({
                                      selectedCountry: e.target.value,
                                    }),
                                      // this.getOptions(e, "city", values),
                                      this.getStatesByCountry(
                                        e.target.value,
                                        true
                                      );
                                  }} ///
                                >
                                  <option default disabled value=""></option>
                                  {field.listValue?.map((opt) => (
                                    <option value={opt.id || opt}>
                                      {opt.name || opt}
                                    </option>
                                  ))}
                                </Field>
                              );
                            } else if (field.name === "city") {
                              return (
                                <Field
                                  className="field form-select"
                                  isSearchable={true}
                                  name={field.name}
                                  as="select"
                                  // value={
                                  //   this.state.selectedState === undefined
                                  //     ? 0
                                  //     : this.state.selectedState
                                  // }
                                  required
                                  onChange={(e) => {
                                    this.getOptions(e, field.name, values);
                                  }}
                                >
                                  <option default disabled value=""></option>
                                  {this.state.statesList?.map((opt) => {
                                    return (
                                      <option value={opt.id || opt}>
                                        {opt.name || opt}
                                      </option>
                                    );
                                  })}
                                </Field>
                              );
                              // }
                            } else if (field.name === "place") {
                              // //;
                              if (this.state.selectedCountry != 104) {
                                return <></>;
                              } else {
                                return (
                                  <Field
                                    className="field form-select"
                                    isSearchable={true}
                                    name={field.name}
                                    required
                                    as="select"
                                  >
                                    <option default disabled value=""></option>

                                    {field.listValue?.map((opt) => (
                                      <option value={opt.id || opt}>
                                        {opt.name || opt}
                                      </option>
                                    ))}
                                  </Field>
                                );
                              }
                            } else if (field.name === "caste_id") {
                              return (
                                <Field
                                  className="field form-select"
                                  isSearchable={true}
                                  name={field.name}
                                  required
                                  as="select"
                                >
                                  <option default value=""></option>
                                  {field.listValue?.map((opt) => (
                                    <option value={opt.id || opt}>
                                      {opt.name || opt}
                                    </option>
                                  ))}
                                </Field>
                              );
                            } else if (field.multipleSelect)
                              return (
                                <Select
                                  isSearchable={true}
                                  closeMenuOnSelect={false}
                                  closeMenuOnScroll={false}
                                  blurInputOnSelect={false}
                                  placeholder="Any"
                                  isMulti
                                  value={this.state.selectedHobbies}
                                  options={field?.listValue}
                                  getOptionLabel={(options) => options["name"]}
                                  getOptionValue={(options) => options["id"]}
                                  components={animatedComponents}
                                  onChange={(e, value) => {
                                    this.setState({ selectedHobbies: e });
                                  }}
                                />
                              );
                            else if (field.name === "allegiance") {
                              return <></>;
                              // return this.state.isVisibleAllegience ? (
                              //   <Field
                              //     className="field form-select"
                              //     name={field.name}
                              //     as="select"
                              //   >
                              //     <option default disabled value=""></option>
                              //     {field.listValue?.map((opt) => (
                              //       <option value={opt.id || opt}>
                              //         {opt.name || opt}
                              //       </option>
                              //     ))}
                              //   </Field>
                              // ) : (
                              //   <></>
                              // );
                            } else if (field.name === "drink") {
                              return (
                                <Field
                                  className="field form-select"
                                  name={field.name}
                                  as="select"
                                >
                                  <option default value=""></option>
                                  {field.listValue?.map(
                                    (opt) =>
                                      opt.id != "118" && (
                                        <option value={opt.id || opt}>
                                          {opt.name || opt}
                                        </option>
                                      )
                                  )}
                                </Field>
                              );
                            } else if (field.name === "smoke") {
                              return (
                                <Field
                                  className="field form-select"
                                  name={field.name}
                                  as="select"
                                >
                                  <option default value=""></option>
                                  {field.listValue?.map(
                                    (opt) =>
                                      opt.id != "119" && (
                                        <option value={opt.id || opt}>
                                          {opt.name || opt}
                                        </option>
                                      )
                                  )}
                                </Field>
                              );
                            } else if (field.name === "father_alive") {
                              return (
                                <Field
                                  className="field form-select"
                                  name={field.name}
                                  value={this.state.isFatherAlive}
                                  as="select"
                                  onChange={(e) => {
                                    this.handleRefresh(
                                      e.target.value,
                                      field.name
                                    );
                                  }}
                                >
                                  <option default value=""></option>
                                  {field.listValue?.map((opt) => (
                                    <option value={opt.id || opt}>
                                      {opt.name || opt}
                                    </option>
                                  ))}
                                </Field>
                              );
                            } else if (field.name === "mother_alive") {
                              return (
                                <Field
                                  className="field form-select"
                                  name={field.name}
                                  value={this.state.isMotherAlive}
                                  as="select"
                                  onChange={(e) => {
                                    this.handleRefresh(
                                      e.target.value,
                                      field.name
                                    );
                                  }}
                                >
                                  <option default value=""></option>
                                  {field.listValue?.map((opt) => (
                                    <option value={opt.id || opt}>
                                      {opt.name || opt}
                                    </option>
                                  ))}
                                </Field>
                              );
                            } else if (field.name === "is_orphan") {
                              return (
                                this.state.isFatherAlive === "No" && (
                                  <Field
                                    className="field form-select"
                                    name={field.name}
                                    as="select"
                                  >
                                    <option default value=""></option>
                                    {field.listValue?.map((opt) => (
                                      <option value={opt.id || opt}>
                                        {opt.name || opt}
                                      </option>
                                    ))}
                                  </Field>
                                )
                              );
                            } else if (field.name === "father_occupation") {
                              if (this.state.isFatherAlive === "Yes") {
                                return (
                                  <Field
                                    className="field form-select"
                                    name={field.name}
                                    as="select"
                                    value={this.state.selectedFatherOccupation}
                                    onChange={(e) => {
                                      initialValues.father_occupation =
                                        e.target.value;
                                      this.setState({
                                        selectedFatherOccupation:
                                          e.target.value,
                                      });
                                    }}
                                  >
                                    <option default value=""></option>
                                    {field.listValue?.map((opt) => (
                                      <option value={opt.id || opt}>
                                        {opt.name || opt}
                                      </option>
                                    ))}
                                  </Field>
                                );
                              } else {
                                initialValues.father_occupation = "";
                                return null;
                              }
                            } else if (field.name === "mother_occupation") {
                              if (this.state.isMotherAlive === "Yes") {
                                return (
                                  <Field
                                    className="field form-select"
                                    name={field.name}
                                    as="select"
                                    value={this.state.selectedMotherOccupation}
                                    onChange={(e) => {
                                      initialValues.mother_occupation =
                                        e.target.value;
                                      this.setState({
                                        selectedMotherOccupation:
                                          e.target.value,
                                      });
                                    }}
                                  >
                                    <option default value=""></option>
                                    {field.listValue?.map((opt) => (
                                      <option value={opt.id || opt}>
                                        {opt.name || opt}
                                      </option>
                                    ))}
                                  </Field>
                                );
                              } else {
                                initialValues.mother_occupation = "";
                                return null;
                              }
                            } else {
                              return (
                                <Field
                                  className="field form-select"
                                  name={field.name}
                                  as="select"
                                >
                                  <option default disabled value=""></option>
                                  {field.listValue?.map((opt) => (
                                    <option value={opt.id || opt}>
                                      {opt.name || opt}
                                    </option>
                                  ))}
                                </Field>
                              );
                            }

                          case "password":
                            return (
                              <Field
                                className="field form-control"
                                type="password"
                                name={field.name}
                              />
                            );

                          default:
                            if (
                              field.key === "Mobile Number" ||
                              field.key === "Mobile" ||
                              field.name == "phone"
                            ) {
                              return (
                                <div className="d-flex">
                                  <Field
                                    className="field form-control"
                                    type="text"
                                    name={field.name}
                                    disabled={field.name === "phone"}
                                  />
                                </div>
                              );
                            } else if (field.key === "Facebook ID-") {
                              return !field?.value ? (
                                <SocialButton
                                  className="field form-control"
                                  scope={["user_link", "public_profile"]}
                                  provider="facebook"
                                  appId="728735384954500"
                                  onLoginSuccess={(user) =>
                                    this.handleFbLogin(user, field)
                                  }
                                  onLoginFailure={this.handleSocialLoginFailure}
                                ></SocialButton>
                              ) : (
                                <div className="position-relative">
                                  <Field
                                    className="field form-control"
                                    type="text"
                                    name="facebook_id"
                                  />
                                  <div
                                    style={{
                                      height: "97%",
                                      width: "30px",
                                      backgroundColor: "#FFF",
                                      top: "1px",
                                      right: "1px",
                                    }}
                                    className="position-absolute d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      values.facebook_id = "";
                                      field.value = "";
                                      this.setState({ changeFbId: true });
                                    }}
                                  >
                                    <BsX color="red" size="20px" />
                                  </div>
                                </div>
                              );
                            } else if (field.key === "Pin") {
                              return (
                                <Field
                                  className="field form-control"
                                  type="text"
                                  name={field.name}
                                  maxLength={6}
                                  minlength={6}
                                />
                              );
                            } else if (field.name === "num_brothers") {
                              return (
                                <input
                                  className="field form-control"
                                  onChange={(e) =>
                                    this.setState({
                                      noOfBrothers: e.target.value,
                                    })
                                  }
                                  value={this.state.noOfBrothers}
                                  type="text"
                                  name={field.name}
                                />
                              );
                            } else if (field.name === "num_brothers_married") {
                              return (
                                <input
                                  className="field form-control"
                                  onChange={(e) =>
                                    this.setState({
                                      noOfBrothersMarried: e.target.value,
                                    })
                                  }
                                  value={this.state.noOfBrothersMarried}
                                  type="text"
                                  name={field.name}
                                />
                              );
                            } else if (field.name === "num_sisters") {
                              return (
                                <input
                                  className="field form-control"
                                  onChange={(e) =>
                                    this.setState({
                                      noOfSisters: e.target.value,
                                    })
                                  }
                                  value={this.state.noOfSisters}
                                  type="text"
                                  name={field.name}
                                />
                              );
                            } else if (field.name === "num_sisters_married") {
                              return (
                                <input
                                  className="field form-control"
                                  onChange={(e) =>
                                    this.setState({
                                      noOfSistersMarried: e.target.value,
                                    })
                                  }
                                  value={this.state.noOfSistersMarried}
                                  type="text"
                                  name={field.name}
                                />
                              );
                            } else if (field.name == "whatsappno") {
                              return (
                                <>
                                  <Row>
                                    <Col>
                                      <Field
                                        className="field form-select"
                                        name={field.name}
                                        as="select"
                                        value={
                                          this.state.selectedWhatsappCountryCode
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            selectedWhatsappCountryCode:
                                              e.target.value,
                                          })
                                        }
                                      >
                                        <option
                                          default
                                          disabled
                                          value=""
                                        ></option>
                                        {this.props.countrycodes.list?.map(
                                          (opt) => (
                                            <option value={opt.id || opt}>
                                              {opt.id || opt}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </Col>
                                    <Col>
                                      <Field
                                        className="field form-control"
                                        type="text"
                                        maxLength={15}
                                        name={field.name}
                                      />
                                    </Col>
                                  </Row>
                                  {/* {field.name === "email" && this.state.isEmailExist && (
                                            <div className="d-flex" style={{color:'red', fontSize:'12px'}}>
                                              Email already exists
                                            </div>
                                          )} */}
                                </>
                              );
                            } else if (field.name == "land") {
                              return (
                                <>
                                  <Row>
                                    <Col>
                                      <Field
                                        className="field form-select"
                                        name={field.name}
                                        as="select"
                                        value={
                                          this.state
                                            .selectedAlternateCountryCode
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            selectedAlternateCountryCode:
                                              e.target.value,
                                          })
                                        }
                                      >
                                        <option
                                          default
                                          disabled
                                          value=""
                                        ></option>
                                        {this.props.countrycodes.list?.map(
                                          (opt) => (
                                            <option value={opt.id || opt}>
                                              {opt.id || opt}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </Col>
                                    <Col>
                                      <Field
                                        className="field form-control"
                                        type="text"
                                        maxLength={15}
                                        name={field.name}
                                      />
                                    </Col>
                                  </Row>

                                  {/* {field.name === "email" && this.state.isEmailExist && (
                                            <div className="d-flex" style={{color:'red', fontSize:'12px'}}>
                                              Email already exists
                                            </div>
                                          )} */}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <Field
                                    className="field form-control"
                                    type="text"
                                    name={field.name}
                                  />
                                  {field.name === "email" &&
                                    this.state.isEmailExist && (
                                      <div
                                        className="d-flex"
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Email already exists
                                      </div>
                                    )}
                                </>
                              );
                            }
                        }
                      })()}

                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors[field.name] && touched[field.name]
                          ? errors[field.name]
                          : ""}
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )
              ) : (
                <div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Age From</label>
                    <select
                      onChange={(e, values) => {
                        this.selectSingle(e, "ageFrom");
                        this.setState({ ageFrom: e.target.value });
                      }}
                      className="form-control"
                    >
                      {/* <option selected={initialValues.age === "Any"} value="0"> */}
                      <option selected={"Any"} value="0">
                        Any
                      </option>
                      {this.props.editInfo[0].listValue?.map((option) => (
                        <option
                          hidden={
                            this.state.ageTo == "0"
                              ? option <= this.state.ageTo
                              : option >= this.state.ageTo
                          }
                          selected={
                            initialValues.age?.slice(
                              0,
                              initialValues.age.indexOf("-")
                            ) === option.toString()
                          }
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Age To</label>
                    <select
                      disabled={this.state.ageFrom === "0"}
                      onChange={(e, values) => {
                        this.selectSingle(e, "ageTo");
                        this.setState({ ageTo: e.target.value });
                      }}
                      className="form-control"
                    >
                      {/* <option selected={initialValues.age === "Any"} value="0"> */}
                      <option selected={"Any"} value="0">
                        Any
                      </option>
                      {this.props.editInfo[0].listValue?.map((option) => (
                        <option
                          hidden={option <= this.state.ageFrom}
                          selected={
                            initialValues.age?.slice(
                              initialValues.age?.indexOf("-") + 1
                            ) === option.toString()
                          }
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Religion</label>
                    <Select
                      isSearchable={false}
                      placeholder=""
                      value={this.props.editInfo[1].listValue.filter(
                        (list) => list.name === initialValues.religion
                      )}
                      options={this.props.editInfo[1].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, values) =>
                        this.getOptions(e, "religion", values)
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Sub Group</label>
                    <Select
                      isSearchable={window.innerWidth < 425 ? false : true}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerCast}
                      options={this.props.editInfo[2].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) =>
                        this.setState({ selectedPartnerCast: e })
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Country Living</label>
                    <Select
                      isSearchable={true}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerCountry_living}
                      options={this.props.editInfo[3].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) => {
                        this.setState({ selectedPartnerCountry_living: e }),
                          this.getStatesPartner(e, true);
                      }}
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">State</label>
                    <Select
                      isSearchable={true}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerStates}
                      options={this.props.editInfo[4].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) => {
                        this.setState({ selectedPartnerStates: e }),
                          this.getDistricts(e, true);
                      }}
                    />
                  </div>
                  {this.state.selectedPartnerCountry_living.length == 1 &&
                    this.state.selectedPartnerCountry_living[0].id == 104 && (
                      <div
                        style={{ marginBottom: "18px" }}
                        className="form-group mb-3"
                      >
                        <label className="label form-label">District</label>
                        <Select
                          isSearchable={window.innerWidth < 425 ? false : true}
                          closeMenuOnSelect={false}
                          closeMenuOnScroll={false}
                          blurInputOnSelect={false}
                          placeholder="Any"
                          isMulti
                          value={
                            this.state.selectedPartnerStates.length == 1
                              ? this.state.selectedPartnerDistricts
                              : []
                          }
                          options={
                            this.state.selectedPartnerStates.length == 1
                              ? this.state.optionsPartnerDistricts
                              : []
                          }
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["id"]}
                          components={animatedComponents}
                          onChange={(e, value) =>
                            this.setState({ selectedPartnerDistricts: e })
                          }
                        />
                      </div>
                    )}
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Qualification</label>
                    <Select
                      isSearchable={window.innerWidth < 425 ? false : true}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerQualification}
                      options={this.props.editInfo[6].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) =>
                        this.setState({ selectedPartnerQualification: e })
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Job</label>
                    <Select
                      isSearchable={window.innerWidth < 425 ? false : true}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerOccupation}
                      options={
                        this.props.editInfo[7].listValue[0].name ==
                          "Not working" && this.props.editInfo[7].listValue
                      }
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) => {
                        this.setState({ selectedPartnerOccupation: e });
                      }}
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Job Sector</label>
                    <Select
                      isSearchable={true}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerSector}
                      options={this.props.editInfo[8].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) =>
                        this.setState({ selectedPartnerSector: e })
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Annual Income</label>
                    <select
                      onChange={(e, index) => this.selectSingle(e, "income")}
                      className="form-control"
                    >
                      <option
                        selected={initialValues.income === "Any"}
                        value="0"
                      >
                        Any
                      </option>

                      {newArray?.map((option) => (
                        <option
                          value={option.id}
                          selected={initialValues.income === option.name}
                        >
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Marital Status</label>
                    <Select
                      isSearchable={false}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerMarritialStatus}
                      options={this.props.editInfo[10].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) =>
                        this.setState({ selectedPartnerMarritialStatus: e })
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Physical Status</label>
                    <Select
                      isSearchable={false}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerPhysicalStatus}
                      options={this.props.editInfo[11].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) =>
                        this.setState({ selectedPartnerPhysicalStatus: e })
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Complexion</label>
                    <Select
                      isSearchable={true}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerComplexion}
                      options={this.props.editInfo[12].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) =>
                        this.setState({ selectedPartnerComplexion: e })
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Body Type</label>
                    <Select
                      isSearchable={false}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerBodytype}
                      options={this.props.editInfo[13].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) =>
                        this.setState({ selectedPartnerBodytype: e })
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Diet</label>
                    <Select
                      isSearchable={false}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerDiet}
                      options={this.props.editInfo[14].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) =>
                        this.setState({ selectedPartnerDiet: e })
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Height(cm) From</label>
                    <select
                      onChange={(e, index) => {
                        this.selectSingle(e, "heightFrom");
                        this.setState({ heightFrom: e.target.value });
                        this.state.heightFrom < this.state.heightTo &&
                          initialValues.height == "0";
                        this.state.heightFrom === "0" &&
                          initialValues.height == "0";
                      }}
                      className="form-control"
                    >
                      <option selected={"Any"} value="0">
                        Any
                      </option>
                      {this.props.editInfo[15].listValue?.map((option) => (
                        <option
                          hidden={
                            this.state.heightTo == "0"
                              ? option <= this.state.heightTo
                              : option >= this.state.heightTo
                          }
                          selected={
                            initialValues.height?.slice(
                              0,
                              initialValues.height.indexOf("-")
                            ) === option.toString()
                          }
                          value={option}
                        >
                          {option} cm
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Height(cm) To</label>
                    <select
                      disabled={this.state.heightFrom === "0"}
                      onChange={(e, index) => {
                        this.selectSingle(e, "heightTo");
                        this.setState({ heightTo: e.target.value });
                      }}
                      className="form-control"
                    >
                      <option selected={"Any"} value="0">
                        Any
                      </option>
                      {this.props.editInfo[15].listValue?.map((option) => (
                        <option
                          hidden={option <= this.state.heightFrom}
                          selected={
                            initialValues.height?.slice(
                              initialValues.height?.indexOf("-") + 1
                            ) === option.toString()
                          }
                          value={option}
                        >
                          {option} cm
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Weight(kg) From</label>
                    <select
                      onChange={(e, index) => {
                        this.selectSingle(e, "weightFrom");
                        this.setState({ weightFrom: e.target.value });
                      }}
                      className="form-control"
                    >
                      <option selected={"Any"} value="0">
                        Any
                      </option>
                      {this.props.editInfo[16].listValue?.map((option) => (
                        <option
                          hidden={
                            this.state.weightTo == "0"
                              ? option <= this.state.weightTo
                              : option >= this.state.weightTo
                          }
                          selected={
                            initialValues.weight?.slice(
                              0,
                              initialValues.weight.indexOf("-")
                            ) === option.toString()
                          }
                          value={option}
                        >
                          {option} kg
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Weight(kg) To</label>
                    <select
                      disabled={this.state.weightFrom === "0"}
                      onChange={(e, index) => {
                        this.selectSingle(e, "weightTo");
                        this.setState({ weightTo: e.target.value });
                      }}
                      className="form-control"
                    >
                      <option selected={"Any"} value="0">
                        Any
                      </option>
                      {this.props.editInfo[16].listValue?.map((option) => (
                        <option
                          hidden={option <= this.state.weightFrom}
                          selected={
                            initialValues.weight?.slice(
                              initialValues.weight?.indexOf("-") + 1
                            ) === option.toString()
                          }
                          value={option}
                        >
                          {option}kg
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Family Status</label>
                    <Select
                      isSearchable={false}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                      blurInputOnSelect={false}
                      placeholder="Any"
                      isMulti
                      value={this.state.selectedPartnerFamilyType}
                      options={this.props.editInfo[17].listValue}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["id"]}
                      components={animatedComponents}
                      onChange={(e, value) =>
                        this.setState({ selectedPartnerFamilyType: e })
                      }
                    />
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Smoke</label>
                    <select
                      onChange={(e, values) => this.selectSingle(e, "smoke")}
                      className="form-control"
                    >
                      {this.props.editInfo[18].listValue?.map(
                        (option) =>
                          option.id != "114" && (
                            <option
                              selected={
                                initialValues?.smoke?.trimRight() ===
                                option.name
                              }
                              value={option.id}
                            >
                              {option.name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                  <div
                    style={{ marginBottom: "18px" }}
                    className="form-group mb-3"
                  >
                    <label className="label form-label">Drink</label>
                    <select
                      onChange={(e, values) => this.selectSingle(e, "drink")}
                      className="form-control"
                    >
                      {this.props.editInfo[19].listValue?.map(
                        (option) =>
                          option.id != "115" && (
                            <option
                              selected={
                                initialValues?.drink?.trimRight() ===
                                option.name
                              }
                              value={option.id}
                            >
                              {option.name}{" "}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                </div>
              )}
              <div
                className="position-sticky d-flex align-items-center"
                style={{ bottom: "0", backgroundColor: "white" }}
              >
                <Button
                  className="register-login-button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
                <Button className="cancel-login-button m-2" type="reset">
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    ) : (
      <div>
        <div>{this.state?.selectedField}</div>
        <div>
          {this.state?.selectedName === "hobbies" ? (
            <></>
          ) : (
            <Form.Check
              style={{ color: "#5B6770" }}
              name={this.state?.selectedName}
              label="Any"
              id={this.state?.selectedName}
              checked={this.state.options?.every(
                (option) => option.selected === false
              )}
              onChange={(e) => {
                this.state.options?.map((opt) => (opt.selected = false));
                this.setState({ checked: !this.state.checked });
              }}
            />
          )}

          {this.state.options?.map((option) => (
            <Form.Check
              style={{ color: "#5B6770" }}
              name={this.state?.selectedName}
              label={option?.name}
              id={this.state?.selectedName}
              checked={option.selected}
              onChange={(e) => {
                this.state.options.filter(
                  (opt) => opt.id === option.id
                )[0].selected = !option.selected;
                this.setState({ checked: !this.state.checked });
              }}
            />
          ))}
          <div
            className="position-sticky d-flex justify-content-start align-items-center pt-4 pb-4"
            style={{ bottom: "0", zIndex: "2", backgroundColor: "white" }}
          >
            <Button
              className="px-5"
              style={{
                width: "146px",
                borderColor: "var(--primary-color)",
                color: "var(--primary-color)",
                backgroundColor: "#FFF",
              }}
              onClick={() => {
                // this.state.options.filter(opt => opt.id === option.id)[0].selected = !option.selected
                this.setState({ showMultiSelect: false });
              }}
            >
              Cancel
            </Button>
            <Button
              className="px-5 mx-5"
              style={{
                width: "146px",
                backgroundColor: "var(--primary-color)",
                borderColor: "var(--primary-color)",
              }}
              onClick={() => this.setState({ showMultiSelect: false })}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
