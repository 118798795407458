import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Row,
  Modal,
} from "react-bootstrap";
import { BiImageAdd, BiSend } from "react-icons/bi";
import {
  MdClose,
  MdFavoriteBorder,
  MdMoreVert,
  MdStarBorder,
  MdOutlineBlock,
  MdStar,
  MdFavorite,
  MdCircle,
} from "react-icons/md";
import {
  BsXCircle,
  BsExclamationOctagon,
  BsTrash,
  BsArrowLeftShort,
} from "react-icons/bs";
import { toast } from "react-toastify";
import {
  Chats,
  ConfirmModal,
  Footer,
  Loader,
  NavbarMenu,
  ReportModal,
} from "../../Components";
import { environment } from "../../environment/env";
import "./Messages.css";
import NavigateToProfile from "./NavigateToProfile";
import { FiArrowLeft } from "react-icons/fi";

const baseURL = environment.hostname;
const imageURL = environment.imageURL;
const chatImageURL = environment.chatImageURL;
let messages, newMessage;
let encodedImage;

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.inputTitle = React.createRef();
    this.messagesscrollview = React.createRef();
    this.state = {
      isLoading: true,
      pendingCount: "",
      profile: true,
      action: "",
      clickEvent: "",
      showConfirmModal: false,
      showReportModal: false,
      image: "",
      chatId: "",
      messages: "",
      showChats: true,
      showMessage: false,
      blockStatus: "none",
      isShortListed: false,
      isInterested: false,
      showModalRemShortlist: false,
      showModalRemInterest: false,
      showModalIgnoreProf: false,
      redirectToProfile: false,
      showCropModal: false,
      croppedImage: null,
      lastlogin: null,
    };
    this.countPending = this.countPending.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0 });

    if (window.location.hash.slice(11)) {
      let chatId = window.location.hash.slice(11);

      fetch(`${baseURL}/basic_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: localStorage.visitingUser,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ lastlogin: response[0].lastlogin });
          let image = response[0].face_image;
          let album_visibility = response[0].album_visibility;
          this.getChatId(chatId, image, album_visibility);
          this.countPending();
        });
    }
  }

  // componentDidMount() {
  //   this.interval = setInterval(() => {
  //       fetch(`${baseURL}/get_message`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             id: localStorage.currentUser,
  //             secondid: this.state.chatId,
  //           }),
  //         })
  //         .then((values) => Promise.all(values.map((value) => value.json())))
  //     .then((response) => {
  //     })

  //   }, 1000);
  // }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }

  countPending = () => {
    fetch(`${baseURL}/count_pending`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.setState({ pendingCount: response });
        this.updateLogin();
      });
  };

  getChatId = (chatId, image, album_visibility) => {
    let getMessageApi = fetch(`${baseURL}/get_message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        secondid: chatId,
      }),
    });

    let blockStatusApi = fetch(`${baseURL}/blockstatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        secondid: chatId,
      }),
    });

    let allStatusApi = fetch(`${baseURL}/get_allstatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: chatId,
        profid: localStorage.currentUser,
      }),
    });

    Promise.all([getMessageApi, blockStatusApi, allStatusApi])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        messages = response[0];
        let bstatus = "none";
        if (response[1].length) {
          bstatus =
            response[1][0].from_id === localStorage.currentUser
              ? "sendBlock"
              : "recievedBlock";
        }
        this.countPending();
        this.setState({
          chatId: chatId,
          image: image,
          album_visibility: album_visibility,
          isLoading: false,
          messages: messages,
          lastlogin: response[0][0]?.lastlogin_new ?? "",
          showChats: false,
          showMessage: true,
          blockStatus: bstatus,
          isShortListed: response[2]["shortlist"] === "true",
          isInterested: response[2]["express"] === "true",
        });
        try {
          var topPos = this.messagesscrollview.scrollHeight;
          this.messagesscrollview.scrollTop = topPos;
        } catch (e) {}
      });
  };

  sendMessage = (event) => {
    if (this.inputTitle.value != "") {
      fetch(`${baseURL}/add_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          from: localStorage.currentUser,
          to: this.state.chatId,
          content: this.inputTitle.value,
          ip: "111.111.1.1",
          type: "0",
          check_user_id: localStorage.currentUser,
          check_session_id: localStorage.session,
          id: localStorage.currentUser,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          messages.push({
            from: localStorage.currentUser,
            to: this.state.chatId,
            content: this.inputTitle.value,
            type: "0",
            timeformat_new: new Date().toLocaleString("sv-SE", {
              timeZone: "UTC",
            }),
          });
          this.inputTitle.value = "";
          this.setState({
            messages: messages,
          });
          try {
            var topPos = this.messagesscrollview.scrollHeight;
            this.messagesscrollview.scrollTop = topPos;
          } catch (e) {}
        });
    }
  };

  uploadPhoto = async (event, title) => {
    var exts = /(.png|.webp|.jpg|.jpeg)/;
    if (event.target.value.match(exts)) {
      let file = event.target.files[0];
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          encodedImage = encoded;
          this.setState({
            showCropModal: true,
            croppedImage: reader.result,
          });
          // fetch(`${baseURL}/add_message_photo`, {
          //   method: "POST",
          //   headers: { "Content-Type": "application/json" },
          //   body: JSON.stringify({
          //     from: localStorage.currentUser,
          //     to: this.state.chatId,
          //     id: localStorage.currentUser,
          //     check_user_id: localStorage.currentUser,
          //     content: encoded,
          //     ip: "111.111.1.1",
          //     check_session_id: localStorage.session,
          //     type: "1",
          //   }),
          // })
          //   .then((response) => response.json())
          //   .then((response) => {
          //     if (response) {
          //       this.getChatId(
          //         this.state.chatId,
          //         this.state.image,
          //         this.state.album_visibility
          //       );
          //     }
          //   });
        };
      });
    } else {
      toast.error("Please choose  valid Image file like .png|.webp|.jpg|.jpeg");
    }
  };

  uploadCroppedImage() {
    this.closeCropModel();
    fetch(`${baseURL}/add_message_photo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: this.state.chatId,
        id: localStorage.currentUser,
        check_user_id: localStorage.currentUser,
        content: encodedImage,
        ip: "111.111.1.1",
        check_session_id: localStorage.session,
        type: "1",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          this.getChatId(
            this.state.chatId,
            this.state.image,
            this.state.album_visibility
          );
        }
      });
  }

  addToShortlist = () => {
    fetch(`${baseURL}/add_shortlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: localStorage.currentUser,
        to: this.state.chatId,
        ip: "111.111.1.1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.sts === 1 || response.sts === 3)
          this.setState({
            isShortListed: true,
          });
      });
  };

  sendInterest = () => {
    fetch(`${baseURL}/add_express_interest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: this.state.chatId,
        ip: "111.111.1.1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        // if (response.sts === 1 || response.sts === 3)
        //   this.setState({
        //     isInterested: true,
        //   });
        if (response.sts === 1) {
          this.setState({
            isInterested: true,
          });
          toast.success("interest request send successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (response.sts === 3) {
          this.setState({
            isInterested: true,
          });
          toast.warning("Already sent request", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  showConfirmModal(type) {
    switch (type) {
      case "rem_shortlist":
        this.setState({ showModalRemShortlist: true });
        break;
      case "rem_interest":
        this.setState({ showModalRemInterest: true });
        break;
      case "ignore":
        this.setState({ showModalIgnoreProf: true });
        break;
    }
  }

  hideallmodals = () => {
    this.setState({
      showModalRemShortlist: false,
      showModalRemInterest: false,
      showModalIgnoreProf: false,
    });
  };

  removeShortListApi = () => {
    fetch(`${baseURL}/delete_shortlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        to_id: this.state.chatId,
        session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.hideallmodals();
        if (!response.toString().includes("401")) {
          this.setState({
            isShortListed: false,
          });
        }
      });
  };

  removeInterestApi = () => {
    fetch(`${baseURL}/removeinterest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        to: this.state.chatId,
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.hideallmodals();
        if (!response.toString().includes("401")) {
          this.setState({
            isInterested: false,
          });
        }
      });
  };

  close = () => {
    this.setState({
      chatId: "",
      showChats: true,
      showMessage: false,
    });
  };

  blockProfile = () => {
    fetch(`${baseURL}/block_chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from_id: localStorage.currentUser,
        to_id: this.state.chatId,
        check_session_id: localStorage.session,
        check_user_id: localStorage.currentUser,
      }),
    }).then((response) => {
      let bstatus =
        this.state.blockStatus === "sendBlock" ? "none" : "sendBlock";
      this.setState({
        blockStatus: bstatus,
      });
    });
  };

  ignoreProfile = () => {
    fetch(`${baseURL}/ignore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: this.state.chatId,
        check_session_id: localStorage.session,
        check_user_id: localStorage.currentUser,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response === "1") toast.success("ignored successfully");
        else if (response === "0") toast.info("already ignored");
        this.setState({
          chatId: "",
        });
      });
  };

  deleteChat = (e) => {
    e.stopPropagation();
    fetch(`${baseURL}/del_chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        myid: localStorage.currentUser,
        id: this.state.chatId,
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
      }),
    }).then((res) => {
      messages = [];
      this.setState({
        showConfirmModal: false,
        profile: true,
        action: "",
        clickEvent: "",
        messages: messages,
      });
    });
  };
  async updateLogin() {
    await fetch(`${baseURL}/loginupdate`, {
      method: "POST",
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
      headers: {
        "Content-type": "application/json",
      },
    }).then((response) => {});
  }

  checkPlanAndImage(imageP, album_visibility, gender) {
    var image = imageURL + imageP;
    if (
      (album_visibility === "55" && localStorage.plan === "2") ||
      album_visibility === "56" ||
      imageP == null
    ) {
      if (gender === "Male" || localStorage.gender === "Female") {
        image = imageURL + "avatar_Male.png";
      } else {
        image = imageURL + "avatar_Female.png";
      }
    }
    return image;
  }

  closeCropModel() {
    this.setState({
      showCropModal: false,
    });
  }
  formattedUserTime(time) {
    var timeT = new Date(time.replace(/-/g, "/"));
    var resultTime = new Date(timeT - localStorage.getItem("time_diff") * 1000);
    return resultTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  render() {
    return (
      <div>
        <NavbarMenu pending={this.state.pendingCount}></NavbarMenu>
        <div className="message-screen">
          <Row>
            <Col
              md={4}
              className={`d-lg-block  ${this.state.showChats ? "" : "d-none"}`}
            >
              <Chats title="message" chatId={this.getChatId}></Chats>
            </Col>
            <Col
              className={`d-lg-block ${this.state.showMessage ? "" : "d-none"}`}
            >
              {this.state.chatId ? (
                <Row className="message-title m-0 align-items-center justify-content-between">
                  <div
                    className="d-flex w-auto align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.setItem("visitingUser", this.state.chatId);
                      this.setState({ redirectToProfile: true });
                    }}
                  >
                    <FiArrowLeft
                      size={26}
                      style={{ marginRight: "5px" }}
                      onClick={this.close}
                      className="back-chat-arrow"
                    />
                    <Col className="col-auto pe-2">
                      <Image
                        src={this.checkPlanAndImage(
                          this.state.image,
                          this.state.album_visibility,
                          null
                        )}
                        roundedCircle
                        width="40px"
                        height="40px"
                      />
                      <MdCircle
                        style={{ marginLeft: "-10px", verticalAlign: "bottom" }}
                        size="20px"
                        color={
                          new Date(
                            this.state.lastlogin + " UTC"
                          ).toDateString() != new Date().toDateString()
                            ? "#C4C4C4"
                            : new Date(
                                this.state.lastlogin + " UTC"
                              ).getHours() != new Date().getHours()
                            ? "#C4C4C4"
                            : new Date().getMinutes() - 5 <=
                                new Date(
                                  this.state.lastlogin + " UTC"
                                ).getMinutes() &&
                              new Date(
                                this.state.lastlogin + " UTC"
                              ).getMinutes() <= new Date().getMinutes()
                            ? "#00BE63"
                            : "#C4C4C4"
                        }
                      />
                    </Col>
                    {this.state.chatId}
                  </div>
                  {this.state.redirectToProfile === true ? (
                    <NavigateToProfile />
                  ) : (
                    <></>
                  )}
                  <div
                    className="w-auto d-flex"
                    style={{ alignItems: "center" }}
                  >
                    {this.state.isShortListed ? (
                      <MdStar
                        size={26}
                        className="me-2"
                        onClick={() => this.showConfirmModal("rem_shortlist")}
                      />
                    ) : (
                      <MdStarBorder
                        size={26}
                        className="me-2"
                        onClick={this.addToShortlist}
                      />
                    )}
                    {this.state.isInterested ? (
                      <MdFavorite
                        size={25}
                        className="me-0"
                        onClick={() => this.showConfirmModal("rem_interest")}
                      />
                    ) : (
                      <MdFavoriteBorder
                        size={25}
                        className="me-0"
                        onClick={this.sendInterest}
                      />
                    )}
                    <Dropdown drop="start">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="p-0 d-flex"
                        style={{
                          backgroundColor: "var(--primary-color)",
                          color: "var(--primary-color)",
                          border: "none",
                        }}
                      >
                        <MdMoreVert size={26} color="#fff" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            this.setState({
                              showConfirmModal: true,
                              profile: false,
                              action: "Delete",
                              clickEvent: this.deleteChat,
                            })
                          }
                        >
                          <BsTrash className="me-2" size="19px" />
                          Delete chat history
                        </Dropdown.Item>
                        <Dropdown.Item onClick={this.blockProfile}>
                          <MdOutlineBlock className="me-2" size="19px" />
                          {this.state.blockStatus != "sendBlock"
                            ? "Block this profile"
                            : "Unblock this profile"}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => this.showConfirmModal("ignore")}
                        >
                          <BsXCircle className="me-2" />
                          Ignore this profile
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            this.setState({ showReportModal: true })
                          }
                        >
                          <BsExclamationOctagon className="me-2" />
                          Report this profile
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <ConfirmModal
                      confirmNow={this.state.showConfirmModal}
                      actionOn={this.state.profile ? "profile" : "chat"}
                      action={this.state.action}
                      id={this.state.chatId}
                      clickEvent={this.state.clickEvent}
                      close={() =>
                        this.setState({
                          showConfirmModal: false,
                          action: "",
                          profile: true,
                          clickEvent: "",
                        })
                      }
                    ></ConfirmModal>
                    <ReportModal
                      profile={this.state.chatId}
                      ReportNow={this.state.showReportModal}
                      closeModal={() =>
                        this.setState({ showReportModal: false })
                      }
                      success={() =>
                        this.setState({ showReportModal: false, chatId: "" })
                      }
                    ></ReportModal>
                  </div>
                </Row>
              ) : (
                <></>
              )}
              {this.state.chatId ? (
                <Card
                  className="message-card"
                  ref={(el) => {
                    this.messagesscrollview = el;
                  }}
                >
                  <div className="h-100 d-flex flex-column justify-content-between message-body">
                    <Row className="m-0">
                      {!this.state.isLoading ? (
                        messages?.map((message, index) => (
                          <>
                            {new Date(
                              message.timeformat_new + " UTC".replace(/-/g, "/")
                            ).toDateString() !=
                            new Date(
                              messages[index - 1]?.timeformat_new +
                                " UTC".replace(/-/g, "/")
                            ).toDateString() ? (
                              <Row
                                className="d-flex justify-content-center"
                                style={{ fontSize: "14px", color: "#C4C4C4" }}
                              >
                                {new Date(
                                  message.timeformat_new +
                                    " UTC".replace(/-/g, "/")
                                ).toLocaleDateString() ===
                                new Date().toLocaleDateString()
                                  ? "Today"
                                  : new Date(
                                      message.timeformat_new +
                                        " UTC".replace(/-/g, "/")
                                    ).toLocaleDateString()}
                              </Row>
                            ) : (
                              ""
                            )}
                            {message?.from === localStorage.currentUser ? (
                              <Row className="flex-row-reverse p-0 m-0">
                                {message?.type === "0" ? (
                                  <Col
                                    md={5}
                                    className="message-sent d-flex justify-content-end"
                                  >
                                    {message.content}
                                    <span
                                      className="ms-3 d-flex align-items-end"
                                      style={{
                                        color: "#ACB3B9",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {new Date(
                                        (
                                          message?.timeformat_new + " UTC"
                                        ).replace(/-/g, "/")
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </span>
                                  </Col>
                                ) : (
                                  <Image
                                    md={5}
                                    height="150px"
                                    className="p-1 message-sent d-flex justify-content-end"
                                    src={`${chatImageURL}${message?.content}`}
                                    onClick={() =>
                                      window.open(
                                        `${chatImageURL}${message?.content}`
                                      )
                                    }
                                  />
                                )}
                              </Row>
                            ) : (
                              <Row>
                                {message?.type === "0" ? (
                                  <Col
                                    md={5}
                                    className="message-recieved d-flex"
                                  >
                                    {message.content}
                                    <span
                                      className="ms-3 d-flex align-items-end"
                                      style={{
                                        color: "#ACB3B9",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {new Date(
                                        (
                                          message?.timeformat_new + " UTC"
                                        ).replace(/-/g, "/")
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </span>
                                  </Col>
                                ) : (
                                  <Image
                                    md={5}
                                    height="150px"
                                    className="message-recieved d-flex justify-content-end"
                                    src={`${chatImageURL}${message?.content}`}
                                    onClick={() =>
                                      window.open(
                                        `${chatImageURL}${message?.content}`
                                      )
                                    }
                                  />
                                )}
                              </Row>
                            )}
                          </>
                        ))
                      ) : (
                        <Loader></Loader>
                      )}
                    </Row>
                  </div>
                </Card>
              ) : (
                <Row
                  className="justify-content-center align-items-center"
                  style={{ minHeight: "65vh" }}
                >
                  Select a Chat to Display Messages
                </Row>
              )}

              {this.state.chatId ? (
                this.state.blockStatus === "sendBlock" ? (
                  <div
                    style={{ color: "#C4C4C4" }}
                    className="d-flex justify-content-center"
                  >
                    You have Blocked this Profile. Unblock to Continue
                  </div>
                ) : this.state.blockStatus === "recievedBlock" ? (
                  ""
                ) : (
                  <div className="bottom-type" style={{ marginTop: "15px" }}>
                    <Form
                      className="message-type-box d-flex"
                      onSubmit={this.sendMessage}
                    >
                      <Form.Control
                        className="message-input"
                        placeholder="Your text goes here.."
                        value={newMessage}
                        ref={(el) => (this.inputTitle = el)}
                      />
                      <div
                        className="image-chat d-flex align-items-center"
                        style={{ paddingRight: "0.5rem" }}
                      >
                        <label
                          role="button"
                          className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                        >
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={(e) => this.uploadPhoto(e, "album2")}
                          />
                          <BiImageAdd color="var(--primary-color)" size={35} />
                        </label>
                      </div>
                      <Button
                        type="submit"
                        className="d-flex align-items-center message-send-button pe-0"
                      >
                        <BiSend
                          size={35}
                          className="me-2"
                          color="var(--primary-color)"
                        />{" "}
                      </Button>
                    </Form>
                  </div>
                )
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Modal
            show={this.state.showModalRemShortlist}
            onHide={() => {}}
            className="logout-modal"
          >
            <Modal.Title>Remove from Shortlist</Modal.Title>
            <Modal.Body>
              Are you sure to remove from shortlist?
              <Modal.Footer>
                <Button
                  style={{
                    borderColor: "var(--primary-color)",
                    backgroundColor: "#FFF",
                    color: "var(--primary-color)",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                  onClick={this.hideallmodals}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    border: "none",
                    backgroundColor: "var(--primary-color)",
                    color: "#FFF",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                  onClick={this.removeShortListApi}
                >
                  Remove
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showModalRemInterest}
            onHide={() => {}}
            className="logout-modal"
          >
            <Modal.Title>Remove from Interest</Modal.Title>
            <Modal.Body>
              Are you sure to remove from Interest?
              <Modal.Footer>
                <Button
                  style={{
                    borderColor: "var(--primary-color)",
                    backgroundColor: "#FFF",
                    color: "var(--primary-color)",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                  onClick={this.hideallmodals}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    border: "none",
                    backgroundColor: "var(--primary-color)",
                    color: "#FFF",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                  onClick={this.removeInterestApi}
                >
                  Remove
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showModalIgnoreProf}
            onHide={() => {}}
            className="logout-modal"
          >
            <Modal.Title>Ignore Profile</Modal.Title>
            <Modal.Body>
              Are you sure to Ignore this profile?
              <Modal.Footer>
                <Button
                  style={{
                    borderColor: "var(--primary-color)",
                    backgroundColor: "#FFF",
                    color: "var(--primary-color)",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                  onClick={this.hideallmodals}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    border: "none",
                    backgroundColor: "var(--primary-color)",
                    color: "#FFF",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                  onClick={this.ignoreProfile}
                >
                  Ignore
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.showCropModal}>
            <Modal.Body className="p-0">
              <div
                className="d-flex justify-content-center"
                style={{ minHeight: "200px" }}
              >
                <img src={this.state.croppedImage} alt="" height="200px" />
              </div>
              <div className="d-flex mt-3 justify-content-end">
                <Button
                  disabled={this.state.disabled}
                  onClick={() => this.closeCropModel()}
                  className="cancel-login-button m-2"
                >
                  Cancel
                </Button>
                <Button
                  disabled={this.state.disabled}
                  onClick={() => this.uploadCroppedImage()}
                  className="register-login-button m-2"
                >
                  Done
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
