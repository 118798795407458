import { Field, Formik } from "formik";
import React, { useState } from "react";
import { Button, Form, Image, Row } from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { Footer, NavbarMenu } from "../../Components";
import { environment } from "../../environment/env";
import "./IdVerification.css";

const baseURL = environment.hostname;

export default function IdVerification() {
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");

  let verifyID = (values, { resetForm }) => {
    fetch(`${baseURL}/verify_id`, {
      method: "POST",
      body: JSON.stringify({
        uid: localStorage.currentUser,
        type: values.type,
        name: values.name,
        idno: values.id,
        file: values.front,
        file2: values.back,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((response) => {
        toast.success("ID proof uploaded successfully");
        resetForm({});
        setFrontImage("");
        setBackImage("");
      })
      .catch((error) =>
        toast.error("An error occured while uploading ID proof")
      );
  };

  let uploadFrontImage = (event, values) => {
    var exts = /(.png|.webp|.jpg|.jpeg)/;
    if (event.target.value.match(exts)) {
      let file = event.target.files[0];
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          values.front = encoded;
          setFrontImage(URL.createObjectURL(file));
        };
        reader.onerror = (error) => reject(error);
      });
    } else {
      toast.error("Please choose  valid Image file like .png|.webp|.jpg|.jpeg");
    }
  };
  let uploadBackImage = (event, values) => {
    var exts = /(.png|.webp|.jpg|.jpeg)/;
    if (event.target.value.match(exts)) {
      let file = event.target.files[0];
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          values.back = encoded;
          setBackImage(URL.createObjectURL(file));
        };
        reader.onerror = (error) => reject(error);
      });
    } else {
      this.cancelAllLoading();
      toast.error("Please choose  valid Image file like .png|.webp|.jpg|.jpeg");
    }
  };
  return (
    <div>
      <NavbarMenu></NavbarMenu>
      <div className="verification">
        <div className="d-flex flex-column align-items-start">
          <Row style={{ color: "#C4C4C4" }}>
            <BsCheck
              className="w-auto"
              color="var(--primary-color)"
              size="24px"
            />
            ID Proof verification is must for creating a profile in Matrimonial
            website as per Government order. Please upload a valid ID proof
          </Row>
          <Row style={{ color: "#C4C4C4" }}>
            <BsCheck
              className="w-auto"
              color="var(--primary-color)"
              size="24px"
            />
            ID proof verified profile gets more response
          </Row>
          <Row style={{ color: "#C4C4C4" }}>
            <BsCheck
              className="w-auto"
              color="var(--primary-color)"
              size="24px"
            />
            Uploaded ID proof must contsin your address, photo and date of birth
          </Row>
          <Row style={{ color: "#C4C4C4" }}>
            <BsCheck
              className="w-auto"
              color="var(--primary-color)"
              size="24px"
            />
            Upload ID proof of profile holder, not the parents
          </Row>
        </div>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={{ name: "", type: "", id: "", front: "", back: "" }}
            onSubmit={verifyID}
            validate={(values) => {
              const errors = {};
              //   let regexname = /^[a-z A-Z]*\.?[a-z A-Z]*$/;
              //   if (!regexname.test(values?.name))
              //     errors.name = "Provide your valid name";
              //   let kerName = values.name;
              //   if (kerName.replaceAll(" ", "") < 3)
              //     errors.name = "Provide  valid name";
              if (!values.name) {
                errors.name = "Enter your  name";
              }
              if (!values.type) {
                errors.type = "Choose type of ID proof";
              }
              if (!values.id) {
                errors.id = "Enter ID number";
              }
              if (!values.front) {
                errors.front = "Upload proof front image";
              }

              return errors;
            }}
          >
            {({
              resetForm,
              handleSubmit,
              errors,
              touched,
              values,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="mt-5">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Full Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Field type="text" name="name" className="form-control" />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.name && touched.name ? errors.name : ""}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Type <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Field as="select" name="type" className="form-control">
                    <option default selected hidden value=""></option>
                    <option value="Adhaar">Adhaar</option>
                    <option value="Driving License">Driving License</option>
                    <option value="Passport">Passport</option>
                    <option value="Voter ID">Voter ID</option>
                    <option value="Other">Other</option>
                  </Field>
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.type && touched.type ? errors.type : ""}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    ID number <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Field type="text" name="id" className="form-select" />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.id && touched.id ? errors.id : ""}
                  </div>
                </Form.Group>
                <Form.Group
                  className={`mb-4 d-flex justify-content-center flex-column ${
                    frontImage ? "align-items-center" : ""
                  }`}
                >
                  {frontImage ? (
                    <div
                      style={{ height: "316px", width: "316px" }}
                      className="position-relative"
                    >
                      <Image
                        src={frontImage}
                        height="316px"
                        width="316px"
                        style={{ borderRadius: "25px" }}
                      />
                      <div className="position-absolute delete-photo-icon p-0 cursor-pointer">
                        <IoCloseSharp
                          color="var(--primary-color)"
                          size={24}
                          onClick={(values) => {
                            values.front = "";
                            setFrontImage("");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <Form.Label>
                        Upload front image{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div
                        className="id-image-slot w-100 form-control"
                        style={{ borderRadius: "10px", width: "100%" }}
                      >
                        <label
                          role="button"
                          className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                        >
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={(e) => uploadFrontImage(e, values)}
                          />
                          <MdOutlineAddPhotoAlternate
                            color="var(--primary-color)"
                            size={50}
                          />
                          <div>Upload front image</div>
                        </label>
                      </div>
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.front && touched.front ? errors.front : ""}
                      </div>
                    </>
                  )}
                </Form.Group>
                <Form.Group
                  className={`mb-4 d-flex justify-content-center flex-column ${
                    backImage ? "align-items-center" : ""
                  }`}
                >
                  {backImage ? (
                    <div
                      style={{ height: "316px", width: "316px" }}
                      className="position-relative"
                    >
                      <Image
                        src={backImage}
                        height="316px"
                        width="316px"
                        style={{ borderRadius: "25px" }}
                      />
                      <div className="position-absolute delete-photo-icon p-0 cursor-pointer">
                        <IoCloseSharp
                          color="var(--primary-color)"
                          size={24}
                          onClick={(values) => {
                            values.back = "";
                            setBackImage("");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <Form.Label>Upload back image</Form.Label>
                      <div
                        className="id-image-slot w-100 form-control"
                        style={{ borderRadius: "10px" }}
                      >
                        <label
                          role="button"
                          className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
                        >
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={(e) => uploadBackImage(e, values)}
                          />
                          <MdOutlineAddPhotoAlternate
                            color="var(--primary-color)"
                            size={50}
                          />
                          <div>Upload back image</div>
                        </label>
                      </div>
                    </>
                  )}
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Button className="invite-button" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
