import React, { useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { FaRegAddressCard } from "react-icons/fa";
import { MdVerified, MdCircle } from "react-icons/md";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { NoData } from "..";
import { environment } from "../../environment/env";
import UpgradeModal from "../Modal/UpgradeModal";
import ProtectedImage from "../ProtectedImage/ProtectedImage";
import "./Card.css";
import { useLocation } from "react-router-dom";

export default function CardComponent(props) {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const asPath = useLocation();
  let navigate = useNavigate();
  let showProfile = (id) => {
    localStorage.setItem("visitingUser", id);
    navigate("/profile");
  };
  let imageURL = environment.imageURL;

  let profiles = props?.profiles;
  let profilesToDisplay = props?.profiles?.slice(0, 3);
  // console.log(props, "ignored...##");

  function checkPlanAndImage(imageP, album_visibility, gender) {
    var image = imageURL + imageP;
    if (
      (album_visibility == "55" && localStorage.plan == "2") ||
      album_visibility == "56" ||
      imageP == null
    ) {
      if (gender === "Male" || localStorage.gender === "Female") {
        image = imageURL + "avatar_Male.png";
      } else {
        image = imageURL + "avatar_Female.png";
      }
    }
    return image;
  }

  if (props?.title) {
    return (
      <div>
        <Card className="card-component">
          {props.title != "Admin Recommended" ||
          (props?.plan && props.plan === "Y") ? (
            <Card.Body style={{ padding: "12px" }}>
              <Card.Title
                className="title d-flex justify-content-center"
                style={{ margin: "30px 0" }}
              >
                {props?.title}
              </Card.Title>
              {profilesToDisplay.length ? (
                profilesToDisplay.map((profile, index) => (
                  <Row
                    style={{
                      marginBottom: "22px",
                      cursor: "pointer",
                      marginLeft: "1px",
                    }}
                    key={index}
                    onClick={() => showProfile(profile?.id)}
                  >
                    <Col
                      xs={"auto"}
                      className="d-flex position-relative px-0 mx-2"
                    >
                      {profile.face_image ? (
                        <>
                          <Card.Img
                            src={checkPlanAndImage(
                              profile.face_image,
                              profile?.album_visibility,
                              profile.gender
                            )}
                            as={Image}
                            className={`card-image ${
                              profile?.album_visibility === "56"
                                ? "blur-image"
                                : ""
                            }`}
                          />
                          {profile?.album_visibility === "56" ? (
                            <ProtectedImage
                              from={null}
                              id={props?.profile?.id}
                              requestButton={false}
                            ></ProtectedImage>
                          ) : (
                            ""
                          )}
                        </>
                      ) : profile.gender === "Male" ||
                        localStorage.gender === "Female" ? (
                        <Card.Img
                          src={imageURL + "avatar_Male.png"}
                          as={Image}
                          className="card-image blur-image"
                        />
                      ) : (
                        <Card.Img
                          src={imageURL + "avatar_Female.png"}
                          as={Image}
                          className="card-image blur-image"
                        />
                      )}
                    </Col>
                    <Col
                      xs={"auto"}
                      className="d-flex flex-column align-items-start pt-2"
                    >
                      <Card.Text
                        className="m-0"
                        style={{
                          fontSize: "20px !important",
                          color: "var(--primary-color)",
                        }}
                      >
                        {profile.id}
                        <MdVerified
                          size="28px"
                          color={
                            props?.profile?.verified_status == "1"
                              ? "var(--secondary-color)"
                              : "#C4C4C4"
                          }
                          className="mx-2"
                        />
                        <MdCircle
                          size="15px"
                          color={
                            new Date(
                              profile?.lastlogin_new + " UTC"
                            ).toDateString() != new Date().toDateString()
                              ? "#C4C4C4"
                              : new Date(
                                  profile?.lastlogin_new + " UTC"
                                ).getHours() != new Date().getHours()
                              ? "#C4C4C4"
                              : new Date().getMinutes() - 5 <=
                                  new Date(
                                    profile?.lastlogin_new + " UTC"
                                  ).getMinutes() &&
                                new Date(
                                  profile?.lastlogin_new + " UTC"
                                ).getMinutes() <= new Date().getMinutes()
                              ? "#00BE63"
                              : "#C4C4C4"
                          }
                          className="mx-1"
                        />
                      </Card.Text>
                      <Card.Text>
                        {profile.age} yrs, {profile.place}
                      </Card.Text>
                    </Col>
                  </Row>
                ))
              ) : (
                <NoData></NoData>
              )}
              {profiles?.length > 3 ? (
                <>
                  <Link
                    to={`/show/${props?.title}`}
                    state={{ profiles: profiles, from: asPath.pathname }}
                    className="justify-content-end m-3 row show-more"
                  >
                    More
                  </Link>
                  <Outlet />
                </>
              ) : (
                ""
              )}
            </Card.Body>
          ) : (
            <Card.Body style={{ padding: "12px" }}>
              <Card.Title
                className="title d-flex justify-content-center"
                style={{ margin: "30px 0" }}
              >
                {props?.title}
              </Card.Title>
              <Row className="align-items-center flex-column justify-content-center h-50">
                <div
                  style={{ fontSize: "16px", color: "var(--primary-color)" }}
                  className="w-auto mb-3"
                >
                  This is a premium feature
                </div>
                <div
                  style={{ color: "#C4C4C4", fontSize: "14px" }}
                  className="w-auto mb-3"
                >
                  You need a higher plan to access this feature
                </div>
                <Link
                  className="p-2"
                  to="/plans-and-payment"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "#FFF",
                    width: "auto",
                    border: "none",
                    borderRadius: "10px",
                  }}
                >
                  Upgrade Plan
                </Link>
              </Row>
            </Card.Body>
          )}
        </Card>
      </div>
    );
  } else {
    return (
      <div>
        <Card
          className="card-component"
          style={{ backgroundColor: "var(--primary-color)" }}
        >
          <Card.Body
          // style={{ padding: "52px 24px" }}
          >
            <Row style={{ marginBottom: "28px" }}>
              <Col xs={3} className="d-flex justify-content-center pt-1">
                <FaRegAddressCard color="#FFF" size="28px" />
              </Col>
              <Link
                to={`/show/Recently Viewed`}
                state={{
                  profiles: props?.recent,
                  title: "Recently Viewed ",
                  from: asPath.pathname,
                }}
                className="p-0 col text-decoration-none"
              >
                <Card.Title className="viewed-title">
                  {" "}
                  Recently Viewed
                </Card.Title>
                <Card.Text className="viewed-description">
                  {" "}
                  You have viewed {props?.recent?.length} profiles{" "}
                </Card.Text>
              </Link>
            </Row>
            <Row style={{ marginBottom: "28px" }}>
              <Col xs={3} className="d-flex justify-content-center pt-1">
                <FaRegAddressCard color="#FFF" size="28px" />
              </Col>
              <Link
                to={`/show/Contacts Viewed`}
                state={{
                  profiles: props?.contacts,
                  title: "Contacts Viewed",
                  from: asPath.pathname,
                }}
                className="p-0 col text-decoration-none"
              >
                <Card.Title className="viewed-title">
                  {" "}
                  Contacts Viewed{" "}
                </Card.Title>
                <Card.Text className="viewed-description">
                  {" "}
                  {props?.contacts?.length} Contact information viewed{" "}
                </Card.Text>
              </Link>
            </Row>
            <Row style={{ marginBottom: "28px" }}>
              <Col xs={3} className="d-flex justify-content-center pt-1">
                <FaRegAddressCard color="#FFF" size="28px" />
              </Col>
              <Link
                to={localStorage.plan != "2" ? `/show/Who Shortlisted You` : ""}
                onClick={
                  localStorage.plan === "2"
                    ? () => setShowUpgradeModal(true)
                    : ""
                }
                state={{
                  profiles: props?.whoShortlist,
                  title: "Profiles shortlisted you",
                  from: asPath.pathname,
                }}
                className="p-0 col text-decoration-none"
              >
                <Card.Title className="viewed-title">
                  {" "}
                  Who Shortlisted You{" "}
                </Card.Title>
                <Card.Text className="viewed-description">
                  {" "}
                  {props?.whoShortlist?.length} Profiles shortlisted you{" "}
                </Card.Text>
              </Link>
            </Row>
            <Row style={{ marginBottom: "28px" }}>
              <Col xs={3} className="d-flex justify-content-center pt-1">
                <FaRegAddressCard color="#FFF" size="28px" />
              </Col>
              <Link
                to={localStorage.plan != "2" ? `/show/Who Viewed You` : ""}
                onClick={
                  localStorage.plan === "2"
                    ? () => setShowUpgradeModal(true)
                    : ""
                }
                state={{
                  profiles: props?.whoView,
                  title: "Who Viewed You",
                  from: asPath.pathname,
                }}
                className="p-0 col text-decoration-none"
              >
                <Card.Title className="viewed-title">
                  {" "}
                  Who Viewed You
                </Card.Title>
                <Card.Text className="viewed-description">
                  {" "}
                  {props?.whoView?.length} Profiles viewed you{" "}
                </Card.Text>
              </Link>
            </Row>
            <Row>
              <Col xs={3} className="d-flex justify-content-center pt-1">
                <FaRegAddressCard color="#FFF" size="28px" />
              </Col>
              <Link
                to={`/show/Igonred Profiles`}
                state={{
                  profiles: props?.ignored,
                  title: "Ignored Profiles",
                  from: asPath.pathname,
                }}
                className="p-0 col text-decoration-none"
              >
                <Card.Title className="viewed-title">
                  {" "}
                  Ignored Profiles{" "}
                </Card.Title>
                <Card.Text className="viewed-description">
                  {" "}
                  {props?.ignored?.length} Profiles ignored{" "}
                </Card.Text>
              </Link>
            </Row>
          </Card.Body>
          <UpgradeModal
            upgradeNow={showUpgradeModal}
            closeModal={() => setShowUpgradeModal(false)}
          ></UpgradeModal>
        </Card>
      </div>
    );
  }
}
