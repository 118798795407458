import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { environment } from "../../environment/env";
import "./FeedbackComponent.css";

const baseURL = environment.hostname;

export default function FeedbackComponent(props) {
  const [feedbackType, setFeedbackType] = useState([]);
  const [isDone, setisDone] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetch(`${baseURL}/feedbacktype`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setFeedbackType(res);
      });
  }, []);

  let sendFeedback = (values, { resetForm }) => {
    var userId = "";
    if (localStorage.currentUser) {
      userId = `(${localStorage.currentUser})`;
    }

    window.open(
      `mailto:help@click4marry.com?subject=Click4marry feedback&body=Name:${values.name} ${userId}%0D%0AEmail:${values.email}%0D%0AType:Feedback%0D%0ATitle:${values.title}%0D%0AMessage:${values.body}`
    );
    setisDone(true);
    if (localStorage.currentUser && localStorage.session) {
      props.navg();
    }
    resetForm({});
  };

  return (
    <Card
      className={`${
        props.page === "contact" ? "position-absolute" : ""
      } feedback-component`}
      style={{ top: "0" }}
    >
      <Card.Title
        style={{
          color: "var(--primary-color)",
          fontSize: "24px",
          marginBottom: "30px",
        }}
      >
        Feedback
      </Card.Title>
      {isDone ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "350px", fontSize: "20px" }}
        >
          Thank you for your feedback..
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{ name: "", email: "", title: "", body: "" }}
          onSubmit={sendFeedback}
          validate={(values) => {
            const errors = {};
            let regexname = /^[a-zA-Z]+$/;
            if (!regexname.test(values?.name))
              errors.name = `Provide a valid name`;
            let kerName = values.name;
            if (kerName.replaceAll(" ", "") < 3)
              errors.name = `Provide a valid name`;
            if (!values.name) errors.name = "Enter your name";
            if (!values.email) {
              errors.email = "Enter your mail ID";
            }

            let regex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(values.email))
              errors.email = "Provide a valid email address";
            return errors;
          }}
        >
          {({
            resetForm,
            handleSubmit,
            errors,
            touched,
            values,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Name <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Field type="text" name="name" className="form-control" />
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.name && touched.name ? errors.name : ""}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Email Id <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Field type="text" name="email" className="form-control" />
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.email && touched.email ? errors.email : ""}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Field as="select" name="title" className="form-select">
                  <option default selected hidden value=""></option>
                  {feedbackType.map((type) => (
                    <option value={type.name}>{type.name}</option>
                  ))}
                </Field>
              </Form.Group>
              {/* <Form.Group className="mb-4">
              <Form.Label>Image</Form.Label>
              <Field type="file" name="file" className="form-control" />
            </Form.Group> */}
              <Form.Group className="mb-4">
                <Form.Label>Body</Form.Label>
                {/* <Field type="text" as="textarea" name='body' className='form-control' /> */}
                <Form.Control
                  as="textarea"
                  name="body"
                  className="form-control"
                  rows={6}
                />
              </Form.Group>

              <div className="d-flex justify-content-end">
                <Button
                  className="otp-verify-button"
                  type="submit"
                  style={{ fontWeight: "500" }}
                >
                  Send Feedback
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Card>
  );
}
