import React, { Component } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { MdOutlineCall, MdOutlineLocationOn } from "react-icons/md";
import { IoRestaurantOutline } from "react-icons/io5";
import { VscMortarBoard } from "react-icons/vsc";
import { CgBriefcase } from "react-icons/cg";
import { GiShipWheel } from "react-icons/gi";
import { BsGlobe } from "react-icons/bs";
import { environment } from "../../environment/env";
import "./ProfileDetails.css";
import { Edit, EditDetails } from "..";
import { toast } from "react-toastify";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";

const baseURL = environment.hostname;
let basicInfo,
  contactInfo,
  jobEducationInfo,
  physicalInfo,
  thoughtsLifestyleInfo,
  courses,
  subgroups,
  allegiances,
  familyDetailsInfo,
  preferenceInfo,
  listValue,
  countries,
  states,
  districts,
  religions,
  isViewed,
  prefixList,
  myPlan;

let countrycodesData = {};
let heightOptions = [],
  weightOptions = [],
  ageOptions = [];

export default class ProfileDetails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    ageOptions = [];
    for (let i = 18; i <= 100; i++) {
      ageOptions.push(i);
    }
    heightOptions = [];
    for (let i = 135; i <= 215; i++) {
      heightOptions.push(i);
    }
    weightOptions = [];
    for (let i = 35; i <= 120; i++) {
      weightOptions.push(i);
    }
    this.state = {
      isLoading: true,
      showModal: false,
      InfoToEdit: "",
      viewCurrentContact: false,
      title: "",
      contactLock: "",
      activeMenuItem: "",
      showupgradePlanModal: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props?.for != "visit")
      localStorage.setItem("visitingUser", localStorage.currentUser);
    this.getDetails();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDetails = () => {
    let listValueApi = fetch(`${baseURL}/list_value`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let basicInfoApi = fetch(`${baseURL}/basic_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
      }),
    });
    let contactInfoApi = fetch(`${baseURL}/basic_contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
      }),
    });
    let jobEducationApi = fetch(`${baseURL}/job_education`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
      }),
    });

    let physicalApi = fetch(`${baseURL}/get_physical`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
      }),
    });

    let thoughtsLifestyleApi = fetch(`${baseURL}/get_lifestyle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
      }),
    });

    let familyDetailsApi = fetch(`${baseURL}/get_family`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
      }),
    });

    let preferenceApi = fetch(`${baseURL}/get_preference`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
      }),
    });

    let countryApi = fetch(`${baseURL}/get_country`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let stateApi = fetch(`${baseURL}/getstate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let districtApi = fetch(`${baseURL}/getdistrict`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state_id: 13,
      }),
    });

    let religionApi = fetch(`${baseURL}/religion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let isViewedApi = fetch(`${baseURL}/is_contact_viewed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
        ip: "111.111.1.1",
      }),
    });
    let isContactLockedApi = fetch(`${baseURL}/is_contact_locked`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profid: localStorage.currentUser,
        id: localStorage.visitingUser,
        session_id: localStorage.session,
      }),
    });

    let myPlanApi = fetch(`${baseURL}/myplan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });

    let allegianceApi = fetch(`${baseURL}/allegiance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
      }),
    });
    let prefixListApi = fetch(`${baseURL}/prefixlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    Promise.all([
      basicInfoApi,
      contactInfoApi,
      jobEducationApi,
      physicalApi,
      familyDetailsApi,
      preferenceApi,
      listValueApi,
      countryApi,
      stateApi,
      districtApi,
      religionApi,
      thoughtsLifestyleApi,
      isViewedApi,
      myPlanApi,
      allegianceApi,
      isContactLockedApi,
      prefixListApi,
    ])
      .then((values) => Promise.all(values.map((value) => value.json())))
      .then((response) => {
        fetch(`${baseURL}/getcourse`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: response[2][0].qualification,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            courses = res;
            fetch(`${baseURL}/getstate`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                country_id: response[1][0]?.country,
              }),
            })
              .then((respStates) => respStates.json())
              .then((respStates) => {
                states = respStates;

                fetch(`${baseURL}/getdistrict`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    state_id: response[1][0]?.city,
                  }),
                })
                  .then((respd) => respd.json())
                  .then((respd) => {
                    // //;
                    fetch(`${baseURL}/caste`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        id: localStorage.currentUser,
                        religion_id: response[11][0].religion_id,
                      }),
                    })
                      .then((resp) => resp.json())
                      .then((resp) => {
                        subgroups = resp;
                        listValue = response[6][0];
                        countries = response[7];
                        // states = response[8];
                        districts = respd;
                        religions = response[10];
                        isViewed = response[12];
                        myPlan = response[13][0];
                        allegiances = response[14];
                        prefixList = response[16];
                        basicInfo = [
                          { key: "Age", value: response[0][0].age },
                          { key: "Date of Birth", value: response[0][0].birth },
                          {
                            key: "Marital Status",
                            value: response[0][0].mstatus,
                          },
                        ];

                        if (
                          response[0][0].mstatus != "Unmarried" &&
                          response[0][0].mstatus != "Nikah only"
                        ) {
                          basicInfo.push({
                            key: "Children",
                            value: response[0][0].num_children,
                          });
                        }

                        var utcDate = response[0][0].lastlogin + " UTC";

                        var date = new Date(utcDate);

                        const date12 = date.toLocaleString("en-US", {
                          hour12: false,
                        });
                        var datearray = date12.split(",");

                        var newdate = datearray[0].split("/");

                        var newdate12 =
                          newdate[1] + "/" + newdate[0] + "/" + newdate[2];

                        var newdatearray = newdate12 + "" + datearray[1];

                        // console.log(newdate12 + "" + datearray[1]);
                        // var date = utcDate.toLocaleString();
                        // var date = utcDate.toString();

                        basicInfo.push(
                          {
                            key: "Profile Created for",
                            value: response[0][0].created_by,
                          },
                          {
                            key: "Last Login",
                            value: newdatearray.toLocaleString(),
                          }
                        );
                        countrycodesData.list = listValue.prefix_code;
                        countrycodesData.selectedWhatsapp =
                          response[1][0]?.countrycode_whatsapp;
                        countrycodesData.selectedAlternate =
                          response[1][0]?.countrycode_land;
                        countrycodesData.selectedPhone =
                          response[1][0]?.countrycode;
                        var mother_occupation_list = [];

                        mother_occupation_list.push({
                          id: "90",
                          parent_id: "21",
                          name: "Not working",
                        });
                        for (var i = 0; i < listValue.occupation.length; i++) {
                          if (listValue.occupation[i].id != "90") {
                            mother_occupation_list.push(
                              listValue.occupation[i]
                            );
                          }
                        }

                        listValue.occupation = mother_occupation_list;

                        if (response[1]) {
                          // //;
                          response[1][0].country == 0
                            ? (response[1][0].country = "")
                            : (response[1][0].country = response[1][0].country);
                          contactInfo = [
                            {
                              title: "Permanent Address",
                              title_icon: (
                                <MdOutlineLocationOn
                                  className="p-0"
                                  size="24px"
                                  color="var(--primary-color)"
                                />
                              ),
                            },
                            {
                              key: "Name",
                              value: response[0][0]?.name || "",
                              name: "name",
                            },
                            {
                              key: "House Name/Number",
                              value: response[1][0]?.address || "",
                              name: "address",
                            },
                            {
                              key: "Father's name",
                              value: response[1][0]?.sonof || "",
                              name: "sonof",
                            },
                            {
                              key: "Mother's name",
                              value: response[0][0]?.mother_name || "",
                              name: "mother_name",
                            },
                            {
                              key: "Country",
                              value: response[1][0]?.country || "",
                              name: "country",
                              type: "select",
                              listValue: countries,
                            },
                            {
                              key: "State",
                              value: response[1][0]?.city || "",

                              name: "city",
                              type: "select",
                              listValue: states,
                            },
                            {
                              key: "District",
                              value: response[1][0]?.place_id,
                              name: "place",
                              type: "select",
                              listValue: districts,
                            },
                            {
                              key: "Street",
                              value: response[1][0]?.street || "",
                              name: "street",
                            },
                            {
                              key: "Pin",
                              value: response[1][0]?.pin || "",
                              name: "pin",
                              validation: "number",
                            },
                            {
                              key: "Landmark",
                              value: response[1][0]?.landmark || "",
                              name: "landmark",
                            },
                            // {
                            //   title: "Living Address",
                            //   title_icon: (
                            //     <MdOutlineLocationOn
                            //       className="p-0"
                            //       size="24px"
                            //       color="var(--primary-color)"
                            //     />
                            //   ),
                            // },
                            {
                              key: "Living  Address",
                              value: response[1][0]?.living_address || "",
                              name: "living_address",
                            },
                            {
                              title: "Phone Numbers",
                              title_icon: (
                                <MdOutlineCall
                                  className="p-0"
                                  size="24px"
                                  color="var(--primary-color)"
                                />
                              ),
                            },
                            {
                              key: "Phone Number ",
                              value: response[1][0]?.phone || "",
                              name: "phone",
                              validation: "number",
                              disabled: true,
                              readOnly: "true",
                            },
                            {
                              key: "Alternate Number",
                              value: response[1][0]?.land || "",
                              name: "land",
                              validation: "number",
                            },
                            {
                              key: "Contact Person",
                              value: response[1][0]?.contact_person || "",
                              name: "contact_person",
                              type: "select",
                              listValue: listValue.contact_person,
                            },
                            {
                              title: "Social Media Connections",
                              title_icon: (
                                <BsGlobe
                                  className="p-0"
                                  size="20px"
                                  color="var(--primary-color)"
                                />
                              ),
                            },
                            {
                              key: "Whatsapp",
                              value: response[1][0]?.whatsappno || "",
                              name: "whatsappno",
                              validation: "number",
                            },
                            {
                              key: "Facebook ID",
                              value: response[1][0]?.facebook_id || "",
                              name: "facebook_id",
                              // validation: "facebook_id",
                            },
                            {
                              key: "Instagram ID",
                              value: response[1][0]?.instagramme_id || "",
                              name: "instagramme_id",
                              // validation: "instagramme_id",
                            },
                            {
                              key: "Email",
                              value: response[1][0]?.email || "",
                              name: "email",
                              validation: "email",
                            },
                          ];
                          // this.setState({ contactLock: response[1][0].locked });
                        } else {
                          contactInfo = false;
                        }

                        // set other to last
                        var sortedQualification = [];
                        sortedQualification.push(...listValue.qualification);
                        var otherIndexQualification =
                          sortedQualification.findIndex((x) =>
                            x.name.toString().toLowerCase().includes("other")
                          );
                        if (otherIndexQualification > -1) {
                          sortedQualification.splice(
                            otherIndexQualification,
                            1
                          );
                          sortedQualification.push(
                            listValue.qualification[otherIndexQualification]
                          );
                        }

                        try {
                          // set other to last
                          var sortedcourse = [];
                          sortedcourse.push(...courses);
                          var otherIndexcourse = sortedcourse.findIndex((x) =>
                            x.name.toString().toLowerCase().includes("other")
                          );
                          if (otherIndexcourse > -1) {
                            sortedcourse.splice(otherIndexcourse, 1);
                            sortedcourse.push(courses[otherIndexcourse]);
                          }
                        } catch (e) {}

                        // set other to last
                        var sortedjob = [];
                        sortedjob.push(...listValue.occupation);
                        var otherIndexjobs = sortedjob.findIndex((x) =>
                          x.name.toString().toLowerCase().includes("other")
                        );
                        if (otherIndexjobs > -1) {
                          sortedjob.splice(otherIndexjobs, 1);
                          sortedjob.push(listValue.occupation[otherIndexjobs]);
                        }

                        response[2][0].sector == 0
                          ? (response[2][0].sector = "")
                          : (response[2][0].sector = response[2][0].sector);
                        response[2][0].annual_income == 0
                          ? (response[2][0].annual_income = "")
                          : (response[2][0].annual_income =
                              response[2][0].annual_income);
                        response[2][0].qualification == 0
                          ? (response[2][0].qualification = "")
                          : (response[2][0].qualification =
                              response[2][0].qualification);
                        response[2][0].occupation == 0
                          ? (response[2][0].occupation = "")
                          : (response[2][0].occupation =
                              response[2][0].occupation);

                        jobEducationInfo = [
                          {
                            title: "Education Details",
                            title_icon: (
                              <VscMortarBoard
                                className="p-0"
                                size="23px"
                                color="var(--primary-color)"
                              />
                            ),
                          },
                          {
                            key: "Qualification",
                            required: true,
                            value: response[2][0].qualification || "",
                            name: "qualification",
                            type: "select",
                            listValue: sortedQualification,
                          },
                          {
                            key: "Course",
                            value: response[2][0].course || "",
                            required: true,
                            name: "course",
                            type: "select",
                            listValue: sortedcourse,
                          },
                          {
                            key: "Institute / College",
                            value: response[2][0].coursedetail || "",
                            name: "coursedetail",
                          },
                          {
                            key: "Additional Qualifications",
                            value: response[2][0].other_qualification || "",
                            name: "additional_qual",
                          },
                          {
                            title: "Job Details",
                            title_icon: (
                              <CgBriefcase
                                className="p-0"
                                size="21px"
                                color="var(--primary-color)"
                              />
                            ),
                          },
                          {
                            key: "Job",
                            value: response[2][0].occupation || "",
                            type: "select",
                            listValue: sortedjob,
                            name: "occupation",
                          },
                          {
                            key: "Sector",
                            value: response[2][0].sector || "",
                            type: "select",
                            listValue: listValue.job_sector,
                            name: "sector",
                          },
                          {
                            key: "Company / Institution",
                            value: response[2][0].company || "",
                            name: "company",
                          },
                          {
                            key: "Job Details",
                            value: response[2][0].jobdetail || "",
                            name: "jobdetail",
                          },
                          {
                            key: "Annual Income",
                            value: response[2][0].annual_income || "",
                            name: "annual_income",
                            type: "select",
                            listValue: listValue.income,
                          },
                        ];

                        response[3][0].body_type =
                          response[3][0].body_type == 0
                            ? ""
                            : response[3][0].body_type;
                        response[3][0].complexion =
                          response[3][0].complexion == 0
                            ? ""
                            : response[3][0].complexion;
                        response[3][0].blood_group =
                          response[3][0].blood_group == 0
                            ? ""
                            : response[3][0].blood_group;
                        response[3][0].height =
                          response[3][0].height == 0
                            ? ""
                            : response[3][0].height;
                        response[3][0].weight =
                          response[3][0].weight == 0
                            ? ""
                            : response[3][0].weight;
                        response[3][0].physical_status =
                          response[3][0].physical_status == 0
                            ? ""
                            : response[3][0].physical_status;

                        physicalInfo = [
                          {
                            key: "Height (cm)",
                            value: response[3][0].height || "",
                            label: response[3][0].height + " cm" || "",
                            name: "height",
                            type: "select",
                            listValue: heightOptions,
                          },
                          {
                            key: "Weight (kg)",
                            label: response[3][0].weight + " kg" || "",

                            value: response[3][0].weight || "",
                            name: "weight",
                            type: "select",
                            listValue: weightOptions,
                          },
                          {
                            key: "Body Type",
                            value: response[3][0].body_type || "",
                            type: "select",
                            listValue: listValue.body_type,
                            name: "body_type",
                          },
                          {
                            key: "Physical Status",
                            value: response[3][0].physical_status || "",
                            type: "select",
                            listValue: listValue.physical_status,
                            name: "physical_status",
                          },
                          {
                            key: "Complexion",
                            value: response[3][0].complexion || "",
                            type: "select",
                            listValue: listValue.complexion,
                            name: "complexion",
                          },
                          {
                            key: "Blood Group",
                            value: response[3][0].blood_group || "",
                            name: "blood_group",
                            type: "select",
                            listValue: listValue.blood_group,
                          },
                        ];

                        // set other to last
                        var sortedreligion = [];
                        sortedreligion.push(...religions);
                        var otherindexrelegion = sortedreligion.findIndex((x) =>
                          x.name.toString().toLowerCase().includes("other")
                        );
                        if (otherindexrelegion > -1) {
                          sortedreligion.splice(otherindexrelegion, 1);
                          sortedreligion.push(religions[otherindexrelegion]);
                        }

                        // set other to last
                        var sortedsubgroups = [];
                        sortedsubgroups.push(...subgroups);
                        var otherindexsubgroup = sortedsubgroups.findIndex(
                          (x) =>
                            x.name.toString().toLowerCase().includes("other")
                        );
                        if (otherindexsubgroup > -1) {
                          sortedsubgroups.splice(otherindexsubgroup, 1);
                          sortedsubgroups.push(subgroups[otherindexsubgroup]);
                        }

                        response[11][0].diet =
                          response[11][0].diet == 0 ? "" : response[11][0].diet;
                        response[11][0].religion_id =
                          response[11][0].religion_id == 0
                            ? ""
                            : response[11][0].religion_id;
                        response[11][0].caste_id =
                          response[11][0].caste_id == 0
                            ? ""
                            : response[11][0].caste_id;

                        thoughtsLifestyleInfo = [
                          {
                            title: "Religious Views",
                            title_icon: (
                              <GiShipWheel
                                className="p-0"
                                size="19px"
                                color="var(--primary-color)"
                              />
                            ),
                          },
                          {
                            key: "Religion",
                            value: response[11][0].religion_id || "",
                            name: "religion_id",
                            required: true,
                            type: "select",
                            listValue: sortedreligion,
                          },
                          {
                            key: "Subgroup",
                            value: response[11][0].caste_id || "",
                            name: "caste_id",
                            required: true,
                            type: "select",
                            listValue: sortedsubgroups,
                          },
                          {
                            key: "Allegiance",
                            value: response[11][0].allegiance || "",
                            type: "select",
                            listValue: allegiances,
                            name: "allegiance",
                          },
                          {
                            title: "Lifestyle",
                            title_icon: (
                              <IoRestaurantOutline
                                className="p-0"
                                size="20px"
                                color="var(--primary-color)"
                              />
                            ),
                          },
                          {
                            key: "Hobbies",
                            value: response[11][0]?.h?.split("~") || "",
                            type: "select",
                            name: "hobbies",
                            listValue: listValue.hobby,
                            multipleSelect: true,
                          },
                          {
                            key: "Diet",
                            value: response[11][0].diet || "",
                            listValue: listValue.diet,
                            type: "select",
                            name: "diet",
                          },
                          {
                            key: "Drink",
                            value: response[11][0].drink || "",
                            type: "select",
                            name: "drink",
                            listValue: listValue.drink_status,
                          },
                          {
                            key: "Smoke",
                            value: response[11][0].smoke || "",
                            type: "select",
                            name: "smoke",
                            listValue: listValue.smoke_status,
                          },
                        ];

                        // set other to last
                        var sortedmotherjob = [];
                        sortedmotherjob.push(...mother_occupation_list);
                        var otherIndexmotherjobs = sortedmotherjob.findIndex(
                          (x) =>
                            x.name.toString().toLowerCase().includes("other")
                        );
                        if (otherIndexmotherjobs > -1) {
                          sortedmotherjob.splice(otherIndexmotherjobs, 1);
                          sortedmotherjob.push(
                            mother_occupation_list[otherIndexmotherjobs]
                          );
                        }

                        response[4][0].family_type =
                          response[4][0].family_type == 0
                            ? ""
                            : response[4][0].family_type;
                        response[4][0].family_status =
                          response[4][0].family_status == 0
                            ? ""
                            : response[4][0].family_status;
                        response[4][0].father_occupation =
                          response[4][0].father_occupation == 0
                            ? ""
                            : response[4][0].father_occupation;
                        response[4][0].mother_occupation =
                          response[4][0].mother_occupation == 0
                            ? ""
                            : response[4][0].mother_occupation;

                        familyDetailsInfo = [
                          {
                            key: "Family Type",
                            value: response[4][0].family_type || "",
                            type: "select",
                            listValue: listValue.family_type,
                            name: "family_type",
                          },
                          {
                            key: "Family Status",
                            value: response[4][0].family_status || "",
                            type: "select",
                            listValue: listValue.family_status,
                            name: "family_status",
                          },
                          {
                            key: "Father Alive",
                            value: response[4][0].father_alive || "",
                            name: "father_alive",
                            type: "select",
                            listValue: ["Yes", "No"],
                          },
                          {
                            key: "Father's Occupation",
                            value: response[4][0].father_occupation || "",
                            name: "father_occupation",
                            type: "select",
                            listValue: sortedjob,
                          },
                          {
                            key: "Mother Alive",
                            value: response[4][0].mother_alive || "",
                            name: "mother_alive",
                            type: "select",
                            listValue: ["Yes", "No"],
                          },
                          {
                            key: "Mother's Occupation",
                            value: response[4][0].mother_occupation || "",
                            name: "mother_occupation",
                            type: "select",
                            listValue: sortedmotherjob,
                          },
                          {
                            key: "No of Brothers",
                            value: response[4][0].num_brothers || "",
                            name: "num_brothers",
                          },
                          {
                            key: `No of Brother's Married`,
                            value: response[4][0].num_brothers_married || "",
                            name: "num_brothers_married",
                            validation: "number",
                          },
                          {
                            key: "No of Sisters",
                            value: response[4][0].num_sisters || "",
                            name: "num_sisters",
                            validation: "number",
                          },
                          {
                            key: `No of Sister's Married`,
                            value: response[4][0].num_sisters_married || "",
                            name: "num_sisters_married",
                            validation: "number",
                          },
                          {
                            key: "Is Orphan",
                            value: response[4][0].is_orphan || "",
                            name: "is_orphan",
                            type: "select",
                            listValue: ["Yes", "No"],
                          },
                        ];

                        var partnerReligionList = [];
                        religions.map((item) => {
                          if (item.id == response[11][0].religion_id) {
                            partnerReligionList.push(item);
                          }
                        });
                        const partner_age_split =
                          response[5][0].partner_age.split("-");
                        const partner_height_split =
                          response[5][0].partner_height.split("-");
                        const partner_weight_split =
                          response[5][0].partner_weight.split("-");

                        preferenceInfo = [
                          {
                            key: "Age",
                            value:
                              response[5][0].partner_age == 0
                                ? "Any"
                                : (partner_age_split[0] === "0"
                                    ? "Any"
                                    : partner_age_split[0]) +
                                  "-" +
                                  (partner_age_split[1] === "0"
                                    ? "Any"
                                    : partner_age_split[1]),

                            name: "age",
                            listValue: ageOptions,
                          },
                          {
                            key: "Religion",
                            value: response[5][0].partner_religion,
                            name: "religion",
                            type: "select",
                            listValue: partnerReligionList,
                          },
                          {
                            key: "Subgroup",
                            value: response[5][0].partner_caste,
                            name: "subgroup",
                            listValue: sortedsubgroups,
                          },
                          {
                            key: "Country living",
                            required: true,
                            value: response[5][0].partner_country_living,
                            name: "country_living",
                            type: "select",
                            listValue: countries,
                          },
                          {
                            key: "State",
                            required: true,
                            value: response[5][0].partner_city,
                            name: "state",
                            type: "select",
                            listValue: states,
                          },
                          {
                            key: "District",
                            required: true,
                            value: response[5][0].partner_place,
                            name: "district",
                            type: "select",
                            listValue: districts,
                          },
                          {
                            key: "Qualification",
                            required: true,
                            value: response[5][0].partner_qualification,
                            name: "qualification",
                            type: "select",
                            listValue: listValue.qualification,
                          },
                          {
                            key: "Job",
                            value: response[5][0].partner_occupation,
                            name: "occupation",
                            type: "select",
                            listValue: listValue.occupation,
                          },
                          {
                            key: "Job sector",
                            value: response[5][0].partner_job_sector,
                            name: "job_sector",
                            type: "select",
                            listValue: listValue.job_sector,
                          },
                          {
                            key: "Annual income",
                            value:
                              response[5][0].partner_annual_income == null
                                ? "Any"
                                : response[5][0].partner_annual_income,
                            name: "income",
                            type: "select",
                            listValue: listValue.income,
                          },
                          {
                            key: "Marital status",
                            value: response[5][0].partner_marital_status,
                            name: "marital_status",
                            type: "select",
                            listValue: listValue.marital_status,
                          },
                          {
                            key: "Physical status",
                            value: response[5][0].partner_physical_status,
                            name: "physical_status",
                            type: "select",
                            listValue: listValue.physical_status,
                          },
                          {
                            key: "Complexion",
                            value: response[5][0].partner_complexion,
                            name: "complexion",
                            type: "select",
                            listValue: listValue.complexion,
                          },
                          {
                            key: "body type",
                            value: response[5][0].partner_body_type,
                            name: "body_type",
                            type: "select",
                            listValue: listValue.body_type,
                          },
                          {
                            key: "Diet",
                            value: response[5][0].partner_diet,
                            name: "diet",
                            type: "select",
                            listValue: listValue.diet,
                          },
                          {
                            key: "Height (cm)",

                            value:
                              response[5][0].partner_height == 0
                                ? "Any"
                                : (partner_height_split[0] === "0"
                                    ? "Any"
                                    : partner_height_split[0]) +
                                  "-" +
                                  (partner_height_split[1] === "0"
                                    ? "Any"
                                    : partner_height_split[1]),
                            label:
                              response[5][0].partner_height == 0
                                ? "Any"
                                : (partner_height_split[0] === "0"
                                    ? "Any"
                                    : partner_height_split[0]) +
                                  "-" +
                                  (partner_height_split[1] === "0"
                                    ? "Any"
                                    : partner_height_split[1]) +
                                  " cm",
                            name: "height",
                            type: "select",
                            listValue: heightOptions,
                          },
                          {
                            key: "Weight (kg)",
                            value:
                              response[5][0].partner_weight == 0
                                ? "Any"
                                : (partner_weight_split[0] === "0"
                                    ? "Any"
                                    : partner_weight_split[0]) +
                                  "-" +
                                  (partner_weight_split[1] === "0"
                                    ? "Any"
                                    : partner_weight_split[1]),
                            label:
                              response[5][0].partner_weight == 0
                                ? "Any"
                                : (partner_weight_split[0] === "0"
                                    ? "Any"
                                    : partner_weight_split[0]) +
                                  "-" +
                                  (partner_weight_split[1] === "0"
                                    ? "Any"
                                    : partner_weight_split[1]) +
                                  " kg",
                            name: "weight",
                            type: "select",
                            listValue: weightOptions,
                          },
                          {
                            key: "Family status",
                            value: response[5][0].partner_family_status,
                            name: "family_status",
                            type: "select",
                            listValue: listValue.family_status,
                          },
                          {
                            key: "Smoke",
                            value: response[5][0].partner_smoke,
                            name: "smoke",
                            type: "select",
                            listValue: listValue.smoke_status,
                          },
                          {
                            key: "Drink",
                            value: response[5][0].partner_drink,
                            name: "drink",
                            type: "select",
                            listValue: listValue.drink_status,
                          },
                        ];
                        this.setState({
                          contactLock: response[15].data[0].locked + "",
                          isLoading: false,
                        });
                      });
                  });
              });
          });
      });
  };

  editDetails = (info, title) => {
    this.setState({
      title: title || "",
      InfoToEdit: info,
      showModal: true,
    });
  };

  closeModal = () => {
    this.getDetails();
    this.setState({
      showModal: false,
    });
  };

  scroll = (id) => {
    let element = document.getElementById(id);
    window.scrollTo(0, element.offsetTop - 180);

    // element.scrollIntoView(true);
    // setTimeout(() => {
    //   window.scrollBy(0, -100);
    // }, 260);
  };

  viewContact = () => {
    fetch(`${baseURL}/view_contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        owner_id: localStorage.currentUser,
        to_id: localStorage.visitingUser,
        ip: "111.111.1.1",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.props.onChangeRead();
        this.setState({ viewCurrentContact: res });
      });
  };

  lockContent = (type, value) => {
    fetch(`${baseURL}/lock_content`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: localStorage.currentUser,
        session_id: localStorage.session,
        type: type,
        status: value,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 1)
          switch (type) {
            case "basic":
              this.setState({ basicLock: value });
              break;
            case "contact":
              this.setState({ contactLock: value });
              break;
            case "job":
              this.setState({ jobLock: value });
              break;
            case "lifestyle":
              this.setState({ lifestyleLock: value });
              break;
            case "physical":
              this.setState({ physicalLock: value });
              break;
            case "family":
              this.setState({ familyLock: value });
              break;
            default:
              break;
          }
      });
  };

  requestUnlock = (type) => {
    this.setState({ contactLock: "3" });
    fetch(`${baseURL}/add_content_request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: localStorage.currentUser,
        to: localStorage.visitingUser,
        ip: "111.111.1.1",
        check_user_id: localStorage.currentUser,
        check_session_id: localStorage.session,
        type: type,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.sts === 1)
          toast.success("Request to unlock send successfully !");
        else if (res.sts === 3) toast.info("Already sent request to unlock");
        else toast.error("Error occured");
      });
  };

  pointing = (type) => {
    document.getElementById(type).scrollIntoView({
      behavior: "smooth",
    });
  };

  getPutCountryCode = (code, number) => {
    if (code != null && code != "" && number != null && number != "") {
      return code;
    } else {
      return "";
    }
  };

  render() {
    document.getElementsByClassName(
      "menu-item__profileDetails"
    ).scrollLeft = 1000;

    if (!this.state.isLoading) {
      return (
        <div
          style={{ scrollBehavior: "smooth" }}
          id="details"
          onScroll={this.scrolledPosition}
        >
          <Modal show={this.state.showModal} onHide={this.closeModal}>
            <EditDetails
              editInfo={this.state.InfoToEdit}
              countrycodes={countrycodesData}
              title={this.state.title}
              close={this.closeModal}
            ></EditDetails>
          </Modal>
          <Modal
            show={this.state.showupgradePlanModal}
            onHide={() => {
              this.setState({ showupgradePlanModal: false });
            }}
            className="logout-modal"
          >
            <Modal.Body>
              <Row className="align-items-center flex-column">
                <div
                  style={{ fontSize: "18px", color: "var(--primary-color)" }}
                  className="w-auto mb-3"
                >
                  This is a premium feature
                </div>
                <div
                  style={{ color: "#3a3838", fontSize: "14px" }}
                  className="w-auto mb-3"
                >
                  You need higher plan to access this feature
                </div>
                <Link
                  className="p-3"
                  to="/plans-and-payment"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "#FFF",
                    width: "auto",
                    border: "none",
                    borderRadius: "10px",
                  }}
                >
                  Upgrade Plan
                </Link>
              </Row>
            </Modal.Body>
          </Modal>
          <Card
            className="details-tab position-sticky pb-0"
            style={{ zIndex: "1" }}
          >
            <Row className="horizontal-scroll justify-content-between mx-0 ">
              <Scrollspy
                className="menu-item-parent__profileDetails"
                offset={-300}
                items={[
                  "basic-info",
                  "contact-info",
                  "job-education",
                  "physical-status",
                  "thoughts-lifestyle",
                  "family-details",
                  "partner-preference",
                ]}
                currentClassName="active-nav-item__profileDetails"
                style={{ marginBottom: "0", marginTop: "0" }}
              >
                <div
                  className="menu-item__profileDetails w-auto"
                  style={{
                    float: "left",
                    minWidth: "90px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => this.scroll("basic-info")}
                >
                  Basic Info
                </div>
                <div
                  className="menu-item__profileDetails w-auto"
                  style={{
                    float: "left",
                    minWidth: "105px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => this.scroll("contact-info")}
                >
                  Contact Info
                </div>
                <div
                  className="menu-item__profileDetails w-auto"
                  style={{
                    float: "left",
                    minWidth: "150px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => this.scroll("job-education")}
                >
                  Job and Education
                </div>
                <div
                  className="menu-item__profileDetails w-auto"
                  style={{
                    float: "left",
                    minWidth: "110px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => this.scroll("physical-status")}
                >
                  Physical Info
                </div>
                <div
                  className="menu-item__profileDetails w-auto"
                  style={{
                    float: "left",
                    minWidth: "150px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => this.scroll("thoughts-lifestyle")}
                >
                  Thoughts/Lifestyle
                </div>
                <div
                  className="menu-item__profileDetails w-auto"
                  style={{
                    float: "left",
                    minWidth: "120px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => this.scroll("family-details")}
                >
                  Family Details
                </div>
                <div
                  className="menu-item__profileDetails w-auto"
                  style={{
                    float: "left",
                    minWidth: "150px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => this.scroll("partner-preference")}
                >
                  Partner Preference
                </div>
              </Scrollspy>
            </Row>
          </Card>

          <Card className="profile-details" id="basic-info">
            <Card.Title className="details-title">Basic Info</Card.Title>
            <Col style={{ columnCount: "2" }} className="h-100 col-count">
              {basicInfo.map((value, i) => (
                <Row className="m-2" key={i}>
                  <Col
                    sm={2}
                    className="px-0 info-label"
                    style={{ width: "35%" }}
                  >
                    {value.key}
                  </Col>
                  <Col className="px-2">{value.value}</Col>
                </Row>
              ))}
            </Col>
          </Card>

          {localStorage.currentUser === localStorage.visitingUser ||
          this.state.contactLock == "0" ? (
            <Card className="profile-details" id="contact-info">
              <Card.Title className="details-title">Contact Info</Card.Title>
              {(contactInfo && isViewed === 2) ||
              this.state.viewCurrentContact ||
              localStorage.currentUser === localStorage.visitingUser ? (
                <>
                  {this.props.edit ? (
                    <Edit
                      edit={() => this.editDetails(contactInfo)}
                      title="Contact Info"
                    ></Edit>
                  ) : (
                    ""
                  )}

                  {/* {localStorage.currentUser === localStorage.visitingUser && (
                    <div className="completed-percentage">
                      {parseInt(
                        (contactInfo.filter((i) => i.value != "").length /
                          contactInfo.length) *
                        100
                      )}
                      % completed{" "}
                    </div>
                  )} */}
                  <Col style={{ columnCount: "2" }} className="h-100 col-count">
                    {contactInfo.map((value, i) =>
                      value.title ? (
                        <Row
                          key={i}
                          className="mx-2 info-label mb-2 pt-4"
                          style={{
                            marginTop:
                              value.title === "Living Address" ? "59px" : "8px",
                          }}
                        >
                          <span className="w-auto ps-0">
                            {value.title_icon}
                          </span>
                          {value.title}
                        </Row>
                      ) : value.key != "District" ||
                        (value.key == "District" &&
                          contactInfo[5].value.includes("104")) ? (
                        <Row className="m-2" key={i}>
                          <Col
                            sm={2}
                            className="px-0 info-label"
                            style={{ width: "35%" }}
                          >
                            {value.key}
                          </Col>
                          {value.name == "phone" ? (
                            this.getPutCountryCode(
                              countrycodesData.selectedPhone,
                              value.value
                            )
                          ) : (
                            <></>
                          )}
                          {value.name == "land" ? (
                            this.getPutCountryCode(
                              countrycodesData.selectedAlternate,
                              value.value
                            )
                          ) : (
                            <></>
                          )}
                          {value.name == "whatsappno" ? (
                            this.getPutCountryCode(
                              countrycodesData.selectedWhatsapp,
                              value.value
                            )
                          ) : (
                            <></>
                          )}
                          {value.listValue ? (
                            <Col className="px-2">
                              {value.listValue.filter(
                                (list) => value.value === list.id
                              )[0]?.name || value.value}
                            </Col>
                          ) : value.key === "Facebook ID" ? (
                            <Col className="px-2">
                              <div
                                rel="noreferrer"
                                title={value?.value}
                                href={value.value}
                                target="_blank"
                              >
                                {value?.value?.slice(0, 30) || ""}
                              </div>
                            </Col>
                          ) : value.key === "Instagram ID" ? (
                            <Col className="px-2">
                              <div
                                rel="noreferrer"
                                title={value?.value}
                                href={value.value}
                                target="_blank"
                              >
                                {value?.value?.slice(0, 30) || ""}
                              </div>
                            </Col>
                          ) : (
                            <Col className="px-2">{value.value}</Col>
                          )}
                        </Row>
                      ) : (
                        <></>
                      )
                    )}
                  </Col>
                </>
              ) : (
                <div className="align-items-center flex-column d-flex">
                  <p align="left" style={{ color: "#5B6770" }}>
                    You have {myPlan.balancecontact} contacts left to read.
                  </p>
                  {myPlan.balancecontact == 0 ? (
                    <Button
                      className="p-3"
                      onClick={() => {
                        this.setState({ showupgradePlanModal: true });
                      }}
                      // to="/plans-and-payment"
                      style={{
                        backgroundColor: "var(--primary-color)",
                        color: "#FFF",
                        width: "auto",
                        border: "none",
                        borderRadius: "10px",
                      }}
                    >
                      Upgrade Plan
                    </Button>
                  ) : (
                    <Button
                      className="view-contact-button"
                      onClick={this.viewContact}
                    >
                      View Contact
                    </Button>
                  )}
                </div>
              )}
            </Card>
          ) : (
            <Card
              className="profile-details"
              style={{ height: "auto" }}
              id="contact-info"
            >
              <Card.Title className="details-title">Contact Info</Card.Title>
              <div className="align-items-center flex-column d-flex">
                <p align="center" style={{ color: "#5B6770" }}>
                  {" "}
                  Contact info locked
                </p>
                {this.state.contactLock === "3" ? (
                  <p>You have already send request</p>
                ) : (
                  <Button
                    className="view-contact-button"
                    onClick={() => this.requestUnlock("contact")}
                  >
                    Request Unlock
                  </Button>
                )}
              </div>
            </Card>
          )}

          <Card className="profile-details" id="job-education">
            <Card.Title className="details-title">Job and Education</Card.Title>
            {this.props.edit ? (
              <Edit
                edit={() => this.editDetails(jobEducationInfo)}
                title="Job and Education"
              ></Edit>
            ) : (
              ""
            )}
            {/* {localStorage.currentUser === localStorage.visitingUser && (
              <div className="completed-percentage">
                {parseInt(
                  (jobEducationInfo.filter((i) => i.value != "").length /
                    jobEducationInfo.length) *
                  100
                )}
                % completed{" "}
              </div>
            )} */}
            <Row className="h-100 pt-2">
              <Col style={{ columnCount: "2" }} className="h-100 col-count">
                {jobEducationInfo.map((value, i) =>
                  value.title ? (
                    <Row key={i} className="m-2 info-label mb-2 pt-4">
                      <span className="w-auto ps-0">{value.title_icon}</span>
                      {value.title}
                    </Row>
                  ) : (
                    <Row key={i} className="m-2">
                      <Col
                        sm={2}
                        className="px-0 info-label"
                        style={{ width: "35%" }}
                      >
                        {value.key}
                      </Col>
                      {value.listValue ? (
                        <Col className="px-2">
                          {value.key === "Course" &&
                          !value.listValue.some(
                            (list) => list.id === value.value
                          )
                            ? ""
                            : value.listValue.filter(
                                (list) => value.value === list.id
                              )[0]?.name || value.value}{" "}
                        </Col>
                      ) : (
                        <Col className="px-2">{value.value}</Col>
                      )}
                    </Row>
                  )
                )}
              </Col>
            </Row>
          </Card>

          <Card className="profile-details" id="physical-status">
            <Card.Title className="details-title">Physical Info</Card.Title>
            {this.props.edit ? (
              <Edit
                edit={() => this.editDetails(physicalInfo)}
                title="Partner Preference"
              ></Edit>
            ) : (
              ""
            )}
            {/* {localStorage.currentUser === localStorage.visitingUser && (
              <div className="completed-percentage">
                {parseInt(
                  (physicalInfo.filter((i) => i.value != "").length /
                    physicalInfo.length) *
                  100
                )}
                % completed{" "}
              </div>
            )} */}
            <Row className="h-100 pt-2">
              <Col style={{ columnCount: "2" }} className="h-100 col-count">
                {physicalInfo.map((value, i) => (
                  <Row className="m-2" key={i}>
                    <Col
                      sm={2}
                      className="px-0 info-label"
                      style={{ width: "35%" }}
                    >
                      {value.key === "Physical Status" && value.value === "30"
                        ? "Physically Challenged"
                        : value.key}
                    </Col>
                    {value.key === "Physical Status" && value.value === "30" ? (
                      "No"
                    ) : value.listValue ? (
                      <Col className="px-0">
                        {value.listValue.filter(
                          (list) => value.value === list.id
                        )[0]?.name || value.label}{" "}
                      </Col>
                    ) : (
                      <Col className="px-2">{value.value}</Col>
                    )}
                  </Row>
                ))}
              </Col>
            </Row>
          </Card>

          <Card className="profile-details" id="thoughts-lifestyle">
            <Card.Title className="details-title">
              Thoughts/Lifestyle
            </Card.Title>
            {this.props.edit ? (
              <Edit
                edit={() =>
                  this.editDetails(thoughtsLifestyleInfo, "Thoughts/Lifestyle")
                }
              ></Edit>
            ) : (
              ""
            )}
            {/* {localStorage.currentUser === localStorage.visitingUser && (
              <div className="completed-percentage">
                {parseInt(
                  (thoughtsLifestyleInfo.filter((i) => i.value != "").length /
                    thoughtsLifestyleInfo.length) *
                  100
                )}
                % completed{" "}
              </div>
            )} */}
            <Row className="h-100 pt-2">
              <Col style={{ columnCount: "2" }} className="h-100 col-count">
                {thoughtsLifestyleInfo.map((value, i) =>
                  value.title ? (
                    <Row key={i} className="m-2 info-label mb-2 pt-4">
                      <span className="w-auto ps-0">{value.title_icon}</span>
                      {value.title}
                    </Row>
                  ) : value.key === "Allegiance" &&
                    thoughtsLifestyleInfo[1].value != "5-bypassAllegience" ? ( // Allegiance removed
                    <Row className="m-2" />
                  ) : (
                    <Row key={i} className="m-2">
                      <Col
                        sm={2}
                        className="px-0 info-label"
                        style={{ width: "35%" }}
                      >
                        {value.key}
                      </Col>
                      {value.listValue ? (
                        value.name === "hobbies" ? (
                          <Col className="px-0">
                            {value.listValue
                              .filter((list) => value.value.includes(list.id))
                              .map((item) => `${item.name}, `)}{" "}
                          </Col>
                        ) : (
                          <Col className="px-0">
                            {value.listValue.filter(
                              (list) => value.value === list.id
                            )[0]?.name || value.value}{" "}
                          </Col>
                        )
                      ) : (
                        <Col className="px-2">{value.value}</Col>
                      )}
                    </Row>
                  )
                )}
              </Col>
            </Row>
          </Card>

          <Card className="profile-details" id="family-details">
            <Card.Title className="details-title">Family Details</Card.Title>

            {this.props.edit ? (
              <Edit
                edit={() =>
                  this.editDetails(familyDetailsInfo, "Family Details")
                }
              ></Edit>
            ) : (
              ""
            )}
            {/* {localStorage.currentUser === localStorage.visitingUser && (
              <div className="completed-percentage">
                {parseInt(
                  (familyDetailsInfo.filter((i) => i.value != "").length /
                    familyDetailsInfo.length) *
                  100
                )}
                % completed{" "}
              </div>
            )} */}
            <Row className="h-100 pt-2">
              <Col style={{ columnCount: "2" }} className="h-100 col-count">
                {familyDetailsInfo.map((value, i) => (
                  <Row className="m-2" key={i}>
                    <Col
                      sm={2}
                      className="px-0 info-label"
                      style={{ width: "35%" }}
                    >
                      {value.name === "father_occupation" &&
                      familyDetailsInfo[2].value === "No" ? (
                        <></>
                      ) : value.name === "mother_occupation" &&
                        familyDetailsInfo[4].value === "No" ? (
                        <></>
                      ) : value.name === "is_orphan" &&
                        familyDetailsInfo[2].value === "Yes" ? (
                        <></>
                      ) : (
                        value.key
                      )}
                    </Col>
                    {value.listValue ? (
                      <Col className="px-2">
                        {value.name === "father_occupation" &&
                        familyDetailsInfo[2].value === "No" ? (
                          <></>
                        ) : value.name === "mother_occupation" &&
                          familyDetailsInfo[4].value === "No" ? (
                          <></>
                        ) : value.name === "is_orphan" &&
                          familyDetailsInfo[2].value === "Yes" ? (
                          <></>
                        ) : (
                          value.listValue.filter(
                            (list) => value.value === list.id
                          )[0]?.name || value.value
                        )}
                        {/* value.listValue.filter(
                          (list) => value.value === list.id
                        )[0]?.name || value.value */}
                      </Col>
                    ) : (
                      <Col className="px-2">{value.value}</Col>
                    )}
                  </Row>
                ))}
              </Col>
            </Row>
          </Card>

          <Card className="profile-details" id="partner-preference">
            <Card.Title className="details-title">
              Partner Preference
            </Card.Title>
            {this.props.edit ? (
              <Edit
                edit={() =>
                  this.editDetails(preferenceInfo, "Partner Preference")
                }
              ></Edit>
            ) : (
              ""
            )}
            <Row className="h-100 pt-2">
              <Col style={{ columnCount: "2" }} className="h-100 col-count">
                {preferenceInfo.map((value, i) =>
                  value.key != "District" ||
                  (value.key == "District" &&
                    preferenceInfo[3].value.includes("India")) ? (
                    <Row className="m-2" key={i}>
                      <Col
                        sm={2}
                        className="px-0 info-label"
                        style={{ width: "35%" }}
                      >
                        {value.key}
                      </Col>
                      <Col className="px-2">
                        {value.name === "height" || value.name === "weight"
                          ? value.label?.replaceAll(null, "")
                          : value.value?.replaceAll(null, "")}
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )
                )}
              </Col>
            </Row>
          </Card>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
