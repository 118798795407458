import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import reportProfileService from '../../Services/ReportProfile'

export default function UpgradeModal(props) {
    const [reason, setReason] = useState('')
    const [remark, setRemark] = useState('')
    const [error, setError] = useState(false)

    let reportProfile = async () => {
        if (reason) {
            await reportProfileService(localStorage.visitingUser, reason, remark).then(res => {
                toast.success('reported successfully')
                setReason('')
                setRemark('')
                props.success()
            }).catch(err => {
                toast.error('An error occured while reporting')
                setReason('')
                setRemark('')
                props.closeModal()
            })
        }
        else {
            setError(true)
        }
    }

    return (
        <Modal show={props.ReportNow} onHide={props.closeModal} className="d-flex flex-column align-items-center">
            <Modal.Title className='title d-flex justify-content-center'>Report Profile {props?.profile}?</Modal.Title>
            <Modal.Body>
                <div style={{ color: 'red', fontSize: '12px' }} className='mb-5'>If information given while reporting a profile is wrong, your profile will be freezed</div>
                <Form.Group className='mb-4'>
                    <Form.Label>Reason</Form.Label>
                    <Form.Select as='select' value={reason} onChange={(e) => { setReason(e.target.value); setError(false) }}>
                        <option value="" selected></option>
                        <option value="Already Married">Already married</option>
                        <option value="Address not correct">Address not correct</option>
                        <option value="Bad behaviour">Bad behaviour</option>
                        <option value="Phone not attending">Phone not attending</option>
                        <option value="Others">Others</option>
                    </Form.Select>
                    {error ? <div style={{ color: 'red', fontSize: '14px' }}>Please choose a reason</div> : ''}
                </Form.Group>
                <Form.Group className='mb-4'>
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control value={remark} onChange={(e) => setRemark(e.target.value)} />
                </Form.Group>
                <div className='d-flex justify-content-end'>
                    <Button className='w-auto me-4' onClick={() => { setReason(''); setRemark(''); props.closeModal() }}
                        style={{ color: '#000', backgroundColor: '#FFF', borderColor: '#5B6770' }}>Cancel</Button>
                    <Button className='w-auto' style={{ color: '#FFF', backgroundColor: 'red', border: 'none' }} onClick={reportProfile}>Report</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}