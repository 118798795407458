import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { MdVerified, MdCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { NoImage, ProtectedImage } from "..";
import { environment } from "../../environment/env";
import "./RoundedProfile.css";

const imageURL = environment.imageURL;
let image;

export default function RoundedProfile(props) {
  let navigate = useNavigate();
  let showProfile = () => {
    localStorage.setItem("visitingUser", props?.profile?.id);
    navigate("/profile");
  };
  if (props?.profile?.face_image) image = imageURL + props?.profile?.face_image;
  else if (
    props?.profile?.gender === "Male" ||
    localStorage.gender === "Female"
  )
    image = imageURL + "avatar_Male.png";
  else image = imageURL + "avatar_Female.png";

  if (
    (props?.profile?.album_visibility == "55" && localStorage.plan == "2") ||
    props?.profile?.album_visibility == "56"
  ) {
    if (props?.profile?.gender === "Male" || localStorage.gender === "Female") {
      image = imageURL + "avatar_Male.png";
    } else {
      image = imageURL + "avatar_Female.png";
    }
  }

  return (
    <div onClick={showProfile} style={{ cursor: "pointer" }}>
      <Row className="justify-content-center position-relative">
        <Image
          src={image}
          roundedCircle
          alt=""
          style={{ height: "136px", width: "160px" }}
        />
        {props?.profile?.album_visibility === "56" ? (
          <ProtectedImage
            from={null}
            id={props?.profile?.id}
            requestButton={false}
          ></ProtectedImage>
        ) : !props?.profile?.face_image ? (
          <NoImage
            status={props?.profile?.non_image_status}
            id={props?.profile?.id}
          ></NoImage>
        ) : (
          ""
        )}
      </Row>
      <Row className="profileName m-0 justify-content-center mt-2">
        <Col className="p-0 d-flex align-items-center justify-content-center">
          {props?.profile?.id}
          <MdVerified
            width="22px"
            size="24px"
            className="mx-1"
            color={
              props?.profile.verified_badge == "Y"
                ? "var(--secondary-color)"
                : "#C4C4C4"
            }
          />
          <MdCircle
            size="11px"
            color={
              new Date(props?.profile?.lastlogin_new + " UTC").toDateString() !=
              new Date().toDateString()
                ? "#C4C4C4"
                : new Date(props?.profile?.lastlogin_new + " UTC").getHours() !=
                  new Date().getHours()
                ? "#C4C4C4"
                : new Date().getMinutes() - 5 <=
                    new Date(
                      props?.profile?.lastlogin_new + " UTC"
                    ).getMinutes() &&
                  new Date(
                    props?.profile?.lastlogin_new + " UTC"
                  ).getMinutes() <= new Date().getMinutes()
                ? "#00BE63"
                : "#C4C4C4"
            }
          />
        </Col>
      </Row>
      <Row className="m-0 justify-content-center">
        {props?.profile?.age} yrs, {props?.profile?.place}{" "}
      </Row>
    </div>
  );
}
