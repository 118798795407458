import { environment } from "../environment/env"

const baseURL = environment.hostname

let removeInterestService = async (profile, tab) => {
    return await fetch(`${baseURL}/removeinterest`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: tab === 'Recieved' ? profile : localStorage.currentUser,
            to: tab === 'Recieved' ? localStorage.currentUser : profile,
            check_user_id: localStorage.currentUser,
            check_session_id: localStorage.session
        }),
    })
}

let acceptRejectInterestService = async (profile, status) => {
    return await fetch(`${baseURL}/express_interest_status`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            to: localStorage.currentUser,
            from: profile,
            check_session_id: localStorage.session,
            check_user_id: localStorage.currentUser,
            status: status
        }),
    })
}

export {
    removeInterestService,
    acceptRejectInterestService
}