import "bootstrap/dist/css/bootstrap.min.css";
import { toast, ToastContainer } from "react-toastify";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Home from "./Views/Home/Home";
import Matches from "./Views/Matches/Matches";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Views/Login/Login";
import Register from "./Views/Register/Register";
import ProfileView from "./Views/MyProfile/MyProfile";
import Dashboard from "./Views/Dashboard/Dashboard";
import Search from "./Views/Search/Search";
import SearchResult from "./Views/SearchResult/SearchResult";
import Request from "./Views/Requests/Request";
import Messages from "./Views/Messages/Messages";
import UserProfile from "./Views/UserProfile/UserProfile";
import { ManagePhotos, WeddingStores } from "./Components";
import VideoSchedule from "./Views/VideoSchedule/VideoSchedule";
import PlansAndPayment from "./Views/Plans&Payment/Plans&Payment";
import "react-toastify/dist/ReactToastify.css";
import ProfileSettings from "./Views/ProfileSettings/ProfileSettings";
import SuccessStories from "./Views/SuccessStories/SuccessStories";
import Contact from "./Views/Contact/Contact";
import ProtectedRoute from "./ProtectedRoute";
import Feedback from "./Views/Feedback/Feedback";
import IdVerification from "./Views/IdVerification/IdVerification";
import "./App.css";
import { useRef } from "react";
import PlanHistory from "./Views/PlanHistory/PlanHistory";
import NotificationHisMob from "./Views/NotificationHistory/NotificationHisMob";

toast.configure();

function App() {
  const tawkMessengerRef = useRef();
  document.documentElement.style.setProperty("--primary-color", "#662666");
  document.documentElement.style.setProperty("--secondary-color", "#FFCC00");

  return (
    <Router>
      <ToastContainer
        autoClose={3000}
        position="top-center"
        className="toast-container"
        toastClassName="dark-toast"
      />

      <Routes>
        <Route exact path="/" index element={<Home />} />
        <Route exact path="login" element={<Login />} />
        <Route exact path="register" element={<Register />} />
        <Route exact path="register/:code" element={<Register />} />
        <Route exact path="contact" element={<Contact />} />
        <Route
          exact
          path="feedback"
          element={
            localStorage.getItem("currentUser") && localStorage.session ? (
              <Feedback />
            ) : (
              <Contact />
            )
          }
        />
        <Route exact path="plans-and-payment" element={<PlansAndPayment />} />
        <Route element={<ProtectedRoute />}>
          <Route exact path="matching" element={<Matches />} />
          <Route exact path="myprofile" element={<ProfileView />} />
          <Route exact path="profile" element={<UserProfile />} />
          <Route exact path="dashboard" element={<Dashboard />} />
          <Route exact path="search" element={<Search />} />
          <Route exact path="search/results" element={<SearchResult />} />
          <Route
            exact
            path="express-interest"
            element={<Request page="interest" />}
          />
          <Route
            exact
            path="album-request"
            element={<Request page="album" />}
          />
          <Route exact path="messages" element={<Messages />} />
          <Route exact path="messages/:id" element={<Messages />} />
          <Route exact path="video-schedule" element={<VideoSchedule />} />
          <Route
            exact
            path="notification-history"
            element={<NotificationHisMob />}
          />
          <Route exact path="show/:page" element={<Request />} />
          <Route
            exact
            path="myprofile/album-upload"
            element={<ManagePhotos />}
          />
          <Route exact path="wedding-stores" element={<WeddingStores />} />

          <Route exact path="profile-settings" element={<ProfileSettings />} />
          <Route
            exact
            path="success-stories/:story"
            element={<SuccessStories />}
          />
          <Route exact path="feedback" element={<Feedback />} />
          <Route exact path="Id-verification" element={<IdVerification />} />
          <Route exact path="plan-history" element={<PlanHistory />} />
        </Route>

        <Route
          path="*"
          element={
            <Navigate
              to={localStorage.getItem("currentUser") ? "/matching" : "/"}
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
