import React, { Component } from 'react'
import { Image } from 'react-bootstrap'
import { BackToTopIcon } from '../../assets'
import { RiWhatsappLine } from "react-icons/ri";
import './WhatsappChat.css'
import whatsappLogo from '../../../src/assets/Whatsapp_support.png';
import ReactTooltip from "react-tooltip";


export default function WhatsappChat() {

    let scrollToTop = () => {
        window.open(`https://wa.me/9645004140`);
    }

    return (
        <div>
            <div className='whatsapp-chat-component d-flex justify-content-center align-items-center' onClick={scrollToTop}
            data-tip
            data-for="help-whatsapp">
                {/* <Image src={BackToTopIcon} className='whatsapp-icon' alt='Go to top' /> */}
                {/* <RiWhatsappLine size="30px" style={{ color: '#fff' }} /> */}
                <img src={whatsappLogo} style={{width:"40px",height:"auto",cursor:"pointer"}}/>
            </div>
            <ReactTooltip
                    className="tooltipClass"
                    id="help-whatsapp"
                    place="bottom"
                    type="light"
                  >
                    <span>Help</span>
                  </ReactTooltip>

        </div>
    )

}
