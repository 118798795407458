import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function UpgradeModal(props) {
  return (
    <Modal
      show={props.upgradeNow}
      onHide={props.closeModal}
      className="flex-column align-items-center md_box home"
      style={{ zIndex: "10000" }}
    >
      <Modal.Title className="title d-flex justify-content-center ">
        Upgrade Your Plan
      </Modal.Title>
      <Modal.Body>
        You have a free plan now. Upgrade your plan to enjoy this feature
      </Modal.Body>
      <Link
        onClick={(e) => {
          e.stopPropagation();
        }}
        to="/plans-and-payment"
        className="btn"
        style={{
          backgroundColor: "var(--primary-color)",
          color: "#FFF",
          width: "auto",
          border: "none",
        }}
      >
        Upgrade Now
      </Link>
    </Modal>
  );
}
