import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { BsArrowRightCircle } from 'react-icons/bs'
import './SearchByCard.css'

export default class SearchByCard extends Component {
    render() {
        return (
            <Card className='search-by-card h-100 '>
                <h5>Search By </h5>
                <div className='search-by-body'>
                    <Row style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => this.props.searchBy('Advanced Search')}>
                        <Col className='col-auto px-0'><BsArrowRightCircle color='var(--primary-color)' size='14px' /></Col>
                        <Col>Advanced Search</Col>
                    </Row>
                    <Row style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => this.props.searchBy('Keyword Search')}>
                        <Col className='col-auto px-0'><BsArrowRightCircle color='var(--primary-color)' size='14px' /></Col>
                        <Col>Keyword Search</Col>
                    </Row>
                    <Row style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => this.props.searchBy('Id Search')}>
                        <Col className='col-auto px-0'><BsArrowRightCircle color='var(--primary-color)' size='14px' /></Col>
                        <Col>Id Search</Col>
                    </Row>
                </div>
            </Card>
        )
    }
}
